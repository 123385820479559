import React, { Component, } from 'react';
// import PropTypes from 'prop-types';
import styled from "styled-components";
import "../css/slidebar.css";
import axios from "axios";
import config from '../config'
import { Dropdown, Radio } from 'semantic-ui-react'
import { CaretRightOutlined, } from '@ant-design/icons';
import { connect, } from 'react-redux';
import { changMapView, changDrawType, selectStatData, selectCategoryData, selectFilterStatData, changeProvinceMode, showModal, layersuccess } from '../redux/actions'
import { data } from 'jquery';

const DivTreeLV = styled.div`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: ${props => props.marginLeft};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${props => props.cate ? props.selectedCateToStat ? '#23898E' : props.selectedCate ? '#51b6bb' : 'black' : props.selectedStat ? '#23898E' : '#007bff'}
`;
const TreeCaret = styled(CaretRightOutlined)`
  font-size: ${props => props.fontSize};
  padding-right: 5px;
`;

const idMainCateNotSchemaPNSO = [
  "63a4c50f-be23-4d6f-a78d-31f84702eddb",
  "c9ae3fa6-142c-449b-a6e1-cd7066c9202d",
  "2056b7ae-66d1-480e-b3eb-6560a09e2c4f",
  "1f04833d-c48c-49a9-8689-eb05d08009a0",
  "8697cff4-294a-4761-b6a0-7ebc1ade6ea4",
  "31b102ef-9fd8-4a23-88dd-5343eb358916",
  "5ad009e2-44b3-4f8b-92cf-edc11308549c",
  "836918d9-25d4-48ee-a941-a32c42079150",
  "c2646678-d992-481a-a77e-4a76fb72d55c",
  "00a71ad9-97b3-4cfa-8996-9f971e849113",
  "2498b688-a39e-4dde-8d20-96150a39c7e2",
  "ee62e346-61b1-431b-b421-ec934d53ed88",
  "d832d856-d17d-4e12-856e-3f1315ab3987",
  "41603d77-5335-4c6b-a978-a354bd9b04e6", //ข้อมูลพื้นฐฐาน
  "35db1d68-4e34-45aa-86f0-eaf68c6b95c0",
  "617b6e8-d947-4557-b248-6bca9bcb8174",
  "d0473627-d58a-4b3b-8f8e-1b79c1f43a6b",
  "60e3b661-ad2f-4505-b645-1c0e10e78706",
  "d6883269-0806-47a6-8d46-25e18f867758",
  "8617b6e8-d947-4557-b248-6bca9bcb8174"

];

class DataList extends Component {

  state = {
    mainCategoryData: [],
    cateDataLevel1: [],
    cateDataLevel2: [],
    cateDataLevel3: [],
    cateDataLevel4: [],
    statData1: [],
    statData2: [],
    statData3: [],
    statData4: [],
    statData5: [],

    areaArrayState: [],
    showTreeLV1: false,
    showTreeLV2: false,
    showTreeLV3: false,
    showTreeLV4: false,
    showTreeLV5: false,
  };

  componentDidMount() {
    // console.log('componentDidMount main datalist >>>')
    const { pathSchema, provinceMode, } = this.props
    // console.log('props in datalist >>>', pathSchema);
    let nameSchemaTemp = provinceMode.NAME_SCHEMA_MODE;
    if (pathSchema) {
      // โหมดจังหวัด
      if (provinceMode.NAME_SCHEMA_PROVINCE === '') {
        nameSchemaTemp = pathSchema
      }
    }
    // console.log('this.props.loginData in datalist >>>', this.props.loginData);
    let configHeaders = {
      'Authorization': 'Bearer ' + this.props.loginData.token,
      'NAME_SCHEMA': nameSchemaTemp
    }
    if (provinceMode.provinceModeOpen) {
      configHeaders = {
        'NAME_SCHEMA': nameSchemaTemp
      }
    }
    axios({
      method: 'post',
      url: config.API_URL + '/api/gis/gisnsocate',
      headers: configHeaders
    }).then((res) => {
      // console.log("response get cate >>>", res)
      let tempDataMainCate = [];
      res.data.map((data, index) => {
        tempDataMainCate.push({ key: index, value: data.ID, text: data.CATEGORIZATION_NAME, description: data.COUNT + ' หมวด', allrawdata: data })
      })
      this.setState({ mainCategoryData: tempDataMainCate, selectedMainCateID: tempDataMainCate[0].value, })
      this.handleGetCateLevel1(tempDataMainCate[0].value)
    }).catch((error) => {
      console.error("error get cate >>>", error)
    })
  }

  // ---------- เลือกและ getdata หมวดหมู่  ----------
  handleGetCateMain = () => {
    const { pathSchema, provinceMode, } = this.props
    console.log('props in datalist >>>', pathSchema);
    let nameSchemaTemp = provinceMode.NAME_SCHEMA_MODE;
    if (pathSchema) {
      // โหมดจังหวัด
      if (provinceMode.NAME_SCHEMA_PROVINCE === '') {
        nameSchemaTemp = pathSchema
      }
    }
    // console.log('this.props.loginData in datalist >>>', this.props.loginData);
    let configHeaders = {
      'Authorization': 'Bearer ' + this.props.loginData.token,
      'NAME_SCHEMA': nameSchemaTemp
    }
    if (provinceMode.provinceModeOpen) {
      configHeaders = {
        'NAME_SCHEMA': nameSchemaTemp
      }
    }
    axios({
      method: 'post',
      url: config.API_URL + '/api/gis/gisnsocate',
      headers: configHeaders
    }).then((res) => {
      // console.log("response get cate >>>", res)
      let tempDataMainCate = [];
      res.data.map((data, index) => {
        tempDataMainCate.push({ key: index, value: data.ID, text: data.CATEGORIZATION_NAME, description: data.COUNT + ' หมวด', allrawdata: data })
      })
      this.setState({ mainCategoryData: tempDataMainCate, })
    }).catch((error) => {
      console.error("error get cate >>>", error)
    })
  }
  handleSelectCateMain = (dataCateMain) => {
    console.log('dataCateMain in handleSelectCateMain >>>', dataCateMain)
    let value = dataCateMain.value;
    let selectedData = dataCateMain.options.find((whichData) => { return whichData.value === value })
    let text = selectedData.text
    this.setState({
      selectedMainCateID: value,
      cateDataLevel1: [],
      statData1: [],
    })
    this.props.setSelectCategoryData({ id: value, name: text, level: '0' })
    this.handleGetCateLevel1(value)
  }
  handleGetCateLevel1 = (selectedMainCateID) => {
    try {
      axios({
        method: 'post',
        url: config.API_URL + '/api/gis/gisnsocatestat',
        headers: {
          //'Authorization': this.state.tokenWithBearer,
          'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
        },
        data: {
          'LEVEL1': selectedMainCateID,
        },
        config: { headers: { 'Content-Type': 'multipart/form-data', } }
      }).then((res) => {
        // console.log("response get cate lv1 >>>", res)
        if (res.data.statlist.length === 0) {
          this.setState({ cateDataLevel1: res.data.category, })
          // console.log('cateDataLevel1 >>',this.state.cateDataLevel1)
        } else {
          this.setState({ statData1: res.data.statlist, })
          // console.log('cateDataLevel1 >>',this.state.cateDataLevel1)
        }
      }).catch((error) => {
        console.error("error get cate lv1 >>>", error)
      })
    } catch (error) {
      console.error(error);
    }
  }
  handleGetCateLevel2 = (selectedLevel1CateID) => {
    try {
      axios({
        method: 'post',
        url: config.API_URL + '/api/gis/gisnsocatestat',
        headers: {
          //'Authorization': this.state.tokenWithBearer,
          'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
        },
        data: {
          'LEVEL1': this.state.selectedMainCateID,
          'LEVEL2': selectedLevel1CateID,
        },
        config: { headers: { 'Content-Type': 'multipart/form-data', } }
      }).then((res) => {
        console.log("response get cate lv2 >>>", res)
        if (res.data.statlist.length === 0) {
          this.setState({ cateDataLevel2: res.data.category, })
        } else {
          this.setState({ statData2: res.data.statlist, })
        }
      }).catch((error) => {
        console.error("error get cate lv2 >>>", error)
      })
    } catch (error) {
      console.error(error);
    }
  }
  handleGetCateLevel3 = (selectedLevel2CateID) => {
    try {
      axios({
        method: 'post',
        url: config.API_URL + '/api/gis/gisnsocatestat',
        headers: {
          //'Authorization': this.state.tokenWithBearer,
          'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
        },
        data: {
          'LEVEL1': this.state.selectedMainCateID,
          'LEVEL2': this.state.selectedLevel1CateID,
          'LEVEL3': selectedLevel2CateID,
        },
        config: { headers: { 'Content-Type': 'multipart/form-data', } }
      }).then((res) => {
        console.log("response get cate lv3 >>>", res)
        if (res.data.statlist.length === 0) {
          this.setState({ cateDataLevel3: res.data.category, })
        } else {
          this.setState({ statData3: res.data.statlist, })
        }
      }).catch((error) => {
        console.error("error get cate lv3 >>>", error)
      })
    } catch (error) {
      console.error(error);
    }
  }
  handleGetCateLevel4 = (selectedLevel3CateID) => {
    try {
      axios({
        method: 'post',
        url: config.API_URL + '/api/gis/gisnsocatestat',
        headers: {
          //'Authorization': this.state.tokenWithBearer,
          'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
        },
        data: {
          'LEVEL1': this.state.selectedMainCateID,
          'LEVEL2': this.state.selectedLevel1CateID,
          'LEVEL3': this.state.selectedLevel2CateID,
          'LEVEL4': selectedLevel3CateID,
        },
        config: { headers: { 'Content-Type': 'multipart/form-data', } }
      }).then((res) => {
        console.log("response get cate lv4 >>>", res)
        if (res.data.statlist.length === 0) {
          this.setState({ cateDataLevel4: res.data.category, })
        } else {
          this.setState({ statData4: res.data.statlist, })
        }
      }).catch((error) => {
        console.error("error get cate lv4 >>>", error)
      })
    } catch (error) {
      console.error(error);
    }
  }
  handleSelectCateLevel = (levelSelect, dataLevelSelect) => {
    switch (levelSelect) {
      case 1:
        this.setState({
          selectedLevel1CateID: dataLevelSelect.ID,
          cateDataLevel2: [],
          statData2: [],
        })
        this.props.setSelectCategoryData({ id: dataLevelSelect.ID, name: dataLevelSelect.CATE_NAME, level: '1' })
        this.handleGetCateLevel2(dataLevelSelect.ID)
        break;
      case 2:
        this.setState({
          selectedLevel2CateID: dataLevelSelect.ID,
          cateDataLevel3: [],
          statData3: [],
        })
        this.props.setSelectCategoryData({ id: dataLevelSelect.ID, name: dataLevelSelect.CATE_NAME, level: '2' })
        this.handleGetCateLevel3(dataLevelSelect.ID)
        break;
      case 3:
        this.setState({
          selectedLevel3CateID: dataLevelSelect.ID,
          cateDataLevel4: [],
          statData4: [],
        })
        this.props.setSelectCategoryData({ id: dataLevelSelect.ID, name: dataLevelSelect.CATE_NAME, level: '3' })
        this.handleGetCateLevel4(dataLevelSelect.ID)
        break;
      case 4:
        this.setState({
          selectedLevel4CateID: dataLevelSelect.ID,
          statData5: [],
        })
        this.props.setSelectCategoryData({ id: dataLevelSelect.ID, name: dataLevelSelect.CATE_NAME, level: '4' })
        this.handleGetStat(dataLevelSelect.ID)
        break;
      default:
        break;
    }
  }

  // ---------- เลือกและ getdata stat ----------
  handleGetStat = (selectedLevel4CateID) => {
    try {
      axios({
        method: 'post',
        url: config.API_URL + '/api/gis/gisnsocatestat',
        headers: {
          //'Authorization': this.state.tokenWithBearer,
          'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
        },
        data: {
          'LEVEL1': this.state.selectedMainCateID,
          'LEVEL2': this.state.selectedLevel1CateID,
          'LEVEL3': this.state.selectedLevel2CateID,
          'LEVEL4': this.state.selectedLevel3CateID,
          'LEVEL5': selectedLevel4CateID,
        },
        config: { headers: { 'Content-Type': 'multipart/form-data', } }
      }).then((res) => {
        console.log("response get stat >>>", res)
        this.setState({ statData5: res.data.statlist, })
        let example_response_data = {
          category: [],
          statlist: [
            {
              CATE_ID: "c0b3c9db-95bb-4163-847d-3976d03685a1",
              CONFIG_HIGHLIGHT: null,
              CREATED_BY: null,
              CREATED_DATE: "2020-03-23 12:40:56+07",
              ID: "9256fce2-9de5-4ac5-8e7d-e548a4efdbb9",
              ID_MAP: "3c0e8ff7-d4b7-49dd-8a18-bdecfe1d00df",
              PIN_HIGHLIGHT: 0,
              PUBLISH: 1,
              STATUS: 1,
              STAT_LIST_COUN: 2,
              STAT_LIST_ID: "pop_24",
              STAT_LIST_NAME: "จำนวนประชากรที่พิการอายุ 15 ปีขึ้นไป ที่มีงานทำ",
              STAT_TYPE_ID: null,
              TAGS: null,
              TAG_ID: null,
              UPDATED_BY: null,
              UPDATED_DATE: null,
            },
          ],
        }
      }).catch((error) => {
        console.error("error get stat >>>", error)
      })
    } catch (error) {
      console.error(error);
    }
  }
  handleSelectStat = async (selectedStatAllData) => {
    console.log('selectedStatAllData >>>', selectedStatAllData)
    let checkForSchemaPNSO = idMainCateNotSchemaPNSO.find((whichData) => { return whichData === this.state.selectedMainCateID })
    if (checkForSchemaPNSO) {
      console.log('change to main schema >>>')
      await this.props.setChangeProvinceMode({ mode: 'change', provinceModeOpen: false, })
    }
    try {

      this.props.setModal({
        modalAction: 'open',
        title: 'กำลังโหลดข้อมูล... กรุณารอสักครู่...',
        icon: 'loading',

      })
      let selectedStatID = selectedStatAllData.ID_MAP
      this.setState({
        selectedLevel1CateIDNew: this.state.selectedLevel1CateID,
        selectedLevel2CateIDNew: this.state.selectedLevel2CateID,
        selectedLevel3CateIDNew: this.state.selectedLevel3CateID,
        selectedLevel4CateIDNew: this.state.selectedLevel4CateID,
        selectedStatID: selectedStatAllData.ID_MAP
      })
      this.props.setSelectStatData({
        statName: selectedStatAllData.STAT_LIST_NAME,
        statId: selectedStatAllData.ID_MAP,
        statCodeId: selectedStatAllData.STAT_LIST_ID,
      })

      // -------------------- get years
      var promiseYear = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisfrequencycontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat year filter >>>", res)
          let responseData = res.data
          let yearArray = []
          let periodTypesArray = []
          let periodTypesQArray = []
          let periodTypesMArray = []
          let responseDataQ = responseData.freq_q
          let responseDataM = responseData.freq_m
          if (responseDataQ) { responseDataQ.forEach(item => { periodTypesQArray.push({ id: item, label: item, allRawData: item, }) }) }
          if (responseDataM) { responseDataM.forEach(item => { periodTypesMArray.push({ id: item, label: item, allRawData: item, }) }) }
          responseData.year.forEach((item) => { yearArray.push({ id: item, label: item, allRawData: item, }) })
          responseData.freq.forEach((item) => {
            if (item.FREQ_NAME === 'รายไตรมาส' && responseDataQ) {
              periodTypesArray.push({ id: item.FREQ_ID, label: item.FREQ_NAME, child: periodTypesQArray, allRawData: item, })
            } else if (item.FREQ_NAME === 'รายเดือน' && responseDataM) {
              periodTypesArray.push({ id: item.FREQ_ID, label: item.FREQ_NAME, child: periodTypesMArray, allRawData: item, })
            } else {
              periodTypesArray.push({ id: item.FREQ_ID, label: item.FREQ_NAME, child: [], allRawData: item, })
            }
          })
          this.props.setSelectFilterStatData({ updateFilter: 'years', dataArrey: yearArray, })
          this.props.setSelectFilterStatData({ updateFilter: 'period_types', dataArrey: periodTypesArray, })

          // ทำการ default ค่าเริ่มต้นลง redux
          if (periodTypesArray.length === 0) {
            this.props.setSelectStatData({ time: { year: yearArray[0], periodType: periodTypesArray[0], periodSelect: '' } })
          } else {
            this.props.setSelectStatData({ time: { year: yearArray[0], periodType: periodTypesArray[0], periodSelect: periodTypesArray[0].child[0] } })
          }
          resolve('promise Year ready')
        }).catch((error) => {
          console.error("error get stat year filter >>>", error)
          reject(Error('promise Year fail'))
        })
      });

      // -------------------- get area
      var promiseArea = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisareacontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat area filter >>>", res)
          let responseData = res.data
          let areaArray = []
          this.setState({ areaArrayState: responseData, })
          responseData.map((item) => {
            areaArray.push({ id: item.ID, label: item.NAME, code: item.CODE_NAME, allRawData: item, })
          })
          this.props.setSelectFilterStatData({ updateFilter: 'areas', dataArrey: areaArray, })

          // ทำการ default ค่าเริ่มต้นลง redux
          this.props.setSelectStatData({ area: areaArray[0] })
          resolve('promise Area ready')
        }).catch((error) => {
          console.error("error get stat area filter >>>", error)
          reject(Error('promise Area fail'))
        })
      });

      // -------------------- get typemap
      var promiseTypeMap = new Promise((resolve, reject) => {
        promiseArea.then((value) => {
          console.log('value after promiseArea >>>', value)
          if (value === 'promise Area ready') {
            // จัดรูปแบบของ ข้อมูลระดับพื้นที่ให้เป็น array ก่อนทำการ foreeach ไปยิง api เอาข้อมูล
            let areaArray = this.state.areaArrayState
            let areaNewArray = []
            let typeMapPromiseArray = []
            let typeMapArray = []
            areaArray.forEach((itemArea) => {
              let typeMapName =
                itemArea.CODE_NAME === 'AR_CL_REG'
                  ? 'reg'
                  : itemArea.CODE_NAME === 'AR_CL_CWT'
                    ? 'prov'
                    : itemArea.CODE_NAME === 'AR_CL_AMP'
                      ? 'dist'
                      : itemArea.CODE_NAME === 'AR_CL_TAM'
                        ? 'subdist'
                        : itemArea.CODE_NAME === 'AR_CL_SAO'
                          ? 'abt'
                          : itemArea.CODE_NAME === 'AR_CL_MUNI'
                            ? 'muni'
                            : ''
              areaNewArray.push({ typeMapName: typeMapName, id: itemArea.ID, label: itemArea.NAME, code: itemArea.CODE_NAME, allRawData: itemArea, })
            })
            areaNewArray.forEach((itemArea) => {
              let promiseEachTypeMap = new Promise((subresolve, subreject) => {
                axios({
                  method: 'post',
                  url: config.API_URL + '/api/geo/getmapdominance',
                  headers: {
                    'Authorization': 'Bearer ' + this.props.loginData.token,
                    'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
                  },
                  data: { name_check: itemArea.typeMapName },
                  config: { headers: { 'Content-Type': 'multipart/form-data', } }
                }).then((res) => {
                  console.log("response get stat typemap filter >>>", itemArea.typeMapName, ' response >>>', res)
                  let responseData = res.data
                  let typeMapEachArray = []
                  responseData.map((item) => {
                    typeMapEachArray.push({ id: item.ID, label: item.NAME, code: item.TABLE_NAME, allRawData: item, })
                  })
                  typeMapArray.push({ id: itemArea.id, label: itemArea.label, code: itemArea.code, child: typeMapEachArray, allRawData: itemArea.allRawData, })

                  // ทำการ default ค่าเริ่มต้นลง redux
                  if (this.props.selectedStat.area.id === itemArea.id) {
                    this.props.setSelectStatData({ areaTypeMap: typeMapEachArray[0] })
                  }
                  subresolve('promise typemap', itemArea.typeMapName, 'ready')
                }).catch((error) => {
                  console.error("error get stat typemap filter >>>", error)
                  subreject(Error('promise typemap fail'))
                })
              })
              typeMapPromiseArray.push(promiseEachTypeMap)
            })

            Promise.all(typeMapPromiseArray)
              .then((values) => {
                console.log('values in promise typemap >>>', values)
                this.props.setSelectFilterStatData({ updateFilter: 'type_map', dataArrey: typeMapArray, })
                resolve('promise typemap ready')
              })
              .catch((errors) => {
                console.error('error in promise typemap >>>', errors)
                reject(Error('promise typemap fail'))
              })

          } else {
            reject(Error('promise typemap fail'))
          }
        });
      })

      // -------------------- get StatType
      var promiseStatType = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/stattype',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: { 'cat_sl_id': selectedStatID, },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat promiseStatType filter >>>", res)
          let responseData = res.data
          if (responseData) {
            this.props.setSelectStatData({
              statType: res.data,
            })
          }
          resolve('promise promiseStatType ready')
        }).catch((error) => {
          console.error("error get stat promiseStatType filter >>>", error)
          reject(Error('promise promiseStatType fail'))
        })
      });

      // -------------------- get codelist
      var promiseCodelist = new Promise((resolve, reject) => {
        promiseStatType.then((value) => {
          console.log('value after promiseStatType >>>', value)
          if (value === 'promise promiseStatType ready') {
            axios({
              method: 'post',
              url: config.API_URL + '/api/gis/giscodelistcontroller',
              headers: {
                'Authorization': 'Bearer ' + this.props.loginData.token,
                'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
              },
              data: {
                'cat_sl_id': selectedStatID,
              },
              config: { headers: { 'Content-Type': 'multipart/form-data', } }
            }).then((res) => {
              console.log("response get stat codelist filter >>>", res)
              let responseData = res.data
              let codelistArray = []
              let codelistArrayForClassify = []
              let checkStatusCodelistHasNoT = false
              responseData.map((item, indexMain) => {
                let codelistChildArray = []
                let codelistChildSelect = {}
                let checkStatusChildlistHasT = false
                item.child.map((item1) => {
                  codelistChildArray.push({ id: item1.CODE, label: item1.CODE_NAME, allRawData: item1, })
                  if (item1.CODE === 't') {
                    codelistChildSelect = { id: item1.CODE, label: item1.CODE_NAME, allRawData: item1, }
                    checkStatusChildlistHasT = true
                  }
                })
                codelistArray.push({ id: item.name_id, label: item.name, childHasT: checkStatusChildlistHasT, child: codelistChildArray, allRawData: item, })
                codelistArrayForClassify.push({
                  check : true,
                  isMain: checkStatusCodelistHasNoT === false && checkStatusChildlistHasT === false ? true : false,
                  codelist: { id: item.name_id, label: item.name, childHasT: checkStatusChildlistHasT, child: codelistChildArray, allRawData: item, },
                  codelistChild: Object.keys(codelistChildSelect).length === 0 && codelistChildSelect.constructor === Object
                    ? codelistChildArray[0]
                    : codelistChildSelect
                })
                if (Object.keys(codelistChildSelect).length === 0 && codelistChildSelect.constructor === Object) {
                  checkStatusCodelistHasNoT = true
                }
              })

              // set ค่าลง redux filter codelist
              this.props.setSelectFilterStatData({ updateFilter: 'code_lists', dataArrey: codelistArray, })

              // set ค่าลง redux select stat ส่วน classify codelist
              if (this.props.selectedStat.statType.CLASSIFY === 0) {
                console.log('รายการข้อมูลนี้เป็นรายการที่ไม่มีการจัดจำแนก >>>')
                this.props.setSelectStatData({ classify: { status: false, statusUseClassify: false, statusCodelistHasNoT: false, classifyData: [] } })
              } else if (this.props.selectedStat.statType.CLASSIFY === 1 && codelistArray.length === 0) {
                console.log('รายการข้อมูลนี้เป็นรายการที่มีการจัดจำแนก แต่!!! codelist การจัดจำแนกไม่สามารถดึงค่ามาได้ บางอย่างผิดพลาด >>>')
                this.props.setSelectStatData({ classify: { status: false, statusUseClassify: false, statusCodelistHasNoT: false, classifyData: [] } })
              } else if (this.props.selectedStat.statType.CLASSIFY === 1 && codelistArray.length !== 0) {
                if (checkStatusCodelistHasNoT === true) {
                  console.log('รายการข้อมูลนี้เป็นรายการที่มีการจัดจำแนก และ codelist การจัดจำแนกบางตัวไม่มี t(รวม) >>>')
                  this.props.setSelectStatData({ classify: { status: true, statusUseClassify: true, statusCodelistHasNoT: true, classifyData: codelistArrayForClassify } })
                } else {
                  console.log('รายการข้อมูลนี้เป็นรายการที่มีการจัดจำแนก และ codelist การจัดจำแนกทุกตัวมี t(รวม) >>>')
                  this.props.setSelectStatData({ classify: { status: true, statusUseClassify: true, statusCodelistHasNoT: false, classifyData: codelistArrayForClassify } })
                }
              } else {
                console.log('รายการข้อมูลนี้บางอย่างผิดพลาดในการดึงข้อมูล codelist >>>')
                this.props.setSelectStatData({ classify: { status: false, statusUseClassify: false, statusCodelistHasNoT: false, classifyData: [] } })
              }
              resolve('promise Codelist ready')
            }).catch((error) => {
              console.error("error get stat codelist filter >>>", error)
              reject(Error('promise Codelist fail'))
            })

          } else {
            reject(Error('promise Codelist fail'))
          }
        })
      });

      // -------------------- get agencys
      var promiseAgency = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisagencycontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat agencys filter >>>", res)
          let responseData = res.data
          let agencyArray = []
          responseData.map((item) => {
            agencyArray.push({ id: item.AGAENCY_ID, label: item.AG_NAME, allRawData: item, })
          })
          this.props.setSelectFilterStatData({ updateFilter: 'agencys', dataArrey: agencyArray, })

          // ทำการ default ค่าเริ่มต้นลง redux
          this.props.setSelectStatData({ agency: agencyArray[0] })
          resolve('promise Agency ready')
        }).catch((error) => {
          console.error("error get stat agencys filter >>>", error)
          reject(Error('promise Agency fail'))
        })
      });

      // -------------------- get description
      var promiseDescription = new Promise((resolve, reject) => {
        promiseAgency.then((data) => {
          axios({
            method: 'post',
            url: config.API_URL + '/api/gis/gisreferancecontroller',
            headers: {
              'Authorization': 'Bearer ' + this.props.loginData.token,
              'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
            },
            data: { 'cat_sl_id': selectedStatID,'agency_id':this.props.selectedStat.agency.allRawData.AGAENCY_ID },
            config: { headers: { 'Content-Type': 'multipart/form-data', } }
          }).then((res) => {
            console.log("response get stat description filter >>>", res)
            let responseData = res.data[0]
            if (responseData) {
              this.props.setSelectStatData({
                description: {
                  agency: responseData.AG_NAME ? responseData.AG_NAME : '-',                                        // หน่วยงานเจ้าของข้อมูล: (สำนักงาน... มหาลัย... กรม...)
                  frequency: responseData.NAME ? responseData.NAME : '-',                                           // ความถี่ในการจัดเก็บ: (รายปี รายไตรมาส รายเดือน)
                  timeValidStart: responseData.VALID_FROM ? responseData.VALID_FROM : '-',                          // ช่วงเวลาข้อมูล: 2555 - 2559
                  timeValidEnd: responseData.VALID_TO ? responseData.VALID_TO : '-',                                // ช่วงเวลาข้อมูล: 2555 - 2559
                  resourceType: responseData.METTHOD_NAME ? responseData.METTHOD_NAME : '-',                        // วิธีการได้มา: (สำรวจ สำมะโน ทะเบียน)
                  resourceFrom: responseData.META_SOURCE ? responseData.META_SOURCE : '-',                          // แหล่งที่มา: (...)
                  resourceLink: responseData.URL ? responseData.URL : '-',                                          // แหล่งข้อมูล: (http:...)
                  usage: responseData.IMPLEMENT ? responseData.IMPLEMENT : '-',                                     // การนำไปใช้ประโยชน์: (...)
                  quality: responseData.QUALITY ? responseData.QUALITY : '-',                                       // คุณภาพข้อมูล: (...)
                  officialStatus: responseData.META_IS_OS === 1 ? true : false,                                           // สถิติทางการ: (ใช่ ไม่ใช่)
                  note: responseData.NOTE ? responseData.NOTE : '-',                                                // หมายเหตุ: (...)
                  statType: responseData.ATTRIBUTE.STATISTIC_ID[0] !== '-' ? responseData.ATTRIBUTE.STATISTIC_ID[0] : '-',        // ประเภทสถิติ: (จำนวน ร้อยละ อัตรา)
                  statUnit: responseData.ATTRIBUTE.UNIT_ID[0] !== '-' ? responseData.ATTRIBUTE.UNIT_ID[0] : '-',                  // หน่วยนับ: (บาท คน ตัว)
                  statUnitMultiply: responseData.ATTRIBUTE.UNITMULT_ID[0] !== '-' ? responseData.ATTRIBUTE.UNITMULT_ID[0] : '-',  // ตัวคูณ: (สิบ ร้อย พัน หมื่น)
                  allRawData: responseData,
                },
              })
            }
            resolve('promise Description ready')
          }).catch((error) => {
            console.error("error get stat description filter >>>", error)
            reject(Error('promise Description fail'))
          })
        }).catch((eror) => {
          console.log('eror :>> ', eror);
        })

      });

      // -------------------- get external-knowledge
      var promiseExKnow = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisexknowcontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat external-knowledge filter >>>", res)
          let responseData = res.data
          let listTemp = []
          if (responseData.length !== 0) {
            responseData.map((item) => {
              listTemp.push({ id: item.ID, nameOfKnowledge: item.INFO_NAME, link: item.URL, allRawData: item })
            })
          }
          // ยังไม่ได้จัดข้อมูลก่อนเก็บเข้า redux (ควรจัดให้อยู่ในรูปแบบ [{id: '', nameOfKnowledge: '', link: '' },{id: '', nameOfKnowledge: '', link: '' },...])
          this.props.setSelectStatData({ externalKnowledge: listTemp })
          resolve('promise external-knowledge ready')
        }).catch((error) => {
          console.error("error get stat external-knowledge filter >>>", error)
          reject(Error('promise external-knowledge fail'))
        })
      });

      // -------------------- get external-infomation
      var promiseExInfo = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisinfocontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat external-infomation filter >>>", res)
          let responseData = res.data
          let listTemp = []
          if (responseData.length !== 0) {
            responseData.map((item) => {
              listTemp.push({ id: item.ID, nameOfKnowledge: item.INFO_NAME, link: item.URL, allRawData: item })
            })
          }
          // ยังไม่ได้จัดข้อมูลก่อนเก็บเข้า redux (ควรจัดให้อยู่ในรูปแบบ [{id: '', nameOfInfomation: '', link: '' },{id: '', nameOfInfomation: '', link: '' },...])
          this.props.setSelectStatData({ externalInformation: listTemp })
          resolve('promise external-infomation ready')
        }).catch((error) => {
          console.error("error get stat external-infomation filter >>>", error)
          reject(Error('promise external-infomation fail'))
        })
      });

      // -------------------- get nametable
      var promiseNameTable = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisnametableController',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat nametable filter >>>", res)
          let responseData = res.data

          // ทำการ set nametable ค่าเริ่มต้นลง redux
          this.props.setSelectStatData({ statNameTable: responseData })
          resolve('promise nametable ready')
        }).catch((error) => {
          console.error("error get stat nametable filter >>>", error)
          reject(Error('promise nametable fail'))
        })
      });

      Promise.all([promiseDescription, promiseYear, promiseArea, promiseTypeMap, promiseCodelist, promiseAgency, promiseExKnow, promiseExInfo, promiseNameTable, promiseStatType])
        .then((values) => {
          console.log('values in promise all >>>', values)
          this.props.setModal({
            modalAction: 'close'
          })
          // ใส่ function ที่ต้องการทำต่อหลังจากได้ข้อมูล รายการที่เลือก และเก็บลง redux แล้วลงในนี้
          // การวาดแผนที่
          this.props.showmap()
          this.pumplike();
        })
        .catch((errors) => {
          console.error('error in promise all >>>', errors)
          this.props.setModal({
            modalAction: 'close'
          })

          this.props.setModal({
            modalAction: 'open',
            title: 'เกิดข้อผิดพลาด!',
            text: 'ข้อมูลในฐานข้อมูลไม่ครบถ้วน ไม่สามารถแสดงผลได้ กรุณาเลือกรายการข้อมูลใหม่อีกครั้ง',
            icon: 'fail',
            button: [
              {
                text: 'ตกลง',
                width: '100px',
                bgcolor: '#d62e45',
                functionIn: (e) => this.handleModal(e, 'this is a text from callback cancle !')
              }
            ]
          })
          this.props.setLayersuccess({ status: this.props.layersuccess.status = false, statusCrassify: 'ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง' });
        })

    } catch (error) {
      console.error(error);
    }
  }
  pumplike = () => {
    axios({
      method: 'post',
      url: config.API_URL + '/api/gis/countstat',
      headers: {
        'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
      },
      data: {
        'cat_sl_id': this.props.selectedStat.statId,
      },
      config: { headers: { 'Content-Type': 'multipart/form-data', } }
    }).then((res) => {
      console.log("pumplike success");
    }).catch((error) => {
      console.error(error + "error pumplike");
    })


  }
  handleModal = (e, param = 'nothing from callback function !') => {
    console.log('test handleModal event from modal >>>', e)
    console.log('test handleModal param from function >>>', param)

    this.props.setModal({ modalAction: 'close', })
  }
  render() {
    // console.log('render main datalist >>>')
    const {
      mainCategoryData, selectedMainCateID, statData1,
      cateDataLevel1, selectedLevel1CateID, selectedLevel1CateIDNew, statData2,
      cateDataLevel2, selectedLevel2CateID, selectedLevel2CateIDNew, statData3,
      cateDataLevel3, selectedLevel3CateID, selectedLevel3CateIDNew, statData4,
      cateDataLevel4, selectedLevel4CateID, selectedLevel4CateIDNew, statData5,
      selectedStatID,
    } = this.state;
    const {
      stringProp, numberProp, functionProp, arrayProp, showmap
    } = this.props;

    return (
      <div>

        {/* เลือกหมวดหลัก */}
        <div style={{ margin: '10px' }}>
          <label><span><strong> หมวดหลัก : </strong></span></label>
          <Dropdown
            placeholder='เลือกหมวดหมู่หลัก...' fluid floating selection
            value={selectedMainCateID}
            options={mainCategoryData}
            onChange={(event, data) => { this.handleSelectCateMain(data) }}
            onFocus={async () => {
              let checkForSchemaPNSO = idMainCateNotSchemaPNSO.find((whichData) => { return whichData === this.state.selectedMainCateID })
              if (checkForSchemaPNSO) {
                console.log('change back to pnso schema >>>')
                await this.props.setChangeProvinceMode({ mode: 'change', provinceModeOpen: true, })
              }
              this.handleGetCateMain()
            }}
          />
        </div>

        {/* รายการข้อมูลของหมวดหลัก */}
        {statData1.map((itemStat) => {
          return (
            <div key={itemStat.ID_MAP}>
              <DivTreeLV marginLeft='10px' selectedStat={this.props.selectedStat.statId === itemStat.ID_MAP} onClick={(event) => { this.handleSelectStat(itemStat) }}>
                <Radio value={itemStat.ID_MAP} checked={this.props.selectedStat.statId === itemStat.ID_MAP}></Radio>
                <span className='ml-2' >{itemStat.STAT_LIST_NAME}
                  {/* ({itemStat.STAT_LIST_ID}) */}
                </span>
              </DivTreeLV>
            </div>
          )
        })}

        {/* หมวดย่อย 1 */}
        {cateDataLevel1.map((item1) => {
          return (
            <div key={item1.ID}>
              <DivTreeLV marginLeft='10px' cate
                selectedCate={this.props.selectedCategory.level1Category.id === item1.ID}
                selectedCateToStat={selectedLevel1CateIDNew === item1.ID}
                onClick={(event) => {
                  if (selectedLevel1CateID === item1.ID) {
                    if (this.state.showTreeLV1) { this.setState({ selectedLevel1CateID: '' }) }
                    this.setState({ showTreeLV1: !this.state.showTreeLV1 })
                  } else {
                    this.handleSelectCateLevel(1, item1)
                    this.setState({ showTreeLV1: true })
                  }
                }}>
                <div className='hiddentext' style={{ width: 'calc(100% - 65px)', }} title={item1.CATE_NAME}>
                  <TreeCaret fontSize='18px' /> {item1.CATE_NAME}
                </div>
                <span style={{ marginLeft: 'auto', color: '#558ed5', fontSize: '12px' }}>{item1.childcount == 0 ? "" : item1.childcount}{item1.childcount == 0 ? "" : item1.is_statlist === 1 ? ' รายการ' : ' หมวด'}</span>
              </DivTreeLV>
              {(this.state.selectedLevel1CateID === item1.ID) ?
                <div>
                  {this.state.showTreeLV1 ? <>
                    {/* รายการข้อมูลของหมวดย่อย 1 */}
                    {statData2.map((itemStat) => {
                      return (
                        <div key={itemStat.ID_MAP}>
                          <DivTreeLV marginLeft='30px' selectedStat={this.props.selectedStat.statId === itemStat.ID_MAP} onClick={(event) => { this.handleSelectStat(itemStat) }}>
                            <Radio value={itemStat.ID_MAP} checked={this.props.selectedStat.statId === itemStat.ID_MAP}></Radio>
                            <span className='ml-2' >{itemStat.STAT_LIST_NAME}
                              {/* ({itemStat.STAT_LIST_ID}) */}
                            </span>
                          </DivTreeLV>
                        </div>
                      )
                    })}

                    {/* หมวดย่อย 2 */}
                    {cateDataLevel2.map((item2) => {
                      return (
                        <div key={item2.ID}>
                          <DivTreeLV marginLeft='30px' cate selectedCate={this.props.selectedCategory.level2Category.id === item2.ID} selectedCateToStat={selectedLevel2CateIDNew === item2.ID} onClick={(event) => {
                            if (selectedLevel2CateID === item2.ID) {
                              if (this.state.showTreeLV2) { this.setState({ selectedLevel2CateID: '' }) }
                              this.setState({ showTreeLV2: !this.state.showTreeLV2 })
                            } else {
                              this.handleSelectCateLevel(2, item2)
                              this.setState({ showTreeLV2: true })
                            }
                          }}>
                            <div className='hiddentext' style={{ width: 'calc(100% - 65px)', }} title={item2.CATE_NAME}>
                              <TreeCaret fontSize='16px' /> {item2.CATE_NAME}
                            </div>
                            <span style={{ marginLeft: 'auto', color: '#558ed5', fontSize: '12px' }}>{item2.childcount == 0 ? "" : item2.childcount}{item2.childcount == 0 ? "" : item2.is_statlist === 1 ? ' รายการ' : ' หมวด'}</span>
                          </DivTreeLV>
                          {(this.state.selectedLevel2CateID === item2.ID) ?
                            <div>
                              {this.state.showTreeLV2 ? <>
                                {/* รายการข้อมูลของหมวดย่อย 2 */}
                                {statData3.map((itemStat) => {
                                  return (
                                    <div key={itemStat.ID_MAP}>
                                      <DivTreeLV marginLeft='50px' selectedStat={this.props.selectedStat.statId === itemStat.ID_MAP} onClick={(event) => { this.handleSelectStat(itemStat) }}>
                                        <Radio value={itemStat.ID_MAP} checked={this.props.selectedStat.statId === itemStat.ID_MAP}></Radio>
                                        <span className='ml-2' >{itemStat.STAT_LIST_NAME}
                                          {/* ({itemStat.STAT_LIST_ID}) */}
                                        </span>
                                      </DivTreeLV>
                                    </div>
                                  )
                                })}

                                {/* หมวดย่อย 3 */}
                                {cateDataLevel3.map((item3) => {
                                  return (
                                    <div key={item3.ID}>
                                      <DivTreeLV marginLeft='50px' cate selectedCate={this.props.selectedCategory.level3Category.id === item3.ID} selectedCateToStat={selectedLevel3CateIDNew === item3.ID} onClick={(event) => {
                                        if (selectedLevel3CateID === item3.ID) {
                                          if (this.state.showTreeLV3) { this.setState({ selectedLevel3CateID: '' }) }
                                          this.setState({ showTreeLV3: !this.state.showTreeLV3 })
                                        } else {
                                          this.handleSelectCateLevel(3, item3)
                                          this.setState({ showTreeLV3: true })
                                        }
                                      }}>
                                        <div className='hiddentext' style={{ width: 'calc(100% - 65px)', }} title={item3.CATE_NAME}>
                                          <TreeCaret fontSize='14px' /> {item3.CATE_NAME}
                                        </div>
                                        <span style={{ marginLeft: 'auto', color: '#558ed5', fontSize: '12px' }}>{item3.childcount == 0 ? "" : item3.childcount}{item3.childcount == 0 ? "" : item3.is_statlist === 1 ? ' รายการ' : ' หมวด'}</span>
                                      </DivTreeLV>
                                      {(this.state.selectedLevel3CateID === item3.ID) ?
                                        <div>
                                          {this.state.showTreeLV3 ? <>
                                            {/* รายการข้อมูลของหมวดย่อย 3 */}
                                            {statData4.map((itemStat) => {
                                              return (
                                                <div key={itemStat.ID_MAP}>
                                                  <DivTreeLV marginLeft='70px' selectedStat={this.props.selectedStat.statId === itemStat.ID_MAP} onClick={(event) => { this.handleSelectStat(itemStat) }}>
                                                    <Radio value={itemStat.ID_MAP} checked={this.props.selectedStat.statId === itemStat.ID_MAP}></Radio>
                                                    <span className='ml-2' >{itemStat.STAT_LIST_NAME}
                                                      {/* ({itemStat.STAT_LIST_ID}) */}
                                                    </span>
                                                  </DivTreeLV>
                                                </div>
                                              )
                                            })}

                                            {/* หมวดย่อย 4 */}
                                            {cateDataLevel4.map((item4) => {
                                              return (
                                                <div key={item4.ID}>
                                                  <DivTreeLV marginLeft='70px' cate selectedCate={this.props.selectedCategory.level4Category.id === item4.ID} selectedCateToStat={selectedLevel4CateIDNew === item4.ID} onClick={(event) => {
                                                    if (selectedLevel4CateID === item4.ID) {
                                                      if (this.state.showTreeLV4) { this.setState({ selectedLevel4CateID: '' }) }
                                                      this.setState({ showTreeLV4: !this.state.showTreeLV4 })
                                                    } else {
                                                      this.handleSelectCateLevel(4, item4)
                                                      this.setState({ showTreeLV4: true })
                                                    }
                                                  }}>
                                                    <div className='hiddentext' style={{ width: 'calc(100% - 65px)', }} title={item4.CATE_NAME}>
                                                      <TreeCaret fontSize='12px' /> {item4.CATE_NAME}
                                                    </div>
                                                    <span style={{ marginLeft: 'auto', color: '#558ed5', fontSize: '12px' }}>{item4.childcount == 0 ? "" : item4.childcount}{item4.childcount == 0 ? "" : item4.is_statlist === 1 ? ' รายการ' : ' หมวด'}</span>
                                                  </DivTreeLV>
                                                  {(this.state.selectedLevel4CateID === item4.ID) ?
                                                    <div>
                                                      {this.state.showTreeLV4 ? <>
                                                        {/* รายการข้อมูลของหมวดย่อย 4 */}
                                                        {statData5.map((itemStat) => {
                                                          return (
                                                            <div key={itemStat.ID_MAP}>
                                                              <DivTreeLV marginLeft='90px' selectedStat={this.props.selectedStat.statId === itemStat.ID_MAP} onClick={(event) => { this.handleSelectStat(itemStat) }}>
                                                                <Radio value={itemStat.ID_MAP} checked={this.props.selectedStat.statId === itemStat.ID_MAP}></Radio>
                                                                <span className='ml-2' >{itemStat.STAT_LIST_NAME}
                                                                  {/* ({itemStat.STAT_LIST_ID}) */}
                                                                </span>
                                                              </DivTreeLV>
                                                            </div>
                                                          )
                                                        })}
                                                      </> : ''}
                                                    </div>
                                                    : '' // แสดง treeLV5 (stat)
                                                  }
                                                </div>
                                              )
                                            })}
                                          </> : ''}
                                        </div>
                                        : '' // แสดง treeLV4
                                      }
                                    </div>
                                  )
                                })}
                              </> : ''}
                            </div>
                            : '' // แสดง treeLV3
                          }
                        </div>
                      )
                    })}
                  </> : ''}
                </div>
                : '' // แสดง treeLV2
              }
            </div>
          )
        })}
      </div>
    )
  }
};

const mapStateToProps = (state) => ({
  loginData: state.loginData,
  menutab: state.menuTab,
  mapView: state.mapView,
  drawType: state.drawType,
  selectedCategory: state.selectedCategory,
  selectedStat: state.selectedStat,
  selectedStatFilter: state.selectedStatFilter,
  provinceMode: state.provinceMode,
  modal: state.modal,
  layersuccess: state.layersuccess,

})

const mapDispatchToProps = (dispatch) => ({
  setSelectCategoryData: (data) => dispatch(selectCategoryData(data)),
  setSelectStatData: (data) => dispatch(selectStatData(data)),
  setSelectFilterStatData: (data) => dispatch(selectFilterStatData(data)),
  setChangeProvinceMode: (data) => dispatch(changeProvinceMode(data)),
  setModal: (data) => dispatch(showModal(data)),
  setLayersuccess: (data) => dispatch(layersuccess(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(DataList);
// export default DataList;
