import React, { Component } from 'react'
import styleimg from '../icon/image042.png'
import { connect } from "react-redux";
import axios from 'axios';
import config from '../config'
import { changMapView, changDrawType, selectStatData, selectCategoryData, selectFilterStatData, changSwipeMap, changeStyle, layersuccess } from '../redux/actions'

class Infor extends Component {
    constructor(props) {
        super(props)
    }
    restring = (str, char) => {
        var xStr = str.substring(0, str.length - 1);
        return xStr + char;
    }
    numberWithCommas = (x) => { //----------------------------------ฟังชั่นใส่คอมม่าให้ค่าของข้อมูล
        // x = parseFloat(x).toFixed(2);
        // let f = x.toString().split(".");
        // x = f[1] == "00" ? x = parseFloat(x).toFixed(0) : x
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    handleLastDigitPlusOne(numberToAddOne) {
        try {
            let stringNumberToAddOne = numberToAddOne.toString()
            let numberToPlus = ''
            let indexDigit = 0
            let arraySplitDot = stringNumberToAddOne.split(".")
            if (arraySplitDot.length == 2) {
                indexDigit = arraySplitDot[1].length
                let lastDigitOne = '1'
                for (var i = 1; i < indexDigit; i++) {
                    lastDigitOne = '0' + lastDigitOne
                }
                numberToPlus = '.' + lastDigitOne
            } else if (arraySplitDot.length == 1) {
                numberToPlus = '1'
            } else {
                numberToPlus = '0'
            }
            let newNumber = (Number(stringNumberToAddOne) + Number(numberToPlus)).toFixed(indexDigit)
            return newNumber
        } catch (error) {
            console.log(error);
        }
    } // done only 17 digit
    render() {
        let data = [];
        let dataswipe = [];
        switch (this.props.changeStyle.status) {
            case 'nb':
                if (this.props.changeStyle.style.LEVEL.length > 0) {
                    this.props.changeStyle.style.COLOR.map((color, index) => {
                        data.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}  key={index} >
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index])}</span> :
                                    index === this.props.changeStyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index - 1])}</span> :
                                        <span style={{ color: 'black' }}>{this.numberWithCommas(this.handleLastDigitPlusOne(this.props.changeStyle.style.LEVEL[index - 1])) + ' - ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index])}</span>
                                }
                            </div>
                        )
                    })
                } else {
                    data.push(
                        <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                            <span className="text-danger">ไม่สามารถแสดงชวงชั้นสีที่ถูกต้องได้เนื่องจากข้อมูลมีน้อยเกินไป</span>
                        </div>
                    )
                }
                break;
            case 'sd':
                this.props.changeStyle.style.COLOR.map((color, index) => {
                    if (this.props.changeStyle.style.COLOR.length === 1) {
                        data.push(
                            <div style={{ display: 'flex', width: '100%' }} key={index} >
                                <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)', width: '65%' }}>
                                    <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                   <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL_TRUE[index])}</span>
                                </div>
                                <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)', width: '35%' }}>
                                   <span style={{ color: 'black' }}>{'(∞,' + this.props.changeStyle.style.LEVEL[index] + ']'}</span>
                                </div>
                            </div>
                        )
                    } else {
                        data.push(
                            <div style={{ display: 'flex', width: '100%' }} key={index} >
                                <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)', width: '65%' }}>
                                    <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                    {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL_TRUE[index + 1])}</span> :
                                        index === this.props.changeStyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL_TRUE[index])}</span> :
                                            <span style={{ color: 'black' }}>{this.numberWithCommas(this.handleLastDigitPlusOne(this.props.changeStyle.style.LEVEL_TRUE[index])) + ' - ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL_TRUE[index + 1])}</span>
                                    }
                                </div>
                                <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)', width: '35%' }}>
                                    {index === 0 ? <span style={{ color: 'black' }}>{'(∞,' + this.props.changeStyle.style.LEVEL[index + 1] + ']'}</span> :
                                        index === this.props.changeStyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'(' + this.props.changeStyle.style.LEVEL[index] + ',∞)'}</span> :
                                            <span style={{ color: 'black' }}>{'(' + this.props.changeStyle.style.LEVEL[index] + ' , ' + this.props.changeStyle.style.LEVEL[index + 1] + ']'}</span>
                                    }
                                </div>
                            </div>
                        )
                    }
                })

                break;
            case 'eq':
                this.props.changeStyle.style.COLOR.map((color, index) => {
                    if (this.props.changeStyle.style.COLOR.length === 1) {
                        data.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }} key={index} >
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index])}</span>
                            </div>
                        )
                    } else {
                        data.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }} key={index} >
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                {/* <span style={{ color: 'black' }}>{this.props.changeStyle.style.LEVEL[index] + '-' + this.props.changeStyle.style.LEVEL[index + 1]}</span> */}
                                {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index + 1])}</span> :
                                    index === this.props.changeStyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index])}</span> :
                                        <span style={{ color: 'black' }}>{this.numberWithCommas(this.handleLastDigitPlusOne(this.props.changeStyle.style.LEVEL[index])) + ' - ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index + 1])}</span>
                                }
                            </div>
                        )
                    }
                })
                break;
            case 'qt':
                if (this.props.changeStyle.style.LEVEL.length > 0) {
                    this.props.changeStyle.style.COLOR.map((color, index) => {
                        data.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }} key={index} >
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index])}</span> :
                                    index === this.props.changeStyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index - 1])}</span> :
                                        <span style={{ color: 'black' }}>{this.numberWithCommas(this.handleLastDigitPlusOne(this.props.changeStyle.style.LEVEL[index - 1])) + ' - ' + this.numberWithCommas(this.props.changeStyle.style.LEVEL[index])}</span>
                                }
                            </div>
                        )
                    })
                } else {
                    data.push(
                        <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                            <span className="text-danger">ไม่สามารถแสดงชวงชั้นสีที่ถูกต้องได้เนื่องจากข้อมูลมีน้อยเกินไป</span>
                        </div>
                    )
                }
                break;
            // case 'qt':
            //     let styleqt = [];
            //     if (this.props.changeStyle.style.LEVEL.length > 7) {
            //         this.props.changeStyle.style.LEVEL.map((color, index) => {
            //             if (index === 0 || index % 2 !== 0) {
            //                 styleqt.push(color);
            //             }
            //         })
            //         this.props.changeStyle.style.COLOR.map((color, index) => {
            //             data.push(
            //                 <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
            //                     <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
            //                     {/* <span style={{ color: 'black' }}>{this.props.changeStyle.style.LEVEL[index] + '-' + this.props.changeStyle.style.LEVEL[index + 1]}</span> */}
            //                     {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(styleqt[index + 1])}</span> :
            //                         index === this.props.changeStyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(styleqt[index])}</span> :
            //                             <span style={{ color: 'black' }}>{this.numberWithCommas(this.handleLastDigitPlusOne(styleqt[index])) + ' - ' + this.numberWithCommas(styleqt[index + 1])}</span>
            //                     }
            //                 </div>
            //             )
            //         })
            //     }else{
            //         data.push(
            //             <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
            //                 <span className="text-danger">ไม่สามารถแสดงชวงชั้นสีที่ถูกต้องได้เนื่องจากข้อมูลมีน้อยเกินไป</span>
            //             </div>
            //         )
            //     }
            //         break;
            case 'pop':
                data.push(
                    <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)', flexDirection: 'column ', justifyContent: 'center ' }}>
                        <div style={{ margin: 'auto', width: '100px', height: '100px', border: '2px white solid', borderRadius: '50%', backgroundColor: `${this.props.changeStyle.style}` }}></div>
                        <span style={{ color: 'black', textAlign: 'center' }}>ขนาดขึ้นอยู่กับจำนวนของข้อมูล</span>
                    </div>
                )
                break;
            case 'pie':
                this.props.changeStyle.style[2].map((color, index) => {
                    if (index === 0) {

                    } else {
                        data.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                <span style={{ color: 'black' }}>{this.props.changeStyle.style[1][(index)]}</span>

                            </div>
                        )
                    }
                })
                break;
            case 'bar':
                this.props.changeStyle.style[2].map((color, index) => {
                    if (index === 0) {

                    } else {
                        data.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                <span style={{ color: 'black' }}>{this.props.changeStyle.style[1][(index)]}</span>

                            </div>
                        )
                    }
                })
                break;
            case 'mncp':
                this.props.changeStyle.style.LEVEL.map((level, index) => {
                    /*    console.log(level); */
                    data.push(
                        <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                            <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${this.props.changeStyle.style.COLOR[index]}` }}></div>
                            <span style={{ color: 'black' }}>{this.props.changeStyle.style.LEVEL_LABEL[index]}</span>
                        </div>
                    )
                })
                break;
            case 'mnbar':
                let ismainbar = this.props.layersuccess.classify.classifyData.find(item => item.isMain === true);
                let indexOfIsMainbar = ismainbar ? this.props.changeStyle.style.COLOR_PIE_BAR.CL.indexOf(ismainbar.codelist.id) : '';
                this.props.changeStyle.style.COLOR_PIE_BAR.CL_SON[indexOfIsMainbar].map((CL, index) => {
                    if (index === 0) {

                    } else {
                        data.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${this.props.changeStyle.style.COLOR_PIE_BAR.COLOR[indexOfIsMainbar][index]}` }}></div>
                                <span style={{ color: 'black' }}>{CL}</span>

                            </div>
                        )
                    }
                })
                break;
            case 'mnpie':
                let ismain = this.props.layersuccess.classify.classifyData.find(item => item.isMain === true);
                let indexOfIsMain = this.props.changeStyle.style.CL.indexOf(ismain.codelist.id);
                this.props.changeStyle.style.CL_SON[indexOfIsMain].map((CL, index) => {
                    if (index === 0) {

                    } else {
                        data.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${this.props.changeStyle.style.COLOR[indexOfIsMain][index]}` }}></div>
                                <span style={{ color: 'black' }}>{CL}</span>

                            </div>
                        )
                    }
                })
                break;
            case 'mnpop':
                data.push(
                    <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)', flexDirection: 'column ', justifyContent: 'center ' }}>
                        <div style={{ margin: 'auto', width: '100px', height: '100px', border: '2px white solid', borderRadius: '50%', backgroundColor: `${this.props.changeStyle.style}` }}></div>
                        <span style={{ color: 'black', textAlign: 'center' }}>ขนาดขึ้นอยู่กับจำนวนของข้อมูล</span>
                    </div>
                )
                break;
            default:
                break;

        }
        /*---------------------------------------------Swipemapstyleinfo-----------------------*/
        switch (this.props.changeStyle.swipestyle.status) {
            case 'nbswipe':
                this.props.changeStyle.swipestyle.style.COLOR.map((color, index) => {
                    dataswipe.push(
                        <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                            <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                            {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index])}</span> :
                                index === this.props.changeStyle.swipestyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index - 1])}</span> :
                                    <span style={{ color: 'black' }}>{this.numberWithCommas(this.handleLastDigitPlusOne(this.props.changeStyle.swipestyle.style.LEVEL[index - 1])) + ' - ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index])}</span>
                            }
                        </div>
                    )
                })
                break;
            case 'sdswipe':
                this.props.changeStyle.swipestyle.style.COLOR.map((color, index) => {
                    dataswipe.push(
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)', width: '65%' }}>
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL_TRUE[index + 1])}</span> :
                                    index === this.props.changeStyle.swipestyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL_TRUE[index])}</span> :
                                        <span style={{ color: 'black' }}>{this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL_TRUE[index]) + ' - ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL_TRUE[index + 1])}</span>
                                }
                            </div>
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)', width: '35%' }}>
                                {index === 0 ? <span style={{ color: 'black' }}>{'(' + this.props.changeStyle.swipestyle.style.LEVEL[index + 1] + ')'}</span> :
                                    index === this.props.changeStyle.swipestyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'(' + this.props.changeStyle.swipestyle.style.LEVEL[index] + ')'}</span> :
                                        <span style={{ color: 'black' }}>{'(' + this.props.changeStyle.swipestyle.style.LEVEL[index] + ' - ' + this.props.changeStyle.swipestyle.style.LEVEL[index + 1] + ')'}</span>
                                }
                            </div>
                        </div >
                    )
                })

                break;
            case 'eqswipe':
                this.props.changeStyle.swipestyle.style.COLOR.map((color, index) => {
                    if (this.props.changeStyle.swipestyle.style.COLOR.length === 1) {
                        dataswipe.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index])}</span>
                            </div>
                        )
                    } else {
                        dataswipe.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                                {/* <span style={{ color: 'black' }}>{this.props.changeStyle.swipestyle.style.LEVEL[index] + '-' + this.props.changeStyle.swipestyle.style.LEVEL[index + 1]}</span> */}
                                {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index + 1])}</span> :
                                    index === this.props.changeStyle.swipestyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index])}</span> :
                                        <span style={{ color: 'black' }}>{this.numberWithCommas(this.handleLastDigitPlusOne(this.props.changeStyle.swipestyle.style.LEVEL[index])) + ' - ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index + 1])}</span>
                                }
                            </div>
                        )
                    }
                })
                break;
            case 'qtswipe':
                this.props.changeStyle.swipestyle.style.COLOR.map((color, index) => {
                    dataswipe.push(
                        <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                            <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
                            {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index])}</span> :
                                index === this.props.changeStyle.swipestyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index - 1])}</span> :
                                    <span style={{ color: 'black' }}>{this.numberWithCommas(this.handleLastDigitPlusOne(this.props.changeStyle.swipestyle.style.LEVEL[index - 1])) + ' - ' + this.numberWithCommas(this.props.changeStyle.swipestyle.style.LEVEL[index])}</span>
                            }
                        </div>
                    )
                })
                break;
            // case 'qtswipe':
            //     let styleqt = [];
            //     this.props.changeStyle.swipestyle.style.LEVEL.map((color, index) => {
            //         if (index === 0 || index % 2 !== 0) {
            //             styleqt.push(color);
            //         }
            //     })
            //     this.props.changeStyle.swipestyle.style.COLOR.map((color, index) => {
            //         dataswipe.push(
            //             <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
            //                 <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${color}` }}></div>
            //                 {/* <span style={{ color: 'black' }}>{this.props.changeStyle.swipestyle.style.LEVEL[index] + '-' + this.props.changeStyle.swipestyle.style.LEVEL[index + 1]}</span> */}
            //                 {index === 0 ? <span style={{ color: 'black' }}>{'≤ ' + this.numberWithCommas(styleqt[index + 1])}</span> :
            //                     index === this.props.changeStyle.swipestyle.style.COLOR.length - 1 ? <span style={{ color: 'black' }}>{'> ' + this.numberWithCommas(styleqt[index])}</span> :
            //                         <span style={{ color: 'black' }}>{this.numberWithCommas(this.handleLastDigitPlusOne((styleqt[index]))) + ' - ' + this.numberWithCommas(styleqt[index + 1])}</span>
            //                 }
            //             </div>
            //         )
            //     })
            //     break;

            case 'mncpswipe':
                if (this.props.changeStyle.swipestyle.style.LEVEL !== undefined)
                    this.props.changeStyle.swipestyle.style.LEVEL.map((level, index) => {
                        /*    console.log(level); */
                        dataswipe.push(
                            <div style={{ display: 'flex', backgroundColor: 'rgb(255, 255, 255,0.5)' }}>
                                <div style={{ width: '25px', height: '25px', border: '2px white solid', backgroundColor: `${this.props.changeStyle.swipestyle.style.COLOR[index]}` }}></div>
                                <span style={{ color: 'black' }}>{this.props.changeStyle.swipestyle.style.LEVEL_LABEL[index]}</span>
                            </div>
                        )
                    })
                break;

            default:
                break;
        }
        var style = {
            position: 'absolute',
            bottom: ' 50px',
            zIndex: '93',
            width: '300px',
            backgroundColor: 'rgb(158, 198, 213,0.7)',
            right: '5rem',

        }
        return (
            <div>
                <div className="card" style={style}>
                    <div className="card-body">
                        <h6 className="card-title text-center"><strong>คำอธิบายสัญลักษณ์</strong></h6>
                        <span style={{}}>{this.props.selectedStat.statName !== '' ? this.props.selectedStat.statName : 'กรุณาเลือกรายการสถิติ'}</span><br />
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '65%' }}>
                                <span>หน่วยนับ {this.props.selectedStat.description.statUnitMultiply !== 'ไม่มีข้อมูล' && this.props.selectedStat.description.statUnitMultiply !== "-" ? this.props.selectedStat.description.statUnitMultiply : ''}{this.props.selectedStat.description.statUnit ? this.props.selectedStat.description.statUnit : ''}</span>
                            </div>
                            {this.props.changeStyle.status === "sd" ? <div style={{ width: '35%' }}>
                                <span>{this.props.selectedStat.description.statUnit ? 'หน่วยนับ:' + 'sd' : ''}</span>
                            </div> : ''
                            }
                        </div>
                        <div className="imgstyle" style={{ clear: 'none' }}>
                            <div>
                                {data}
                            </div>
                        </div>
                        <span>ที่มาข้อมูล: {this.props.selectedStat.agency.label ? this.props.selectedStat.agency.label : ''}</span>
                        <br />
                        {/*--------------------------swipemap info-------------------------*/}
                        <div className="swipeinfo" style={{ display: this.props.swipeMap.swipeMapOpen === true ? "block" : "none" }}>
                            <hr />
                            <span style={{}}>{this.props.swipeMap.swipeMapStat.statName !== '' ? this.props.swipeMap.swipeMapStat.statName : 'กรุณาเลือกรายการสถิติ'}</span><br />
                            <div style={{ width: '100%', display: 'flex' }}>
                                <div style={{ width: '65%' }}>
                                    <span>หน่วยนับ {this.props.swipeMap.swipeMapStat.description.statUnitMultiply !== 'ไม่มีข้อมูล' && this.props.swipeMap.swipeMapStat.description.statUnitMultiply !== "-" ? this.props.swipeMap.swipeMapStat.description.statUnitMultiply : ''}{this.props.swipeMap.swipeMapStat.description.statUnit ? this.props.swipeMap.swipeMapStat.description.statUnit : ''}</span>
                                </div>
                                {this.props.changeStyle.status === "sd" ? <div style={{ width: '35%' }}>
                                    <span>{this.props.swipeMap.swipeMapStat.description.statUnit ? 'หน่วยนับ:' + 'sd' : ''}</span>
                                </div> : ''
                                }
                            </div>
                            <div className="imgstyle" style={{ clear: 'none' }}>
                                <div>
                                    {dataswipe}
                                </div>
                            </div>
                            <span>ที่มาข้อมูล: {this.props.swipeMap.swipeMapStat.agency.label ? this.props.swipeMap.swipeMapStat.agency.label : ''}</span><br /><br />
                        </div>
                        <span>ที่มาแผนที่: {this.props.mapView.namemap}</span><br />
                        <span>ปิดการแสดงค่า <input type="checkbox" checked={!this.props.check} onChange={this.props.oncheck} /></span><br />
                        <span style={{ fontSize: '12px' }}>ชั้นข้อมูลแผนที่ใช้แสดงร่วม/นำเสนอแผนที่สถิติเท่านั้น</span><br />
                        <span style={{ fontSize: '12px', color: '#F54' }}><strong>*ห้ามใช้อ้างอิงเป็นเขตการปกครอง</strong></span>



                    </div>
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    menutab: state.menuTab,
    mapView: state.mapView,
    drawType: state.drawType,
    selectedCategory: state.selectedCategory,
    selectedStat: state.selectedStat,
    selectedStatFilter: state.selectedStatFilter,
    swipeMap: state.swipeMap,
    provinceMode: state.provinceMode,
    changeStyle: state.changeStyle,
    layersuccess: state.layersuccess,

    //paramurl: state.paramurl,
})
export default connect(mapStateToProps, null)(Infor)