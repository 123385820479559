import { combineReducers } from 'redux';
import loginReducer from './login';
import mapViewReducer from './mapView';
import menuTabReducer from './menuTab';
import drawTypeReducer from './drawType';
import selectedCategoryReducer from './selectedCategory';
import selectedStatReducer from './selectedStat';
import selectedStatFilterReducer from './selectedStatFilter';
import paramurlReducer from './paramurl';
import swipeMapReducer from './swipeMap';
import provinceModeReducer from './provinceMode';
import selectStyle from './selectStyle';
import layersuccess from './layersuccess';
import selectedMenu from './selectedMenu';
import modalReducer from './modal';

const allReducer = combineReducers({
  loginData: loginReducer,
  mapView: mapViewReducer,
  menuTab: menuTabReducer,
  drawType: drawTypeReducer,
  selectedCategory: selectedCategoryReducer,
  selectedStat: selectedStatReducer,
  selectedStatFilter: selectedStatFilterReducer,
  paramurl:paramurlReducer,
  swipeMap: swipeMapReducer,
  provinceMode: provinceModeReducer,
  changeStyle: selectStyle,
  layersuccess: layersuccess,
  selectedMenu: selectedMenu,
  modal: modalReducer,
})

export default allReducer;