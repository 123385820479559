import React, { Component, } from "react";
import { Link } from '@version/react-router-v3';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/home.css";
import logo from '../images/logo.jpg';
import user_circle from '../icon/user_circle.png';
import user_circlelogin from '../icon/user_circle2.png';
import navbar_icon_1_v2 from '../icon/navbar_icon_1_v2.png';
import navbar_icon_1_active from '../icon/navbar_icon_1_active.png';
import navbar_icon_2_v2 from '../icon/navbar_icon_2_v2.png';
import navbar_icon_2_active from '../icon/navbar_icon_2_active.png';
import navbar_icon_3_v2 from '../icon/navbar_icon_3_v2.png';
import navbar_icon_3_active from '../icon/navbar_icon_3_active.png';
import navbar_icon_4_v2 from '../icon/navbar_icon_4_v2.png';
import navbar_icon_4_active from '../icon/navbar_icon_4_active.png';
import { connect, } from 'react-redux';
import { login, logout, changMenuTab, changMapView, changDrawType, selectStatData, selectCategoryData, selectFilterStatData, changSwipeMap, showModal, } from '../redux/actions';
import axios from "axios";
import config from '../config';
import { Loader, Icon } from 'semantic-ui-react';
import { AreaChartOutlined, DashboardOutlined } from '@ant-design/icons';
import { GoogleReCaptchaProvider, } from "react-google-recaptcha-v3";

class Navbar extends Component {


  constructor(props) {
    super(props)
    this.activebutton = this.activebutton.bind(this);
    this.state = {
      username: '',
      password: '',
      token: '',
      statusLoading: false,
      statusText: '',
      // statusCaptcha: 'hidden'
    }
  }
  componentDidMount () {
    let loginToken = window.sessionStorage.getItem('token');
    let loginUser = window.sessionStorage.getItem('user');
    // console.log('loginToken >>>',loginToken)
    if (loginToken) {
      this.props.login({ username: loginUser, token: loginToken, })
    }
  }
  exampleFunction = async () =>{
    //`ตัวอย่างการใช้ session storage (local storage ก็คล้ายกัน) ดังนี้`

    //`1 การเซตตัวแปรและค่าไปเก็บ`
    window.sessionStorage.setItem("nameOfNewItemSetToStorage", 'dataOfItem');

    //`2 การเรียกใช้งาน ถ้าเป็นค่า string โดยตรงใช้ได้เลย ถ้าเป็น object ต้องแปลงกลับก่อน`
    const loginData = window.sessionStorage.getItem('nameOfItemStringInStorage');
    const schemaData = await JSON.parse(window.sessionStorage.getItem('nameOfItemObjectInStorage'));

    //`3 การเคลียค่า มีทั้งแบบรายตวแปร และแบบทั้งหมด (เมื่อเสร็จแล้ว reload หากต้องการเปลี่ยนแปลงทั่วทั้งเว็บ)`
    window.sessionStorage.clear();
    window.location.reload();
  }
  activebutton = (id) => {
    var element = document.getElementById(id);
    var selectclass = document.getElementsByClassName("maptab");
    for (var i = 0; i < selectclass.length; i++) {
      selectclass[i].classList.remove("active");
    }
    element.classList.add("active");

  }

  handleLogin = () => {
    const { username, password, } = this.state
    const { login } = this.props
    try {
      let dataToLogin = {
        USER_NAME: username,
        PASSWORD: password,
      }
      this.setState({ statusLoading: true, statusText: 'Processing', })
      axios({
        method: 'post',
        url: config.API_URL + '/api/login',
        headers: {
          //'Authorization': this.state.tokenWithBearer,
          'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
        },
        data: dataToLogin,
        config: { headers: { 'Content-Type': 'multipart/form-data', } }
      })
        .then((res) => {
          console.log("response login >>>", res)
          let responseLogin = res
          if (responseLogin.data.success) {
            this.setState({   token: responseLogin.data.success.token,  })
            // แบบเก่า เก็บใน redux
            login({ username: username, token: responseLogin.data.success.token, })
            // แบบใหม่เก็บใน session
            window.sessionStorage.setItem("token", responseLogin.data.success.token);
            window.sessionStorage.setItem("user", username);
            this.setState({ statusLoading: false, statusText: 'Login Success', })
          }
        })
        .catch((error) => {
          console.error("error login >>>", error)
          this.setState({ statusLoading: false, statusText: 'Login Fail!', })
        })
    } catch (error) {
      console.error(error);
    }
  }
  handleLogout = () => {
    const { logout, loginData, } = this.props
    try {
      this.setState({ statusLoading: true, statusText: 'Processing', })
      // axios({
      //   method: 'post',
      //   url: config.API_URL + '/api/logout',
      //   headers: {
      //     'Authorization': loginData.token,
      //   },
      // })
      //   .then((res) => {
      //     console.log("response logout >>>", res)
      //     let responseLogout = res
      //     if (responseLogout.data.success) {

        this.setState({
          username: '',
          password: '',
          token: '',
          statusLoading: false,
          statusText: '',
        })
        logout()
        window.sessionStorage.clear();
        window.location.reload();

      //   }
      // })
      // .catch((error) => {
      //   console.error("error logout >>>", error)
      // this.setState({ statusLoading: false,statusText: 'Logout Fail!', })
      // })

    } catch (error) {
      console.error(error);
    }
  }
  handleModal = (e, param = 'nothing from callback function !') => {
    console.log('test handleModal >>> event >>>', e,' >>> param >>> ',param)
    // do somthing
    // ...

    // then close modal
    this.props.setModal({ modalAction: 'close', })
  }
  handleClearModal = () => {
    this.setState({
      username: '',
      password: '',
      token: '',
      statusLoading: false,
      statusText: '',
    })
    this.handleHideCaptcha()
  }
  handleHideCaptcha = () => {
    const captcha = document.getElementsByClassName('grecaptcha-badge')[0];
    if (captcha) { captcha.style.visibility = 'hidden'; }
  }
  handleShowCaptcha = () => {
    const captcha = document.getElementsByClassName('grecaptcha-badge')[0];
    if (captcha) { captcha.style.visibility = 'visible'; }
  }
  render() {
    const { statusLoading, statusText, } = this.state
    const { loginData, } = this.props
    const recaptchaText = (
      <div>
        {' This site is protected by reCAPTCHA and the Google '}
        <a style={{ fontWeight: 'bold' }} href="https://policies.google.com/privacy">{' Privacy Policy '}</a>
        {' and '}
        <a style={{ fontWeight: 'bold' }} href="https://policies.google.com/terms">{' Terms of Service '}</a>
        {' apply. '}
      </div>
    )


    return (
      <div >

        <nav className="navbar navbar-expand-lg navbar-light" id="navbar">
          <a className="navbar-brand mr-1" href="" >
            <img src={logo} className="img-fluid top-logo navlogo" alt="Responsive" style={{ width: '200px', height: '55px' }} />
          </a>
          <span className="navbar-text text-primary ml-2" style={{ fontSize: '1rem' }}>
            ระบบนำเสนอข้อมูลสถิติด้วยแผนที่
          <p className="text-primary" style={{ fontSize: '1.5rem' }}>{this.props.name ? this.props.name : "ประเทศไทย"}</p>
          </span>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse " id="navbarTogglerDemo02" >
            <ul className="nav navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link active" href="#"><strong>หน้าหลัก</strong></a>
              </li>
              <li className="nav-item">
                <Link to="/suduku" className="nav-link" href="#" data-toggle="modal" data-target="#help"><strong>วิธีการใช้งาน</strong></Link>
              </li>
              <li className="nav-item">
                <Link to="#" className="nav-link" href="https://gis.nso.go.th/"><strong>NSO-GISPORTAL</strong></Link>
              </li>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item dropdown no-arrow ">
                <a className="nav-link dropdown-toggle " href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src={loginData && loginData.loginStatus ? user_circle : user_circlelogin} alt="" width={50} />
                </a>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown" style={{ paddingLeft: '0px !impotant' }}>
                  {this.props.loginData.loginStatus ?
                    <span className="dropdown-item" >{'บัญชีผู้ใช้งาน : ' + this.props.loginData.username}</span> :
                    <span className="dropdown-item" >ผู้ใช้ทั่วไป</span>}

                  {/*  <span className="dropdown-item" >ระดับ{this.props.name ? this.props.name : "ประเทศไทย"}</span> */}
                  <div className="dropdown-divider" />
                  <a className="dropdown-item" href="#" data-backdrop="static" data-toggle="modal" data-target="#loginModal"
                    onClick={() => { this.handleShowCaptcha() }}>{loginData && loginData.loginStatus ? 'Logout' : 'Login'}</a>
                </div>
              </li>
            </ul>
            <button className="btn btn-link btn-sm text-dark order-1 order-sm-0 hide" id="sidebarToggle" href="#">
              <i className="fas fa-bars fa-3x" />
            </button>
          </div>
        </nav>
        {/*คู่มือการใช้งาน MOdal */}
        <div className="modal fade" id="help" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title w-100 text-center" id="exampleModalCenterTitle">วิธีการใช้งาน</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p><strong>คู่มือการใช้งานระบบนำเสนอข้อมูลสถิติด้วยแผนที่ประเทศไทย</strong></p>
                <a type="button" className="btn btn-primary" href="https://statgisv3.nso.go.th/pdf/คู่มือการใช้งานโปรแกรมประยุกต์ NSO-StatGIS.pdf" target="_blank"><i className="fa fa-file-pdf-o fa-1x text-light"></i> ดาวโหลด</a>
                <hr />
                <p><strong>คู่มือการใช้งานระบบนำเสนอข้อมูลสถิติด้วยแผนที่จังหวัด</strong></p>
                <a type="button" className="btn btn-primary" href="https://statgisv3.nso.go.th/pdf/คู่มือการใช้งานโปรแกรมประยุกต์ PNSO-StatGIS.pdf" target="_blank"><i className="fa fa-file-pdf-o fa-1x text-light"></i> ดาวโหลด</a>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger " data-dismiss="modal"> Close </button>
              </div>
            </div>
          </div>
        </div>
        {/* Login Logout Modal*/}
        <div className="modal fade" id="loginModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <GoogleReCaptchaProvider
              reCaptchaKey={config.RECAPTCHA_KEY} // สำหรับขึ้น server
              // reCaptchaKey="6LeZYugUAAAAAAqLnz2wfxYy2cp9fWfiWL8ka8M4" // สำหรับ dev
              data-badge="inline" >
              <div className="modal-content">
                <div className="modal-header">
                  <img src={logo} style={{ width: '350px', margin: '0% auto' }} className="img-fluid" alt="Responsive" />
                  <button className="close" type="button" data-dismiss="modal" aria-label="Close"
                    onClick={(event) => {
                      this.handleClearModal()
                    }}>
                    <span aria-hidden="true" style={{ fontSize: '30px' }} >×</span>
                  </button>
                </div>
                {loginData && loginData.loginStatus
                  ?
                  <>
                    {(statusText === 'Login Success')
                      ? <>
                        <div className="modal-body">
                          <div style={{ textAlign: 'center', color: `${(statusText === 'Login Success') ? 'green' : (statusText === 'Login Fail!') ? 'red' : ''}`, }}>{statusText}</div>
                        </div>
                        <div className="modal-footer" style={{ justifyContent: 'center', }} >
                          <button className="btn btn-primary" type="button" data-dismiss="modal"
                            onClick={(event) => {
                              this.handleClearModal()
                            }}>OK</button>
                          {recaptchaText}
                        </div>
                      </>
                      : <>
                        <div className="modal-body">
                          <Loader active={statusLoading} inline='centered' size='big' style={{ padding: '10px', }} />
                          <div style={{ textAlign: 'center', color: `${(statusText === 'Login Success') ? 'green' : (statusText === 'Login Fail!') ? 'red' : ''}`, }}>{statusText}</div>
                          <div style={{ paddingTop: '15px', textAlign: 'center', }}>
                            <span className='text-primary'>ต้องการออกจากระบบ</span>
                          </div>
                        </div>
                        <div className="modal-footer" style={{ justifyContent: 'center', }} >
                          <button className="btn btn-secondary" type="button" data-dismiss="modal"
                            onClick={(event) => {
                              this.handleClearModal()
                            }}>ยกเลิก</button>
                          <button className="btn btn-primary" type="button"
                            //data-dismiss="modal"
                            onClick={(event) => { this.handleLogout() }}>ยืนยัน</button>
                          {recaptchaText}
                        </div>
                      </>}
                  </>
                  :
                  <>
                    <div className="modal-body">
                      <div style={{ paddingBottom: '5px' }}>
                        <span className='text-primary'>กรุณาใส่ Username และ Password ให้ถูกต้อง </span>
                      </div>
                      <div style={{ color: 'red', paddingLeft: '100px' }}>{this.state.usernameTextError}</div>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: '100px' }}>Username</span>
                        </div>
                        <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                          onBlur={() => {
                            if (this.state.username.trim() === '') { this.setState({ usernameTextError: 'กรุณากรอก' }) } else { this.setState({ usernameTextError: '' }) }
                          }}
                          onChange={(event) => {
                            this.setState({
                              username: event.target.value,
                              statusText: '',
                            })
                          }} />
                      </div>
                      <div style={{ color: 'red', paddingLeft: '100px' }}>{this.state.passwordTextError}</div>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: '100px' }}>Password</span>
                        </div>
                        <input type="password" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                          onBlur={() => {
                            if (this.state.password.trim() === '') { this.setState({ passwordTextError: 'กรุณากรอก' }) } else { this.setState({ passwordTextError: '' }) }
                          }}
                          onChange={(event) => {
                            this.setState({
                              password: event.target.value,
                              statusText: '',
                            })
                          }} />
                      </div>
                      <Loader active={statusLoading} inline='centered' size='big' style={{ padding: '10px', }} />
                      <div style={{ textAlign: 'center', color: `${(statusText === 'Login Success') ? 'green' : (statusText === 'Login Fail!') ? 'red' : ''}`, }}>{statusText}</div>
                    </div>
                    <div className="modal-footer" style={{ justifyContent: 'center', }}>
                      <button className="btn btn-block btn-primary" type="button"
                        // data-dismiss="modal"
                        onClick={(event) => { this.handleLogin() }}>Login</button>
                         <button className="btn btn-block btn-secondary" type="button" data-dismiss="modal"
                        onClick={(event) => {
                          this.handleClearModal()
                        }}>Cancel</button>
                      {recaptchaText}
                    </div>
                  </>
                }
              </div>
            </GoogleReCaptchaProvider>
          </div>
        </div>
        {/* แสดงรายการสถิติที่เลือก และแถบเลือกแผนที่ */}
        <nav className="navbar navbar-light  " style={{ backgroundColor: '#67a6fc', filter: 'drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2))' }}>
          <form className="form-inline ">
            <li className="breadcrumb-item active text-light"><strong>รายการสถิติที่เลือก</strong></li>
            <li className="text-light">&nbsp;|&nbsp;</li>
            <li className="breadcrumb-item active " style={{ color: `${this.props.selectedStat.statName ? '#FFF' : '#000'}` }}>{this.props.selectedStat.statName ? this.props.selectedStat.statName : 'กรุณาเลือกรายการสถิติ'}</li>
            {this.props.layersuccess && this.props.layersuccess.statusCrassify !== "" ?
              <>
                <li className="" style={{ color: `${this.props.layersuccess.status ? '#FFF' : '#ef0909'}` }}>{this.props.layersuccess.statusCrassify.replace(/,/g , "")}</li>
              </> : ''}
            {/* {this.props.selectedStat.statName && this.props.layersuccess.status ?
              <>
                <li className="breadcrumb-item active " style={{ color: `${this.props.selectedStat.statName ? '#FFF' : '#C75272'}` }}>{this.props.selectedStat.classify.status ? this.props.selectedStat.classify.classifyData.map((item, index) => { return (<span key={index}>{item.codelist.label + '-' + item.codelistChild.label}</span>) }) : ''}</li>
                <li className="breadcrumb-item active " style={{ color: `${this.props.selectedStat.statName ? '#FFF' : '#C75272'}` }}>{this.props.selectedStat.classify.status ? 'ปี พ.ศ ' + this.props.selectedStat.time.year.label : ''}</li>
                <li className="breadcrumb-item active " style={{ color: `${this.props.selectedStat.statName ? '#FFF' : '#C75272'}` }}>{this.props.selectedStat.classify.status ? 'ระดับพื้นที่ ' + this.props.selectedStat.area.label : ''}</li>
              </> : ''} */}
          </form>
          <div className="form-inline" id="menu-top">
            {/* <button id="maptab1" type="button" name="close" className="maptab active ml-3" style={{ borderRadius: '5px' }}
              onClick={() => { this.props.setmenu("close"); this.activebutton("maptab1") }}>
              <img src={imgmap1} alt="" title="แผนที่" width={35} />
            </button>
            <button id="maptab2" type="button" name="showchart" className="maptab ml-3" style={{ borderRadius: '5px' }}
              onClick={() => { this.props.setmenu("showchart"); this.activebutton("maptab2") }}>
              <img src={imgmap2} alt="" title="กราฟ" width={35} />
            </button>
            <button id="maptab3" type="button" name="showtable" className="maptab ml-3" style={{ borderRadius: '5px' }}
              onClick={() => { this.props.setmenu("showtable"); this.activebutton("maptab3") }}>
              <img src={imgmap3} alt="" title="ตาราง" width={35} />
            </button>
            <button id="maptab4" type="button" name="showdashboard" className="maptab ml-3" style={{ borderRadius: '5px' }}
              onClick={() => { this.props.setmenu("showdashboard"); this.activebutton("maptab4") }}>
              <img src={imgmap4} alt="" title="ผังชี้สภาพ" width={35} />
            </button> */}

            <img title="แผนที่" height={40} className="maptabnew" onClick={() => { this.props.setmenu("close") }}
              src={`${!this.props.menutab.showchart && !this.props.menutab.showtable && !this.props.menutab.showdashboard ? navbar_icon_1_active : navbar_icon_1_v2}`} />
            <img title="กราฟ" height={40} className="maptabnew" onClick={() => { this.props.setmenu("showchart") }}
              src={`${this.props.menutab.showchart ? navbar_icon_2_active : navbar_icon_2_v2}`} />
            <img title="ตาราง" height={40} className="maptabnew" onClick={() => { this.props.setmenu("showtable") }}
              src={`${this.props.menutab.showtable ? navbar_icon_3_active : navbar_icon_3_v2}`} />

            {/* <img title="🚫ผังชี้สภาพ" height={40}  className="maptabnew" onClick={() => { this.props.setmenu("showdashboard") }}
              src={`${this.props.menutab.showdashboard ? navbar_icon_4_active : navbar_icon_4_v2}`} /> */}
          </div>
        </nav>
      </div>

    );
  }
}
const mapStateToProps = (state) => ({
  loginData: state.loginData,
  menutab: state.menuTab,
  mapView: state.mapView,
  drawType: state.drawType,
  selectedCategory: state.selectedCategory,
  selectedStat: state.selectedStat,
  selectedStatFilter: state.selectedStatFilter,
  swipeMap: state.swipeMap,
  provinceMode: state.provinceMode,
  layersuccess: state.layersuccess,
})

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(login(data)),
  logout: () => dispatch(logout()),
  setmenu: (data) => dispatch(changMenuTab(data)),
  setChangMapView: (data) => dispatch(changMapView(data)),
  setChangDrawType: (data) => dispatch(changDrawType(data)),
  setSelectCategoryData: (data) => dispatch(selectCategoryData(data)),
  setSelectStatData: (data) => dispatch(selectStatData(data)),
  setSelectFilterStatData: (data) => dispatch(selectFilterStatData(data)),
  setChangSwipeMap: (data) => dispatch(changSwipeMap(data)),
  setModal: (data) => dispatch(showModal(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
