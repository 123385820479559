const provinceModeState = {
  provinceModeOpen: false,
  NAME_SCHEMA_MODE: 'NSO_STAT_GIS',
  NAME_SCHEMA_MAIN: 'NSO_STAT_GIS',
  NAME_SCHEMA_PROVINCE: '',
}

// { mode: 'add', NAME_SCHEMA: 'PNSO_EX', }
// { mode: 'change', provinceModeOpen: true, }
// { mode: 'change', provinceModeOpen: false, }

const provinceModeReducer = (state = provinceModeState, action) => {
  switch (action.type) {
    case "CHANGE_PROVINCE_MODE":

      switch (action.payload.mode) {
        case 'add':
          state = {
            provinceModeOpen: false,
            NAME_SCHEMA_MODE: 'NSO_STAT_GIS',
            NAME_SCHEMA_MAIN: 'NSO_STAT_GIS',
            NAME_SCHEMA_PROVINCE: action.payload.NAME_SCHEMA,
          }
          return state;

        case 'change':
          action.payload.provinceModeOpen
            ? state = {
              provinceModeOpen: true,
              NAME_SCHEMA_MODE: state.NAME_SCHEMA_PROVINCE,
              NAME_SCHEMA_MAIN: state.NAME_SCHEMA_MAIN,
              NAME_SCHEMA_PROVINCE: state.NAME_SCHEMA_PROVINCE,
            }
            : state = {
              provinceModeOpen: false,
              NAME_SCHEMA_MODE: state.NAME_SCHEMA_MAIN,
              NAME_SCHEMA_MAIN: state.NAME_SCHEMA_MAIN,
              NAME_SCHEMA_PROVINCE: state.NAME_SCHEMA_PROVINCE,
            }
          return state;

        default:
          return state;
      }
      
    default:
      return state;
  }
}
export default provinceModeReducer; 