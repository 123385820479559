const modalState = {
  status: false,
  title: '',
  icon: '',
  text: '',
  button: [],
}

const modalReducer = (state = modalState, action) => {
  switch (action.type) {
    case "MODAL":
      switch (action.payload.modalAction) {
        case 'open':
          state = {
            status: true,
            title: action.payload.title,
            icon: action.payload.icon,
            text: action.payload.text,
            button: action.payload.button,
          }
          break;
        case 'close':
          state = {
            status: false,
            title: '',
            icon: '',
            text: '',
            button: [],
          }
          break;
        default:
          break;
      }
      return state;
    default:
      return state;
  }
}

export default modalReducer; 