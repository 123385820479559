
const paramurl = {
    changwat: '',
    center:'',
  }
  const paramurlReducer = (state = paramurl, action) => {
    switch (action.type) {
        case "CHANGE_paramurl":
            state = {
                changwat: action.payloadurl,
                center: action.payloadcenter,
            }
            return state;
        default:
            return state;
    }
  }
  export default paramurlReducer;