import React, { Component } from "react";
import Navbar from "../../componentnso/Navbar";
import Map from "../../Action/Maps";
// import Tabbar from "../../componentnso/Tabbar";
import Footer from "../../componentnso/Footer";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidepnso: false,
    };
  }
  componentDidMount() {
    if (this.props.params.name !== null) {
      this.setState({
        slidepnso: true,
      });
    }
  }
  render() {
    return (
      <div style={{ height: '100% !important' }}>
        <Navbar name={this.props.params.name} />
        {/* <Tabbar /> */}
        <div className="w-100 " style={{ height: '80vh', maxHeight: '100vh' }}>
          <Map menu={this.state.slidepnso} />
        </div>
        <Footer />
      </div>
    );
  }
}
export default MainPage;