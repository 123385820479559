import React, { Component } from 'react'
import '../css/chart.css'
import styled from "styled-components";
import Chartgl from "react-google-charts";
import { connect } from "react-redux";
import loadding from '../icon/loadding.gif';
import { Icon as SICON } from 'semantic-ui-react';
import { selectStatData } from '../redux/actions'

const Tabmenuchart = styled.nav`
    position:static;
    margin-top:-15px;
    width:100%;
    height:50px;
    background-color: white;
`;
const Buttonchart = styled.button`
    height:100%;
    float:right;
    font-size: 16px;

`;
var checkdata = false;
class Chart extends Component {
  constructor(props) {
    super(props)
    this.changchart = this.changchart.bind(this);
    this.state = {
      page: 1,
      chart: 'horizontal',
      chartbar: this.props.data,
    }

  }

  componentDidMount = () => {
    this.setState({
      chartbar: this.props.data,
    })
    /*--------------------------------------------------pagination--------------------------------*/
    const firstLinks = document.querySelectorAll(".first a");
    const thirdLinks = document.querySelectorAll(".third a");

    // JS code needed for first pagination effect
    firstLinks.forEach((link) => {
      link.addEventListener("mouseover", mouseOverEvent);
      link.addEventListener("mouseleave", mouseLeaveEvent);
    });

    function mouseOverEvent(e) {
      firstLinks.forEach((link) => {
        link.style.opacity = 0.7;
      });

      e.target.style.opacity = 1;
      e.target.style.transform = "scale(1.2)";
    }

    function mouseLeaveEvent(e) {
      firstLinks.forEach((link) => {
        link.style.opacity = 1;
        e.target.style.transform = "scale(1)";
      });
    }

    // JS code needed for third pagination effect
    let opacityValue = 0;
    thirdLinks.forEach((link, index) => {
      if (index > 5) {
        opacityValue -= 0.15;
      } else {
        opacityValue += 0.15;
      }

      link.style.opacity = opacityValue;
    });
    /*--------------------------------------------------pagination--------------------------------*/


  }
  /*  componentDidUpdate = (prevProps) => {
     console.log("did")
     if (prevProps.data !== this.props.data) {
       this.setState({ chartbar: this.props.data })
     }
   }
   componentWillUpdate = (prevProps) => {
     console.log("will")
   }
  */

  changchart = (name) => {
    this.setState({
      chart: name,
    })
  }
  next = async () => {

    await this.props.setSelectStatData({ pagechart: { status: true, page: this.props.selectedStat.pagechart.page + 1 } })
    await this.props.channgpage()
  }
  preview = async () => {
    await this.props.setSelectStatData({ pagechart: { status: true, page: this.props.selectedStat.pagechart.page - 1 } })
    await this.props.channgpage()
  }
  calmaxdata = (data)=>{
    let newal = [];
    data.forEach((item,index)=>{
    if(index > 0)
    newal.push(item[1])
    })
    console.log(Math.max(...newal))
    return Math.max(...newal)
  }
  render() {
    const {
      data,
      datatreemap,
    } = this.props;

    let loaddings = <div ><img src={loadding} alt='' width={100} style={{ position: 'absolute', display: 'block', margin: 'auto', top: 0, left: 0, right: 0, bottom: 0 }}></img></div>

    return (
      <div className={`divchart ${this.props.show ? 'active animate__animated animate__fadeInRight' : ''}`} id="divchart">
        <Tabmenuchart>
          <Buttonchart className="btn btn-outline-danger" onClick={() => { this.changchart('Treemap') }}>กราฟทรีแมพ</Buttonchart>
          <Buttonchart className="btn btn-outline-success" onClick={() => { this.changchart('line') }}>กราฟเส้นตรง</Buttonchart>
          <Buttonchart className="btn btn-outline-info" onClick={() => { this.changchart('horizontal') }}>กราฟแท่ง</Buttonchart>
        </Tabmenuchart>
        <p style={{ fontSize: '18px', textAlign: 'center', margin: 'center', textDecoration: 'underline' }}>{this.props.selectedStat.statName !== '' && this.props.layersuccess.status === true ? this.props.selectedStat.statName : 'กรุณาเลือกรายการสถิติ'}</p>
        {this.props.layersuccess.status === true ?
          <div>
            {this.state.chart === 'horizontal' &&
              <Chartgl
              width={'100%'}
                //style={{width:'100%',height:`${this.props.selectedStat.area.code === "AR_CL_SAO" ? '5000vh':this.props.selectedStat.area.code === "AR_CL_AMP" ? '50vh':this.props.selectedStat.area.code === "AR_CL_REG" ? '25vh':'200vh'}`,overflowY:'auto'}}
                chartType="Bar"
                loader={<div>Loading Chart</div>}
                data={data ? data.items.data : ''}
                //style={{ width: '100%', height: '50vh' }}
                options={{
                  animation: {
                    duration: 1000,
                    easing: 'out',
                    startup: true,
                  },
                  hAxis: {
                    viewWindow: {
                      max: data ? data.items.max : 10000,
                      min: 0,
                    }
                  },

                  height: data ? (data.items.data.length - 1) * 30 + 80 : 380,
                  'width': "100%",

                  bars: 'horizontal',
                  bar: { groupWidth: "90%", },
                  chartArea: {
                    height: "5%",
                  },

                  chart: {
                    title: this.props.selectedStat.statName !== '' ? 'แหล่งที่มา ' + this.props.selectedStat.agency.label : 'กรุณาเลือกรายการสถิติ',
                    subtitle: `หน่วยนับ ${this.props.selectedStat.description.statUnitMultiply !== 'ไม่มีข้อมูล' && this.props.selectedStat.description.statUnitMultiply !== "-" ? this.props.selectedStat.description.statUnitMultiply : ''}${this.props.selectedStat.description.statUnit ? this.props.selectedStat.description.statUnit : '-'}`,

                  },
                  chartArea: { width: '100%', height: '5%' },
                  explorer: { axis: 'horizontal' },
                  axes: {
                    x: {
                      0: { side: 'top', }, // Top x-axis.
                      all: {
                        range: {
                          max: data ? data.items.max : 10000,
                          min: 0
                        }
                      }
                    },

                  },
                  trendlines: { 0: {} },
                  legend: 'none',

                }}

                rootProps={{ 'data-testid': '1' }}
              //legendToggle

              />}
            {this.state.chart === 'line' &&
              <Chartgl
                width={'100%'}
                height={'50vh'}
                chartType="ScatterChart"
                loader={<div>Loading Chart</div>}
                data={data ? data.items.data : ''}

                options={{
                  // Material design options
                  animation: {
                    duration: 1000,
                    easing: 'out',
                    startup: true,
                  },
                  vAxis: {
                    viewWindow: {
                      max: data ? data.items.max : 10000,
                      min: 0,
                    }
                  },
                  chart: {
                    title: this.props.selectedStat.statName !== '' ? 'แหล่งที่มา ' + this.props.selectedStat.agency.label : 'กรุณาเลือกรายการสถิติ',
                    subtitle: `หน่วยนับ ${this.props.selectedStat.description.statUnitMultiply !== 'ไม่มีข้อมูล' ? this.props.selectedStat.description.statUnitMultiply : ''}${this.props.selectedStat.description.statUnit ? this.props.selectedStat.description.statUnit : '-'}`,
                  },
                  trendlines: { 0: {} },
                  explorer: { axis: 'horizontal' },
                  legend: 'none',
                  /* axes: {
                    y: {
                      'hours studied': { label: 'Hours Studied' },
                      'final grade': { label: 'Final Exam Grade' },
                    },
                  }, */
                }}
                rootProps={{ 'data-testid': '1' }}

              />
            }
            {this.state.chart === 'Treemap' &&
              <Chartgl
                width={'100%'}
                height={'50vh'}
                chartType="TreeMap"
                loader={<div>Loading Chart</div>}
                data={datatreemap ? datatreemap : ''}
                options={{
                  onClick: false,
                  minColor: '#B2EBF2',
                  midColor: '#00BCD4',
                  maxColor: '#006064',
                  headerHeight: 15,
                  fontColor: 'black',
                  showScale: true,
                }}
                rootProps={{ 'data-testid': '0' }}
              />
            }
            {this.state.chart === "Treemap" ? "" :
              <div className="pagination-section">
                <ul className="pagination first">
                  <li className="page-item"><a onClick={() => { if (this.props.selectedStat.pagechart.page == 1) { } else { this.preview() } }}><i className="angle double left small icon"></i>
                  </a></li>
                  <li className="page-item"><input type="number" value={this.props.selectedStat.pagechart.page} id="pagevalue" onChange={async (e) => {
                    console.log(e.target.value)
                    if (e.target.value == "") {
                      console.log("e.target.value == null")
                      this.props.setSelectStatData({ pagechart: { status: false } })
                    } else if (e.target.value >= 1 && e.target.value <= data.items.last_page) {
                      await this.props.setSelectStatData({ pagechart: { status: true, page: parseInt(e.target.value) } })
                      this.props.channgpage();
                    } else {
                      console.log("e.target.value no case")
                      this.props.setSelectStatData({ pagechart: { status: false } })
                    }
                  }} /></li>
                   <li className="page-item"><p>/{data ? data.items.last_page : ""}</p></li>
                  <li className="page-item"><a onClick={() => {
                    if (data) {
                      if (data.items.last_page == this.props.selectedStat.pagechart.page) {
                      } else {
                        this.next()
                      }
                    } else { }
                  }}><i className="angle double right small icon"></i>
                  </a></li>
                  <li className="page-item"><p>แสดง {data ? data.items.data.length - 1 : ""} จาก {data ? (data.items.total - data.items.last_page) + " รายการ" : ""}</p></li>
                </ul>
              </div>}
          </div> : loaddings}


      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  menutab: state.menuTab,
  mapView: state.mapView,
  drawType: state.drawType,
  selectedCategory: state.selectedCategory,
  selectedStat: state.selectedStat,
  selectedStatFilter: state.selectedStatFilter,
  swipeMap: state.swipeMap,
  provinceMode: state.provinceMode,
  layersuccess: state.layersuccess,
})
const mapDispatchToProps = (dispatch) => ({
  setSelectStatData: (data) => dispatch(selectStatData(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chart)
