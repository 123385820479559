import TileLayer from "ol/layer/Tile";
// import TileWMS from "ol/source/TileWMS";
import TileJSON from "ol/source/TileJSON";
import BingMaps from "ol/source/BingMaps";
import {Group as LayerGroup} from "ol/layer";
import {
  // Vector as VectorSource,
  // OSM as OSMSource,
  // XYZ as XYZSource,
  TileWMS as TileWMSSource,
  OSM,
  TileImage,
} from "ol/source";

export var APIWMS = new TileLayer({
  title: "APIWMS",
  source: new TileWMSSource({
    url: "https://mapserv.nso.go.th/geoserver/NSOGIS/wms?",
    params: {
      LAYERS: "NSOGIS:HALLOWEENLAYER",
      TILED: true,
      SLD: "https://statgis.nso.go.th/sld/VINDBUILD/GROWTH3.sld",
    },
    serverType: "geoserver",
    attributions: "NSO @COPPYRIGHT",
  }),
});
export var APIWMS2 = new TileLayer({
  title: "APIWMS2",
  source: new TileWMSSource({
    url: "https://mapserv.nso.go.th/geoserver/NSOGIS/wms?",
    params: {
      LAYERS: "NSOGIS:HALLOWEENLAYER",
      TILED: true,
      SLD: "https://statgis.nso.go.th/sld/INDAGRI_SK2/RATIOHDA.sld",
      //SLD_BODY: 'http://28c3f27e.ngrok.io/geosld/province.sld',
      // STYLES: "province2554",
    },
    serverType: "geoserver",
    attributions: "NSO@COPPYRIGHT",
  }),
});


export const OpenstreetMapStandrad = new TileLayer({
  source: new OSM(),
  title: "mapstandard",

});
export const watercolor = new TileLayer({
  title: "OpenstreetMapwatercolor",
  source: new OSM({
    url: "http://tile.stamen.com/watercolor/{z}/{x}/{y}.jpg",
  }),
  crossOrigin: 'anonymous',
});

export const OpenstreetMapwatercolor = new TileLayer({
  title: "OpenstreetMapwatercolor",
  source: new OSM({
    url: "https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg",
  }),
  visible: false,
});
export const OpenstreetMaptoner = new TileLayer({
  title: "OpenstreetMaptoner",
  source: new OSM({
    url: "http://tile.stamen.com/toner/{z}/{x}/{y}.png/กวย",
  }),
  visible: false,
});
export const OpenMapdark = new TileLayer({
  title: "OpenMapdark",
  source: new TileJSON({
    url:
      "https://api.maptiler.com/maps/darkmatter/tiles.json?key=O7dq18egPFS3MBQOEvHx",
  }),
  visible: false,
  attributions: "© MapTiler © OpenStreetMap contributors",
});
//----------------------------------------google-maps--------------------------------------------------------------//
export var googleLayerRoadNames = new TileLayer({
  title: "Google Road Names",
  source: new OSM({
    url: "http://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}",
  }),
  visible: false,
});

export var googleLayerRoadmap = new TileLayer({
  title: "Google Road Map",
  source: new OSM({
    url: "http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
  }),
  visible: false,
});

export var googleLayerSatellite = new TileLayer({
  title: "Google Satellite",
  source: new OSM({
    url: "http://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}",
  }),
  visible: false,
});

export var googleLayerHybrid = new TileLayer({
  title: "Google Satellite & Roads",
  source: new OSM({
    url: "http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
  }),
  visible: false,
});

export var googleLayerTerrain = new TileLayer({
  title: "Google Terrain",
  source: new OSM({
    url: "http://mt1.google.com/vt/lyrs=t&x={x}&y={y}&z={z}",
  }),
  visible: false,
});

export var googleLayerHybrid2 = new TileLayer({
  title: "Google Terrain & Roads",
  source: new OSM({
    url: "http://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
  }),
  visible: true,
});

export var googleLayerOnlyRoad = new TileLayer({
  title: "Google Road without Building",
  source: new OSM({
    url: "http://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
  }),
  visible: false,
});
//-----------------------------THAI-CHOTE------------------------------------------------------------//
export var Thaichote = new TileLayer({
  title: "Thaichote",
  source: new TileImage({
    url:
      "http://go-tiles1.gistda.or.th/mapproxy/wmts/thaichote/GLOBAL_WEBMERCATOR/{z}/{x}/{y}.png",
    attributions: "GISDA @COPPYRIGHT",
  }),
  visible: false,
});
const stylesBing = [
  "RoadOnDemand",
  "Aerial",
  "AerialWithLabelsOnDemand",
  "CanvasDark",
  "OrdnanceSurvey",
];
export const bingmap = [];
let i, ii;
for (i = 0, ii = stylesBing.length; i < ii; ++i) {
  bingmap.push(
    new TileLayer({
      title: "bing" + i,
      visible: false,
      preload: Infinity,
      source: new BingMaps({
        /* key: "AgbS6SXsli6OsqVKNjx2V7i1CFIKu6HkYal24RVwFlqkxnu4NtdSA828j8pq7P-X", */
        key: "AiTQNCOhuHGcLSibauELW0o_wHp-EiajkrHSO6b15hFDBaL0F679q7uzieHO2OFK",
        imagerySet: stylesBing[i],
        // use maxZoom 19 to see stretched tiles instead of the BingMaps
        // "no photos at this zoom level" tiles
        // maxZoom: 19
      }),
    })
  );
}
export const LayerGroups = new LayerGroup({
  layers: [
    OpenstreetMapStandrad,
    googleLayerRoadNames,
    googleLayerSatellite,
    googleLayerRoadmap,
    googleLayerHybrid,
    bingmap[0],
    bingmap[1],
    bingmap[2],
    bingmap[3],
    bingmap[4],
    Thaichote,
    OpenstreetMapwatercolor,
    OpenstreetMaptoner,
    OpenMapdark,
  ],
  name:"layergroup"
});