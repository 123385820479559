const style = {
    status: '',
    style: '',
    swipestyle: {
        status: '',
        style: '',
    }
}
const selectStyle = (state = style, action) => {
    switch (action.type) {
        case "CHANG_STYLE":
            state = {
                status: action.payload.status ? action.payload.status : state.status,
                style: action.payload.style ? action.payload.style : state.style,
                swipestyle: action.payload.swipestyle ? action.payload.swipestyle : state.swipestyle,
            }
            return state;
        default:
            return state;
    }
}
export default selectStyle;