import React, { Component } from 'react'
import '../css/table.css'
import styled from "styled-components";
// import DataTable from 'react-data-table-component';
// import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Dropdown } from 'semantic-ui-react'

import axios from 'axios';
import config from '../config';
import loadding from '../icon/loadding.gif';
var $ = require('jquery');
$.DataTable = require('datatables.net');
const Tabmenutable = styled.nav`
    position:static;
    width:100%;
    height:50px;
    /* background-color: whitesmoke; */
`;
// const Buttondowload = styled.button`
//     position:relative;
//     float:right;
//     height:100%;
//     margin-right:10px;
//     font-size:16px;
//     &:focus{
//         i{
//             background-color:white;
//         }
//     }
//     &:hover{
//         i{
//             background-color:white;
//         }
//     }`;


class Table extends Component {
  // constructor(props) {
  //   super(props)
  // }

  componentDidMount() {
    /*   this.$el = $(this.el)
      this.$el.DataTable({
        data: this.props.datatable[0],
        columns:this.props.datatable[1],
      }) */
    /*  var table = $('#myTable').DataTable( {
       select: true,
       data:data[0],
       columns: data[1],
     }); */
    /* var myTable = $('#myTable').DataTable({
      "columns": [
        //"dummy" configuration
        { visible: true }, //col 1
        { visible: true }, //col 2
        { visible: true }, //col 3
        { visible: true }, //col 4
        { visible: true }, //col 5
        { visible: true }, //col 6
        { visible: true }, //col 7
        { visible: true }  //col 8
      ]
    }); */

  }
  /*  componentWillUnmount(){
     this.$el.DataTable.destroy(true);
     console.log("willunmount")
   } */
  componentDidUpdate = (prevProps) => {

    if (prevProps.datatable !== this.props.datatable) {
      /* $('#myTable').DataTable().destroy(); */

      this.createTable();
      this.updateTable();

    }
  }
  numcolumn=(data)=>{
    let numcolumn=[];
    data.forEach((item,index)=>{
      if(index == 0){

      }else{
      numcolumn.push(index);
      }
    });
    return numcolumn;
  }
  createTable = () => {
    if ($.fn.DataTable.isDataTable('#myTable')) {
      $('#myTable').DataTable().destroy();
      $('#myTable').empty();
      console.log("destory")
    }
    console.log("createtable")

    let table = $('#myTable').DataTable({
      "responsive": true,
      "columns": this.props.datatable ? this.props.datatable[1] :null,
      "data": this.props.datatable ? this.props.datatable[0]:null,
      "columnDefs": [{
        "searchable": true,
        "orderable": true,
        "targets": this.numcolumn(this.props.datatable ? this.props.datatable[1] :null),
        "className": 'dt-body-right'
      }],
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      "order": [[1, 'asc']],
      "language": {
        "paginate": {
          "previous": "ย้อนกลับ",
          "next": "หน้าถัดไป",
        },
        "search": 'ค้นหาข้อมูล',
        "info": `แสดงผลข้อมูลตั้งแต่ _START_ ถึง _END_ จากทั้งหมด _TOTAL_ ข้อมูล : แหล่งที่มา ${this.props.selectedStat.agency.label}`,
        "lengthMenu": "แสดงผลข้อมูล _MENU_ แถว",
      }

    });
    /* table.on( 'order.dt search.dt', function () {
      table.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
          cell.innerHTML = i+1;
      } );
  } ).draw(); *///เพิ่มตัวเลขอัตโนมัติ
  }
  updateTable = () => {
  /*   console.log("tableupdate")
    var table = $('#myTable').DataTable();
     table.clear();
     table.rows.add(this.props.datatable[0]).draw(); */
  }
  Exportpdf = () => {
    let exportpdf = new FormData();
    exportpdf.append('nametable', this.props.selectedStat.statNameTable);
    exportpdf.append('type_map', this.checktypemap());
    exportpdf.append('area', this.props.selectedStat.area.code);
    exportpdf.append('statlist_code_id', this.props.selectedStat.statCodeId.toUpperCase());
    exportpdf.append('year', this.props.selectedStat.time.year.id);
    exportpdf.append('REF', this.props.selectedStat.agency.label);
    exportpdf.append('YEAR_DATA', this.props.selectedStat.time.year.label);
    exportpdf.append('DATA_ITEM', this.props.selectedStat.statName);
    exportpdf.append(
      "agency_id",
      this.props.selectedStat.agency.allRawData.REF_ID
    );
    if (this.props.selectedStat.classify.status) {
      this.props.selectedStat.classify.classifyData.map((itemClassify, index) => {
        exportpdf.append(`codelist_[${index}]`, itemClassify.codelist.id);
        itemClassify.codelist.child.map((son, index) => {
          exportpdf.append(`codelist_son[0][${index}]`, son.id);
          return ''
        })
        return ''
      })

    }

    axios({
      method: 'post',
      url: config.API_URL + '/api/gis/tablepdfexport',
      responseType: 'blob',
      headers: {
        'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE,
        'Access-Control-Allow-Origin': '*',

      },
      data: exportpdf,
      config: { headers: { 'Content-Type': 'multipart/form-data', } },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.props.selectedStat.statName+this.props.selectedStat.time.year.label+`.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  }
  Exportcsv = () => {
    let exportcsv = new FormData();
    exportcsv.append('nametable', this.props.selectedStat.statNameTable);
    exportcsv.append('type_map', this.checktypemap());
    exportcsv.append('area', this.props.selectedStat.area.code);
    exportcsv.append('statlist_code_id', this.props.selectedStat.statCodeId.toUpperCase());
    exportcsv.append('year', this.props.selectedStat.time.year.id);
    exportcsv.append('REF', this.props.selectedStat.agency.label);
    exportcsv.append('YEAR_DATA', this.props.selectedStat.time.year.label);
    exportcsv.append('DATA_ITEM', this.props.selectedStat.statName);
    exportcsv.append(
      "agency_id",
      this.props.selectedStat.agency.allRawData.REF_ID
    );
    if (this.props.selectedStat.classify.status) {
      this.props.selectedStat.classify.classifyData.map((itemClassify, index) => {
        exportcsv.append(`codelist_[${index}]`, itemClassify.codelist.id);
        itemClassify.codelist.child.map((son, index) => {
          exportcsv.append(`codelist_son[0][${index}]`, son.id);
        return ''
        })
        return ''
      })
    }
    axios({
      method: 'post',
      url: config.API_URL + '/api/gis/tablecsvexport',
      responseType: 'blob',
      headers: {
        'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
      },
      data: exportcsv,
      config: { headers: { 'Content-Type': 'multipart/form-data', } },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(response.data)
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.props.selectedStat.statName+this.props.selectedStat.time.year.label+'.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  }
  Exportxlsx = () => {
    let exportxlsx = new FormData();
    exportxlsx.append('nametable', this.props.selectedStat.statNameTable);
    exportxlsx.append('type_map', this.checktypemap());
    exportxlsx.append('area', this.props.selectedStat.area.code);
    exportxlsx.append('statlist_code_id', this.props.selectedStat.statCodeId.toUpperCase());
    exportxlsx.append('year', this.props.selectedStat.time.year.id);
    exportxlsx.append('REF', this.props.selectedStat.agency.label);
    exportxlsx.append('YEAR_DATA', this.props.selectedStat.time.year.label);
    exportxlsx.append('DATA_ITEM', this.props.selectedStat.statName);
    exportxlsx.append(
      "agency_id",
      this.props.selectedStat.agency.allRawData.REF_ID
    );
    if (this.props.selectedStat.classify.status) {
      this.props.selectedStat.classify.classifyData.map((itemClassify, index) => {
        exportxlsx.append(`codelist_[${index}]`, itemClassify.codelist.id);
        itemClassify.codelist.child.map((son, index) =>
          exportxlsx.append(`codelist_son[0][${index}]`, son.id)
        )
        return ''
      })
    }
    axios({
      method: 'post',
      url: config.API_URL + '/api/gis/tablexlsxexport',
      responseType: 'blob',
      headers: {
        'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
      },
      data: exportxlsx,
      config: { headers: { 'Content-Type': 'multipart/form-data', } },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.props.selectedStat.statName+this.props.selectedStat.time.year.label+'.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  }
  checktypemap = () => {
    let typemap = null;
    if (this.props.selectedStat.area.code === "AR_CL_REG") {
      typemap = this.props.selectedStat.areaTypeMap.code;
    }
    if (this.props.selectedStat.area.code === "AR_CL_CWT") {
      if (parseInt(this.props.selectedStat.time.year.id) <= 2553) {
        typemap = 'province 2543-2553';
      } else {
        typemap = 'province 2554-now';
      }
    } else if (this.props.selectedStat.area.code === "AR_CL_AMP") {
      if (parseInt(this.props.selectedStat.time.year.id) <= 2549) {
        typemap = 'district 2543-2549';
      } else if (parseInt(this.props.selectedStat.time.year.id) >= 2550 && parseInt(this.props.selectedStat.time.year.id) < 2551) {
        typemap = 'district 2550-2551';
      } else if (parseInt(this.props.selectedStat.time.year.id) >= 2552 && parseInt(this.props.selectedStat.time.year.id) < 2553) {
        typemap = 'district 2552-2553';
      } else if (parseInt(this.props.selectedStat.time.year.id) > 2554) {
        typemap = 'district 2554-now';
      } else {
        typemap = 'district 2543-2549';
      }
    } else if (this.props.selectedStat.area.code === "AR_CL_TAM") {
      if (parseInt(this.props.selectedStat.time.year.id) <= 2553) {
        typemap = 'sub-district 2553-2553';
      } else if (parseInt(this.props.selectedStat.time.year.id) >= 2554 && parseInt(this.props.selectedStat.time.year.id) < 2559) {
        typemap = 'sub-district 2554-2559';
      } else {
        typemap = 'sub-district 2560-now';

      }
    } else if (this.props.selectedStat.area.code === "AR_CL_MUNI") {
      if (parseInt(this.props.selectedStat.time.year.id) <= 2543) {
        typemap = this.props.selectedStat.areaTypeMap.code;
      } else {
        typemap = this.props.selectedStat.areaTypeMap.code;
      }
    } else if (this.props.selectedStat.area.code === "AR_CL_CLU") {
      if (parseInt(this.props.selectedStat.time.year.id) <= 2543) {
        typemap = this.props.selectedStat.areaTypeMap.code;
      } else {
        typemap = this.props.selectedStat.areaTypeMap.code;
      }
    }
    return typemap;
  }
  render() {
    let loaddings = <div ><img src={loadding} alt='' width={100} style={{ position: 'absolute', display: 'block', margin: 'auto', top: 0, left: 0, right: 0, bottom: 0 }}></img></div>
    return (
      <div className={`animate__animated animate__fadeInRight divtable ${this.props.show ? 'active' : ''}`}>
        <Tabmenutable>
          <div>
            <Dropdown
              text='Export File'
              icon='file'
              floating
              labeled
              button
              className='icon'
              style={{ color: '#23898E', backgroundColor: '#FFF', border: '2px #23898E solid', float: 'right', marginTop: '2px' }}
            >
              <Dropdown.Menu>
                <Dropdown.Item><a href="#" onClick={() => { this.Exportcsv() }}><i className="fa fa-file-text-o fa-1x text-primary"></i> Export CSV</a></Dropdown.Item>
                <Dropdown.Item><a href="#" onClick={() => { this.Exportxlsx() }}><i className="fa fa-file-excel-o fa-1x text-primary"></i> Export XLSX</a></Dropdown.Item>
                <Dropdown.Item><a href="#" onClick={() => { this.Exportpdf() }}><i className="fa fa-file-pdf-o fa-1x text-primary"></i> Export PDF</a></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Tabmenutable>

        <div style={{ paddingLeft: '20px', height: '80%', overflowY: 'scroll' }}>
          <p style={{ fontSize: '20px', textAlign: 'center', margin: 'center', textDecoration: 'underline' }}>{this.props.selectedStat.statName !== '' && this.props.layersuccess.status === true ? this.props.selectedStat.statName : 'กรุณาเลือกรายการสถิติ'}</p>

          {this.props.layersuccess.status === true ?
            <div className="container" id="tb">
              <span>หน่วยนับ {this.props.selectedStat.description.statUnitMultiply !== 'ไม่มีข้อมูล' && this.props.selectedStat.description.statUnitMultiply !== "-" ? this.props.selectedStat.description.statUnitMultiply : ''}{this.props.selectedStat.description.statUnit ? this.props.selectedStat.description.statUnit : '-'}</span>
              <table className="table table-fixed table-bordered table-striped" id="myTable" data-order='[[ 1, "asc" ]]' data-page-length='10' style={{ width: '100%' }} ref={el => this.el = el}>
                {/*   <thead>
                  <tr>
                    {this.props.datatable[1] ? colum.map((namecolumn,index) =>
                    <th key={index.toString()}>xxx</th>
                    ) :''

                    }
                  </tr>
                </thead> */}
                <tbody>

                </tbody>

              </table>
            </div>
            : <>{loaddings}</>}


        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  menutab: state.menuTab,
  mapView: state.mapView,
  drawType: state.drawType,
  selectedCategory: state.selectedCategory,
  selectedStat: state.selectedStat,
  selectedStatFilter: state.selectedStatFilter,
  swipeMap: state.swipeMap,
  provinceMode: state.provinceMode,
  layersuccess: state.layersuccess,
})

export default connect(mapStateToProps, null)(Table)
