const loginState = {
  loginStatus: false,
  username: '',
  token: '',
}

// form of possible
// dispatch(login({username: '', token: '',}))
// dispatch(logout)

const loginReducer = (state = loginState, action) => {
  switch (action.type) {
    case "LOGIN":
      state = {
        loginStatus: true,
        username: action.payload.username,
        token: action.payload.token,
      }
      return state;
    case "LOGOUT":
      state = {
        loginStatus: false,
        username: '',
        token: '',
      }
      return state;
    default:
      return state;
  }
}

export default loginReducer; 