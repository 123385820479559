import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
import Navbar from "../componentnso/Navbar";
import Map from "./Maps";
import Footer from "../componentnso/Footer";
import styled from "styled-components";
import { connect, } from 'react-redux';
import {changparamurl} from '../redux/actions'
import { changeProvinceMode, } from '../redux/actions'

const PathName77Provinces = [
  // ---------- ภาคเหนือ (Northern)
  { nameTH: 'จังหวัดเชียงใหม่', NAME_SCHEMA: "PNSO_CHMAI_STAT_GIS", nameEN: 'chiangmai', nameENfull: 'chiang-mai', },
  { nameTH: 'จังหวัดเชียงราย', NAME_SCHEMA: "PNSO_CHRAI_STAT_GIS", nameEN: 'chiangrai', nameENfull: 'chiang-rai', },
  { nameTH: 'จังหวัดลำปาง', NAME_SCHEMA: "PNSO_LAMPANG_STAT_GIS", nameEN: 'lampang', },
  { nameTH: 'จังหวัดลำพูน', NAME_SCHEMA: "PNSO_LAMPHUN_STAT_GIS", nameEN: 'lamphun', },
  { nameTH: 'จังหวัดแม่ฮ่องสอน', NAME_SCHEMA: "PNSO_MAEHSON_STAT_GIS", nameEN: 'maehson', nameENfull: 'mae-hong-son', },
  { nameTH: 'จังหวัดน่าน', NAME_SCHEMA: "PNSO_NAN_STAT_GIS", nameEN: 'nan', },
  { nameTH: 'จังหวัดพะเยา', NAME_SCHEMA: "PNSO_PHAYAO_STAT_GIS", nameEN: 'phayao', },
  { nameTH: 'จังหวัดแพร่', NAME_SCHEMA: "PNSO_PHRAE_STAT_GIS", nameEN: 'phrae', },
  { nameTH: 'จังหวัดอุตรดิตถ์', NAME_SCHEMA: "PNSO_UTRADIT_STAT_GIS", nameEN: 'uttaradit', },
  // ---------- ภาคตะวันออกเฉียงเหนือ (Northeastern)
  { nameTH: 'จังหวัดกาฬสินธุ์', NAME_SCHEMA: "PNSO_KALASIN_STAT_GIS", nameEN: 'kalasin', },
  { nameTH: 'จังหวัดขอนแก่น', NAME_SCHEMA: "PNSO_KHKAEN_STAT_GIS", nameEN: 'khonkaen', nameENfull: 'khon-kaen', },
  { nameTH: 'จังหวัดชัยภูมิ', NAME_SCHEMA: "PNSO_CHYAPHUM_STAT_GIS", nameEN: 'chaiyaphum', },
  { nameTH: 'จังหวัดนครพนม', NAME_SCHEMA: "PNSO_NKPHANOM_STAT_GIS", nameEN: 'nkphanom', nameENfull: 'nakhon-phanom', },
  { nameTH: 'จังหวัดนครราชสีมา', NAME_SCHEMA: "PNSO_NKRAT_STAT_GIS", nameEN: 'nkrat', nameENfull: 'nakhon-ratchasima', },
  { nameTH: 'จังหวัดบึงกาฬ', NAME_SCHEMA: "PNSO_BUNGKAN_STAT_GIS", nameEN: 'bungkan', nameENfull: 'bueng-kan', },
  { nameTH: 'จังหวัดบุรีรัมย์', NAME_SCHEMA: "PNSO_BURIRAM_STAT_GIS", nameEN: 'buriram', },
  { nameTH: 'จังหวัดมหาสารคาม', NAME_SCHEMA: "PNSO_SARAKHAM_STAT_GIS", nameEN: 'mahasarakham', nameENfull: 'maha-sarakham', },
  { nameTH: 'จังหวัดมุกดาหาร', NAME_SCHEMA: "PNSO_MUKDAHAN_STAT_GIS", nameEN: 'mukdahan', },
  { nameTH: 'จังหวัดยโสธร', NAME_SCHEMA: "PNSO_YASOTHON_STAT_GIS", nameEN: 'yasothon', },
  { nameTH: 'จังหวัดร้อยเอ็ด', NAME_SCHEMA: "PNSO_ROIET_STAT_GIS", nameEN: 'roiet', nameENfull: 'roi-et', },
  { nameTH: 'จังหวัดเลย', NAME_SCHEMA: "PNSO_LOEI_STAT_GIS", nameEN: 'loei', },
  { nameTH: 'จังหวัดสกลนคร', NAME_SCHEMA: "PNSO_SAKONNK_STAT_GIS", nameEN: 'sakonnk', nameENfull: 'sakon-nakhon', },
  { nameTH: 'จังหวัดสุรินทร์', NAME_SCHEMA: "PNSO_SURIN_STAT_GIS", nameEN: 'surin', },
  { nameTH: 'จังหวัดศรีสะเกษ', NAME_SCHEMA: "PNSO_SISAKET_STAT_GIS", nameEN: 'sisaket', },
  { nameTH: 'จังหวัดหนองคาย', NAME_SCHEMA: "PNSO_NONGKHAI_STAT_GIS", nameEN: 'nongkhai', nameENfull: 'nong-khai', },
  { nameTH: 'จังหวัดหนองบัวลำภู', NAME_SCHEMA: "PNSO_NBLAMPHU_STAT_GIS", nameEN: 'nongbualamphu', nameENfull: 'nong-bua-lamphu', },
  { nameTH: 'จังหวัดอุดรธานี', NAME_SCHEMA: "PNSO_UDON_STAT_GIS", nameEN: 'udon', nameENfull: 'udon-thani', },
  { nameTH: 'จังหวัดอุบลราชธานี', NAME_SCHEMA: "PNSO_UBON_STAT_GIS", nameEN: 'ubon', nameENfull: 'ubon-ratchathani', },
  { nameTH: 'จังหวัดอำนาจเจริญ', NAME_SCHEMA: "PNSO_AMNATCHR_STAT_GIS", nameEN: 'amnatchr', nameENfull: 'amnat-charoen', },
  // ---------- ภาคกลาง (Central)
  { nameTH: 'กรุงเทพมหานคร', NAME_SCHEMA: "PNSO_BANGKOK_STAT_GIS", nameEN: 'bangkok', },
  { nameTH: 'จังหวัดกำแพงเพชร', NAME_SCHEMA: "PNSO_KPPHET_STAT_GIS", nameEN: 'kpphet', nameENfull: 'kamphaeng-phet', },
  { nameTH: 'จังหวัดชัยนาท', NAME_SCHEMA: "PNSO_CHAINAT_STAT_GIS", nameEN: 'chainat', nameENfull: 'chai-nat', },
  { nameTH: 'จังหวัดนครนายก', NAME_SCHEMA: "PNSO_NKNAYOK_STAT_GIS", nameEN: 'nknayok', nameENfull: 'nakhon-nayok', },
  { nameTH: 'จังหวัดนครปฐม', NAME_SCHEMA: "PNSO_NKPATHOM_STAT_GIS", nameEN: 'nkpathom', nameENfull: 'nakhon-pathom', },
  { nameTH: 'จังหวัดนครสวรรค์', NAME_SCHEMA: "PNSO_NKSAWAN_STAT_GIS", nameEN: 'nksawan', nameENfull: 'nakhon-sawan', },
  { nameTH: 'จังหวัดนนทบุรี', NAME_SCHEMA: "PNSO_NONTBURI_STAT_GIS", nameEN: 'nontburi', nameENfull: 'nonthaburi', },
  { nameTH: 'จังหวัดปทุมธานี', NAME_SCHEMA: "PNSO_PTTHANI_STAT_GIS", nameEN: 'pathumthani', nameENfull: 'pathum-thani', },
  { nameTH: 'จังหวัดพระนครศรีอยุธยา', NAME_SCHEMA: "PNSO_AYUTTAYA_STAT_GIS", nameEN: 'ayuttaya', nameENfull: 'phra-nakhon-si-ayutthaya', },
  { nameTH: 'จังหวัดพิจิตร', NAME_SCHEMA: "PNSO_PHICHIT_STAT_GIS", nameEN: 'phichit', },
  { nameTH: 'จังหวัดพิษณุโลก', NAME_SCHEMA: "PNSO_PSNULOK_STAT_GIS", nameEN: 'phitsanulok', },
  { nameTH: 'จังหวัดเพชรบูรณ์', NAME_SCHEMA: "PNSO_PHCHABUN_STAT_GIS", nameEN: 'phchabun', nameENfull: 'phetchabun', },
  { nameTH: 'จังหวัดลพบุรี', NAME_SCHEMA: "PNSO_LOPBURI_STAT_GIS", nameEN: 'lopburi', },
  { nameTH: 'จังหวัดสมุทรปราการ', NAME_SCHEMA: "PNSO_SMPRAKAN_STAT_GIS", nameEN: 'smprakan', nameENfull: 'samut-prakan', },
  { nameTH: 'จังหวัดสมุทรสงคราม', NAME_SCHEMA: "PNSO_SMSKHRAM_STAT_GIS", nameEN: 'samutsongkhram', nameENfull: 'samut-songkhram', },
  { nameTH: 'จังหวัดสมุทรสาคร', NAME_SCHEMA: "PNSO_SMSAKHON_STAT_GIS", nameEN: 'smsakhon', nameENfull: 'samut-sakhon', },
  { nameTH: 'จังหวัดสิงห์บุรี', NAME_SCHEMA: "PNSO_SINGBURI_STAT_GIS", nameEN: 'singburi', nameENfull: 'sing-buri', },
  { nameTH: 'จังหวัดสุโขทัย', NAME_SCHEMA: "PNSO_SKHOTHAI_STAT_GIS", nameEN: 'sukhothai', },
  { nameTH: 'จังหวัดสุพรรณบุรี', NAME_SCHEMA: "PNSO_SUPHAN_STAT_GIS", nameEN: 'suphan', nameENfull: 'suphan-buri', },
  { nameTH: 'จังหวัดสระบุรี', NAME_SCHEMA: "PNSO_SARABURI_STAT_GIS", nameEN: 'saraburi', },
  { nameTH: 'จังหวัดอ่างทอง', NAME_SCHEMA: "PNSO_ANGTHONG_STAT_GIS", nameEN: 'angthong', nameENfull: 'ang-thong', },
  { nameTH: 'จังหวัดอุทัยธานี', NAME_SCHEMA: "PNSO_UTHAI_STAT_GIS", nameEN: 'uthai', nameENfull: 'uthai-thani', },
  // ---------- ภาคตะวันออก (Eastern)
  { nameTH: 'จังหวัดจันทบุรี', NAME_SCHEMA: "PNSO_CHTBURI_STAT_GIS", nameEN: 'chanthaburi', },
  { nameTH: 'จังหวัดฉะเชิงเทรา', NAME_SCHEMA: "PNSO_CHCHSAO_STAT_GIS", nameEN: 'chchsao', nameENfull: 'chachoengsao', },
  { nameTH: 'จังหวัดชลบุรี', NAME_SCHEMA: "PNSO_CHONBURI_STAT_GIS", nameEN: 'chonburi', },
  { nameTH: 'จังหวัดตราด', NAME_SCHEMA: "PNSO_TRAT_STAT_GIS", nameEN: 'trat', },
  { nameTH: 'จังหวัดปราจีนบุรี', NAME_SCHEMA: "PNSO_PCHBURI_STAT_GIS", nameEN: 'pchburi', nameENfull: 'prachinburi', },
  { nameTH: 'จังหวัดระยอง', NAME_SCHEMA: "PNSO_RAYONG_STAT_GIS", nameEN: 'rayong', },
  { nameTH: 'จังหวัดสระแก้ว', NAME_SCHEMA: "PNSO_SAKAEO_STAT_GIS", nameEN: 'sakaeo', nameENfull: 'sa-kaeo', },
  // ---------- ภาคตะวันตก (Western)
  { nameTH: 'จังหวัดกาญจนบุรี', NAME_SCHEMA: "PNSO_KCNBURI_STAT_GIS", nameEN: 'kanchanaburi', },
  { nameTH: 'จังหวัดตาก', NAME_SCHEMA: "PNSO_TAK_STAT_GIS", nameEN: 'tak', },
  { nameTH: 'จังหวัดประจวบคีรีขันธ์', NAME_SCHEMA: "PNSO_PCHKKHAN_STAT_GIS", nameEN: 'pchkkhan', nameENfull: 'prachuap-khiri-khan', },
  { nameTH: 'จังหวัดเพชรบุรี', NAME_SCHEMA: "PNSO_PHETBURI_STAT_GIS", nameEN: 'phetburi', nameENfull: 'phetchaburi', },
  { nameTH: 'จังหวัดราชบุรี', NAME_SCHEMA: "PNSO_RATBURI_STAT_GIS", nameEN: 'ratburi', nameENfull: 'ratchaburi', },
  // ---------- ภาคใต้ (Southern)
  { nameTH: 'จังหวัดกระบี่', NAME_SCHEMA: "PNSO_KRABI_STAT_GIS", nameEN: 'krabi', },
  { nameTH: 'จังหวัดชุมพร', NAME_SCHEMA: "PNSO_CHUMPHON_STAT_GIS", nameEN: 'chumphon', },
  { nameTH: 'จังหวัดตรัง', NAME_SCHEMA: "PNSO_TRANG_STAT_GIS", nameEN: 'trang', },
  { nameTH: 'จังหวัดนครศรีธรรมราช', NAME_SCHEMA: "PNSO_NKSITHAM_STAT_GIS", nameEN: 'nksitham', nameENfull: 'nakhon-si-thammarat', },
  { nameTH: 'จังหวัดนราธิวาส', NAME_SCHEMA: "PNSO_NARATWAT_STAT_GIS", nameEN: 'narathiwat', },
  { nameTH: 'จังหวัดปัตตานี', NAME_SCHEMA: "PNSO_PATTANI_STAT_GIS", nameEN: 'pattani', },
  { nameTH: 'จังหวัดพังงา', NAME_SCHEMA: "PNSO_PHANGNGA_STAT_GIS", nameEN: 'phangnga', nameENfull: 'phang-nga', },
  { nameTH: 'จังหวัดพัทลุง', NAME_SCHEMA: "PNSO_PHATLUNG_STAT_GIS", nameEN: 'phatlung', nameENfull: 'phatthalung', },
  { nameTH: 'จังหวัดภูเก็ต', NAME_SCHEMA: "PNSO_PHUKET_STAT_GIS", nameEN: 'phuket', },
  { nameTH: 'จังหวัดระนอง', NAME_SCHEMA: "PNSO_RANONG_STAT_GIS", nameEN: 'ranong', },
  { nameTH: 'จังหวัดสตูล', NAME_SCHEMA: "PNSO_SATUN_STAT_GIS", nameEN: 'satun', },
  { nameTH: 'จังหวัดสงขลา', NAME_SCHEMA: "PNSO_SONGKHLA_STAT_GIS", nameEN: 'songkhla', },
  { nameTH: 'จังหวัดสุราษฎ์ธานี', NAME_SCHEMA: "PNSO_SURAT_STAT_GIS", nameEN: 'surat', nameENfull: 'surat-thani', },
  { nameTH: 'จังหวัดยะลา', NAME_SCHEMA: "PNSO_YALA_STAT_GIS", nameEN: 'yala', },
]

class Nso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidepnso: false,
      pathName: '',
      pathSchema: '',
    };
  }
  componentDidMount() {
    var path = window.location.pathname;
    var pathname = path.split('/');
    // console.log('path >>>', path)
    var provincePath = PathName77Provinces.find((item) => item.nameEN === pathname[1])
    if (provincePath) {
      this.props.setChangeProvinceMode({ mode: 'add', NAME_SCHEMA: provincePath.NAME_SCHEMA, })
      this.props.setChangeProvinceMode({ mode: 'change', provinceModeOpen: true, })
      this.setState({
        slidepnso: true,
        pathName: provincePath.nameTH,
        pathSchema: provincePath.NAME_SCHEMA,
      });
      this.props.setChangparamurl(provincePath.nameEN,"","")
    }

  }

  render() {
    var path = window.location.pathname;
    var pathname = path.split('/');
    // console.log('path >>>', path)
    var provincePath = PathName77Provinces.find((item) => item.nameEN === pathname[1])
    return (
      <div style={{ height: '100% !important' }} id="allmap">
        <Navbar name={this.state.pathName} />
       {/*  <div style={{position:'relative'}}> */}
        <Map menu={this.state.slidepnso} pathSchema={provincePath ? provincePath.NAME_SCHEMA : undefined} paramurl={provincePath && provincePath.nameEN ? provincePath.nameEN : ''} />
       {/*  </div> */}
        <Footer />
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  changwat: state.paramurl,
})
const mapDispatchToProps = (dispatch) => ({
  setChangparamurl: (url,center) => dispatch(changparamurl(url,center)),
  setChangeProvinceMode: (provinceMode) => dispatch(changeProvinceMode(provinceMode))


})

export default connect(mapStateToProps, mapDispatchToProps)(Nso);
