const menustate = {
    namemenu:'',
    typemap:'',
}
const selectedMenu = (state = menustate, action) => {
    switch (action.type) {
        case "CHANG_MENU":
            state = {
                namemenu: action.payload.namemenu ? action.payload.namemenu : state.namemenu,
                typemap: action.payload.typemap ? action.payload.typemap : state.typemap,

            }
            return state;
        default:
            return state;
    }
  }
  export default selectedMenu;