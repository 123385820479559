const swipeMapState = {
  swipeMapOpen: false,
  swipeMapCategory: {
    mainCategory: { id: '', name: '' },
    level1Category: { id: '', name: '' },
    level2Category: { id: '', name: '' },
    level3Category: { id: '', name: '' },
    level4Category: { id: '', name: '' },
    lastCategory: { id: '', name: '', level: '' },
  },
  swipeMapStat: {
    statName: '',
    statNameTable: '',
    statId: '',
    statCodeId: '',
    statType:'',
    time: {
      year: {},
      periodType: {
        child: []
      },
      periodSelect: {},
    },
    area: {},
    areaTypeMap: {},
    agency: {},
    classify: {
      status: false,
      statusUseClassify: false,
      statusCodelistHasNoT: false,
      classifyData: [],
    },
    description: {
      agency: '',
      frequency: '',
      timeValidStart: '',
      timeValidEnd: '',
      resourceType: '',
      resourceFrom: '',
      resourceLink: '',
      usage: '',
      quality: '',
      officialStatus: false,
      note: '',
      statType: '',
      statUnit: '',
      statUnitMultiply: '',
    },
    externalKnowledge: [],
    externalInformation: [],
  },
  swipeMapStatFilter: {
    classifyCodelists: [],
    timeYears: [],
    timePeriodTypes: [],
    areas: [],
    agencys: [],
    typeMaps: [],
  },
}

// {swipeMapOpen: true/false }
// {swipeMapCategory: {level: 0/1} }

const swipeMapReducer = (state = swipeMapState, action) => {
  switch (action.type) {
    case "CHANGE_SWIPE_MAP":
      state = {
        swipeMapOpen: (action.payload.swipeMapOpen === true || action.payload.swipeMapOpen === false) ? action.payload.swipeMapOpen : state.swipeMapOpen,
        swipeMapCategory: action.payload.swipeMapCategory ?
          action.payload.swipeMapCategory.level === '0'
            ? {
              mainCategory: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name },
              level1Category: { id: '', name: '' },
              level2Category: { id: '', name: '' },
              level3Category: { id: '', name: '' },
              level4Category: { id: '', name: '' },
              lastCategory: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name, level: action.payload.swipeMapCategory.level },
            }
            : action.payload.swipeMapCategory.level === '1'
              ? {
                mainCategory: { id: state.swipeMapCategory.mainCategory.id, name: state.swipeMapCategory.mainCategory.name },
                level1Category: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name },
                level2Category: { id: '', name: '' },
                level3Category: { id: '', name: '' },
                level4Category: { id: '', name: '' },
                lastCategory: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name, level: action.payload.swipeMapCategory.level },
              }
              : action.payload.swipeMapCategory.level === '2'
                ? {
                  mainCategory: { id: state.swipeMapCategory.mainCategory.id, name: state.swipeMapCategory.mainCategory.name },
                  level1Category: { id: state.swipeMapCategory.level1Category.id, name: state.swipeMapCategory.level1Category.name },
                  level2Category: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name },
                  level3Category: { id: '', name: '' },
                  level4Category: { id: '', name: '' },
                  lastCategory: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name, level: action.payload.swipeMapCategory.level },
                }
                : action.payload.swipeMapCategory.level === '3'
                  ? {
                    mainCategory: { id: state.swipeMapCategory.mainCategory.id, name: state.swipeMapCategory.mainCategory.name },
                    level1Category: { id: state.swipeMapCategory.level1Category.id, name: state.swipeMapCategory.level1Category.name },
                    level2Category: { id: state.swipeMapCategory.level2Category.id, name: state.swipeMapCategory.level2Category.name },
                    level3Category: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name },
                    level4Category: { id: '', name: '' },
                    lastCategory: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name, level: action.payload.swipeMapCategory.level },
                  }
                  : action.payload.swipeMapCategory.level === '4'
                    ? {
                      mainCategory: { id: state.swipeMapCategory.mainCategory.id, name: state.swipeMapCategory.mainCategory.name },
                      level1Category: { id: state.swipeMapCategory.level1Category.id, name: state.swipeMapCategory.level1Category.name },
                      level2Category: { id: state.swipeMapCategory.level2Category.id, name: state.swipeMapCategory.level2Category.name },
                      level3Category: { id: state.swipeMapCategory.level3Category.id, name: state.swipeMapCategory.level3Category.name },
                      level4Category: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name },
                      lastCategory: { id: action.payload.swipeMapCategory.id, name: action.payload.swipeMapCategory.name, level: action.payload.swipeMapCategory.level },
                    }
                    : {
                      mainCategory: { id: state.swipeMapCategory.mainCategory.id, name: state.swipeMapCategory.mainCategory.name },
                      level1Category: { id: state.swipeMapCategory.level1Category.id, name: state.swipeMapCategory.level1Category.name },
                      level2Category: { id: state.swipeMapCategory.level2Category.id, name: state.swipeMapCategory.level2Category.name },
                      level3Category: { id: state.swipeMapCategory.level3Category.id, name: state.swipeMapCategory.level3Category.name },
                      level4Category: { id: state.swipeMapCategory.level4Category.id, name: state.swipeMapCategory.level4Category.name },
                      lastCategory: { id: state.swipeMapCategory.lastCategory.id, name: state.swipeMapCategory.lastCategory.name, level: state.swipeMapCategory.lastCategory.level, },
                    }
          : state.swipeMapCategory,
        swipeMapStat: action.payload.swipeMapStat ? {
          statName: action.payload.swipeMapStat.statName ? action.payload.swipeMapStat.statName : state.swipeMapStat.statName,
          statNameTable: action.payload.swipeMapStat.statNameTable ? action.payload.swipeMapStat.statNameTable : state.swipeMapStat.statNameTable,
          statId: action.payload.swipeMapStat.statId ? action.payload.swipeMapStat.statId : state.swipeMapStat.statId,
          statCodeId: action.payload.swipeMapStat.statCodeId ? action.payload.swipeMapStat.statCodeId : state.swipeMapStat.statCodeId,
          statType: action.payload.swipeMapStat.statType ? action.payload.swipeMapStat.statType : state.swipeMapStat.statType,
          time: action.payload.swipeMapStat.time ? {
            year: action.payload.swipeMapStat.time.year ? action.payload.swipeMapStat.time.year : state.swipeMapStat.time.year,
            periodType: action.payload.swipeMapStat.time.periodType ? action.payload.swipeMapStat.time.periodType : state.swipeMapStat.time.periodType,
            periodSelect: action.payload.swipeMapStat.time.periodSelect ? action.payload.swipeMapStat.time.periodSelect : state.swipeMapStat.time.periodSelect,
          } : state.swipeMapStat.time,
          area: action.payload.swipeMapStat.area ? action.payload.swipeMapStat.area : state.swipeMapStat.area,
          areaTypeMap: action.payload.swipeMapStat.areaTypeMap ? action.payload.swipeMapStat.areaTypeMap : state.swipeMapStat.areaTypeMap,
          agency: action.payload.swipeMapStat.agency ? action.payload.swipeMapStat.agency : state.swipeMapStat.agency,
          classify: action.payload.swipeMapStat.classify ? {
            status: (action.payload.swipeMapStat.classify.status === true || action.payload.swipeMapStat.classify.status === false) ? action.payload.swipeMapStat.classify.status : state.swipeMapStat.classify.status,
            statusUseClassify: (action.payload.swipeMapStat.classify.statusUseClassify === true || action.payload.swipeMapStat.classify.statusUseClassify === false) ? action.payload.swipeMapStat.classify.statusUseClassify : state.swipeMapStat.classify.statusUseClassify,
            statusCodelistHasNoT: (action.payload.swipeMapStat.classify.statusCodelistHasNoT === true || action.payload.swipeMapStat.classify.statusCodelistHasNoT === false) ? action.payload.swipeMapStat.classify.statusCodelistHasNoT : state.swipeMapStat.classify.statusCodelistHasNoT,
            classifyData: action.payload.swipeMapStat.classify.classifyData ? action.payload.swipeMapStat.classify.classifyData : state.swipeMapStat.classify.classifyData,
          } : state.swipeMapStat.classify,
          description: action.payload.swipeMapStat.description ? {
            agency: action.payload.swipeMapStat.description.agency ? action.payload.swipeMapStat.description.agency : state.swipeMapStat.description.agency,
            frequency: action.payload.swipeMapStat.description.frequency ? action.payload.swipeMapStat.description.frequency : state.swipeMapStat.description.frequency,
            timeValidStart: action.payload.swipeMapStat.description.timeValidStart ? action.payload.swipeMapStat.description.timeValidStart : state.swipeMapStat.description.timeValidStart,
            timeValidEnd: action.payload.swipeMapStat.description.timeValidEnd ? action.payload.swipeMapStat.description.timeValidEnd : state.swipeMapStat.description.timeValidEnd,
            resourceType: action.payload.swipeMapStat.description.resourceType ? action.payload.swipeMapStat.description.resourceType : state.swipeMapStat.description.resourceType,
            resourceFrom: action.payload.swipeMapStat.description.resourceFrom ? action.payload.swipeMapStat.description.resourceFrom : state.swipeMapStat.description.resourceFrom,
            resourceLink: action.payload.swipeMapStat.description.resourceLink ? action.payload.swipeMapStat.description.resourceLink : state.swipeMapStat.description.resourceLink,
            usage: action.payload.swipeMapStat.description.usage ? action.payload.swipeMapStat.description.usage : state.swipeMapStat.description.usage,
            quality: action.payload.swipeMapStat.description.quality ? action.payload.swipeMapStat.description.quality : state.swipeMapStat.description.quality,
            officialStatus: (action.payload.swipeMapStat.description.officialStatus === true || action.payload.swipeMapStat.description.officialStatus === false)  ? action.payload.swipeMapStat.description.officialStatus : state.swipeMapStat.description.officialStatus,
            note: action.payload.swipeMapStat.description.note ? action.payload.swipeMapStat.description.note : state.swipeMapStat.description.note,
            statType: action.payload.swipeMapStat.description.statType ? action.payload.swipeMapStat.description.statType : state.swipeMapStat.description.statType,
            statUnit: action.payload.swipeMapStat.description.statUnit ? action.payload.swipeMapStat.description.statUnit : state.swipeMapStat.description.statUnit,
            statUnitMultiply: action.payload.swipeMapStat.description.statUnitMultiply ? action.payload.swipeMapStat.description.statUnitMultiply : state.swipeMapStat.description.statUnitMultiply,
          } : state.swipeMapStat.description,
          externalKnowledge: action.payload.swipeMapStat.externalKnowledge ? action.payload.swipeMapStat.externalKnowledge : state.swipeMapStat.externalKnowledge,
          externalInformation: action.payload.swipeMapStat.externalInformation ? action.payload.swipeMapStat.externalInformation : state.swipeMapStat.externalInformation,
        } : state.swipeMapStat,
        swipeMapStatFilter: action.payload.swipeMapStatFilter ? {
          classifyCodelists: action.payload.swipeMapStatFilter.classifyCodelists ? action.payload.swipeMapStatFilter.classifyCodelists : state.swipeMapStatFilter.classifyCodelists,
          timeYears: action.payload.swipeMapStatFilter.timeYears ? action.payload.swipeMapStatFilter.timeYears : state.swipeMapStatFilter.timeYears,
          timePeriodTypes: action.payload.swipeMapStatFilter.timePeriodTypes ? action.payload.swipeMapStatFilter.timePeriodTypes : state.swipeMapStatFilter.timePeriodTypes,
          areas: action.payload.swipeMapStatFilter.areas ? action.payload.swipeMapStatFilter.areas : state.swipeMapStatFilter.areas,
          agencys: action.payload.swipeMapStatFilter.agencys ? action.payload.swipeMapStatFilter.agencys : state.swipeMapStatFilter.agencys,
          typeMaps: action.payload.swipeMapStatFilter.typeMaps ? action.payload.swipeMapStatFilter.typeMaps : state.swipeMapStatFilter.typeMaps,
        } : state.swipeMapStatFilter,
      }
      return state;
    default:
      return state;
  }
}
export default swipeMapReducer;