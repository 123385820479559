import React, { Component } from "react";
import '../../css/notfound.css';
class NotFoundPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidepnso: false,
    };
  }
  render() {
    return (
      <div style={{ height: '100% !important' }}>
        <div id="notfound">
          <div class="notfound">
            <div class="notfound-404">
              <h1>404</h1>
              <h2>Page not found</h2>
              <h3>Please Support NsoStatgis</h3>

            </div>
            <a href="https://statgisv3.nso.go.th">กลับสู่หน้าหลัก</a>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;