const statState = {
  statName: '',
  statNameTable: '',
  statId: '',
  statCodeId: '',
  statType:'',
  time: {
    year: {},
    periodType: {
      child:[]
    },
    periodSelect: {},
  },
  area: {},
  areaTypeMap: {},
  agency: {},
  classify: {
    status: false,
    statusUseClassify: false,
    statusCodelistHasNoT: false,
    classifyData: [],
  },
  description: {
    agency: '',
    frequency: '',
    timeValidStart: '',
    timeValidEnd: '',
    resourceType: '',
    resourceFrom: '',
    resourceLink: '',
    usage: '',
    quality: '',
    officialStatus: false,
    note: '',
    statType: '',
    statUnit: '',
    statUnitMultiply: '',
  },
  externalKnowledge: [],
  externalInformation: [],
  pagechart:{ status: false, page: 1},
  typemap:'',
}

const selectedStatReducer = (state = statState, action) => {
  switch (action.type) {
    case "SELECT_STAT":
      state = {
        statName: action.payload.statName ? action.payload.statName : state.statName,
        statNameTable: action.payload.statNameTable ? action.payload.statNameTable : state.statNameTable,
        statId: action.payload.statId ? action.payload.statId : state.statId,
        statCodeId: action.payload.statCodeId ? action.payload.statCodeId : state.statCodeId,
        statType: action.payload.statType ? action.payload.statType : state.statType,
        time: action.payload.time ? {
          year: action.payload.time.year ? action.payload.time.year : state.time.year,
          periodType: action.payload.time.periodType ? action.payload.time.periodType : state.time.periodType,
          periodSelect: action.payload.time.periodSelect ? action.payload.time.periodSelect : state.time.periodSelect,
        } : state.time,
        area: action.payload.area ? action.payload.area : state.area,
        areaTypeMap: action.payload.areaTypeMap ? action.payload.areaTypeMap : state.areaTypeMap,
        agency: action.payload.agency ? action.payload.agency : state.agency,
        classify: action.payload.classify ? {
          status: (action.payload.classify.status === true || action.payload.classify.status === false) ? action.payload.classify.status : state.classify.status,
          statusUseClassify: (action.payload.classify.statusUseClassify === true || action.payload.classify.statusUseClassify === false) ? action.payload.classify.statusUseClassify : state.classify.statusUseClassify,
          statusCodelistHasNoT: (action.payload.classify.statusCodelistHasNoT === true || action.payload.classify.statusCodelistHasNoT === false) ? action.payload.classify.statusCodelistHasNoT : state.classify.statusCodelistHasNoT,
          classifyData: action.payload.classify.classifyData ? action.payload.classify.classifyData : state.classify.classifyData,
        } : state.classify,
        description: action.payload.description ? {
          agency: action.payload.description.agency ? action.payload.description.agency : state.description.agency,
          frequency: action.payload.description.frequency ? action.payload.description.frequency : state.description.frequency,
          timeValidStart: action.payload.description.timeValidStart ? action.payload.description.timeValidStart : state.description.timeValidStart,
          timeValidEnd: action.payload.description.timeValidEnd ? action.payload.description.timeValidEnd : state.description.timeValidEnd,
          resourceType: action.payload.description.resourceType ? action.payload.description.resourceType : state.description.resourceType,
          resourceFrom: action.payload.description.resourceFrom ? action.payload.description.resourceFrom : state.description.resourceFrom,
          resourceLink: action.payload.description.resourceLink ? action.payload.description.resourceLink : state.description.resourceLink,
          usage: action.payload.description.usage ? action.payload.description.usage : state.description.usage,
          quality: action.payload.description.quality ? action.payload.description.quality : state.description.quality,
          officialStatus: (action.payload.description.officialStatus === true || action.payload.description.officialStatus === false) ? action.payload.description.officialStatus : state.description.officialStatus,
          note: action.payload.description.note ? action.payload.description.note : state.description.note,
          statType: action.payload.description.statType ? action.payload.description.statType : state.description.statType,
          statUnit: action.payload.description.statUnit ? action.payload.description.statUnit : state.description.statUnit,
          statUnitMultiply: action.payload.description.statUnitMultiply ? action.payload.description.statUnitMultiply : state.description.statUnitMultiply,
          allRawData: action.payload.description.allRawData ? action.payload.description.allRawData : state.description.allRawData,
        } : state.description,
        externalKnowledge: action.payload.externalKnowledge ? action.payload.externalKnowledge : state.externalKnowledge,
        externalInformation: action.payload.externalInformation ? action.payload.externalInformation : state.externalInformation,
        pagechart: action.payload.pagechart
          ? action.payload.pagechart.status
            ? action.payload.pagechart
            : { status: false, page: '' }
          : state.pagechart,
        //typemap: action.payload.typemap?action.payload.typemap:state.payload.typemap,
      }
      return state;
    default:
      return state;
  }
}
export default selectedStatReducer;