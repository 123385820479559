const categoryState = {
  mainCategory: { id: '', name: '' },
  level1Category: { id: '', name: '' },
  level2Category: { id: '', name: '' },
  level3Category: { id: '', name: '' },
  level4Category: { id: '', name: '' },
  lastCategory: { id: '', name: '', level: '' },
}

// form of payload possible
// { id: 'main_cate_id', name: 'ชื่อหมวดหลักที่เลือก', level: '0' }
// { id: 'level_1_cate_id', name: 'ชื่อหมวดย่อย 1 ที่เลือก', level: '1' }
// { id: 'level_2_cate_id', name: 'ชื่อหมวดย่อย 2 ที่เลือก', level: '2' }
// { id: 'level_3_cate_id', name: 'ชื่อหมวดย่อย 3 ที่เลือก', level: '3' }
// { id: 'level_4_cate_id', name: 'ชื่อหมวดย่อย 4 ที่เลือก', level: '4' }

const selectedCategoryReducer = (state = categoryState, action) => {
  switch (action.type) {
    case "SELECT_CATE":
      switch (action.payload.level) {
        case '0':
          state = {
            mainCategory: { id: action.payload.id, name: action.payload.name },
            level1Category: { id: '', name: '' },
            level2Category: { id: '', name: '' },
            level3Category: { id: '', name: '' },
            level4Category: { id: '', name: '' },
            lastCategory: { id: action.payload.id, name: action.payload.name, level: action.payload.level },
          }
          return state;
        case '1':
          state = {
            mainCategory: { id: state.mainCategory.id, name: state.mainCategory.name },
            level1Category: { id: action.payload.id, name: action.payload.name },
            level2Category: { id: '', name: '' },
            level3Category: { id: '', name: '' },
            level4Category: { id: '', name: '' },
            lastCategory: { id: action.payload.id, name: action.payload.name, level: action.payload.level },
          }
          return state;
        case '2':
          state = {
            mainCategory: { id: state.mainCategory.id, name: state.mainCategory.name },
            level1Category: { id: state.level1Category.id, name: state.level1Category.name },
            level2Category: { id: action.payload.id, name: action.payload.name },
            level3Category: { id: '', name: '' },
            level4Category: { id: '', name: '' },
            lastCategory: { id: action.payload.id, name: action.payload.name, level: action.payload.level },
          }
          return state;
        case '3':
          state = {
            mainCategory: { id: state.mainCategory.id, name: state.mainCategory.name },
            level1Category: { id: state.level1Category.id, name: state.level1Category.name },
            level2Category: { id: state.level2Category.id, name: state.level2Category.name },
            level3Category: { id: action.payload.id, name: action.payload.name },
            level4Category: { id: '', name: '' },
            lastCategory: { id: action.payload.id, name: action.payload.name, level: action.payload.level },
          }
          return state;
        case '4':
          state = {
            mainCategory: { id: state.mainCategory.id, name: state.mainCategory.name },
            level1Category: { id: state.level1Category.id, name: state.level1Category.name },
            level2Category: { id: state.level2Category.id, name: state.level2Category.name },
            level3Category: { id: state.level3Category.id, name: state.level3Category.name },
            level4Category: { id: action.payload.id, name: action.payload.name },
            lastCategory: { id: action.payload.id, name: action.payload.name, level: action.payload.level },
          }
          return state;
        default:
          return state;
      }
    default:
      return state;
  }
}

export default selectedCategoryReducer; 