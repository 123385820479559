import React, { Component } from 'react'
import '../css/dashboard.css'
// import styled from 'styled-components'
// import { Line, Bar } from 'react-chartjs-2'
import { connect } from "react-redux";
// import axios from 'axios';
// import config from '../config';
import loadding from '../icon/loadding.gif';

// const Dash = styled.div`
//   ${props => (props.height ? `height:20vh;` : `height:20vh;`)}
//   ${props =>
//     props.width ? `width:100%;` : `width:30vw;`}

//   background-color: #fff;
//   border: 1px solid rgba(0, 0, 0, 0.125);
//   border-radius: 0.25rem;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   color: #333;
//   .item {
//     position: relative;
//     width: 100px;
//     padding-top: 3.5em;
//   }
//   .item2 {
//     position: relative;
//     width: 100%;
//   }
// `

// const data = {
//   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//   datasets: [
//     {
//       label: 'จำนวนเกษตรในเขตปฎิรูปที่ดิน: หน่วยนับ = คน',
//       type: 'line',
//       backgroundColor: 'rgba(80,158,177,0.2)',
//       borderColor: 'rgba(80,158,177,1)',
//       borderWidth: 1,
//       hoverBackgroundColor: 'rgba(80,158,177,5)',
//       hoverBorderColor: 'rgba(80,158,177,10)',
//       data: [65, 59, 80, 81, 56, 55, 40]
//     }
//   ]
// }
class Dashboard extends Component {
  // constructor(props) {
  //   super(props)
  // }
  componentDidMount() {

  }
  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  render() {
    let loaddings = <div ><img src={loadding} alt='' width={100} style={{ position: 'absolute', display: 'block', margin: 'auto', top: 0, left: 0, right: 0, bottom: 0 }}></img></div>

    return (
      <div className={`animate__animated animate__fadeInRight divdashboard ${this.props.show ? 'active' : ''}`}>
        <p style={{ fontSize: '20px', textAlign: 'center', margin: 'center', textDecoration: 'underline' }}>{this.props.selectedStat.statName !== '' && this.props.layersuccess.status === true ? 'รายการผังชี้สภาพ' : 'กรุณาเลือกรายการสถิติ'}</p>
        <p>{this.props.datadashboard == null || this.props.datadashboard.length < 1 ? 'ไม่มีการตั้งค่าแดชบอร์ด' : ''}</p>
        <div className='containerdashboard'>
          {this.props.datadashboard && this.props.layersuccess.status === true ? this.props.datadashboard.map((value, index) => {
            return (<div className="box" draggable="true"><span>{value.CODELIST_NAME + ' : ' + value.CODELIST_SON_NAME}</span><span>{value.STAT_LIST_NAME + ' : ' + this.numberWithCommas(value.STAT_LIST_COUNT)}</span></div>)
          }) : loaddings}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  menutab: state.menuTab,
  mapView: state.mapView,
  drawType: state.drawType,
  selectedCategory: state.selectedCategory,
  selectedStat: state.selectedStat,
  selectedStatFilter: state.selectedStatFilter,
  swipeMap: state.swipeMap,
  provinceMode: state.provinceMode,
  layersuccess: state.layersuccess,
})
export default connect(mapStateToProps, null)(Dashboard)
