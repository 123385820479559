import React, { Component } from "react";
import ReactDOM from "react-dom";
import "ol/ol.css";
import * as basemap from "./MapTileLayer";
import Map from "ol/Map";
import View from "ol/View";
import {
  defaults as defaultControls,
  ScaleLine,
  FullScreen,
  ZoomToExtent,

} from "ol/control";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import Slidebar from "../componentnso/Slidebarnso";
import Tabbar from "../componentnso/TabbarNew2";
import Info from "../componentmap/Infor";
import Chart from "../componentmap/Chart";
import Table from "../componentmap/Table";
import Dashboard from "../componentmap/Dashboard";
import Modalbug from "../componentmodal/Modalbug";
import { connect } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import image048 from "../icon/image048.png";
import image046 from "../icon/image046.png";
import image047 from "../icon/image047.png";
import imginfo from "../icon/info.png";
import imginfo2 from "../icon/info2.png";
import imgswipe from "../icon/A&B.png";
import imgswipe2 from "../icon/A&B2.png";
import slide from "../icon/slide.png";
import slide2 from "../icon/slide2.png";
import image053 from "../icon/image053.png";
import image054 from "../icon/image054.png";
import image055 from "../icon/image055.png";
import image056 from "../icon/image056.png";
import imgfine from "../icon/fine.png";
import imgfine2 from "../icon/fine2.png";
import centermap from "../icon/centermap.png";
import imgdraw from "../icon/dwg.png";
import imgdraw2 from "../icon/dwg2.png";
import imageinfo from "../icon/image040.png";
import imgflag from "../icon/flag.svg";
import "../css/control.css";
import queryString from "query-string";
//----------------------------------------------semantic----------------------------------------
import { Input } from "semantic-ui-react";
import { Icon as SICON } from "semantic-ui-react";

//------------------------------------------ol-ext--------------------------------------
import ol_control_Timeline from "ol-ext/control/Timeline";
import ol_control_Swipe from "ol-ext/control/Swipe";
import ol_control_Print from "ol-ext/control/Print";
import ordering from "ol-ext/render/Ordering";
import Chartpine from "ol-ext/style/Chart";
import FontSymbol from "ol-ext/style/FontSymbol";
import "ol-ext/style/FontAwesomeDef.js";
import {
  textAlign,
  textBaseline,
  textOverflow,
} from "ol-ext/style/SetTextPath";
//--------------------------------// start Openlayers imports
import Overlay from "ol/Overlay";
import { toLonLat } from "ol/proj";
import { toStringHDMS } from "ol/coordinate";
import Feature from "ol/Feature";
import { Circles, Point, Polygon } from "ol/geom";
import { unByKey } from "ol/Observable";
import * as olEasing from "ol/easing";
import { click, pointerMove, altKeyOnly } from "ol/events/condition";
import PluggableMap from "ol/PluggableMap";
import {
  Fill,
  Stroke,
  Style,
  Text,
  RegularShape,
  Circle,
  Icon,
} from "ol/style";
import ImageStyle from "ol/style/Image";
import MultiPolygon from "ol/geom/MultiPolygon";
//import { center, nan, bangkok, chiangmai } from "../geojson"; // I got a sample geojson from the web
import { GeoJSON, XYZ } from "ol/format";
import sync from "ol-hashed";
import WMSGetFeatureInfo from "ol/format/WMSGetFeatureInfo";
import {
  Vector as VectorLayer,
  Group as LayerGroup,
  Vector as VectorImageLayer,
} from "ol/layer";
import {
  Vector as VectorSource,
  OSM as OSMSource,
  XYZ as XYZSource,
  TileWMS as TileWMSSource,
  OSM,
  TileImage,
} from "ol/source";
import {
  Select as SelectInteraction,
  defaults as DefaultInteractions,
} from "ol/interaction";
import {
  Attribution,
  ZoomSlider,
  Zoom,
  Rotate,
  MousePosition,
  OverviewMap,
  defaults as DefaultControls,
} from "ol/control";
import Geometry from "ol/geom/Geometry";
import { Projection, get as getProjection } from "ol/proj";
import { fromLonLat } from "ol/proj";
import { getCenter } from "ol/extent";
import { Draw, Modify, Snap } from "ol/interaction";
import { Circle as CircleStyle } from "ol/style";
import Draw2, { createRegularPolygon, createBox } from "ol/interaction/Draw";
import { getRenderPixel } from "ol/render";
import {
  changMapView,
  changDrawType,
  selectStatData,
  selectCategoryData,
  selectFilterStatData,
  changSwipeMap,
  changeStyle,
  layersuccess,
  showModal,
  selectedMenu,
} from "../redux/actions";
// End Openlayers imports
import $ from "jquery";
/* API */
import axios from "axios";
import config from "../config";
import { none } from "ol/centerconstraint";
import synchronize from "ol-hashed";

const PNSO = [
  {
    id: "63a4c50f-be23-4d6f-a78d-31f84702eddb",
    NameT: "prov_gp1",
    Name: "กลุ่มจังหวัด ภาคกลางตอนบน",
    Typemap: "up-central60",
    Typemapdistrict: "up-central60-district",
    Typemapsupdistrict: "up-central60-sub-district",

  },
  {
    id: "c9ae3fa6-142c-449b-a6e1-cd7066c9202d",
    NameT: "prov_gp2",
    Name: "กลุ่มจังหวัด ภาคกลางปริมณฑล",
    Typemap: "perimeter-cen60",
    Typemapdistrict: "perimeter-cen60-district",
    Typemapsupdistrict: "perimeter-cen60-sub-district",
  },
  {
    id: "2056b7ae-66d1-480e-b3eb-6560a09e2c4f",
    NameT: "prov_gp3",
    Name: "กลุ่มจังหวัด ภาคกลางตอนล่าง 1",
    Typemap: "lower-cen1",
    Typemapdistrict: "lower-cen1-district",
    Typemapsupdistrict: "lower-cen1-sub-district",
  },
  {
    id: "1f04833d-c48c-49a9-8689-eb05d08009a0",
    NameT: "prov_gp4",
    Name: "กลุ่มจังหวัด ภาคกลางตอนล่าง 2",
    Typemap: "lower-cen2",
    Typemapdistrict: "lower-cen2-district",
    Typemapsupdistrict: "lower-cen2-sub-district",
  },
  {
    id: "8697cff4-294a-4761-b6a0-7ebc1ade6ea4",
    NameT: "prov_gp5",
    Name: "กลุ่มจังหวัด ภาคใต้ฝั่งอ่าวไทย",
    Typemap: "gulf of south",
    Typemapdistrict: "gulf of south-district",
    Typemapsupdistrict: "gulf of south-sub-district",
  },
  {
    id: "31b102ef-9fd8-4a23-88dd-5343eb358916",
    NameT: "prov_gp6",
    Name: "กลุ่มจังหวัด ภาคใต้ฝั่งอันดามัน",
    Typemap: "andaman of south",
    Typemapdistrict: "andaman of south-district",
    Typemapsupdistrict: "andaman of south-sub-district",
  },
  {
    id: "5ad009e2-44b3-4f8b-92cf-edc11308549c",
    NameT: "prov_gp7",
    Name: "กลุ่มจังหวัด ภาคใต้ชายแดน",
    Typemap: "south-border",
    Typemapdistrict: "south-border-district",
    Typemapsupdistrict: "south-border-sub-district",
  },
  {
    id: "836918d9-25d4-48ee-a941-a32c42079150",
    NameT: "prov_gp8",
    Name: "กลุ่มจังหวัด ภาคตะวันออก 1",
    Typemap: "east1",
    Typemapdistrict: "east1-district",
    Typemapsupdistrict: "east1-sub-district",
  },
  {
    id: "c2646678-d992-481a-a77e-4a76fb72d55c",
    NameT: "prov_gp9",
    Name: "กลุ่มจังหวัด ภาคตะวันออก 2",
    Typemap: "east2",
    Typemapdistrict: "east2-district",
    Typemapsupdistrict: "east2-sub-district",
  },
  {
    id: "00a71ad9-97b3-4cfa-8996-9f971e849113",
    NameT: "prov_gp10",
    Name: "กลุ่มจังหวัด ภาคตะวันออกเฉียงเหนือตอนบน 1",
    Typemap: "up-NE1",
    Typemapdistrict: "up-NE1-district",
    Typemapsupdistrict: "up-NE1-sub-district",
  },
  {
    id: "2498b688-a39e-4dde-8d20-96150a39c7e2",
    NameT: "prov_gp11",
    Name: "กลุ่มจังหวัด ภาคตะวันออกเฉียงเหนือตอนบน 2",
    Typemap: "up-NE2",
    Typemapdistrict: "up-NE2-district",
    Typemapsupdistrict: "up-NE2-sub-district",
  },
  {
    id: "ee62e346-61b1-431b-b421-ec934d53ed88",
    NameT: "prov_gp12",
    Name: "กลุ่มจังหวัด ภาคตะวันออกเฉียงเหนือตอนกลาง",
    Typemap: "cen-NE",
    Typemapdistrict: "cen-NE-district",
    Typemapsupdistrict: "cen-NE-sub-district",
  },
  {
    id: "d832d856-d17d-4e12-856e-3f1315ab3987",
    NameT: "prov_gp13",
    Name: "กลุ่มจังหวัด ภาคตะวันออกเฉียงเหนือตอนล่าง 1",
    Typemap: "low-NE",
    Typemapdistrict: "low-NE-district",
    Typemapsupdistrict: "low-NE-sub-district",
  },
  {
    id: "35db1d68-4e34-45aa-86f0-eaf68c6b95c0",
    NameT: "prov_gp14",
    Name: "กลุ่มจังหวัด ภาคตะวันออกเฉียงเหนือตอนล่าง 2",
    Typemap: "low-NE2",
    Typemapdistrict: "low-NE2-district",
    Typemapsupdistrict: "low-NE2-sub-district",
  },
  {
    id: "8617b6e8-d947-4557-b248-6bca9bcb8174",
    NameT: "prov_gp15",
    Name: "กลุ่มจังหวัด ภาคเหนือตอนบน 1",
    Typemap: "up-north1",
    Typemapdistrict: "up-north1-district",
    Typemapsupdistrict: "up-north1-sub-district",
  },
  {
    id: "d0473627-d58a-4b3b-8f8e-1b79c1f43a6b",
    NameT: "prov_gp16",
    Name: "กลุ่มจังหวัด ภาคเหนือตอนบน 2",
    Typemap: "up-north2",
    Typemapdistrict: "up-north2-district",
    Typemapsupdistrict: "up-north2-sub-district",
  },
  {
    id: "60e3b661-ad2f-4505-b645-1c0e10e78706",
    NameT: "prov_gp17",
    Name: "กลุ่มจังหวัด ภาคเหนือตอนล่าง 1",
    Typemap: "low-north1",
    Typemapdistrict: "low-north1-district",
    Typemapsupdistrict: "low-north1-sub-district",
  },
  {
    id: "d6883269-0806-47a6-8d46-25e18f867758",
    NameT: "prov_gp18",
    Name: "กลุ่มจังหวัด ภาคเหนือตอนล่าง 2",
    Typemap: "low-north2",
    Typemapdistrict: "low-north2-district",
    Typemapsupdistrict: "low-north2-sub-district",
  },
];

//----------------------------------------------------mapsAll---------------------------------------------------------------------//

var source = new VectorSource(); //ใช้ในการวาดรูป
var vector = new VectorLayer({
  //ใช้ในการวาดรูป
  zIndex: 10,
  name: "draw",
  source: source,
  style: new Style({
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)",
    }),
    stroke: new Stroke({
      color: "#ff0000",
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: "#ff0000",
      }),
    }),
  }),
});
var iconStyle = new Style({
  image: new Icon({
    color: "#FFF",
    crossOrigin: "anonymous",
    imgSize: [50, 50],
    src: "https://image.flaticon.com/icons/svg/202/202917.svg",
    //scale: 0,
  }),
});

var style = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.6)",
  }),
  stroke: new Stroke({
    color: "#319FD3",
    width: 1,
  }),
  text: new Text({
    overflow: true,
    font: "12px Calibri,sans-serif",
    fill: new Fill({
      color: "#000",
    }),
    stroke: new Stroke({
      color: "#fff",
      width: 3,
    }),
  }),
});
//---------------------------------------------------styleของขอบเขตจังหวัด--------------------------------//
var stylescope = function (feature) {
  let area = feature
    .getKeys()
    .filter(
      (f) =>
        f === "reg_cls_n" || /* reg_n_t */
        f === "pro_n_t" ||
        f === "cwt_name_t" ||
        f === "dit_n_t" ||
        f === "tam_n_t" ||
        f === "sdt_n_t" ||
        f === "sdt_t" ||
        f === "abt_name_t" ||
        f === "mun_n_t" ||
        f === "schoolname"
    );
  let namearea = feature.get(area[0]);
  let type = feature.getGeometry().getType();
  console.log(namearea);
  if (type === "MultiPolygon") {
    let style = new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.2)",
      }),
      stroke: new Stroke({
        color: "#000",
        width: 1.5,
      }),
      text: new Text({
        //overflow: true,
        font: "20px Calibri,sans-serif",
        fill: new Fill({
          color: "#F35",
        }),

        offsetY: 17,
        stroke: new Stroke({
          color: "#FFF",
          width: 3,
        }),
      }),
    });
    style.getText().setText(namearea);
    return style;
  } else {
    let stylepoint = new Style({
      image: new FontSymbol({
        glyph: "fa-university",
        //form:'square',
        fill: new Fill({ color: "#fff" }),
        //stroke: new Stroke({ color: '#fff', width: 2 }),
        color: "blue",
        radius: 10,
        anchor: [0.5, 1],
      }),
    });
    return stylepoint;
  }
};

/*-----------------------------------------------------CHECKEVEN CLICK-------------------------------------*/
var checkdatainfo = false;
var checkswipmap = false;
var checkplayertime = false;
var checkstylemanual = false;
/*----------------------------------------------------STYLED COMPONENT---------------------------------------------------------------------------------------------*/
const Controllmap = styled.div`
  /* notset */
`;
const ctrlswipe = new ol_control_Swipe({
  //className:'swipemap',
});
const SwipeMap = styled.div`
  input[type="range"] {
    position: absolute;
    top: 70%;
    width: 100%;
    z-index: 98;
    background-color: #1d1c25;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 0;
      background: #ffff;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: 2px solid;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      max-width: 80px;
      position: relative;
      bottom: 11px;
      background-color: #1d1c25;
      cursor: -webkit-grab;

      -webkit-transition: border 1000ms ease;
      transition: border 1000ms ease;
    }
  }
`;
/*--------------------------------------------------------VectorPine-----------------------------------------------*/
var styleCache = [];
var vectorpine = new VectorLayer({
  name: "Pine",
  renderOrder: ordering.yOrdering(),
});
//------------------------------------------------------VectorChangwat------------------------------------------//
const Changwat = new VectorLayer({
  name: "Changwat",
  serverType: "geoserver",
  attributions: "NSO@COPPYRIGHT",
  visible: true,
});
//------------------------------------------------------ADDSCOPE------------------------------------------//
const ADDSCOPE = new VectorLayer({
  name: "ADDSCOPE",
  serverType: "geoserver",
  attributions: "NSO@COPPYRIGHT",
  zIndex: 3,
});
const WMSBACKEND = new TileLayer({
  name: "WMSBACKEND",
  serverType: "geoserver",
  attributions: "NSO@COPPYRIGHT",
  zIndex: 3,
});

//----------------------------------------------------- ADDWMS-----------------------------------------//
const ADDWMS = new TileLayer({
  name: "ADDWMS",
  serverType: "geoserver",
  attributions: "NSO@COPPYRIGHT",
  zIndex: 3,
});

//------------------------------------------------------ VECTORMAP APIตอนดึงข้อมูล--------------------------//
const MAPAPI = new VectorLayer({
  name: "MAPAPI",
  zIndex: 1,
});
const MAPAPIPOINT = new VectorLayer({
  name: "MAPAPIPOINT",
  zIndex: 2,
  visible: false,
});
const MAPAPIPOINTSWIPE = new VectorLayer({
  name: "MAPAPIPOINTSWIPE",
  zIndex: 2,
  visible: false,
});
const MAPAPIPINEBAR = new VectorLayer({
  name: "MAPAPIPINEBAR",
  zIndex: 2,
  visible: true,
});
//------------------------------------------------------ VECTORMAP API SWIPEตอนดึงข้อมูล--------------------------//
const MAPAPISWIPE = new VectorLayer({
  name: "MAPAPISWIPE",
  zIndex: 0,
});
const PLAYMAPPLAYER = new LayerGroup({});
//------------------------------------------------------BASE MAP-----------------------------------------

const map = new Map({
  layers: [
    basemap.LayerGroups,
    //Changwat
  ],
  /* view: new View({
    zoom: 10,
    maxZoom: 11,

  }), */
  controls:/*  DefaultControls().extend( */[
    new MousePosition(),
    new ScaleLine(),

    //fullscreen,
  ]/* ) */,
});
/*-------------------------------------------------ฟังชั่นstyleเริ้มต้น--------------------------------*/
var Gocenter = false;
var features = new Array();
var container;
var id;
var pro_id;
var namechangwat;
var poptype;
var statlist;
var datastatlist;
var closer;



class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showswichmap: "block",
      info: true,
      urlwfs: "http://wms.nso.go.th/geoserver/NSOMAP/ows?service=WFS",
      layerwfs: "NSOMAP:PROVINCE_NOW@DAT_RUNNO46",
      sld: "http://statgis.nso.go.th/sld/VINDBUILD/GROWTH3.sld",
      showdraw: "LineString",
      showswichmap: "block",
      info: true,
      fullscreenSlidebar: false,
      DigitalMapReg: [],
      DigitalMapProv: [],
      DigitalMapDist: [],
      DigitalMapAbt: [],
      DigitalMapMuni: [],
      DigitalMapSubdist: [],
      DigitalMapSchool: [],
      Stylemanual: "",
      Stylenaturalbreak: [],
      Stylequartile: [],
      Stylenequalinterval: [],
      Stylestandrad: [],
      Styleproporation: "#F54",
      stylepinebar: [],
      StylePie: "",
      Stylenaturalbreakswipe: [],
      Stylequartileswipe: [],
      Stylenequalintervalswipe: [],
      Stylestandradswipe: [],
      btcontrolldraw: false,
      btcontrollserch: false,
      btcontrollstylecolor: false,
      btcontrollstylelayer: false,
      btcontrollscope: false,
      btcontrollbasemap: false,
      statemodal: false,
      datatable: [
        [],
        [{ title: "AREA_NAME" }, { title: "TM_YEAR" }, { title: "STAT_COUNT" }],
      ],
      wmsbackend: [],
      successCreateMap: false,
      layerlistselect: [],
      loopArea: [],
      loopAreaSwipe: [],
      fontstyle: 16,
      showtext: true,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.Showapimap = this.Showapimap.bind(this);
  }
  updateDimensions() {
    const h = window.innerHeight;
    if (this.state.height) {
    } else {
      this.setState({ height: h });
    }
    console.log(window.innerHeight);
    /*  if (window.innerHeight >= 850) {
       this.setState({ fullscreenSlidebar: true })
       console.log(1)
     } else {
       this.setState({ fullscreenSlidebar: false })
       console.log(2)
     } */
  }
  UNSAFE_componentWillMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }
  textmaps = (resolution) => {
    return {
      overflow: resolution <= 120 ? true : false,
      font: `${this.state.fontstyle}px Calibri,sans-serif`,
      fill: new Fill({
        color: "#000",
      }),
      stroke: new Stroke({
        color: "#FFF",
        width: 2,
      }),
    }
  }
  componentDidMount() {
    const defaultStyle = (feature, resolution) => {
      var checkprop = feature.getKeys();
      var textsize = (10 / resolution) * 100; // 10 being the default font size in px

      var Extent = feature.getGeometry().getExtent(); // ดึงขอบเขตคำนวนแล้วจากMutipolygon
      var X = Extent[0] + (Extent[2] - Extent[0]) / 2; //คำนวนพิกัดแกนx
      var Y = Extent[1] + (Extent[3] - Extent[1]) / 2; //คำนวนพิกัดแกนy
      features.push(new Feature(new Point([X, Y])));
      if ((Gocenter === false) & (this.props.paramurl !== "")) {
        Gocenter = true;
        map.getView().fit(feature.getGeometry());
        this.setState({
          Center: [X, Y],
          featurepoint: features,
          Zoom: map.getView().getZoom(),
        });
      } else if ((Gocenter === false) & (this.props.paramurl === "")) {
        Gocenter = true;
        map.setView(
          new View({
            projection: "EPSG:3857",
            center: [11212786.887401765, 1452220.9713594653],
            zoom: 5.5,
            minZoom: 3,
            maxZoom: 19,
          })
        );
        this.setState({
          featurepoint: features,
        });
      }
      var defaultStyle = new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.0)",
        }),
        stroke: new Stroke({
          color: "#198cff",
          width: 1.5,
        }),
        text: new Text({
          //scale	: 0.5,
          overflow: false,
          font: "15px Calibri,sans-serif",
          fill: new Fill({
            color: "#fff",
          }),
          stroke: new Stroke({
            color: "#000",
            width: 3,
          }),
        }),
      });
      if (resolution <= 600) {
        defaultStyle.getStroke().setWidth(6);
      }
      defaultStyle.getText().setText(feature.get("pro_n_t"));

      return defaultStyle;
    };
    /*------------------------------------------------------------------------------------------------------------*/
    // Create an Openlayer Map instance with two tile layers
    map.setTarget("map");
    this.setState({
      map: map,
    });
    var ZOOM = new Zoom({
      target: document.querySelector(".controlapi"),
    });
    var fullscreen = new FullScreen({
      className: "ol-zoom",
      target: document.querySelector(".controlapi"),
      /*  source: 'all', */
      keys: false,
    });
    map.addControl(ZOOM);
    map.addControl(fullscreen);

    // $(".addfullscreenandremoveclass").removeClass("ol-unselectable ol-control");
    sync(map);
    map.renderSync();

    /*------------------------------------------โชวพื้นที่ระดับจังหวัดเมื่อเข้าครั้งแรก----------------------------*/
    var checkpartchangwat;
    if (this.props.paramurl === "") {
      checkpartchangwat = require(`../geojson/thailand.geojson`);
    } else {
      checkpartchangwat = require(`../geojson/${this.props.paramurl}.geojson`);
    }
    var SourceChangwat = new VectorSource({
      url: checkpartchangwat,
      format: new GeoJSON(),
      serverType: "geoserver",
      crossOrigin: "anonymous",
    });

    Changwat.setSource(SourceChangwat);

    Changwat.setStyle(defaultStyle);

    /*---------------------------------ตั้งค่าStrat Layer---------------------------------------------------*/
    map.addLayer(Changwat);
    //----------------------------------------------เมื่อกดปุ่มขยายหน้าจอ-------------------//
    window.addEventListener("resize", () => {
      if (this.state.height === window.innerHeight) {
        if (this.state.fullscreenSlidebar === true) {
          this.setState({ fullscreenSlidebar: !this.state.fullscreenSlidebar });
        } else {
        }
      }
    });
    $(".fullscreen").click(() => {
      this.setState({ fullscreenSlidebar: !this.state.fullscreenSlidebar });
      if (this.state.fullscreenSlidebar === true) {
        /* document.getElementById("map").style.height = "100vh"; */
      } else {
        /*  document.getElementById("map").style.height = "76vh"; */
      }
    });
    $("body").keydown((e) => {
      console.log(e);
      if (e.keyCode === 27) {
        if (this.state.fullscreenSlidebar === true) {
          this.setState({ fullscreenSlidebar: !this.state.fullscreenSlidebar });
        } else {
        }
      }
    });

    /*-------------------------------------------------------ฟังชั่นเครื่องมือ controll ด้านขวา-------------------------------*/
    /*----------------------------ฟังชั่นกลับไปจุดศูนย์กลางmap---------------------------------------------*/

    var setcentermap = document
      .getElementById("centermap")
      .addEventListener("click", () => {
        flyTo(
          this.state.Center
            ? this.state.Center
            : [11212786.887401765, 1452220.9713594653],
          this.state.Zoom ? this.state.Zoom : 5.5,
          () => { }
        );
      });
    function flyTo(location, setzoom, done) {
      var duration = 2000;
      var zoom = map.getView().getZoom();
      var parts = 2;
      var called = false;
      function callback(complete) {
        --parts;
        if (called) {
          return;
        }
        if (parts === 0 || !complete) {
          called = true;
          done(complete);
        }
      }
      map.getView().animate(
        {
          center: location,
          duration: duration,
        },
        callback
      );
      map.getView().animate(
        {
          zoom: zoom - 1,
          duration: duration / 2,
        },
        {
          zoom: setzoom,
          duration: duration / 2,
        },
        callback
      );
    }

    /*---------------------------- เปลี่ยน basemap ด้านหลัง---------------*/
    var selectmap = document.querySelectorAll(
      ".dropdown-content > input[name='changbasemap']"
    );
    for (let selectmaps of selectmap) {
      selectmaps.addEventListener("change", function () {
        let selectmapvalue = this.value;
        basemap.LayerGroups.getLayers().forEach(function (
          element,
          index,
          array
        ) {
          let baseselectlayer = element.get("title");
          element.setVisible(baseselectlayer === selectmapvalue);
          //console.log(baseselectlayer, selectmapvalue);
        });
      });
    }

    /*---------------------ฟังชั่นวาดรูป-------------------------------------------------------------------------------------------*/
    var typeSelect = document.getElementById("type");
    var draw; // global so we can remove it later
    var btndrawdel = document.getElementById("btndrawdel");
    function addInteraction() {
      var value = typeSelect.value;
      if (value !== "None") {
        var geometryFunction;
        if (value === "Square") {
          value = "Circle";
          geometryFunction = createRegularPolygon(4);
        } else if (value === "clear") {
        } else if (value === "Box") {
          value = "Circle";
          geometryFunction = createBox();
        } else if (value === "Star") {
          value = "Circle";
          geometryFunction = function (coordinates, geometry) {
            var center = coordinates[0];
            var last = coordinates[1];
            var dx = center[0] - last[0];
            var dy = center[1] - last[1];
            var radius = Math.sqrt(dx * dx + dy * dy);
            var rotation = Math.atan2(dy, dx);
            var newCoordinates = [];
            var numPoints = 12;
            for (var i = 0; i < numPoints; ++i) {
              var angle = rotation + (i * 2 * Math.PI) / numPoints;
              var fraction = i % 2 === 0 ? 1 : 0.5;
              var offsetX = radius * fraction * Math.cos(angle);
              var offsetY = radius * fraction * Math.sin(angle);
              newCoordinates.push([center[0] + offsetX, center[1] + offsetY]);
            }
            newCoordinates.push(newCoordinates[0].slice());
            if (!geometry) {
              geometry = new Polygon([newCoordinates]);
            } else {
              geometry.setCoordinates([newCoordinates]);
            }
            return geometry;
          };
        }
        draw = new Draw2({
          zIndex: 3,
          source: source,
          type: value,
          geometryFunction: geometryFunction,
        });
        map.addInteraction(draw);
      } else {
        //vector.getSource().clear();
        map.renderSync();
      }
    }
    typeSelect.onchange = function () {
      map.removeLayer(vector); //ไว้อันสุดท้าย
      map.removeInteraction(draw);
      addInteraction();
      map.addLayer(vector); //ไว้อันสุดท้าย
      map.renderSync();
    };
    btndrawdel.onclick = function () {
      document.getElementById("type").options.selectedIndex = 0;
      vector.getSource().clear();
      map.removeInteraction(draw);
      map.renderSync();
    };
    /*-------------------------------------ทำอีเว้นเมื่อคลิ้ก----------------------------------------*/
    map.on("click", (e) => {
      this.handlebtcontrollOpen(""); //handleของเมนูอื่นๆที่เป็นdropdraw ปิดเมนูทั้งหมด
    });
    /*--------------------------------------showpopup ค่าข้อมูลgeojson------------------------------------*/
    container = document.getElementById("popup");
    id = document.getElementById("popup-id");
    pro_id = document.getElementById("popup-pro_id");
    namechangwat = document.getElementById("popup-namechangwat");
    poptype = document.getElementById("popup-type");
    statlist = document.getElementById("popup-statlist");
    datastatlist = document.getElementById("popup-data");
    closer = document.getElementById("popup-closer");

    /*------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    this.GetNameMapDigital(); //ยิงapiดึงชื่อขอบเขตแผนที่
    document.querySelector(
      "#serchvalue"
    ).disabled = true; /* disable ปุ่ม input ค้นหา*/

    /*-----------------------------------------------------------เชคปุ่มว่ากดที่ manualหรือป่าว-----------------------*/
    let checkradiostylelayer = document.querySelectorAll(".colorstyle");
    checkradiostylelayer.forEach((radio) => {
      radio.addEventListener("change", () => {
        checkradiostylelayer.forEach((checkradio) => {
          if (checkradio.checked === true) {
            if (checkradio.id === "q1") {
              if ("COLOR_BAR" in this.state.Stylemanual) {
                document.querySelector("#Barchart").disabled = false;
              } else {
                document.querySelector("#Barchart").disabled = true;
              }
              /*  if (('COLOR_PIE' in this.state.Stylemanual)) {
                 document.querySelector('#checkpie').disabled = false;

               } else {
                 document.querySelector('#checkpie').disabled = true;

               } */
              if ("COLOR_PROPOR" in this.state.Stylemanual) {
                document.querySelector("#Proportion").disabled = false;
              } else {
                document.querySelector("#Proportion").disabled = true;
              }
              if ("COLOR_CHOROPLETH" in this.state.Stylemanual) {
                document.querySelector("#Choropleth").disabled = false;
                map.getLayers().forEach((layer, element) => {
                  if (
                    layer === basemap.LayerGroups ||
                    layer === Changwat ||
                    layer === ADDWMS ||
                    layer === ADDSCOPE ||
                    layer === MAPAPISWIPE ||
                    layer.get("attributions") === "NSO@COPPYRIGHT"
                  ) {
                  } else if (
                    layer.get("name") === "MAPAPI"
                  ) {
                    layer.setVisible(true);
                    layer.setStyle(this.Stylemanualcolopleth);
                    this.props.setChangeStyle({
                      style: this.state.Stylemanual.COLOR_CHOROPLETH,
                      status: "mncp",
                    });
                  } else {
                    layer.setVisible(false);
                  }
                });
                document.querySelector("#Choropleth").checked = true;
              } else {
                document.querySelector("#Choropleth").disabled = true;
              }
              checkstylemanual = true;
            } else {
              /* document.querySelector('#Barchart').disabled = false;
              document.querySelector('#checkpie').disabled = false; */
              document.querySelector("#Proportion").disabled = false;
              document.querySelector("#Choropleth").disabled = false;
              checkstylemanual = false;
            }
          }
        });
        vector.setVisible(true);
      });
    });

  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  handlebtcontrollOpen = (selectcontrollOpen) => {
    this.setState({
      btcontrolldraw:
        selectcontrollOpen === "draw" ? !this.state.btcontrolldraw : false,
      btcontrollserch:
        selectcontrollOpen === "serch" ? !this.state.btcontrollserch : false,
      btcontrollstylecolor:
        selectcontrollOpen === "stylecolor"
          ? !this.state.btcontrollstylecolor
          : false,
      btcontrollstylelayer:
        selectcontrollOpen === "stylelayer"
          ? !this.state.btcontrollstylelayer
          : false,
      btcontrollscope:
        selectcontrollOpen === "scope" ? !this.state.btcontrollscope : false,
      btcontrollbasemap:
        selectcontrollOpen === "basemap"
          ? !this.state.btcontrollbasemap
          : false,
    });
  };
  checkclickevent = () => {
    if (checkdatainfo === true) {
      document.getElementById("datainfo").click();
    }
    if (checkswipmap === true) {
      document.getElementById("SwipeMap").click();
      this.props.setChangSwipeMap({
        swipeMapOpen: !this.props.swipeMap.swipeMapOpen,
      });
    } else {
      this.props.setChangSwipeMap({
        swipeMapOpen: !this.props.swipeMap.swipeMapOpen,
      });
    }
    if (checkplayertime === true) {
      document.getElementById("slidemap").click();
    }
  };
  /*--------------------------------------ฟังชั่นGetชื่อแผนที่ระดับต่างๆ---------------------------------------*/
  GetNameMapDigital = () => {
    try {
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/getmapdominance",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: {
          name_check: "reg", //prov//dist//subdist//muni//abt//school
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          // console.log("response  >>>", res.data)
          this.setState({
            DigitalMapReg: res.data,
          });
        })
        .catch((error) => {
          console.error("error  >>>", error);
        });
    } catch (error) {
      console.error(error);
    }
    try {
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/getmapdominance",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: {
          name_check: "prov", //prov//dist//subdist//muni//abt//school
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          // console.log("response  >>>", res.data)
          this.setState({
            DigitalMapProv: res.data,
          });
        })
        .catch((error) => {
          console.error("error  >>>", error);
        });
    } catch (error) {
      console.error(error);
    }
    try {
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/getmapdominance",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: {
          name_check: "dist", //prov//dist//subdist//muni//abt//school
        },

        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          // console.log("response  >>>", res.data)
          this.setState({
            DigitalMapDist: res.data,
          });
        })
        .catch((error) => {
          console.error("error  >>>", error);
        });
    } catch (error) {
      console.error(error);
    }
    try {
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/getmapdominance",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: {
          name_check: "subdist", //prov//dist//subdist//muni//abt//school
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          // console.log("response  >>>", res.data)
          this.setState({
            DigitalMapSubdist: res.data,
          });
        })
        .catch((error) => {
          console.error("error  >>>", error);
        });
    } catch (error) {
      console.error(error);
    }
    try {
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/getmapdominance",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: {
          name_check: "muni", //prov//dist//subdist//muni//abt//school
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          // console.log("response  >>>", res.data)
          this.setState({
            DigitalMapMuni: res.data,
          });
        })
        .catch((error) => {
          console.error("error  >>>", error);
        });
    } catch (error) {
      console.error(error);
    }
    try {
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/getmapdominance",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: {
          name_check: "abt", //prov//dist//subdist//muni//abt//school
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          // console.log("response  >>>", res.data)
          this.setState({
            DigitalMapAbt: res.data,
          });
        })
        .catch((error) => {
          console.error("error  >>>", error);
        });
    } catch (error) {
      console.error(error);
    }
    try {
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/getmapdominance",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: {
          name_check: "school", //prov//dist//subdist//muni//abt//school
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          // console.log("response  >>>", res.data)
          this.setState({
            DigitalMapSchool: res.data,
          });
        })
        .catch((error) => {
          console.error("error  >>>", error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  /*------------------------------------------------STYLE PINE ------------------------*/
  ChangStyleLayer = async (namestyle) => {
    console.log("test get in ChangStyleLayer >>>");
    let checklayeronmap = false;
    map.getLayers().forEach((layer, element) => {
      if (
        layer.get("name") === "MAPAPI" ||
        layer.get("name") === "MAPAPISWIPE" ||
        layer.get("name") === "MAPAPIPINEBAR"
      ) {
        checklayeronmap = true;
      }
    });
    if (checklayeronmap) {
      switch (namestyle) {
        case "Choropleth map":
          if (checkstylemanual === true) {
            map.getLayers().forEach((layer, element) => {
              if (
                layer === basemap.LayerGroups ||
                layer === Changwat ||
                layer === ADDSCOPE ||
                layer === ADDWMS ||
                layer.get("attributions") === "NSO@COPPYRIGHT"
              ) {
              } else if (
                layer.get("name") === "MAPAPI" ||
                layer.get("name") === "MAPAPISWIPE"
              ) {
                layer.setVisible(true);
                layer.setStyle(this.Stylemanualcolopleth);
                this.props.setChangeStyle({
                  style: this.state.Stylemanual.COLOR_CHOROPLETH,
                  status: "mncp",
                });
              } else {
                layer.setVisible(false);
              }
            });
          } else {
            map.getLayers().forEach((layer, element) => {
              if (
                layer === basemap.LayerGroups ||
                layer === Changwat ||
                layer === ADDSCOPE ||
                layer === ADDWMS ||
                layer.get("attributions") === "NSO@COPPYRIGHT"
              ) {
              } else if (
                layer.get("name") === "MAPAPI" ||
                layer.get("name") === "MAPAPISWIPE"
              ) {
                layer.setVisible(true);
                let checkstylecolor = document.querySelectorAll(".colorstyle");
                checkstylecolor.forEach((radio) => {
                  if (radio.checked === true) {
                    console.log(radio.value);
                    switch (radio.value) {
                      case "NaturalBreaks":
                        layer.setStyle(this.Stylenaturalbreak);
                        this.props.setChangeStyle({
                          style: this.state.Stylenaturalbreak,
                          status: "nb",
                        });
                        break;
                      case "EqualInterval":
                        layer.setStyle(this.Styleequalinterval);
                        this.props.setChangeStyle({
                          style: this.state.Stylenequalinterval,
                          status: "eq",
                        });
                        break;
                      case "Quantile":
                        layer.setStyle(this.StyleQuantile);
                        this.props.setChangeStyle({
                          style: this.state.Stylequartile,
                          status: "qt",
                        });
                        break;
                      case "StandardDeviation":
                        layer.setStyle(this.Stylestandrad);
                        this.props.setChangeStyle({
                          style: this.state.Stylestandrad,
                          status: "sd",
                        });
                        break;

                      default:
                        layer.setStyle(this.Stylenaturalbreak);
                        this.props.setChangeStyle({
                          style: this.state.Stylenaturalbreak,
                          status: "nb",
                        });
                        break;
                    }
                  }
                });
                /*  layer.setStyle(this.Stylenaturalbreak)
                 this.props.setChangeStyle({ style: this.state.Stylenaturalbreak, status: 'nb' }); */
              } else {
                layer.setVisible(false);
              }
            });
          }
          break;
        case "Barchart map":
          if (
            this.props.selectedStat.classify.classifyData.find(
              (item) => item.codelistChild.id !== "t"
            )
          ) {
            if (checkstylemanual === true) {
              map.getLayers().forEach(async (layer, element) => {
                if (
                  layer === basemap.LayerGroups ||
                  layer === Changwat ||
                  layer === ADDSCOPE ||
                  layer === ADDWMS ||
                  layer.get("attributions") === "NSO@COPPYRIGHT"
                ) {

                } else {
                  layer.setVisible(false);
                }
              });
              await this.Showapimappinebar();
              MAPAPIPINEBAR.setVisible(true);
              MAPAPIPINEBAR.setStyle(this.Stylemanualbar);
              this.props.setChangeStyle({
                style: this.state.Stylemanual.COLOR_BAR,
                status: "mnbar",
              });
            } else {
              map.getLayers().forEach(async (layer, element) => {
                if (
                  layer === basemap.LayerGroups ||
                  layer === Changwat ||
                  layer === ADDSCOPE ||
                  layer === ADDWMS ||
                  layer.get("attributions") === "NSO@COPPYRIGHT"
                ) {

                } else {
                  layer.setVisible(false);
                }
              });
              await this.Showapimappinebar();
              MAPAPIPINEBAR.setStyle(this.StyleBar);
              MAPAPIPINEBAR.setVisible(true);
              this.props.setChangeStyle({
                style: this.state.stylepinebar,
                status: "bar",
              });
            }
          } else {
            document.getElementById("Barchart").checked = false;
            // document.getElementById("Choropleth").checked = true;
            this.props.setModal({
              modalAction: "open",
              title: "คำอธิบาย?",
              text: "กรุณาทำการเลือกรายการจัดจำแนกก่อน...!",
              icon: "info",
              button: [
                {
                  text: "ตกลง",
                  width: "80px",
                  bgcolor: "#3085d6",
                  functionIn: (e) =>
                    this.handleModal(e, "this is a text from callback ok !"),
                },
              ],
            });
          }
          break;
        case "Pie Chart":
          if (
            this.props.selectedStat.classify.classifyData.find(
              (item) => item.codelistChild.id !== "t"
            )
          ) {
            if (checkstylemanual === true) {
              map.getLayers().forEach(async (layer, element) => {
                if (
                  layer === basemap.LayerGroups ||
                  layer === Changwat ||
                  layer === ADDSCOPE ||
                  layer === ADDWMS ||
                  layer.get("attributions") === "NSO@COPPYRIGHT"
                ) {
                } else {
                  layer.setVisible(false);
                }
              });
              await this.Showapimappinebar();
              MAPAPIPINEBAR.setVisible(true);
              MAPAPIPINEBAR.setStyle(this.Stylemanualpie);
              this.props.setChangeStyle({
                style: this.state.Stylemanual.COLOR_PIE,
                status: "mnpie",
              });
            } else {
              map.getLayers().forEach(async (layer, element) => {
                if (
                  layer === basemap.LayerGroups ||
                  layer === Changwat ||
                  layer === ADDSCOPE ||
                  layer === ADDWMS ||
                  layer.get("attributions") === "NSO@COPPYRIGHT"
                ) {
                } else {
                  layer.setVisible(false);
                }
              });
              await this.Showapimappinebar();
              MAPAPIPINEBAR.setVisible(true);
              MAPAPIPINEBAR.setStyle(this.StylePine);
              this.props.setChangeStyle({
                style: this.state.stylepinebar,
                status: "pie",
              });
            }
          } else {
            document.getElementById("checkpie").checked = false;
            // document.getElementById("Choropleth").checked = true;
            this.props.setModal({
              modalAction: "open",
              title: "คำอธิบาย?",
              text: "กรุณาทำการเลือกรายการจัดจำแนกก่อน...!",
              icon: "info",
              button: [
                {
                  text: "ตกลง",
                  width: "80px",
                  bgcolor: "#3085d6",
                  functionIn: (e) =>
                    this.handleModal(e, "this is a text from callback ok !"),
                },
              ],
            });
          }
          break;
        case "Proportion Circle":
          if (checkstylemanual === true) {
            map.getLayers().forEach((layer, element) => {
              if (
                layer === basemap.LayerGroups ||
                layer === Changwat ||
                layer === ADDSCOPE ||
                layer === ADDWMS ||
                layer.get("attributions") === "NSO@COPPYRIGHT"
              ) {
              } else if (layer === MAPAPIPOINT) {
                this.props.setChangeStyle({
                  style: this.state.Stylemanual.COLOR_PROPOR,
                  status: "mnpop",
                });
                layer.setVisible(true);
                layer.setStyle(this.Stylemanualpop);
              } else {
                layer.setVisible(false);
              }
            });
          } else {
            map.getLayers().forEach((layer, element) => {
              if (
                layer === basemap.LayerGroups ||
                layer === Changwat ||
                layer === ADDSCOPE ||
                layer === ADDWMS ||
                layer.get("attributions") === "NSO@COPPYRIGHT"
              ) {
              } else if (layer === MAPAPIPOINT) {
                this.props.setChangeStyle({
                  style: this.state.Styleproporation,
                  status: "pop",
                });
                layer.setVisible(true);
                layer.setStyle(this.StyleProportion);
              } else {
                layer.setVisible(false);
              }
            });
          }

          break;
        default:
          break;
      }
    } else {
      this.props.setModal({
        modalAction: "open",
        title: "คำอธิบาย?",
        text: "กรุณาเลือกรายการสถิติเพื่อแสดงผลบนแผนที่ก่อน!",
        icon: "info",
        button: [
          {
            text: "ตกลง",
            width: "80px",
            bgcolor: "#3085d6",
            functionIn: (e) =>
              this.handleModal(e, "this is a text from callback ok !"),
          },
        ],
      });
      //document.getElementById("Choropleth").checked = true;
    }
  };
  StylePine = (feature, select) => {
    let data = [];
    let color = [];
    this.state.stylepinebar[0].forEach((fil, index) => {
      if (index === 0) {
      } else {
        //console.log(feature.get(fil))
        if (feature.get(fil) === "-") {
          data.push(0);
          color.push(this.state.stylepinebar[2][index]);
        } else {
          data.push(parseInt(feature.get(fil)));
          color.push(this.state.stylepinebar[2][index]);
        }
      }
    });
    //console.log(feature.getKeys())
    var sumdata = 0;
    for (var i = 0; i < data.length; i++) {
      sumdata += data[i];
    }
    var k =
      $("#graph").val() +
      "-" +
      $("#color").val() +
      "-" +
      (select ? "1-" : "") +
      data;
    let style = styleCache[k];
    if (!style) {
      var radius = 15;
      radius = 8 * Math.sqrt(50 / Math.PI);
      radius *= select ? 1.2 : 1;
      // Create chart style
      //console.log("data>>>>", data)
      style = [
        new Style({
          image: new Chartpine({
            type: "pie",
            radius: radius,
            data: data,
            colors: color,
            rotateWithView: true,
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
          }),
        }),
      ];

      // Show values on selectect
      if (select) {
        /*
        var sum = 0;
        for (var i=0; i<data.length; i++)
        {	sum += data[i];
        }
        */
        var sum = sumdata;

        var s = 0;
        for (var ii = 0; ii < data.length; ii++) {
          var d = data[ii];
          var a = ((2 * s + d) / sum) * Math.PI - Math.PI / 2;
          var v = Math.round((d / sum) * 1000);
          if (v > 0) {
            style.push(
              new Style({
                text: new Text({
                  font: "18px Calibri,sans-serif",
                  text: d + "" /*(d) d.toString() */ /* (v / 10) + "%", */,
                  offsetX: Math.cos(a) * (radius + 3),
                  offsetY: Math.sin(a) * (radius + 3),
                  textAlign: a < Math.PI / 2 ? "left" : "right",
                  textBaseline: "middle",
                  stroke: new Stroke({ color: "#fff", width: 2.5 }),
                  fill: new Fill({ color: "#333" }),
                }),
              })
            );
          }
          s += d;
        }
      }
    }
    // styleCache[k] = style;
    return style;
  };
  StyleBar = (feature, select) => {
    let data = [];
    let color = [];

    this.state.stylepinebar[0].forEach((fil, index) => {
      if (index === 0) {
      } else {
        if (feature.get(fil) === "-") {
          data.push(0);
          color.push(this.state.stylepinebar[2][index]);
        } else {
          data.push(parseInt(feature.get(fil)));
          color.push(this.state.stylepinebar[2][index]);
        }
      }
    });
    var sumdata = 0;
    for (var i = 0; i < data.length; i++) {
      sumdata += data[i];
    }
    var radius = 30;
    radius = 10 * Math.sqrt(100 / Math.PI);
    //radius *= (select ? 1.2 : 1);
    // Create chart style
    style = [
      new Style({
        image: new Chartpine({
          type: "bar",
          radius: radius,
          colors: color,
          data: data,
          animation: 1,
          snapToPixel: true,
          rotateWithView: true,
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }),
      }),
    ];
    // Show values on selectect
    if (select) {
      /*
        var sum = 0;
        for (var i=0; i<data.length; i++)
        {	sum += data[i];
        }
        */
      var sum = sumdata;

      var s = 0;
      for (var ii = 0; ii < data.length; ii++) {
        var d = data[ii];
        var a = ((2 * s + d) / sum) * Math.PI - Math.PI / 2;
        var v = Math.round((d / sum) * 1000);
        if (v > 0) {
          style.push(
            new Style({
              text: new Text({
                font: "18px Calibri,sans-serif",
                text: d + "" /* d.toString() */,
                offsetX: Math.cos(a) * (radius - 100),
                offsetY: Math.sin(a) * (radius - 100),
                textAlign: a < Math.PI / 2 ? "left" : "right",
                textBaseline: "middle",
                stroke: new Stroke({ color: "#fff", width: 2.5 }),
                fill: new Fill({ color: "#333" }),
              }),
            })
          );
        }
        s += d;
      }
    }
    //style[1].getText().setText(feature.get('pro_n_t'));
    return style;
  };
  StyleProportion = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get('TOTAL'));
    let level = this.state.Stylenaturalbreak.LEVEL.map(x => +x);
    let average = (array) => array.reduce((a, b) => a + b) / array.length;
    let radius = 1 * Math.sqrt(checkvalue / Math.PI / 2);
    let maxmin = Math.max(...level) == 0 ? 1 : Math.max(...level);
    let test = 100 * Math.sqrt(checkvalue / maxmin) == 0 ? 10 : 100 * Math.sqrt(checkvalue / maxmin);

    var population = new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: "#F54",
        }),
        radius: test,
        scale: 1,
        stroke: new Stroke({
          color: "#FFF",
          width: 1.5,
        }),
      }),
      text: new Text({
        overflow: false,
        font: "16px Calibri,sans-serif",
        fill: new Fill({
          color: "#FFF",
        }),
        stroke: new Stroke({
          color: "#000",
          width: 2,
        }),
      }),
    });
    population.getText().setText(this.numberWithCommas(checkvalue.toString()));
    return population;
  };
  /*-------------------------------------ค้นหาแบบมีเงื่อนไข--------------------------------------------*/
  Serchif = (x = "serch") => {
    const Resetstyle = () => {
      console.log("resetstyle");
      let isstyle = this.props.changeStyle.status;
      if (isstyle === "nb") {
        MAPAPI.setStyle(this.Stylenaturalbreak);
      } else if (isstyle === "eq") {
        MAPAPI.setStyle(this.Styleequalinterval);
      } else if (isstyle === "qt") {
        MAPAPI.setStyle(this.StyleQuantile);
      } else if (isstyle === "sd") {
        MAPAPI.setStyle(this.Stylestandrad);
      } else if (isstyle === "mncp") {
        MAPAPI.setStyle(this.Stylemanualcolopleth);
      }
      MAPAPISWIPE.setStyle(this.Stylenaturalbreak);
    };
    if (x === "clear") {
      document.querySelector("#serchvalue").value = "";
      document.querySelector("#serchvalue2").value = "";

      map.getLayers().forEach((layer) => {
        if (layer === basemap.LayerGroups || layer === Changwat) {
        } else if (layer === MAPAPI || layer === MAPAPISWIPE) {
          /*  layer.setStyle(this.Stylenaturalbreak);
           document.querySelector('#q2').checked = true; */
          Resetstyle();
        } else {
          layer.setVisible(false);
        }
      });
    } else {
      if (this.props.selectedStat.statCodeId !== "") {
        let elimentserch = document.querySelector("#serchif").value;
        //console.log('elimentserch',elimentserch)
        let elimentserchstyle = document.querySelector("#serchstyle").value;
        //console.log('serchstyle',elimentserchstyle)
        if (elimentserch !== "") {
          switch (elimentserchstyle) {
            case "higtligt":
              MAPAPI.setVisible(true);
              MAPAPIPOINT.setVisible(false);
              MAPAPI.setStyle(this.StyleSerchHigtligt);
              /*------------------------------------------*/
              MAPAPISWIPE.setVisible(true);
              MAPAPIPOINTSWIPE.setVisible(false);
              MAPAPISWIPE.setStyle(this.StyleSerchHigtligt);
              break;
            case "point":
              MAPAPIPOINT.setVisible(true);
              MAPAPIPOINTSWIPE.setVisible(true);
              MAPAPI.setVisible(true);
              MAPAPISWIPE.setVisible(true);
              Resetstyle();
              MAPAPIPOINT.setStyle(this.StyleSerchcolor);
              MAPAPIPOINTSWIPE.setStyle(this.StyleSerchcolor);
              /*  document.getElementById("q2").checked = false; */
              Resetstyle();
              break;
            case "star":
              MAPAPIPOINT.setVisible(true);
              MAPAPIPOINTSWIPE.setVisible(true);
              MAPAPI.setVisible(true);
              MAPAPISWIPE.setVisible(true);
              Resetstyle();
              MAPAPIPOINT.setStyle(this.StyleSerchStar);
              MAPAPIPOINTSWIPE.setStyle(this.StyleSerchStar);
              /*  document.getElementById("q2").checked = false; */

              break;
            case "flag":
              MAPAPIPOINT.setVisible(true);
              MAPAPIPOINTSWIPE.setVisible(true);
              MAPAPI.setVisible(true);
              MAPAPISWIPE.setVisible(true);
              Resetstyle();
              MAPAPIPOINT.setStyle(this.StyleSerchFlag);
              MAPAPIPOINTSWIPE.setStyle(this.StyleSerchFlag);
              /*  document.getElementById("q2").checked = false; */

              break;
            default:
              MAPAPIPOINT.setVisible(false);
              MAPAPIPOINTSWIPE.setVisible(false);
              Resetstyle();
              document.getElementById("Choropleth").checked = true;

              break;
          }
        } else {
        }
      } else {
        this.props.setModal({
          modalAction: "open",
          title: "คำอธิบาย?",
          text: "กรุณาเลือกรายการสถิติเพื่อแสดงผลบนแผนที่ก่อน!",
          icon: "info",
          button: [
            {
              text: "ตกลง",
              width: "80px",
              bgcolor: "#3085d6",
              functionIn: (e) =>
                this.handleModal(e, "this is a text from callback ok !"),
            },
          ],
        });
      }
    }
  };
  /*---------------------------------ฟังชันStyle ตามค่าการค้นหา StyleSerchHigtligt--------------------------------------------*/
  StyleSerchHigtligt = (feature) => {
    let elimentserch = document.querySelector("#serchif").value;
    let elimentserchvalue = document.querySelector("#serchvalue").value;
    let elimentserchvalue2 = document.querySelector("#serchvalue2").value;
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let styleserch = new Style({
      fill: new Fill({
        color: "#FFCCCC",
      }),
      image: new Icon({
        anchor: [0.5, 0.5],
        size: [52, 52],
        offset: [52, 0],
        opacity: 0.5,
        scale: 1.0,
        src: "https://image.flaticon.com/icons/svg/202/202917.svg",
      }),
      stroke: new Stroke({
        color: "#000",
        width: 1,
      }),
      text: new Text({
        overflow: false,
        font: "18px Calibri,sans-serif",
        fill: new Fill({
          color: "#FFF",
        }),
        stroke: new Stroke({
          color: "#000",
          width: 2,
        }),
      }),
    });
    if (elimentserch === "little") {
      if (checkvalue < elimentserchvalue) {
        // styleserch.getStroke().setColor('#F51')
        styleserch.getFill().setColor("rgb(249, 148, 0,1)");
        styleserch
          .getText()
          .setText(this.numberWithCommas(checkvalue.toString()));
      }
      /*       return styleserch;
       */
    }
    if (elimentserch === "over") {
      if (checkvalue > elimentserchvalue) {
        // styleserch.getStroke().setColor('#F51')
        styleserch.getFill().setColor("rgb(249, 148, 0,1)");
        styleserch
          .getText()
          .setText(this.numberWithCommas(checkvalue.toString()));
      }
      /*       return styleserch;
       */
    }
    if (elimentserch === "as") {
      if (checkvalue == elimentserchvalue) {
        // styleserch.getStroke().setColor('#F51')
        styleserch.getFill().setColor("rgb(249, 148, 0,1)");
        styleserch
          .getText()
          .setText(this.numberWithCommas(checkvalue.toString()));
      }
      /*       return styleserch;
       */
    }
    if (elimentserch === "littlepush") {
      if (checkvalue <= elimentserchvalue) {
        // styleserch.getStroke().setColor('#F51')
        styleserch.getFill().setColor("rgb(249, 148, 0,1)");
        styleserch
          .getText()
          .setText(this.numberWithCommas(checkvalue.toString()));
      }
      /*       return styleserch;
       */
    }
    if (elimentserch === "overpush") {
      if (checkvalue >= elimentserchvalue) {
        // styleserch.getStroke().setColor('#F51')
        styleserch.getFill().setColor("rgb(249, 148, 0,1)");
        styleserch
          .getText()
          .setText(this.numberWithCommas(checkvalue.toString()));
      }
      /*       return styleserch;
       */
    }
    if (elimentserch === "notas") {
      if (checkvalue !== elimentserchvalue) {
        // styleserch.getStroke().setColor('#F51')
        styleserch.getFill().setColor("rgb(249, 148, 0,1)");
        styleserch
          .getText()
          .setText(this.numberWithCommas(checkvalue.toString()));
      }
      /*       return styleserch;
       */
    }
    if (elimentserch === "eqar") {
      //ระหว่าง
      if (checkvalue >= elimentserchvalue && checkvalue <= elimentserchvalue2) {
        styleserch.getFill().setColor("rgb(249, 148, 0,1)");
        styleserch
          .getText()
          .setText(this.numberWithCommas(checkvalue.toString()));
      }
    }
    styleserch.getText().setText(this.numberWithCommas(checkvalue.toString()));
    return styleserch;
  };
  StyleSerchFlag = (feature) => {
    let elimentserch = document.querySelector("#serchif").value;
    let elimentserchvalue = document.querySelector("#serchvalue").value;
    let elimentserchvalue2 = document.querySelector("#serchvalue2").value;

    //let Keystatelist = this.props.selectedStat.statCodeId.toUpperCase();
    let checkvalue = parseInt(feature.get("TOTAL"));
    //console.log(feature.getKeys())
    var stylepoint = new Style({
      image: new Icon({
        color: "#FFF",
        crossOrigin: "anonymous",
        imgSize: [20, 20],
        src: imgflag,
        anchor: [0.5, 0.1],
        scale: 0,
      }),
    });
    if (elimentserch === "little") {
      if (checkvalue < elimentserchvalue) {
        stylepoint.getImage().setScale(1);
        return stylepoint;
      }
      return stylepoint;
    }
    if (elimentserch === "over") {
      if (checkvalue > elimentserchvalue) {
        stylepoint.getImage().setScale(1);
        return stylepoint;
      }
      return stylepoint;
    }
    if (elimentserch === "as") {
      if (checkvalue == elimentserchvalue) {
        stylepoint.getImage().setScale(1);
        return stylepoint;
      }
      return stylepoint;
    }
    if (elimentserch === "littlepush") {
      if (checkvalue <= elimentserchvalue) {
        stylepoint.getImage().setScale(1);
        return stylepoint;
      }
      return stylepoint;
    }
    if (elimentserch === "overpush") {
      if (checkvalue >= elimentserchvalue) {
        stylepoint.getImage().setScale(1);
        return stylepoint;
      }
      return stylepoint;
    }
    if (elimentserch === "notas") {
      if (checkvalue !== elimentserchvalue) {
        stylepoint.getImage().setScale(1);
        return stylepoint;
      }
      return stylepoint;
    }
    if (elimentserch === "eqar") {
      if (checkvalue >= elimentserchvalue && checkvalue <= elimentserchvalue2) {
        stylepoint.getImage().setScale(1);
        return stylepoint;
      }
    }
    return stylepoint;
  };
  StyleSerchcolor = (feature) => {
    let elimentserch = document.querySelector("#serchif").value;
    let elimentserchvalue = document.querySelector("#serchvalue").value;
    let elimentserchvalue2 = document.querySelector("#serchvalue2").value;
    let checkvalue = parseInt(feature.get("TOTAL"));

    var stylepointcolor = new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: "#18ecf2",
        }),
        stroke: new Stroke({
          color: "#FFF",
          width: 3,
        }),
        radius: 10,
        scale: 0,
      }),
    });

    if (elimentserch === "little") {
      if (checkvalue < elimentserchvalue) {
        stylepointcolor.getImage().setScale(1);
      } else {
        stylepointcolor.getImage().setScale(0);
      }
    }

    if (elimentserch === "over") {
      if (checkvalue > elimentserchvalue) {
        stylepointcolor.getImage().setScale(1);
      } else {
        stylepointcolor.getImage().setScale(0);
      }
    }

    if (elimentserch === "as") {
      if (checkvalue == elimentserchvalue) {
        stylepointcolor.getImage().setScale(1);
      } else {
        stylepointcolor.getImage().setScale(0);
      }
    }

    if (elimentserch === "littlepush") {
      if (checkvalue <= elimentserchvalue) {
        stylepointcolor.getImage().setScale(1);
      } else {
        stylepointcolor.getImage().setScale(0);
      }
    }

    if (elimentserch === "overpush") {
      if (checkvalue >= elimentserchvalue) {
        stylepointcolor.getImage().setScale(1);
      } else {
        stylepointcolor.getImage().setScale(0);
      }
    }

    if (elimentserch === "notas") {
      if (checkvalue !== elimentserchvalue) {
        stylepointcolor.getImage().setScale(1);
      } else {
        stylepointcolor.getImage().setScale(0);
      }
    }

    if (elimentserch === "eqar") {
      if (checkvalue >= elimentserchvalue && checkvalue <= elimentserchvalue2) {
        console.log("eqar yessss");
        stylepointcolor.getImage().setScale(1);
        return stylepointcolor;
      } else {
        console.log("eqar nooooo");
        stylepointcolor.getImage().setScale(0);
      }
    }

    return stylepointcolor;
  };
  StyleSerchStar = (feature) => {
    console.log("get in StyleSerchStar >>>");
    let elimentserch = document.querySelector("#serchif").value;
    let elimentserchvalue = document.querySelector("#serchvalue").value;
    let elimentserchvalue2 = document.querySelector("#serchvalue2").value;

    let checkvalue = parseInt(feature.get("TOTAL"));
    //console.log(feature.getKeys())
    var opacity = 0;

    if (elimentserch === "little") {
      if (checkvalue < elimentserchvalue) {
        opacity = 1;
      } else {
        opacity = 0;
      }
    } else if (elimentserch === "over") {
      if (checkvalue > elimentserchvalue) {
        opacity = 1;
      } else {
        opacity = 0;
      }
    } else if (elimentserch === "as") {
      if (checkvalue == elimentserchvalue) {
        opacity = 1;
      } else {
        opacity = 0;
      }
    } else if (elimentserch === "littlepush") {
      if (checkvalue <= elimentserchvalue) {
        opacity = 1;
      } else {
        opacity = 0;
      }
    } else if (elimentserch === "overpush") {
      if (checkvalue >= elimentserchvalue) {
        opacity = 1;
      } else {
        opacity = 0;
      }
    } else if (elimentserch === "notas") {
      if (checkvalue !== elimentserchvalue) {
        opacity = 1;
      } else {
        opacity = 0;
      }
    } else if (elimentserch === "eqar") {
      if (checkvalue >= elimentserchvalue && checkvalue <= elimentserchvalue2) {
        opacity = 1;
      } else {
        opacity = 0;
      }
    }
    var stylestar = new Style({
      image: new FontSymbol({
        glyph: "fa-star",
        opacity: opacity,
        //form:'square',
        fill: new Fill({ color: "#fff" }),
        //stroke: new Stroke({ color: '#fff', width: 2 }),
        color: "#f9f787",
        radius: 10,
        anchor: [0.5, 0.1],
      }),
    });

    return stylestar;
  };

  /*---------------------------------ฟังชั่นStyleของเมนูด้านซ้าย--------------------------------------------*/
  ChangStyleColor = (namestyle) => {
    let checklayeronmap = false;
    map.getLayers().forEach((layer, element) => {
      if (
        layer.get("name") === "MAPAPI" ||
        layer.get("name") === "MAPAPISWIPE"
      ) {
        checklayeronmap = true;
      }
    });
    if (checklayeronmap) {
      switch (namestyle) {
        case "Manaul":
          let styleallcheck = document.querySelectorAll(".mapallstyle");
          styleallcheck.forEach((radio) => {
            if (radio.checked === true) {
              radio.checked = false;
            }
          });
          map.getLayers().forEach((layer, element) => {
            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDWMS ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);

              /* layer.setStyle(this.StyleStatlist) */
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
              /* layer.setStyle(this.StyleStatlist) */
            } else {
              layer.setVisible(false);
            }
          });
          break;
        case "NaturalBreaks":
          map.getLayers().forEach((layer, element) => {
            console.log("attibute>>>>>>>", layer.get("attributions")) // : "NSO@COPPYRIGHT",

            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDWMS ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);
              layer.setStyle(this.Stylenaturalbreak);
              this.props.setChangeStyle({
                style: this.state.Stylenaturalbreak,
                status: "nb",
              });
              document.getElementById("Choropleth").checked = true;
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
            } else {
              layer.setVisible(false);
            }
          });
          break;
        case "EqualInterval":
          map.getLayers().forEach((layer, element) => {
            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDWMS ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);
              layer.setStyle(this.Styleequalinterval);
              this.props.setChangeStyle({
                style: this.state.Stylenequalinterval,
                status: "eq",
              });
              document.getElementById("Choropleth").checked = true;
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
            } else {
              layer.setVisible(false);
            }
          });
          break;
        case "Quantile":
          map.getLayers().forEach((layer, element) => {
            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDWMS ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);
              layer.setStyle(this.StyleQuantile);
              this.props.setChangeStyle({
                style: this.state.Stylequartile,
                status: "qt",
              });
              document.getElementById("Choropleth").checked = true;
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
            } else {
              layer.setVisible(false);
            }
          });
          break;
        case "StandardDeviation":
          map.getLayers().forEach((layer, element) => {
            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDWMS ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);
              layer.setStyle(this.Stylestandrad);
              this.props.setChangeStyle({
                style: this.state.Stylestandrad,
                status: "sd",
              });
              document.getElementById("Choropleth").checked = true;
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
            } else {
              layer.setVisible(false);
            }
          });
          break;
        default:
          break;
      }
    } else {
      this.props.setModal({
        modalAction: "open",
        title: "คำอธิบาย?",
        text: "กรุณาเลือกรายการสถิติเพื่อแสดงผลบนแผนที่ก่อน!",
        icon: "warning",
        button: [
          {
            text: "ตกลง",
            width: "80px",
            bgcolor: "#3085d6",
            functionIn: (e) =>
              this.handleModal(e, "this is a text from callback ok !"),
          },
        ],
      });
      document.getElementById("q2").checked = true;
    }
    vector.setVisible(true);
  };
  ChangStyleColorswipe = (namestyle) => {
    let checklayeronmap = false;
    map.getLayers().forEach((layer, element) => {
      if (layer.get("name") === "MAPAPISWIPE") {
        checklayeronmap = true;
      }
    });
    if (checklayeronmap) {
      switch (namestyle) {
        case "Manaul":
          let styleallcheck = document.querySelectorAll(".mapallstyle");
          styleallcheck.forEach((radio) => {
            if (radio.checked === true) {
              radio.checked = false;
            }
          });
          map.getLayers().forEach((layer, element) => {
            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
              layer.setStyle(this.Stylemanualswipecolopleth)
              this.props.setChangeStyle({
                swipestyle: {
                  style: this.state.Stylemanualswipe.COLOR_CHOROPLETH,
                  status: "mncpswipe",
                },
              });
            } else {
              layer.setVisible(false);
            }
          });
          break;
        case "NaturalBreaks":
          map.getLayers().forEach((layer, element) => {
            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);
              document.getElementById("Choropleth").checked = true;
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
              layer.setStyle(this.Stylenaturalbreakswipe);
              this.props.setChangeStyle({
                swipestyle: {
                  style: this.state.Stylenaturalbreakswipe,
                  status: "nbswipe",
                },
              });
            } else {
              layer.setVisible(false);
            }
          });
          break;
        case "EqualInterval":
          map.getLayers().forEach((layer, element) => {
            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);
              document.getElementById("Choropleth").checked = true;
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
              layer.setStyle(this.Styleequalintervalswipe);
              this.props.setChangeStyle({
                swipestyle: {
                  style: this.state.Stylenequalintervalswipe,
                  status: "eqswipe",
                },
              });
            } else {
              layer.setVisible(false);
            }
          });
          break;
        case "Quantile":
          map.getLayers().forEach((layer, element) => {
            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);
              document.getElementById("Choropleth").checked = true;
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
              layer.setStyle(this.StyleQuantileswipe);
              this.props.setChangeStyle({
                swipestyle: {
                  style: this.state.Stylequartileswipe,
                  status: "qtswipe",
                },
              });
            } else {
              layer.setVisible(false);
            }
          });
          break;
        case "StandardDeviation":
          map.getLayers().forEach((layer, element) => {
            if (
              layer === basemap.LayerGroups ||
              layer === Changwat ||
              layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT"
            ) {
            } else if (layer.get("name") === "MAPAPI") {
              layer.setVisible(true);
              document.getElementById("Choropleth").checked = true;
            } else if (layer.get("name") === "MAPAPISWIPE") {
              layer.setVisible(true);
              layer.setStyle(this.Stylestandradswipe);
              this.props.setChangeStyle({
                swipestyle: {
                  style: this.state.Stylestandradswipe,
                  status: "sdswipe",
                },
              });
            } else {
              layer.setVisible(false);
            }
          });
          break;
        default:
          break;
      }
    } else {
      this.props.setModal({
        modalAction: "open",
        title: "คำอธิบาย?",
        text: "กรุณาเลือกรายการสถิติเพื่อแสดงผลบนแผนที่ก่อน!",
        icon: "warning",
        button: [
          {
            text: "ตกลง",
            width: "80px",
            bgcolor: "#3085d6",
            functionIn: (e) =>
              this.handleModal(e, "this is a text from callback ok !"),
          },
        ],
      });
      document.getElementById("q2").checked = true;
    }
  };
  Stylenaturalbreak = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;
    let level = this.state.Stylenaturalbreak.LEVEL.map((x) => +x);
    let color = this.state.Stylenaturalbreak.COLOR;

    let stylenaturalbreak = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)'
      }),
      text: new Text(this.textmaps(resolution)),
    });
    if (checkvalue <= level[0]) {
      stylenaturalbreak.getFill().setColor(color[0]);
    } else if (checkvalue > level[0] && checkvalue <= level[1]) {
      stylenaturalbreak.getFill().setColor(color[1]);
    } else if (checkvalue > level[1] && checkvalue <= level[2]) {
      stylenaturalbreak.getFill().setColor(color[2]);
    } else if (checkvalue > level[2] && checkvalue <= level[3]) {
      stylenaturalbreak.getFill().setColor(color[3]);
    } else if (checkvalue > level[3]) {
      stylenaturalbreak.getFill().setColor(color[4]);
    } else {
      stylenaturalbreak.getFill().setColor(color[color.length - 1]);
    }
    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      stylenaturalbreak.getFill().setColor("rgb(103 166 252 / 0%)");
      stylenaturalbreak.getText().setText(textnodata);
    } else {
      stylenaturalbreak.getText().setText(text);
    }
    return stylenaturalbreak;
  };
  Styleequalinterval = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;


    let level = this.state.Stylenequalinterval.LEVEL.map((x) => +x);
    let color = this.state.Stylenequalinterval.COLOR;

    let styleequalinterval = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)'
      }),
      text: new Text(this.textmaps(resolution)),

    });
    if (level.length === 1) {
      if (checkvalue <= level[0]) {
        styleequalinterval.getFill().setColor(color[0]);
      }
    } else {
      if (checkvalue <= level[1]) {
        styleequalinterval.getFill().setColor(color[0]);
      } else if (checkvalue > level[1] && checkvalue <= level[2]) {
        styleequalinterval.getFill().setColor(color[1]);
      } else if (checkvalue > level[2] && checkvalue <= level[3]) {
        styleequalinterval.getFill().setColor(color[2]);
      } else if (checkvalue > level[3] && checkvalue <= level[4]) {
        styleequalinterval.getFill().setColor(color[3]);
      } else if (checkvalue > level[4]) {
        styleequalinterval.getFill().setColor(color[4]);
      } else {
      }
    }

    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      styleequalinterval.getFill().setColor("rgb(103 166 252 / 0%)");
      styleequalinterval.getText().setText(textnodata);
    } else {
      styleequalinterval.getText().setText(text);
    }
    return styleequalinterval;
  };
  StyleQuantile = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;


    let level = this.state.Stylequartile.LEVEL.map((x) => +x);
    let color = this.state.Stylequartile.COLOR;
    let styleQuantile = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)'
      }),
      text: new Text(this.textmaps(resolution)),

    });
    // if (checkvalue <= level[1]) {
    //   styleQuantile.getFill().setColor(color[0]);
    // } else if (checkvalue >= level[1] && checkvalue <= level[3]) {
    //   styleQuantile.getFill().setColor(color[1]);
    // } else if (checkvalue >= level[3] && checkvalue <= level[5]) {
    //   styleQuantile.getFill().setColor(color[2]);
    // } else if (checkvalue >= level[5] && checkvalue <= level[7]) {
    //   styleQuantile.getFill().setColor(color[3]);
    // } else if (checkvalue >= level[7]) {
    //   styleQuantile.getFill().setColor(color[4]);
    // } else {
    // }
    if (checkvalue <= level[0]) {
      styleQuantile.getFill().setColor(color[0]);
    } else if (checkvalue > level[0] && checkvalue <= level[1]) {
      styleQuantile.getFill().setColor(color[1]);
    } else if (checkvalue > level[1] && checkvalue <= level[2]) {
      styleQuantile.getFill().setColor(color[2]);
    } else if (checkvalue > level[2] && checkvalue <= level[3]) {
      styleQuantile.getFill().setColor(color[3]);
    } else if (checkvalue > level[3]) {
      styleQuantile.getFill().setColor(color[4]);
    } else {
      styleQuantile.getFill().setColor(color[color.length - 1]);
    }
    // level.forEach((value, index) => {
    //   if (index === 0) {
    //     if (checkvalue <= value) {
    //       styleQuantile.getFill().setColor(color[index]);
    //     }
    //   } else if (index === level.length - 1) {
    //     if (checkvalue > value) {
    //       styleQuantile.getFill().setColor(color[index]);
    //     }
    //   } else {
    //     if (checkvalue > value && checkvalue <= level[index+1]) {
    //       styleQuantile.getFill().setColor(color[index]);
    //     }
    //   }
    // })
    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      styleQuantile.getFill().setColor("rgb(103 166 252 / 0%)");
      styleQuantile.getText().setText(textnodata);
    } else {
      styleQuantile.getText().setText(text);
    }
    return styleQuantile;
  };
  Stylestandrad = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;


    let level = this.state.Stylestandrad.LEVEL_TRUE.map((x) => +x);
    let color = this.state.Stylestandrad.COLOR;
    let stylestandrad = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)'
      }),
      text: new Text(this.textmaps(resolution)),

    });
    if (level.length === 1) {
      if (checkvalue <= level[0]) {
        stylestandrad.getFill().setColor(color[0]);
      } else {
      }
    } else {
      if (checkvalue <= level[1]) {
        stylestandrad.getFill().setColor(color[0]);
      } else if (checkvalue > level[1] && checkvalue <= level[2]) {
        stylestandrad.getFill().setColor(color[1]);
      } else if (checkvalue > level[2] && checkvalue <= level[3]) {
        stylestandrad.getFill().setColor(color[2]);
      } else if (checkvalue > level[3] && checkvalue <= level[4]) {
        stylestandrad.getFill().setColor(color[3]);
      } else if (checkvalue > level[4]) {
        stylestandrad.getFill().setColor(color[4]);
      } else {
      }
    }

    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      stylestandrad.getFill().setColor("rgb(103 166 252 / 0%)");
      stylestandrad.getText().setText(textnodata);
    } else {
      stylestandrad.getText().setText(text);
    }
    return stylestandrad;
  };
  Stylenaturalbreakswipe = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;


    let level = this.state.Stylenaturalbreakswipe.LEVEL.map((x) => +x);
    let color = this.state.Stylenaturalbreakswipe.COLOR;
    let stylenaturalbreak = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: "rgba(0, 0, 0, 0)",
      }),
      text: new Text(this.textmaps(resolution)),

    });
    if (checkvalue <= level[0]) {
      stylenaturalbreak.getFill().setColor(color[0]);
    } else if (checkvalue > level[0] && checkvalue <= level[1]) {
      stylenaturalbreak.getFill().setColor(color[1]);
    } else if (checkvalue > level[1] && checkvalue <= level[2]) {
      stylenaturalbreak.getFill().setColor(color[2]);
    } else if (checkvalue > level[2] && checkvalue <= level[3]) {
      stylenaturalbreak.getFill().setColor(color[3]);
    } else if (checkvalue > level[3]) {
      stylenaturalbreak.getFill().setColor(color[4]);
    } else {
      stylenaturalbreak.getFill().setColor(color[color.length - 1]);
    }
    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      stylenaturalbreak.getFill().setColor("rgb(103 166 252 / 0%)");
      stylenaturalbreak.getText().setText(textnodata);
    } else {
      stylenaturalbreak.getText().setText(text);
    }
    return stylenaturalbreak;
  };
  Styleequalintervalswipe = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;


    let level = this.state.Stylenequalintervalswipe.LEVEL.map((x) => +x);
    let color = this.state.Stylenequalintervalswipe.COLOR;
    console.log("swipestyle");
    let styleequalinterval = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)'
      }),
      text: new Text(this.textmaps(resolution)),

    });
    if (level.length === 1) {
      if (checkvalue <= level[0]) {
        styleequalinterval.getFill().setColor(color[0]);
      }
    } else {
      if (checkvalue <= level[1]) {
        styleequalinterval.getFill().setColor(color[0]);
      } else if (checkvalue > level[1] && checkvalue <= level[2]) {
        styleequalinterval.getFill().setColor(color[1]);
      } else if (checkvalue > level[2] && checkvalue <= level[3]) {
        styleequalinterval.getFill().setColor(color[2]);
      } else if (checkvalue > level[3] && checkvalue <= level[4]) {
        styleequalinterval.getFill().setColor(color[3]);
      } else if (checkvalue > level[4]) {
        styleequalinterval.getFill().setColor(color[4]);
      } else {
      }
    }

    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      styleequalinterval.getFill().setColor("rgb(103 166 252 / 0%)");
      styleequalinterval.getText().setText(textnodata);
    } else {
      styleequalinterval.getText().setText(text);
    }
    return styleequalinterval;
  };
  StyleQuantileswipe = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;


    let level = this.state.Stylequartileswipe.LEVEL.map((x) => +x);
    let color = this.state.Stylequartileswipe.COLOR;
    let styleQuantile = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)'
      }),
      text: new Text(this.textmaps(resolution)),

    });
    /*  if (checkvalue >= level[0] && checkvalue <= level[1]) {
       styleQuantile.getFill().setColor(color[0]);
     } else if (checkvalue >= level[2] && checkvalue <= level[3]) {
       styleQuantile.getFill().setColor(color[1]);
     } else if (checkvalue >= level[4] && checkvalue <= level[5]) {
       styleQuantile.getFill().setColor(color[2]);
     } else if (checkvalue >= level[6] && checkvalue <= level[7]) {
       styleQuantile.getFill().setColor(color[3]);
     } else if (checkvalue >= level[8] && checkvalue <= level[9]) {
       styleQuantile.getFill().setColor(color[4]);
     } else {
     } */
    if (checkvalue <= level[0]) {
      styleQuantile.getFill().setColor(color[0]);
    } else if (checkvalue >= level[0] && checkvalue <= level[1]) {
      styleQuantile.getFill().setColor(color[1]);
    } else if (checkvalue >= level[1] && checkvalue <= level[2]) {
      styleQuantile.getFill().setColor(color[2]);
    } else if (checkvalue >= level[2] && checkvalue <= level[3]) {
      styleQuantile.getFill().setColor(color[3]);
    } else if (checkvalue >= level[3]) {
      styleQuantile.getFill().setColor(color[4]);
    } else {
      styleQuantile.getFill().setColor(color[color.length - 1]);
    }

    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      styleQuantile.getFill().setColor("rgb(103 166 252 / 0%)");
      styleQuantile.getText().setText(textnodata);
    } else {
      styleQuantile.getText().setText(text);
    }
    return styleQuantile;
  };
  Stylestandradswipe = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;


    let level = this.state.Stylestandradswipe.LEVEL_TRUE.map((x) => +x);
    let color = this.state.Stylestandradswipe.COLOR;
    let stylestandrad = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)'
      }),
      text: new Text(this.textmaps(resolution)),

    });
    if (level.length === 1) {
      if (checkvalue <= level[0]) {
        stylestandrad.getFill().setColor(color[0]);
      } else {
      }
    } else {
      if (checkvalue <= level[1]) {
        stylestandrad.getFill().setColor(color[0]);
      } else if (checkvalue > level[1] && checkvalue <= level[2]) {
        stylestandrad.getFill().setColor(color[1]);
      } else if (checkvalue > level[2] && checkvalue <= level[3]) {
        stylestandrad.getFill().setColor(color[2]);
      } else if (checkvalue > level[3] && checkvalue <= level[4]) {
        stylestandrad.getFill().setColor(color[3]);
      } else if (checkvalue > level[4]) {
        stylestandrad.getFill().setColor(color[4]);
      } else {
      }
    }
    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      stylestandrad.getFill().setColor("rgb(103 166 252 / 0%)");
      stylestandrad.getText().setText(textnodata);
    } else {
      stylestandrad.getText().setText(text);
    }
    return stylestandrad;
  };
  Stylemanualcolopleth = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;


    let level = this.state.Stylemanual.COLOR_CHOROPLETH.LEVEL.map((x) => x);
    console.log("level >>>", level);
    let color = this.state.Stylemanual.COLOR_CHOROPLETH.COLOR;
    let stylestandrad = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)'
      }),
      text: new Text(this.textmaps(resolution)),

    });
    level.forEach((itemLevel, index) => {
      if (index === 0) {
        if (checkvalue <= itemLevel[1]) { stylestandrad.getFill().setColor(color[index]) }
      } else if (index === level.length - 1) {
        if (checkvalue > itemLevel[0]) { stylestandrad.getFill().setColor(color[index]) }
      } else {
        if (checkvalue > itemLevel[0] && checkvalue <= itemLevel[1]) {
          stylestandrad.getFill().setColor(color[index]);
        }
      }
    });
    /*  if (checkvalue <= level[1]) {
       stylestandrad.getFill().setColor(color[0])
     } else if (checkvalue > level[1] && checkvalue <= level[2]) {
       stylestandrad.getFill().setColor(color[1])
     } else if (checkvalue > level[2] && checkvalue <= level[3]) {
       stylestandrad.getFill().setColor(color[2])
     } else if (checkvalue > level[3] && checkvalue <= level[4]) {
       stylestandrad.getFill().setColor(color[3])
     } else if (checkvalue > level[4]) {
       stylestandrad.getFill().setColor(color[4])
     } else {

     } */

    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      stylestandrad.getFill().setColor("rgb(103 166 252 / 0%)");
      stylestandrad.getText().setText(textnodata);
    } else {
      stylestandrad.getText().setText(text);
    }
    return stylestandrad;
  };
  Stylemanualswipecolopleth = (feature, resolution) => {
    let checkvalue = parseFloat(feature.get("TOTAL"));
    let area = feature
      .getKeys()
      .filter(
        (f) =>
          f === "reg_n_t" ||
          f === "pro_n_t" ||
          f === "cwt_name_t" ||
          f === "dit_n_t" ||
          f === "tam_n_t" ||
          f === "sdt_n_t" ||
          f === "sdt_t" ||
          f === "abt_name_t" ||
          f === "mun_n_t" ||
          f === "schoolname"
      );
    let namearea = feature.get(area[0]);
    let text = `${namearea}\n${this.state.showtext ? this.numberWithCommas(checkvalue.toString()) : ' '}`;
    let level = this.state.Stylemanualswipe.COLOR_CHOROPLETH.LEVEL.map((x) => x);
    console.log("level >>>", level);
    let color = this.state.Stylemanualswipe.COLOR_CHOROPLETH.COLOR;
    let stylestandrad = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0)'
      }),
      text: new Text(this.textmaps(resolution)),

    });

    level.forEach((itemLevel, index) => {
      if (index === 0) {
        if (checkvalue <= itemLevel[1]) { stylestandrad.getFill().setColor(color[index]) }
      } else if (index === level.length - 1) {
        if (checkvalue > itemLevel[0]) { stylestandrad.getFill().setColor(color[index]) }
      } else {
        if (checkvalue > itemLevel[0] && checkvalue <= itemLevel[1]) {
          stylestandrad.getFill().setColor(color[index]);
        }
      }
    });

    /*  if (checkvalue <= level[1]) {
       stylestandrad.getFill().setColor(color[0])
     } else if (checkvalue > level[1] && checkvalue <= level[2]) {
       stylestandrad.getFill().setColor(color[1])
     } else if (checkvalue > level[2] && checkvalue <= level[3]) {
       stylestandrad.getFill().setColor(color[2])
     } else if (checkvalue > level[3] && checkvalue <= level[4]) {
       stylestandrad.getFill().setColor(color[3])
     } else if (checkvalue > level[4]) {
       stylestandrad.getFill().setColor(color[4])
     } else {

     } */

    if (Number.isNaN(checkvalue)) {
      let textnodata = namearea + "\n" + "ไม่มีข้อมูล";
      stylestandrad.getFill().setColor("rgb(103 166 252 / 0%)");
      stylestandrad.getText().setText(textnodata);
    } else {
      stylestandrad.getText().setText(text);
    }
    return stylestandrad;
  };
  Stylemanualbar = (feature, select) => {
    let data = [];
    let color = [];
    this.state.Stylemanual.COLOR_BAR.COLOR_PIE_BAR.CL_SON[0].map(
      (fil, index) => {
        if (index === 0) {
        } else {
          if (feature.get(fil) === "-") {
            data.push(0);
            color.push(
              this.state.Stylemanual.COLOR_BAR.COLOR_PIE_BAR.COLOR[0][index]
            );
          } else {
            data.push(parseInt(feature.get(fil)));
            color.push(
              this.state.Stylemanual.COLOR_BAR.COLOR_PIE_BAR.COLOR[0][index]
            );
          }
        }
      }
    );
    var sumdata = 0;
    for (var i = 0; i < data.length; i++) {
      sumdata += data[i];
    }
    var radius = 30;
    radius = 8 * Math.sqrt(100 / Math.PI);
    radius *= select ? 1.2 : 1;
    // Create chart style
    style = [
      new Style({
        image: new Chartpine({
          type: "bar",
          radius: radius,
          colors: color,
          data: data,
          animation: 1,

          rotateWithView: true,

          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }),
      }),
    ];
    // Show values on selectect
    if (select) {
      /*
        var sum = 0;
        for (var i=0; i<data.length; i++)
        {	sum += data[i];
        }
        */
      var sum = sumdata;

      var s = 0;
      for (var ii = 0; ii < data.length; ii++) {
        var d = data[ii];
        var a = ((2 * s + d) / sum) * Math.PI - Math.PI / 2;
        var v = Math.round((d / sum) * 1000);
        if (v > 0) {
          style.push(
            new Style({
              text: new Text({
                font: "18px Calibri,sans-serif",
                text: d + "" /* d.toString() */,
                offsetX: Math.cos(a) * (radius - 100),
                offsetY: Math.sin(a) * (radius - 100),
                textAlign: a < Math.PI / 2 ? "left" : "right",
                textBaseline: "middle",
                stroke: new Stroke({ color: "#fff", width: 2.5 }),
                fill: new Fill({ color: "#333" }),
              }),
            })
          );
        }
        s += d;
      }
    }
    //style[1].getText().setText(feature.get('pro_n_t'));
    return style;
  };
  Stylemanualpie = (feature, select) => {
    let data = [];
    let color = [];

    this.state.Stylemanual.COLOR_PIE.CL_SON[0].forEach((fil, index) => {
      if (index === 0) {
      } else {
        //console.log(feature.get(fil))
        if (feature.get(fil) === "-") {
          data.push(0);
          color.push(this.state.Stylemanual.COLOR_PIE.COLOR[0][index]);
        } else {
          data.push(parseInt(feature.get(fil)));
          color.push(this.state.Stylemanual.COLOR_PIE.COLOR[0][index]);
          console.log(feature.get(fil));
          console.log(this.state.Stylemanual.COLOR_PIE.COLOR[0][index]);
        }
      }
    });
    //console.log(feature.getKeys())
    var sumdata = 0;
    for (var i = 0; i < data.length; i++) {
      sumdata += data[i];
    }
    var k =
      $("#graph").val() +
      "-" +
      $("#color").val() +
      "-" +
      (select ? "1-" : "") +
      data;
    let style = styleCache[k];
    if (!style) {
      var radius = 15;
      radius = 8 * Math.sqrt(50 / Math.PI);
      radius *= select ? 1.2 : 1;
      // Create chart style
      //console.log("data>>>>", data)
      style = [
        new Style({
          image: new Chartpine({
            type: "pie",
            radius: radius,
            data: data,
            colors: color,
            rotateWithView: true,
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
          }),
        }),
      ];

      // Show values on selectect
      if (select) {
        /*
        var sum = 0;
        for (var i=0; i<data.length; i++)
        {	sum += data[i];
        }
        */
        var sum = sumdata;

        var s = 0;
        for (var ii = 0; ii < data.length; ii++) {
          var d = data[ii];
          var a = ((2 * s + d) / sum) * Math.PI - Math.PI / 2;
          var v = Math.round((d / sum) * 1000);
          if (v > 0) {
            style.push(
              new Style({
                text: new Text({
                  font: "18px Calibri,sans-serif",
                  text: v / 10 + "%" /* d.toString() */,
                  offsetX: Math.cos(a) * (radius + 3),
                  offsetY: Math.sin(a) * (radius + 3),
                  textAlign: a < Math.PI / 2 ? "left" : "right",
                  textBaseline: "middle",
                  stroke: new Stroke({ color: "#fff", width: 2.5 }),
                  fill: new Fill({ color: "#333" }),
                }),
              })
            );
          }
          s += d;
        }
      }
    }
    // styleCache[k] = style;
    return style;
  };
  Stylemanualpop = (feature, resolution) => {
    let checkvalue = parseInt(feature.get("TOTAL"));
    let radius = 1 * Math.sqrt(checkvalue / Math.PI / 2);
    let level = this.state.Stylenaturalbreak.LEVEL.map((x) => +x);
    let test = 100 * Math.sqrt(checkvalue / Math.max(...level));
    // console.log(Math.max(...level))
    var population = new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: this.state.Stylemanual.COLOR_PROPOR,
        }),
        radius: test,
        scale: 1,
        stroke: new Stroke({
          color: "#FFF",
          width: 1.5,
        }),
      }),
      text: new Text({
        overflow: false,
        font: "16px Calibri,sans-serif",
        fill: new Fill({
          color: "#FFF",
        }),
        stroke: new Stroke({
          color: "#000",
          width: 2,
        }),
      }),
    });
    population.getText().setText(this.numberWithCommas(checkvalue.toString()));
    return population;
  };
  StyleStatlist = (feature, resolution) => {
    let checkpropotie = feature.getKeys();
    //console.log(checkpropotie)
    var checkvalueclsex = parseFloat(feature.get("POP_5"));
    console.log(checkvalueclsex);
    var checkvalue = parseInt(feature.get("TOTAL"));
    //console.log(checkvalue)
    /*--------------------------------------------------------------*/
    let stylestatlist = new Style({
      stroke: new Stroke({
        color: "#FFF",
        width: 0,
        lineCap: "round",
      }),
      fill: new Fill({
        color: "#FFF",
      }),
      text: new Text({
        overflow: false,
        font: "18px Calibri,sans-serif",
        fill: new Fill({
          color: "#FFF",
        }),
        stroke: new Stroke({
          color: "#000",
          width: 2,
        }),
      }),
    });

    if (checkvalue <= 1000) {
      stylestatlist.getFill().setColor("rgb(35, 239, 229,0.5)");
    } else if (checkvalue <= 2000) {
      stylestatlist.getFill().setColor("rgb(35, 219, 207,0.5)");
    } else if (checkvalue <= 4000) {
      stylestatlist.getFill().setColor("rgb(35, 179, 166,0.5)");
    } else if (checkvalue <= 5000) {
      stylestatlist.getFill().setColor("rgb(35, 137, 132,0.5)");
    } else {
      stylestatlist.getFill().setColor("rgb(35, 84, 88,1)");
    }
    if (Number.isNaN(checkvalue)) {
      stylestatlist.getText().setText("ไม่มีข้อมูล");
      stylestatlist.getFill().setColor("rgb(35, 137, 132,0)");
    } else {
      stylestatlist
        .getText()
        .setText(this.numberWithCommas(checkvalue.toString()));
    }
    return stylestatlist;
  };
  GetStylelayermapAll = async () => {
    try {
      let data = new FormData();
      data.append(
        "agency_id",
        this.props.selectedStat.agency.allRawData.REF_ID
      );
      data.append("AREA", this.props.selectedStat.area.code);
      if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
        data.append(
          "YEAR",
          this.props.selectedStat.time.year.id +
          "_Q" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else if (this.props.selectedStat.time.periodType.label === "รายเดือน") {
        data.append(
          "YEAR",
          this.props.selectedStat.time.year.id +
          "_M" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else {
        data.append("YEAR", this.props.selectedStat.time.year.id);
      }
      data.append("LEVEL", this.props.selectedStat.area.label == "ภาค" ? "4" : "5");
      data.append("POP", this.props.selectedStat.statCodeId);
      data.append("cat_sl_id", this.props.selectedStat.statId);
      if (this.props.selectedStat.statType.CLASSIFY !== 0) {
        if (this.props.selectedStat.classify.status) {
          this.props.selectedStat.classify.classifyData.map(
            (itemClassify, index) => {
              if (itemClassify.check !== false) {
                if (itemClassify.codelistChild.id != "-") {
                  data.append(`FIELD_CL[${index}]`, itemClassify.codelist.id);
                  data.append(
                    `CODE_SON[${index}]`,
                    itemClassify.codelistChild.id
                  );
                }
              }
            }
          );
        }
      }
      let x1 = await axios({
        method: "post",
        url: config.API_URL + "/api/geo/getbreaks",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          console.log(">>>>>>>1");

          await this.setState({
            Stylenaturalbreak: res.data,
          });
        })
        .catch((eror) => {
          this.setState({
            Stylenaturalbreak: { LEVEL: [], COLOR: [] },
          });
        });
      let x2 = await axios({
        method: "post",
        url: config.API_URL + "/api/geo/equalinterval",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          console.log(">>>>>>>2");

          await this.setState({
            Stylenequalinterval: res.data,
          });
        })
        .catch((eror) => {
          this.setState({
            Stylenequalinterval: { LEVEL: [], COLOR: [] },
          });
        });
      let x3 = await axios({
        method: "post",
        url: config.API_URL + "/api/geo/quartile",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          console.log(">>>>>>>3");

          await this.setState({
            Stylequartile: res.data,
          });
        })
        .catch((eror) => {
          this.setState({
            Stylequartile: { LEVEL: [], COLOR: [] },
          });
        });
      let x4 = await axios({
        method: "post",
        url: config.API_URL + "/api/geo/standarddeviation",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          console.log(">>>>>>>4");

          await this.setState({
            Stylestandrad: res.data,
          });
        })
        .catch((eror) => {
          this.setState({
            Stylestandrad: { LEVEL: [], LEVEL_TRUE: [], COLOR: [] },
          });
        });
      let manual = await axios({
        method: "post",

        url: config.API_URL + "/api/geo/manaul",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          console.log(">>>>>>>manual");
          await this.setState({
            Stylemanual: res.data,
          });
        })
        .catch((eror) => {
          this.setState({
            Stylemanual: "",
          });
        });
    } catch (error) {
      console.error("errorstyle>>>>>", error);
    }
  };
  GetStylelayermapswipeAll = async () => {
    try {
      let data = new FormData();
      data.append(
        "agency_id",
        this.props.swipeMap.swipeMapStat.agency.allRawData.REF_ID
      );
      data.append("AREA", this.props.swipeMap.swipeMapStat.area.code);
      if (
        this.props.swipeMap.swipeMapStat.time.periodType.label === "รายไตรมาส"
      ) {
        data.append(
          "YEAR",
          this.props.swipeMap.swipeMapStat.time.year.id +
          "_Q" +
          this.props.swipeMap.swipeMapStat.time.periodSelect.id
        );
      } else if (
        this.props.swipeMap.swipeMapStat.time.periodType.label === "รายเดือน"
      ) {
        data.append(
          "YEAR",
          this.props.swipeMap.swipeMapStat.time.year.id +
          "_M" +
          this.props.swipeMap.swipeMapStat.time.periodSelect.id
        );
      } else {
        data.append("YEAR", this.props.swipeMap.swipeMapStat.time.year.id);
      }
      data.append("LEVEL", "5");
      data.append("POP", this.props.swipeMap.swipeMapStat.statCodeId);
      data.append("cat_sl_id", this.props.swipeMap.swipeMapStat.statId);
      if (this.props.swipeMap.swipeMapStat.statType.CLASSIFY !== 0) {
        if (this.props.swipeMap.swipeMapStat.classify.status) {
          this.props.swipeMap.swipeMapStat.classify.classifyData.map(
            (itemClassify, index) => {
              if (itemClassify.check !== false) {
                if (itemClassify.codelistChild.id != "-") {
                  data.append(`FIELD_CL[${index}]`, itemClassify.codelist.id);
                  data.append(
                    `CODE_SON[${index}]`,
                    itemClassify.codelistChild.id
                  );
                }
              }
            }
          );
        }
      }

      let x1 = await axios({
        method: "post",
        url: config.API_URL + "/api/geo/getbreaks",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then(async (res) => {
        console.log(">>>>>>>1");

        await this.setState({
          Stylenaturalbreakswipe: res.data,
        });
      });
      let x2 = await axios({
        method: "post",
        url: config.API_URL + "/api/geo/equalinterval",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then(async (res) => {
        console.log(">>>>>>>2");

        await this.setState({
          Stylenequalintervalswipe: res.data,
        });
      });
      let x3 = await axios({
        method: "post",
        url: config.API_URL + "/api/geo/quartile",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then(async (res) => {
        console.log(">>>>>>>3");

        await this.setState({
          Stylequartileswipe: res.data,
        });
      });
      let x4 = await axios({
        method: "post",
        url: config.API_URL + "/api/geo/standarddeviation",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then(async (res) => {
        console.log(">>>>>>>4");

        await this.setState({
          Stylestandradswipe: res.data,
        });
      });
      let manualswipe = await axios({
        method: "post",

        url: config.API_URL + "/api/geo/manaul",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          console.log(">>>>>>>manualswipe");
          await this.setState({
            Stylemanualswipe: res.data,
          });
        })
        .catch((eror) => {
          this.setState({
            Stylemanualswipe: "",
          });
        });
    } catch (error) {
      console.error("errorstyle>>>>>", error);
    }
  };
  GetTable = async () => {
    try {
      let datatable = new FormData();
      datatable.append(
        "agency_id",
        this.props.selectedStat.agency.allRawData.REF_ID
      );
      datatable.append("nametable", this.props.selectedStat.statNameTable);
      datatable.append("type_map", await this.checktypemap());
      if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
        datatable.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_Q" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else if (this.props.selectedStat.time.periodType.label === "รายเดือน") {
        datatable.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_M" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else {
        datatable.append("year", this.props.selectedStat.time.year.id);
      }
      datatable.append("area", this.props.selectedStat.area.code);
      datatable.append(
        "statlist_code_id",
        this.props.selectedStat.statCodeId.toUpperCase()
      );
      if (
        this.props.provinceMode.provinceModeOpen === false &&
        this.props.provinceMode.NAME_SCHEMA_MODE === "NSO_STAT_GIS"
      ) {
        let checkmenu = PNSO.find(
          (item) => item.id === this.props.selectedCategory.mainCategory.id
        );
        if (checkmenu === undefined) {
          datatable.append("ssr", this.props.nameparam.changwat.toUpperCase()); //เพิ่มมาใหม่
        } else {
        }
      }
      if (this.props.selectedStat.classify.status) {
        let newmaintrue = [];
        let maintrue = this.props.selectedStat.classify.classifyData.find(
          (itemClassify) => {
            return itemClassify.isMain === true;
          }
        );
        if (maintrue) {
          newmaintrue.push(maintrue);
          this.props.selectedStat.classify.classifyData.forEach(
            (itemClassify, index) => {
              if (itemClassify.codelist.id !== maintrue.codelist.id) {
                newmaintrue.push(itemClassify);
              }
            }
          );
          newmaintrue.forEach((itemClassify, index) => {
            if (itemClassify.codelist.id === maintrue.codelist.id) {
              datatable.append(`codelist_[${index}]`, itemClassify.codelist.id);
              itemClassify.codelist.child.forEach((son, indexChild) => {
                datatable.append(
                  `codelist_son[${index}][${indexChild}]`,
                  son.id
                );
              });
            } else {
              /*  datatable.append(`codelist_[${index}]`, itemClassify.codelist.id);
               datatable.append(`codelist_son[${index}]`, itemClassify.codelistChild.id); */
              if (itemClassify.check !== false) {
                if (itemClassify.codelistChild.id != "-") {
                  datatable.append(
                    `codelist_[${index}]`,
                    itemClassify.codelist.id
                  );
                  datatable.append(
                    `codelist_son[${index}]`,
                    itemClassify.codelistChild.id
                  );
                }
              }
            }
          });
        } else {
          this.props.selectedStat.classify.classifyData.forEach(
            (itemClassify, index) => {
              if (index === 0) {
                datatable.append(
                  `codelist_[${index}]`,
                  itemClassify.codelist.id
                );
                itemClassify.codelist.child.forEach((son, indexChild) => {
                  datatable.append(
                    `codelist_son[${index}][${indexChild}]`,
                    son.id
                  );
                });
              } else {
                if (itemClassify.check !== false) {
                  if (itemClassify.codelistChild.id != "-") {
                    datatable.append(
                      `codelist_[${index}]`,
                      itemClassify.codelist.id
                    );
                    datatable.append(
                      `codelist_son[${index}]`,
                      itemClassify.codelistChild.id
                    );
                  }
                }

              }
            }
          );
        }
      }
      axios({
        method: "post",
        url: config.API_URL + "/api/gis/gisdisplaytableController",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: datatable,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          this.setState({
            datatable: res.data,
          });
        })
        .catch((error) => {
          console.log("error Show map >>>", error);
          this.setState({
            datatable: [
              [["", "", "", ""]],
              [{ title: "" }, { title: "" }, { title: "" }, { title: "" }],
            ],
          });
        });
    } catch (error) {
      console.error(error);
      this.handleModal();
    }
  };
  GetapiChart = async () => {
    let datachart = new FormData();
    datachart.append(
      "agency_id",
      this.props.selectedStat.agency.allRawData.REF_ID
    );
    datachart.append("nametable", this.props.selectedStat.statNameTable);
    datachart.append("type_map", await this.checktypemap());
    if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
      datachart.append(
        "year",
        this.props.selectedStat.time.year.id +
        "_Q" +
        this.props.selectedStat.time.periodSelect.id
      );
    } else if (this.props.selectedStat.time.periodType.label === "รายเดือน") {
      datachart.append(
        "year",
        this.props.selectedStat.time.year.id +
        "_M" +
        this.props.selectedStat.time.periodSelect.id
      );
    } else {
      datachart.append("year", this.props.selectedStat.time.year.id);
    }
    datachart.append("area", this.props.selectedStat.area.code);
    datachart.append(
      "statlist_code_id",
      this.props.selectedStat.statCodeId.toUpperCase()
    );
    datachart.append("per_data", 10);
    datachart.append("page", this.props.selectedStat.pagechart.page);
    if (
      this.props.provinceMode.provinceModeOpen === false &&
      this.props.provinceMode.NAME_SCHEMA_MODE === "NSO_STAT_GIS"
    ) {
      let checkmenu = PNSO.find(
        (item) => item.id === this.props.selectedCategory.mainCategory.id
      );
      if (checkmenu === undefined) {
        datachart.append("ssr", this.props.nameparam.changwat.toUpperCase()); //เพิ่มมาใหม่
      } else {
      }
    }
    if (this.props.selectedStat.statType.CLASSIFY !== 0) {
      if (this.props.selectedStat.classify.status) {
        this.props.selectedStat.classify.classifyData.forEach(
          (itemClassify, index) => {
            if (itemClassify.check !== false) {
              if (itemClassify.codelistChild.id != "-") {
                datachart.append(`codelist_[${index}]`, itemClassify.codelist.id);
                datachart.append(
                  `codelist_son[${index}]`,
                  itemClassify.codelistChild.id
                );
              }
            }
          }
        );
      }
    }
    //--------------------------------------------------------------API CHART BAR AND LINE
    axios({
      method: "post",
      url: config.API_URL + "/api/gis/gisdisplaygrapbarController",
      headers: {
        NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
      },
      data: datachart,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((res) => {
      this.setState({
        Datachart: res.data,
      });
    });
    //-------------------------------------------------------------API TREEMAP
    axios({
      method: "post",
      url: config.API_URL + "/api/gis/gisdisplaygraptreemapController",
      headers: {
        NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
      },
      data: datachart,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((res) => {
      this.setState({
        DatachartTreemap: res.data,
      });
    });
  };
  Getapidashboard = () => {
    try {
      let datasent = new FormData();
      if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
        datasent.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_Q" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else if (this.props.selectedStat.time.periodType.label === "รายเดือน") {
        datasent.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_M" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else {
        datasent.append("year", this.props.selectedStat.time.year.id);
      }
      datasent.append("area", this.props.selectedStat.area.code);
      datasent.append("cat_sl_id", this.props.selectedStat.statId);
      datasent.append(
        "statlist_code_id",
        this.props.selectedStat.statCodeId.toUpperCase()
      );
      axios({
        method: "post",
        url: config.API_URL + "/api/gis/dashboard",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: datasent,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          if (res.data.message === "no data") {
            this.setState({
              dashboard: [],
            });
          } else {
            this.setState({
              dashboard: res.data,
            });
          }
        })
        .catch((error) => {
          console.error("error >>>", error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  /*----------------------------------------------------ฟังชั่นapi SHOWMAP-----------------------------------------------------*/
  Checkresentapimap = async () => {
    console.log("<<< Checkresentapimap >>>");
    console.log("กรณีแมพไม่แสดง");
    await this.setState({
      successCreateMap: true,
    });
    let newcodlistadd = [];
    let newClassifyData = [];
    let newClassifyFilter = [];
    let newChildItem = {
      id: "-",
      label: "ไม่มีการกำหนดค่า",
      allRawData: {
        CODE: "-",
        CODE_NAME: "ไม่มีการกำหนดค่า",
        DESCRIPTION: null,
        POSITION: 0,
      },
    };
    await this.props.selectedStat.classify.classifyData.forEach((item, index) => {
      var newItem = item;
      // เพิ่มลูก - เข้าไปถ้ายังไม่มี
      if (!newItem.codelist.child.find((item) => item.id === "-")) {
        newItem.codelist.child.push(newChildItem);
      }
      newClassifyData.push(newItem);
      newClassifyFilter.push(newItem.codelist);
    });
    // เชคเพื่อเปลี่ยน classify ให้เหลือตัวเดียวที่ไม่เลือก -
    console.log("<<< check for what - start >>>");
    if (newClassifyData.find((item) => item.isMain)) {
      console.log("<<< มี isMain === true");
      // มีตัวที่เป็น main
      await newClassifyData.forEach((item, index) => {
        if (!item.isMain) {
          console.log("<<< isMain === false >>> add -");
          newClassifyData[index].codelistChild = newChildItem;
        } else {
          console.log("<<< isMain === true >>> check child");
          if (item.codelistChild.id === "-") {
            console.log("<<< isMain === true >>> child = - >>> = [0]");
            // ถ้าตัวที่เป็น main เป็น -
            newClassifyData[index].codelistChild = item.codelist.child[0];
            newClassifyData[index].isMain = true;
          } else {
            console.log("<<< isMain === true >>> child != - >>> do nothing");
          }
        }
      });
    } else {
      console.log("<<< ไม่มี isMain === true");
      // ไม่มีตัวที่เป็น main
      if (
        newClassifyData.find(
          (item) =>
            item.codelistChild.id !== "t" && item.codelistChild.id !== "-"
        )
      ) {
        console.log("<<< มี ตัวที่ไม่ใช่ t และ -");
        // มีบางตัวที่ไม่ใช่ทั้ง t และ -
        let setMain = false;
        await newClassifyData.forEach((item, index) => {
          if (
            setMain === false &&
            item.codelistChild.id !== "t" &&
            item.codelistChild.id !== "-"
          ) {
            setMain = true;
            console.log("<<< ตัวนี้ที่ไม่ใช่ t และ - (ตัวแรก)");
            newClassifyData[index].isMain = true;
          } else {
            newClassifyData[index].codelistChild = newChildItem;
            console.log("<<< ตัวนี้เป็น t และ -");
          }
        });
      } else {
        console.log("<<< ทุกตัวที่เป็น t และ -");
        if (newClassifyData.find((item) => item.codelistChild.id === "t")) {
          console.log("<<< มีบางตัวเป็น t");
          // มีบางตัวเป็น t
          let setMain2 = false;
          await newClassifyData.forEach((item, index) => {
            if (setMain2 === false && item.codelistChild.id === "t") {
              setMain2 = true;
              console.log("<<< ตัวนี้เป็น t (ตัวแรก)");
              newClassifyData[index].isMain = true;
            } else {
              newClassifyData[index].codelistChild = newChildItem;
              console.log("<<< ตัวนี้ไม่เป็น t ");
            }
          });
        } else {
          console.log("<<< ทุกตัวเป็น -");
          // ทุกตัวเป็น -
          await newClassifyData.forEach((item, index) => {
            if (index === 0) {
              console.log("<<< ตัวนี้เป็น - (ตัวแรก)");
              newClassifyData[index].codelistChild =
                newClassifyData[index].codelist.child[0];
              newClassifyData[index].isMain = true;
            } else {
              console.log("<<< ตัวนี้ไม่เป็น - ");
            }
          });
        }
      }
    }

    console.log("newClassifyData >>>", newClassifyData);
    console.log("newClassifyFilter >>>", newClassifyFilter);
    console.log("<<< check for what - end >>>");
    // set ลง stat ที่เลือก
    this.props.setSelectStatData({
      classify: { statusUseClassify: true, classifyData: newClassifyData },
    });
    // set ลง stat filter
    this.props.setSelectFilterStatData({
      updateFilter: "code_lists",
      dataArrey: newClassifyFilter,
    });
    try {
      this.props.setModal({
        modalAction: "open",
        text: "กำลังสร้างแผนที่กรุณารอสักครู่...",
        icon: "loading",
      });
      this.props.setLayersuccess({
        status: (this.props.layersuccess.status = false),
        statusCrassify: "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง",
      });

      var dataToCreateMap = new FormData();
      dataToCreateMap.append(
        "nametable",
        this.props.selectedStat.statNameTable
      );
      dataToCreateMap.append(
        "agency_id",
        this.props.selectedStat.agency.allRawData.REF_ID
      );
      dataToCreateMap.append("type_map", await this.checktypemap());
      if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
        dataToCreateMap.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_Q" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else if (this.props.selectedStat.time.periodType.label === "รายเดือน") {
        dataToCreateMap.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_M" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else {
        dataToCreateMap.append("year", this.props.selectedStat.time.year.id);
      }
      dataToCreateMap.append("area", this.props.selectedStat.area.code);
      dataToCreateMap.append(
        "statlist_code_id",
        this.props.selectedStat.statCodeId
      );
      if (
        this.props.provinceMode.provinceModeOpen === false &&
        this.props.provinceMode.NAME_SCHEMA_MODE === "NSO_STAT_GIS"
      ) {
        let checkmenu = PNSO.find(
          (item) => item.id === this.props.selectedCategory.mainCategory.id
        );
        if (checkmenu === undefined) {
          dataToCreateMap.append(
            "ssr",
            this.props.nameparam.changwat.toUpperCase()
          ); //เพิ่มมาใหม่
        } else {
        }
      }
      if (this.props.selectedStat.statType.CLASSIFY !== 0) {
        if (this.props.selectedStat.classify.status) {
          // this.props.selectedStat.classify.classifyData.forEach(
          newClassifyData.forEach(
            (itemClassify, index) => {
              if (this.state.successCreateMap) {
                if (itemClassify.codelistChild.id !== "-") {
                  dataToCreateMap.append(
                    `codelist_[${index}]`,
                    itemClassify.codelist.id
                  );
                  dataToCreateMap.append(
                    `codelist_son[${index}]`,
                    itemClassify.codelistChild.id
                  );
                }
              }
            }
          );
        }
      }
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/exportgojson",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: dataToCreateMap,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          await this.GetStylelayermapAll();
          this.GetapiChart();
          this.GetTable();
          this.Getapidashboard();
          this.props.setChangeStyle({
            style: this.state.Stylenaturalbreak,
            status: "nb",
          });
          const showmap = new VectorSource({
            serverType: "geoserver",
            crossOrigin: "anonymous",
            format: new GeoJSON(),
            url: res.data,
          });
          MAPAPI.setSource(showmap);

          if (this.state.Stylemanual !== "" && this.state.Stylemanual.COLOR_CHOROPLETH !== undefined) { /*---------เช็คค่าว่ามีแมนนวลไหมให้ใช้แมนนวลก่อน-------------*/
            await MAPAPI.setStyle(this.Stylemanualcolopleth);
            this.props.setChangeStyle({
              style: this.state.Stylemanual.COLOR_CHOROPLETH,
              status: "mncp",
            });
            document.getElementById("q1").checked = true;

          } else {
            await MAPAPI.setStyle(this.Stylenaturalbreak);
            this.props.setChangeStyle({
              style: this.state.Stylenaturalbreak,
              status: "nb",
            });
            document.getElementById("q2").checked = true;

          }
          await map.getLayers().forEach((layer) => {
            if (layer === basemap.LayerGroups) {
            } else if (layer === MAPAPI) {
              map.removeLayer(layer);
              map.removeLayer(MAPAPIPINEBAR);
            } else {
              //this.state.map.removeLayer(layer)
              //layer.setVisible(false);
              //map.removeLayer(layer);
            }
          });
          await this.state.map.addLayer(MAPAPI);
          MAPAPI.setVisible(true);
          //เซ็ตradiobox ให้เป็นเริ่มต้น
          checkstylemanual = false;
          showmap.once("change", (e) => {
            this.handleModal();
            console.log("success");
          });
          MAPAPI.once("change", (e) => {
            // เช็คการเปลี่ยนแปลงของตัวแปรที่ทำงานอยู่
            this.Getwmsbackend();
            this.props.setLayersuccess({
              status: true,
              statusCrassify: `${this.props.selectedStat.classify.status
                ? this.props.selectedStat.classify.classifyData.map(
                  (item, index) => {
                    if (item.codelistChild.label !== "ไม่มีการกำหนดค่า") {
                      return ((" / " + item.codelist.label + ":" + item.codelistChild.label).replace(/,/g, ""))
                    }
                  })
                : ""
                } / ปี ${this.props.selectedStat.time.year.label
                  ? this.props.selectedStat.time.year.label
                  : ""
                } ${this.props.selectedStat.time.periodType.label == "รายไตรมาส"
                  ? "ไตรมาส" + this.props.selectedStat.time.periodSelect.label
                  : ""
                } / ระดับพื้นที่ ${this.props.selectedStat.area.label
                  ? this.props.selectedStat.area.label
                  : ""
                } `,
              classify: this.props.selectedStat.classify,
            });
          });
        })
        .catch((error) => {
          // this.Checkresentapimap();
          console.log("error Show map >>>", error);
          // this.props.setModal({
          //   modalAction: "open",
          //   title: "เกิดข้อผิดพลาด!",
          //   text: "ไม่สามารถทำการสร้างแผนที่ได้ กรุณาแจ้งปัญหาระบบ...!",
          //   icon: "fail",
          //   button: [
          //     {
          //       text: "ตกลง",
          //       width: "100px",
          //       bgcolor: "#d62e45",
          //       functionIn: (e) =>
          //         this.handleModal(e, "this is a text from callback cancle !"),
          //     },
          //   ],
          // });
          map.getLayers().forEach((layer) => {
            if (layer === basemap.LayerGroups) {
            } else if (layer === MAPAPI) {
              map.removeLayer(layer);
              map.removeLayer(MAPAPIPINEBAR);
            } else if (layer === MAPAPIPOINT) {
              map.removeLayer(layer);
            }
          });

          this.props.setLayersuccess({
            status: (this.props.layersuccess.status = false),
            statusCrassify: "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง",
          });
        });
      /*-------------------------------------------POINTMAP---------------------------------*/
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/midpointmap",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: dataToCreateMap,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (point) => {
          const showmappoint = new VectorSource({
            serverType: "geoserver",
            crossOrigin: "anonymous",
            format: new GeoJSON(),
            url: point.data,
          });
          MAPAPIPOINT.setSource(showmappoint);
          /*         MAPAPIPOINT.setStyle(this.StyleProportion)
           */ await this.state.map.getLayers().forEach(async (layer) => {
            if (layer === basemap.LayerGroups) {
            } else if (layer === MAPAPIPOINT) {
              map.removeLayer(MAPAPIPOINT);
            } else {
              //layer.setVisible(false);
            }
          });
          await this.state.map.addLayer(MAPAPIPOINT);
          MAPAPIPOINT.setVisible(false);
          MAPAPIPOINT.once("change", (e) => {
            // เช็คการเปลี่ยนแปลงของตัวแปรที่ทำงานอยู่
            console.log("POINT");
            this.props.setLayersuccess({
              status: true,
              statusCrassify: `${this.props.selectedStat.classify.status
                ? this.props.selectedStat.classify.classifyData.map(
                  (item, index) => {
                    if (item.codelistChild.label !== "ไม่มีการกำหนดค่า") {
                      return ((" / " + item.codelist.label + ":" + item.codelistChild.label).replace(/,/g, ""))
                    }
                  })
                : ""
                } / ปี ${this.props.selectedStat.time.year.label
                  ? this.props.selectedStat.time.year.label
                  : ""
                } ${this.props.selectedStat.time.periodType.label == "รายไตรมาส"
                  ? "ไตรมาส" + this.props.selectedStat.time.periodSelect.label
                  : ""
                } / ระดับพื้นที่ ${this.props.selectedStat.area.label
                  ? this.props.selectedStat.area.label
                  : ""
                } `,
              classify: this.props.selectedStat.classify,
            });

            this.handleModal();
          });
        })
        .catch((error) => {
          console.log("error Show map >>>", error);
          this.props.setLayersuccess({
            status: (this.props.layersuccess.status = false),
            statusCrassify: "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง",
          });

          this.props.setModal({
            modalAction: "open",
            title: "เกิดข้อผิดพลาด!",
            text: "ไม่สามารถทำการสร้างแผนที่ได้ กรุณาแจ้งปัญหาระบบ...!",
            icon: "fail",
            button: [
              {
                text: "ตกลง",
                width: "100px",
                bgcolor: "#d62e45",
                functionIn: (e) =>
                  this.handleModal(e, "this is a text from callback cancle !"),
              },
            ],
          });
          /*--------------------------เช็คเปลียนระดับพื้นที่------------------------*/
          let searchArea = this.props.selectedStatFilter.areas.filter((item) => {
            if ([item].some(item => this.state.loopArea.includes(item))) {
            } else {
              return item;
            }
          });
          console.log("เช็คเปลียนระดับพื้นที่>>>>", searchArea);

          if (typeof searchArea !== 'undefined' && searchArea.length > 0) {
            this.props.setSelectStatData({
              area: searchArea[0],
            });
            this.Showapimap();
          } else {
            this.setState({ loopArea: [] })
          }

          /*----------------------------------------------------------------------*/

        });
    } catch (error) {
      console.error(error);
      this.handleModal();
      this.props.setLayersuccess({
        status: false,
        statusCrassify: "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง",
      });

    }
    if (map.getLoading()) {
      this.handleModal();
    }

    $("#Choropleth").click();
    this.handdleClickTools();

    await this.setState({
      successCreateMap: false,
    });
  };
  /*---------------------------------------------------------สร้างแมพตัวแรก----------------------------------------------*/
  Showapimap = async (recreatemap = true) => {
    this.setState({ loopArea: [...this.state.loopArea, this.props.selectedStat.area] })

    try {
      this.props.setModal({
        modalAction: "open",
        text: "กำลังสร้างแผนที่กรุณารอสักครู่...",
        icon: "loading",
      });

      this.props.setLayersuccess({
        status: (this.props.layersuccess.status = false),
        statusCrassify: "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง",
      });

      var dataToCreateMap = new FormData();
      dataToCreateMap.append(
        "nametable",
        this.props.selectedStat.statNameTable
      );
      dataToCreateMap.append(
        "agency_id",
        this.props.selectedStat.agency.allRawData.REF_ID
      );
      dataToCreateMap.append("type_map", await this.checktypemap());
      if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
        dataToCreateMap.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_Q" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else if (this.props.selectedStat.time.periodType.label === "รายเดือน") {
        dataToCreateMap.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_M" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else {
        dataToCreateMap.append("year", this.props.selectedStat.time.year.id);
      }
      dataToCreateMap.append("area", this.props.selectedStat.area.code);
      dataToCreateMap.append(
        "statlist_code_id",
        this.props.selectedStat.statCodeId
      );
      if (
        this.props.provinceMode.provinceModeOpen === false &&
        this.props.provinceMode.NAME_SCHEMA_MODE === "NSO_STAT_GIS"
      ) {
        let checkmenu = PNSO.find(
          (item) => item.id === this.props.selectedCategory.mainCategory.id
        );
        if (checkmenu === undefined) {
          dataToCreateMap.append(
            "ssr",
            this.props.nameparam.changwat.toUpperCase()
          ); //เพิ่มมาใหม่
        } else {
        }
      }
      if (this.props.selectedStat.statType.CLASSIFY !== 0) {
        if (this.props.selectedStat.classify.status) {
          this.props.selectedStat.classify.classifyData.forEach(
            (itemClassify, index) => {
              if (itemClassify.check !== false) {
                if (itemClassify.codelistChild.id != "-") {
                  dataToCreateMap.append(
                    `codelist_[${index}]`,
                    itemClassify.codelist.id
                  );
                  dataToCreateMap.append(
                    `codelist_son[${index}]`,
                    itemClassify.codelistChild.id
                  );
                }
              }
            }
          );
        }
      }
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/exportgojson",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: dataToCreateMap,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          await this.GetStylelayermapAll();

          this.GetapiChart();
          this.GetTable();
          this.Getapidashboard();

          const showmap = new VectorSource({
            serverType: "geoserver",
            crossOrigin: "anonymous",
            format: new GeoJSON(),
            url: res.data,
          });
          MAPAPI.setSource(showmap);

          if (this.state.Stylemanual !== "" && this.state.Stylemanual.COLOR_CHOROPLETH !== undefined) { /*---------เช็คค่าว่ามีแมนนวลไหมให้ใช้แมนนวลก่อน-------------*/

            await MAPAPI.setStyle(this.Stylemanualcolopleth);
            this.props.setChangeStyle({
              style: this.state.Stylemanual.COLOR_CHOROPLETH,
              status: "mncp",
            });
            document.getElementById("q1").checked = true;

          } else {
            await MAPAPI.setStyle(this.Stylenaturalbreak);
            this.props.setChangeStyle({
              style: this.state.Stylenaturalbreak,
              status: "nb",
            });
            document.getElementById("q2").checked = true;

          }
          await map.getLayers().forEach((layer) => {
            if (layer === basemap.LayerGroups) {
            } else if (layer === MAPAPI) {
              map.removeLayer(layer);
              map.removeLayer(MAPAPIPINEBAR);
            } else {
              //this.state.map.removeLayer(layer)
              //layer.setVisible(false);
              //map.removeLayer(layer);
            }
          });
          await this.state.map.addLayer(MAPAPI);
          MAPAPI.setVisible(true);
          //เซ็ตradiobox ให้เป็นเริ่มต้น
          checkstylemanual = false;
          showmap.once("change", (e) => {
            this.handleModal();
            console.log("success");
          });
          MAPAPI.once("change", (e) => {
            // เช็คการเปลี่ยนแปลงของตัวแปรที่ทำงานอยู่
            this.Getwmsbackend();
            this.props.setLayersuccess({
              status: true,
              statusCrassify: `${this.props.selectedStat.classify.status
                ? this.props.selectedStat.classify.classifyData.map(
                  (item, index) => {
                    if (item.codelistChild.label !== "ไม่มีการกำหนดค่า") {
                      return ((" / " + item.codelist.label + ":" + item.codelistChild.label).replace(/,/g, ""))
                    }
                  })
                : ""
                } / ปี ${this.props.selectedStat.time.year.label
                  ? this.props.selectedStat.time.year.label
                  : ""
                } ${this.props.selectedStat.time.periodType.label == "รายไตรมาส"
                  ? "ไตรมาส" + this.props.selectedStat.time.periodSelect.label
                  : ""
                } / ระดับพื้นที่ ${this.props.selectedStat.area.label
                  ? this.props.selectedStat.area.label
                  : ""
                } `,
              classify: this.props.selectedStat.classify,
            });
          });
        })
        .catch(async (error) => {
          console.log("error Show map >>>", error);
          this.props.setModal({
            modalAction: "open",
            title: <h4>ไม่มีชุดข้อมูลที่ท่านเลือก!</h4>,
            text: `${recreatemap ? "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง" : "กรุณาทำการเลือก การจัดจำแนก เวลา พื้นที่ หรือหน่วยงานเจ้าของข้อมูล ใหม่อีกครั้ง ㅤ ตามคำอธิบายของรายการข้อมูล"}`,
            icon: "fail",
            button: [
              {
                text: "ตกลง",
                width: "100px",
                bgcolor: "#d62e45",
                functionIn: (e) =>
                  this.handleModal(e, "this is a text from callback cancle !"),
              },
            ],
          });
          await map.getLayers().forEach(async (layer) => {
            if (layer === basemap.LayerGroups) {
            } else if (layer === MAPAPI) {
              await map.removeLayer(layer);
              await map.removeLayer(MAPAPIPINEBAR);
            } else if (layer === MAPAPIPOINT) {
              await map.removeLayer(layer);
            }
          });

          this.props.setLayersuccess({
            status: (this.props.layersuccess.status = false),
            statusCrassify: "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง",
          });
        });
      /*-------------------------------------------POINTMAP---------------------------------*/
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/midpointmap",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: dataToCreateMap,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (point) => {
          const showmappoint = new VectorSource({
            serverType: "geoserver",
            crossOrigin: "anonymous",
            format: new GeoJSON(),
            url: point.data,
          });
          MAPAPIPOINT.setSource(showmappoint);
          /*         MAPAPIPOINT.setStyle(this.StyleProportion)
           */ await this.state.map.getLayers().forEach(async (layer) => {
            if (layer === basemap.LayerGroups) {
            } else if (layer === MAPAPIPOINT) {
              map.removeLayer(MAPAPIPOINT);
            } else {
              //layer.setVisible(false);
            }
          });
          await this.state.map.addLayer(MAPAPIPOINT);
          MAPAPIPOINT.setVisible(false);
          MAPAPIPOINT.once("change", (e) => {
            // เช็คการเปลี่ยนแปลงของตัวแปรที่ทำงานอยู่
            console.log("POINT");
            this.props.setLayersuccess({
              status: true,
              statusCrassify: `${this.props.selectedStat.classify.status
                ? this.props.selectedStat.classify.classifyData.map(
                  (item, index) =>
                    " / " +
                    item.codelist.label +
                    ":" +
                    item.codelistChild.label
                )
                : ""
                } / ปี ${this.props.selectedStat.time.year.label
                  ? this.props.selectedStat.time.year.label
                  : ""
                } ${this.props.selectedStat.time.periodType.label == "รายไตรมาส"
                  ? "ไตรมาส" + this.props.selectedStat.time.periodSelect.label
                  : ""
                } / ระดับพื้นที่ ${this.props.selectedStat.area.label
                  ? this.props.selectedStat.area.label
                  : ""
                } `,
              classify: this.props.selectedStat.classify,
            });

            this.handleModal();
          });
        })
        .catch(async (error) => {
          console.log("error Show map >>>", error);
          if (recreatemap) {
            this.Checkresentapimap(); //กรณีมิติซ้อนโดยไม่ครอสกันให้ไปทำฟังชั่นสร้างmapใหม่ หรือเกิดเออเร่อต่างๆ
          }
          this.props.setLayersuccess({
            status: (this.props.layersuccess.status = false),
            statusCrassify: "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง",
          });
          await map.getLayers().forEach(async (layer) => {
            if (layer === basemap.LayerGroups) {
            } else if (layer === MAPAPI) {
              await map.removeLayer(layer);
              await map.removeLayer(MAPAPIPINEBAR);
            } else if (layer === MAPAPIPOINT) {
              await map.removeLayer(layer);
            }
          });
          /* this.props.setModal({  /*ไปใช้เอ่อเร่อในการสร้างแมพซ้ำแทน
          modalAction: 'open',
          title: 'เกิดข้อผิดพลาด!',
          text: 'ไม่สามารถทำการสร้างแผนที่ได้ กรุณาแจ้งปัญหาระบบ...!',
          icon: 'fail',
          button: [
            {
              text: 'ตกลง',
              width: '100px',
              bgcolor: '#d62e45',
              functionIn: (e) => this.handleModal(e, 'this is a text from callback cancle !')
            }
          ]
        }) */
        });
      /*-----------------------------------------------------------------------------------*/
    } catch (error) {
      console.error(error);
      this.handleModal();
      this.props.setLayersuccess({
        status: false,
        statusCrassify: "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง",
      });
    }
    if (map.getLoading()) {
      this.handleModal();
    }

    $("#Choropleth").click();
    let checkwmsbackend = Array.from(document.querySelectorAll(".wmsbackend"));
    checkwmsbackend.forEach((check) => {
      check.checked = false;
    })
    //this.handdleClickTools();
  };
  /*----------------------------------------------------ฟังชั่นapi Showapimapswipe-----------------------------------------------------*/
  Showapimapswipe = async () => {
    this.setState({ loopAreaSwipe: [...this.state.loopAreaSwipe, this.props.swipeMap.swipeMapStat.area] })
    try {
      this.props.setModal({
        modalAction: "open",
        text: "กำลังสร้างแผนที่กรุณารอสักครู่...",
        icon: "loading",
      });
      let dataToCreateMap = new FormData();
      dataToCreateMap.append(
        "nametable",
        this.props.swipeMap.swipeMapStat.statNameTable
      );
      dataToCreateMap.append(
        "agency_id",
        this.props.swipeMap.swipeMapStat.agency.allRawData.REF_ID
      );
      dataToCreateMap.append("type_map", await this.checktypemap("mapright"));
      if (
        this.props.swipeMap.swipeMapStat.time.periodType.label === "รายไตรมาส"
      ) {
        dataToCreateMap.append(
          "year",
          this.props.swipeMap.swipeMapStat.time.year.id +
          "_Q" +
          this.props.swipeMap.swipeMapStat.time.periodSelect.id
        );
      } else if (
        this.props.swipeMap.swipeMapStat.time.periodType.label === "รายเดือน"
      ) {
        dataToCreateMap.append(
          "year",
          this.props.swipeMap.swipeMapStat.time.year.id +
          "_M" +
          this.props.swipeMap.swipeMapStat.time.periodSelect.id
        );
      } else {
        dataToCreateMap.append(
          "year",
          this.props.swipeMap.swipeMapStat.time.year.id
        );
      }
      dataToCreateMap.append(
        "area",
        this.props.swipeMap.swipeMapStat.area.code
      );
      dataToCreateMap.append(
        "statlist_code_id",
        this.props.swipeMap.swipeMapStat.statCodeId
      );
      /* if (this.props.selectedCategory.mainCategory.name === "ข้อมูลพื้นฐาน" || this.props.selectedCategory.mainCategory.name === "กลุ่มจังหวัดภาคเหนือตอนล่าง 2") {
        dataToCreateMap.append('ssr', this.props.nameparam.changwat);//เพิ่มมาใหม่
      }
      if (this.props.swipeMap.swipeMapStat.classify.status) {
        this.props.swipeMap.swipeMapStat.classify.classifyData.forEach((itemClassify, index) => {
          dataToCreateMap.append(`codelist_[${index}]`, itemClassify.codelist.id);
          dataToCreateMap.append(`codelist_son[${index}]`, itemClassify.codelistChild.id);
        })
      } */
      if (
        this.props.provinceMode.provinceModeOpen === false &&
        this.props.provinceMode.NAME_SCHEMA_MODE === "NSO_STAT_GIS"
      ) {
        let checkmenu = PNSO.find(
          (item) => item.id === this.props.selectedCategory.mainCategory.id
        );
        if (checkmenu === undefined) {
          dataToCreateMap.append(
            "ssr",
            this.props.nameparam.changwat.toUpperCase()
          ); //เพิ่มมาใหม่
        } else {
        }
      }
      if (this.props.swipeMap.swipeMapStat.statType.CLASSIFY !== 0) {
        if (this.props.swipeMap.swipeMapStat.classify.status) {
          this.props.swipeMap.swipeMapStat.classify.classifyData.forEach(
            (itemClassify, index) => {
              if (itemClassify.check !== false) {
                if (itemClassify.codelistChild.id != "-") {
                  dataToCreateMap.append(
                    `codelist_[${index}]`,
                    itemClassify.codelist.id
                  );
                  dataToCreateMap.append(
                    `codelist_son[${index}]`,
                    itemClassify.codelistChild.id
                  );
                }
              }
            }
          );
        }
      }
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/exportgojson",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: dataToCreateMap,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          await this.GetStylelayermapswipeAll();
          /*  this.props.setChangeStyle({
             swipestyle: {
               style: this.state.Stylenaturalbreakswipe,
               status: "nbswipe",
             },
           }); */
          const showmap = new VectorSource({
            serverType: "geoserver",
            crossOrigin: "anonymous",
            format: new GeoJSON(),
            url: res.data,
          });
          MAPAPISWIPE.setSource(showmap);
          //MAPAPISWIPE.setStyle(this.Stylenaturalbreakswipe);
          if (this.state.Stylemanualswipe !== "" && this.state.Stylemanualswipe.COLOR_CHOROPLETH !== undefined) { /*---------เช็คค่าว่ามีแมนนวลไหมให้ใช้แมนนวลก่อน-------------*/
            await MAPAPISWIPE.setStyle(this.Stylemanualswipecolopleth);
            this.props.setChangeStyle({
              swipestyle: {
                style: this.state.Stylemanualswipe.COLOR_CHOROPLETH,
                status: "mncpswipe",
              },
            });
            document.getElementById("q1swipe").checked = true;

          } else {
            await MAPAPISWIPE.setStyle(this.Stylenaturalbreakswipe);
            this.props.setChangeStyle({
              swipestyle: {
                style: this.state.Stylenaturalbreakswipe,
                status: "nbswipe",
              },
            });
            document.getElementById("q2swipe").checked = true;

          }
          await this.state.map.removeLayer(MAPAPISWIPE);
          await this.state.map.getLayers().forEach(async (layer) => {
            if (layer === basemap.LayerGroups || layer === MAPAPI || layer === MAPAPIPOINT || layer === vector || layer === ADDSCOPE ||
              layer.get("attributions") === "NSO@COPPYRIGHT") {

            } else {
              this.state.map.removeLayer(layer);
            }
          });
          await this.state.map.addLayer(MAPAPISWIPE);
          MAPAPISWIPE.once("change", (e) => {
            // เช็คการเปลี่ยนแปลงของตัวแปรที่ทำงานอยู่
            this.handleModal();
          });
          //this.state.map.Changwat.setVisible(false);
        })
        .catch((error) => {
          this.Checkresentapimapswipe();
          console.error("error Show map >>>", error);
          this.handleModal();
          this.props.setModal({
            modalAction: "open",
            title: "เกิดข้อผิดพลาด!",
            text: "ไม่สามารถทำการสร้างแผนที่ได้ กรุณาแจ้งปัญหาระบบ...!",
            icon: "fail",
            button: [
              {
                text: "ตกลง",
                width: "100px",
                bgcolor: "#d62e45",
                functionIn: (e) =>
                  this.handleModal(e, "this is a text from callback cancle !"),
              },
            ],
          });
        });
      /*-------------------------------------------POINTMAP---------------------------------*/
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/midpointmap",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: dataToCreateMap,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (point) => {

          const showmappoint = new VectorSource({
            serverType: "geoserver",
            crossOrigin: "anonymous",
            format: new GeoJSON(),
            url: point.data,
          });
          MAPAPIPOINTSWIPE.setSource(showmappoint);
    /*         MAPAPIPOINTSWIPE.setStyle(this.StyleProportion)
     */ await this.state.map.getLayers().forEach(async (layer) => {
            if (layer === basemap.LayerGroups) {
            } else if (layer === MAPAPIPOINTSWIPE) {
              map.removeLayer(MAPAPIPOINTSWIPE);
            } else {
              //layer.setVisible(false);
            }
          });
          await this.state.map.addLayer(MAPAPIPOINTSWIPE);
          MAPAPIPOINTSWIPE.setVisible(false);
          MAPAPIPOINTSWIPE.once("change", (e) => {
            // เช็คการเปลี่ยนแปลงของตัวแปรที่ทำงานอยู่
            console.log("POINT");
            this.props.setLayersuccess({
              status: true,
              // statusCrassify: `${this.props.selectedStat.classify.status
              //   ? this.props.selectedStat.classify.classifyData.map(
              //     (item, index) =>
              //       " / " +
              //       item.codelist.label +
              //       ":" +
              //       item.codelistChild.label
              //   )
              //   : ""
              statusCrassify: `${this.props.selectedStat.classify.status
                ? this.props.selectedStat.classify.classifyData.map(
                  (item, index) => {
                    if (item.codelistChild.label !== "ไม่มีการกำหนดค่า") {
                      return ((" / " + item.codelist.label + ":" + item.codelistChild.label).replace(/,/g, ""))
                    }
                  })
                : ""
                } / ปี ${this.props.selectedStat.time.year.label
                  ? this.props.selectedStat.time.year.label
                  : ""
                } ${this.props.selectedStat.time.periodType.label == "รายไตรมาส"
                  ? "ไตรมาส" + this.props.selectedStat.time.periodSelect.label
                  : ""
                } / ระดับพื้นที่ ${this.props.selectedStat.area.label
                  ? this.props.selectedStat.area.label
                  : ""
                } `,
              classify: this.props.selectedStat.classify,
            });

            this.handleModal();
          });
        })
        .catch((error) => {
          console.error("error Show map >>>", error);
          this.Checkresentapimapswipe(); //กรณีมิติซ้อนโดยไม่ครอสกันให้ไปทำฟังชั่นสร้างmapใหม่ หรือเกิดเออเร่อต่างๆ
          this.props.setLayersuccess({
            status: (this.props.layersuccess.status = false),
            statusCrassify: "ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง",
          });

        });
    } catch (error) {
      console.error(error);
      this.handleModal();
    }
    $("#q2swipe").click();
  };
  Checkresentapimapswipe = async () => {
    await this.setState({
      successCreateMap: true,
    });
    let newcodlistadd = [];
    this.props.swipeMap.swipeMapStat.classify.classifyData.map(
      (item, index) => {
        if (index == 0) {
          newcodlistadd.push(item);
        } else {
          if (
            item.codelist.child.includes({
              id: "-",
              label: "ไม่มีการกำหนดค่า",
              allRawData: {
                CODE: "-",
                CODE_NAME: "ไม่มีการกำหนดค่า",
                DESCRIPTION: null,
                POSITION: 0,
              },
            })
          ) {
            item.codelist.child.push({
              id: "-",
              label: "ไม่มีการกำหนดค่า",
              allRawData: {
                CODE: "-",
                CODE_NAME: "ไม่มีการกำหนดค่า",
                DESCRIPTION: null,
                POSITION: 0,
              },
            });
            item.codelistChild = {
              id: "-",
              label: "ไม่มีการกำหนดค่า",
              allRawData: {
                CODE: "-",
                CODE_NAME: "ไม่มีการกำหนดค่า",
                DESCRIPTION: null,
                POSITION: 0,
              },
            };
            newcodlistadd.push(item);
          } else {
            item.codelistChild = {
              id: "-",
              label: "ไม่มีการกำหนดค่า",
              allRawData: {
                CODE: "-",
                CODE_NAME: "ไม่มีการกำหนดค่า",
                DESCRIPTION: null,
                POSITION: 0,
              },
            };
            newcodlistadd.push(item);
          }
        }
      }
    );
    this.props.setChangSwipeMap({
      swipeMapStat: {
        classify: { statusUseClassify: true, classifyData: newcodlistadd },
      },
    });
    this.props.setChangSwipeMap({
      swipeMapStatFilter: {
        updateFilter: "code_lists",
        dataArrey: newcodlistadd,
      },
    });

    try {
      this.props.setModal({
        modalAction: "open",
        text: "กำลังสร้างแผนที่กรุณารอสักครู่...",
        icon: "loading",
      });
      let dataToCreateMap = new FormData();
      dataToCreateMap.append(
        "nametable",
        this.props.swipeMap.swipeMapStat.statNameTable
      );
      dataToCreateMap.append(
        "agency_id",
        this.props.swipeMap.swipeMapStat.agency.allRawData.REF_ID
      );
      dataToCreateMap.append("type_map", await this.checktypemap("mapright"));
      if (
        this.props.swipeMap.swipeMapStat.time.periodType.label === "รายไตรมาส"
      ) {
        dataToCreateMap.append(
          "year",
          this.props.swipeMap.swipeMapStat.time.year.id +
          "_Q" +
          this.props.swipeMap.swipeMapStat.time.periodSelect.id
        );
      } else if (
        this.props.swipeMap.swipeMapStat.time.periodType.label === "รายเดือน"
      ) {
        dataToCreateMap.append(
          "year",
          this.props.swipeMap.swipeMapStat.time.year.id +
          "_M" +
          this.props.swipeMap.swipeMapStat.time.periodSelect.id
        );
      } else {
        dataToCreateMap.append(
          "year",
          this.props.swipeMap.swipeMapStat.time.year.id
        );
      }
      dataToCreateMap.append(
        "area",
        this.props.swipeMap.swipeMapStat.area.code
      );
      dataToCreateMap.append(
        "statlist_code_id",
        this.props.swipeMap.swipeMapStat.statCodeId
      );
      /* if (this.props.selectedCategory.mainCategory.name === "ข้อมูลพื้นฐาน" || this.props.selectedCategory.mainCategory.name === "กลุ่มจังหวัดภาคเหนือตอนล่าง 2") {
        dataToCreateMap.append('ssr', this.props.nameparam.changwat);//เพิ่มมาใหม่
      }
      if (this.props.swipeMap.swipeMapStat.classify.status) {
        this.props.swipeMap.swipeMapStat.classify.classifyData.forEach((itemClassify, index) => {
          dataToCreateMap.append(`codelist_[${index}]`, itemClassify.codelist.id);
          dataToCreateMap.append(`codelist_son[${index}]`, itemClassify.codelistChild.id);
        })
      } */
      if (
        this.props.provinceMode.provinceModeOpen === false &&
        this.props.provinceMode.NAME_SCHEMA_MODE === "NSO_STAT_GIS"
      ) {
        let checkmenu = PNSO.find(
          (item) => item.id === this.props.selectedCategory.mainCategory.id
        );
        if (checkmenu === undefined) {
          dataToCreateMap.append(
            "ssr",
            this.props.nameparam.changwat.toUpperCase()
          ); //เพิ่มมาใหม่
        } else {
        }
      }
      if (this.props.swipeMap.swipeMapStat.statType.CLASSIFY !== 0) {
        if (this.props.swipeMap.swipeMapStat.classify.status) {
          this.props.swipeMap.swipeMapStat.classify.classifyData.forEach(
            (itemClassify, index) => {
              if (index == 0) {
                dataToCreateMap.append(
                  `codelist_[${index}]`,
                  itemClassify.codelist.id
                );
                dataToCreateMap.append(
                  `codelist_son[${index}]`,
                  itemClassify.codelistChild.id
                );
              }
            }
          );
        }
      }
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/exportgojson",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: dataToCreateMap,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          await this.GetStylelayermapswipeAll();
          this.props.setChangeStyle({
            swipestyle: {
              style: this.state.Stylenaturalbreakswipe,
              status: "nbswipe",
            },
          });
          const showmap = new VectorSource({
            serverType: "geoserver",
            crossOrigin: "anonymous",
            format: new GeoJSON(),
            url: res.data,
          });
          MAPAPISWIPE.setSource(showmap);
          MAPAPISWIPE.setStyle(this.Stylenaturalbreakswipe);
          await this.state.map.getLayers().forEach(async (layer) => {
            if (layer === basemap.LayerGroups || layer === MAPAPI) {
            } else {
              this.state.map.removeLayer(layer);
            }
          });
          await this.state.map.addLayer(MAPAPISWIPE);
          MAPAPISWIPE.once("change", (e) => {
            // เช็คการเปลี่ยนแปลงของตัวแปรที่ทำงานอยู่
            this.handleModal();
          });
          //this.state.map.Changwat.setVisible(false);
        })
        .catch((error) => {

          console.error("error Show map >>>", error);
          this.handleModal();
          this.props.setModal({
            modalAction: "open",
            title: "เกิดข้อผิดพลาด!",
            text: "ไม่สามารถทำการสร้างแผนที่ได้ กรุณาแจ้งปัญหาระบบ...!",
            icon: "fail",
            button: [
              {
                text: "ตกลง",
                width: "100px",
                bgcolor: "#d62e45",
                functionIn: (e) =>
                  this.handleModal(e, "this is a text from callback cancle !"),
              },
            ],
          });

          /*--------------------------เช็คเปลียนระดับพื้นที่------------------------*/
          let searchArea = this.props.swipeMap.swipeMapStatFilter.areas.filter((item) => {
            if ([item].some(item => this.state.loopAreaSwipe.includes(item))) {
            } else {
              return item;
            }
          });
          console.log("เช็คเปลียนระดับพื้นที่>>>>", searchArea[0]);

          if (typeof searchArea !== 'undefined' && searchArea.length > 0) {
            this.props.setChangSwipeMap({
              swipeMapStat: { area: searchArea[0] }
            });
            this.Showapimapswipe();
          } else {
            this.setState({ loopAreaSwipe: [] })
          }

          /*----------------------------------------------------------------------*/
        });

    } catch (error) {
      console.error(error);
      this.handleModal();

    }
    await this.setState({
      successCreateMap: false,
    });
  };

  Showapimappinebar = async () => {
    /*  let checkme = false;
     await this.state.map.getLayers().forEach((layer) => {
       if (layer === MAPAPIPINEBAR) {
         checkme = true;
       } else {

       }
     })
     if (checkme) {
       MAPAPIPINEBAR.setVisible(true);
     } else { */
    await map.removeLayer(MAPAPIPINEBAR);
    try {
      this.props.setModal({
        modalAction: "open",
        text: "กำลังสร้างแผนที่กรุณารอสักครู่...",
        icon: "loading",
      });
      let dataToCreateMap = new FormData();
      dataToCreateMap.append(
        "agency_id",
        this.props.selectedStat.agency.allRawData.REF_ID
      );
      dataToCreateMap.append(
        "nametable",
        this.props.selectedStat.statNameTable
      );
      dataToCreateMap.append("type_map", await this.checktypemap());
      if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
        dataToCreateMap.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_Q" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else if (this.props.selectedStat.time.periodType.label === "รายเดือน") {
        dataToCreateMap.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_M" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else {
        dataToCreateMap.append("year", this.props.selectedStat.time.year.id);
      }
      dataToCreateMap.append("area", this.props.selectedStat.area.code);
      dataToCreateMap.append(
        "statlist_code_id",
        this.props.selectedStat.statCodeId.toUpperCase()
      );
      if (
        this.props.selectedCategory.mainCategory.name === "ข้อมูลพื้นฐาน" ||
        this.props.selectedCategory.mainCategory.name ===
        "กลุ่มจังหวัดภาคเหนือตอนล่าง 2"
      ) {
        dataToCreateMap.append("ssr", this.props.nameparam.changwat); //เพิ่มมาใหม่
      }

      if (this.props.selectedStat.classify.status) {
        this.props.selectedStat.classify.classifyData.forEach(
          (itemClassify, index) => {
            if (itemClassify.isMain) {
              dataToCreateMap.append("codelistmain", itemClassify.codelist.id);
            }

            if (itemClassify.codelistChild.id != "-") {
              dataToCreateMap.append(
                `codelist_[${index}]`,
                itemClassify.codelist.id
              );
              dataToCreateMap.append(
                `codelist_son[${index}]`,
                itemClassify.codelistChild.id
              );
            }
          }
        );
      }
      let x = await axios({
        method: "post",
        url: config.API_URL + "/api/geo/piegeojson",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: dataToCreateMap,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          this.setState({
            stylepinebar: res.data.value,
          });
          const showmappine = new VectorSource({
            serverType: "geoserver",
            crossOrigin: "anonymous",
            format: new GeoJSON(),
            url: res.data.file,
          });

          await MAPAPIPINEBAR.setSource(showmappine);
          //MAPAPIPINEBAR.setStyle(this.StyleBar)
          await this.state.map.getLayers().forEach(async (layer) => {
            if (layer === basemap.LayerGroups || layer === MAPAPIPINEBAR) {
            } else if (layer.get("name") === "Changwat") {
              layer.setVisible(true);
            } else if (layer.get("name") === "MAPAPIPINEBAR") {
              map.removeLayer(layer);
            } else if (layer.get("name") === "ADDSCOPE") {
              layer.setVisible(true);
            } else {
              layer.setVisible(false);
            }
          });
          await this.state.map.addLayer(MAPAPIPINEBAR);
          MAPAPIPINEBAR.once("change", (e) => {
            // เช็คการเปลี่ยนแปลงของตัวแปรที่ทำงานอยู่
            this.handleModal();
          });
          //this.state.map.Changwat.setVisible(false);
        })
        .catch((error) => {
          console.error("error Show map >>>", error);
          this.handleModal();
        });
    } catch (error) {
      console.error(error);
      this.handleModal();
    }

    /* } */
  };
  /*-----------------------------------------------------------------ฟังชั่นเพิ่มขอบเขตจังหวัด-----------------------------------------------------*/
  AddScope = async (value) => {
    try {
      await map.getLayers().forEach((layer) => {
        if (layer === ADDSCOPE) {
          map.removeLayer(layer);
        } else {
        }
      });
      this.props.setModal({
        modalAction: "open",
        text: "กำลังสร้างแผนที่กรุณารอสักครู่...",
        icon: "loading",
      });
      axios({
        method: "post",
        url: config.API_URL + "/api/geo/exportmapdominance",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: {
          nametable: value,
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(async (res) => {
          console.log("AddScope  >>>", res.data)
          //await map.removeLayer(ADDSCOPE);
          let sourceAddscope = new VectorSource({
            url: await res.data,
            format: new GeoJSON(),
            serverType: "geoserver",
            crossOrigin: "anonymous",

          });
          await ADDSCOPE.setSource(sourceAddscope);
          await ADDSCOPE.setStyle(stylescope);
          await map.addLayer(ADDSCOPE);
          //MAPAPI.setZIndex(1);
          await ADDSCOPE.on("change", (e) => {
            console.log("ADDSCOPESUCCESS")
            // เช็คการเปลี่ยนแปลงของตัวแปรที่ทำงานอยู่
            this.handleModal();
          });
        })
        .catch((error) => {
          console.error("error  >>>", error);
          this.handleModal();
          this.props.setModal({
            modalAction: "open",
            title: "เกิดข้อผิดพลาด",
            text: "ไม่สามารสร้างแผนที่ได้ในขณะนี้...",
            icon: "fail",
          });
        });
    } catch (error) {
      console.error(error);
      this.handleModal();
    }
  };
  /*-------------------------------------------------------------ฟังชัน เพิ่ม WMS บนแผนที่ -------------------------------------*/
  AddWMS = () => {
    var url = document.querySelector("#urlwms").value;
    const values = queryString.parse(url);

    var paramsLayer = values.layers;
    var paramsSLD = values.SLD;
    var paramsSTYLES = values.styles;
    var cutlegend_options = values.legend_options;

    function cutUrl(str) {
      var matched = str.match(/([^/]*\/){6}/);
      return matched ? matched[0] : str /* or null if you wish */;
    }
    console.log(paramsLayer, paramsSLD, paramsSTYLES);
    console.log(url);
    console.log(cutUrl(url));
    map.removeLayer(ADDWMS);
    if (url !== "") {
      var layerwms = new TileWMSSource({
        url: cutlegend_options ? cutUrl(url) : url,
        params: {
          LAYERS: paramsLayer,
          TILED: true,
          SLD: paramsSLD,
          //SLD_BODY: 'http://28c3f27e.ngrok.io/geosld/province.sld',
          // STYLES: "province2554",
        },
        serverType: "geoserver",
        attributions: "NSO@COPPYRIGHT",
      });
      ADDWMS.setSource(layerwms);
      map.addLayer(ADDWMS);
    }
  };
  DeleteScope = () => {
    let radiocheck = document.querySelectorAll(".scope");
    radiocheck.forEach((check) => {
      if (check.checked === true) {
        check.checked = false;
      }
    });
    map.getLayers().forEach((layer) => {
      if (layer === ADDSCOPE || layer === ADDWMS) {
        map.removeLayer(layer);
      }
    });
    /* map.getLayers().forEach((layer) => {
      if (layer === WMSBACKEND) {
        map.removeLayer(layer);
      } else {
      }
    }); */


    this.state.map.getLayers().forEach((layer) => {
      //console.log(layer.get("name"))
      //const ly = layer && layer.get('name');

      if (layer === basemap.LayerGroups || layer === Changwat || layer === MAPAPI || layer === MAPAPIPOINT || layer === MAPAPISWIPE || layer === MAPAPIPOINTSWIPE) {

      } else {
        map.removeLayer(layer);
      }


    })

    let checkwmsbackend = Array.from(document.querySelectorAll(".wmsbackend"));
    checkwmsbackend.forEach((check) => {
      check.checked = false;
    })

    this.setState({ layerlistselect: [] });
    document.querySelector("#urlwms").value = "";
  };
  Popupmapfunction = () => {
    var overlay = new Overlay({
      element: container,
      autoPan: false,
      autoPanAnimation: {
        duration: 250,
      },
    });

    // Select  interaction
    var select = new SelectInteraction({
      // hitTolerance: 5,
      // layers:[MAPAPI],
      style: this.popupfeature,
      multi: false,
      condition: click,
    });

    closer.onclick = () => {
      //console.log('x')
      overlay.setPosition(undefined);
      closer.blur();
      //document.elementFromPoint(100, 250).click();
      var evt = {};
      evt.type = "click";
      evt.coordinate = [];
      evt.coordinate[0] = 0;
      evt.coordinate[1] = 0;
      select.dispatchEvent(evt);
      return false;
    };

    map.addInteraction(select);

    if (checkdatainfo === false) {
      /*โชวข้อความแนะนำผู้ใช้*/

      this.props.setModal({
        modalAction: "open",
        text: "กดบนแผนที่เพื่อแสดงข้อมูล...",
        icon: "question",
        button: [
          {
            text: "ตกลง",
            width: "80px",
            bgcolor: "#3085d6",
            functionIn: (e) =>
              this.handleModal(e, "this is a text from callback ok !"),
          },
        ],
      });
      container.style.display = "block";
      //console.log(select)
      /*------------------------------------------------*/
      checkdatainfo = true;
      map.on("click", (e) => {
        showInfo(e);
      });

      const showInfo = (event) => {
        var namearea = null;
        map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
          var getpop = feature.getKeys();
          /*  namearea = getpop.filter(f => f === "reg_n_t" || f === "pro_n_t" || f === "cwt_name_t" || f === "dit_n_t" || f === "tam_name" || f === "sdt_n_t"|| f === "sdt_t" || f === "abt_name_t" || f === "mun_n_t" || f === "schoolname");
           console.log(namearea); */
        });

        map.addOverlay(overlay);
        var coordinate = event.coordinate;
        overlay.setPosition(coordinate);
        var features = map.getFeaturesAtPixel(event.pixel);
        if (features.length === 0) {
          namechangwat.innerText = "";
          namechangwat.style.opacity = 0;
          statlist.innerText = "";
          statlist.style.opacity = 0;
          /*   id.innerText = '';
            id.style.opacity = 0;
            pro_id.innerText = '';
            pro_id.style.opacity = 0;

            datastatlist.innerText = '';
            datastatlist.style.opacity = 0;
   */
          map.removeOverlay(overlay);
          return;
        }
      };
    } else {
      checkdatainfo = false;
      map.removeOverlay(overlay);
      map.removeInteraction(select);
      map.getInteractions().pop(); //----------ถ้าบรรทัดอันบนลบไม่ออกให้ใช้ตัวนี้
    }
  };
  popupfeature = (feature) => {
    let getpop = feature.getKeys();
    let namearea = getpop.filter(
      (f) =>
        f === "reg_n_t" ||
        f === "pro_n_t" ||
        f === "cwt_name_t" ||
        f === "dit_n_t" ||
        f === "tam_n_t" ||
        f === "sdt_n_t" ||
        f === "sdt_t" ||
        f === "abt_name_t" ||
        f === "mun_n_t" ||
        f === "schoolname"
    );
    console.log(namearea);
    var showname = "";
    if (namearea[0] === "reg_n_t") {
      showname = "ภาค " + JSON.stringify(feature.get(namearea[0]), null, 2);
    } else if (namearea[0] === "pro_n_t" || namearea[0] === "cwt_name_t") {
      showname = "จังหวัด" + JSON.stringify(feature.get(namearea[0]), null, 2);
    } else if (namearea[0] === "dit_n_t") {
      showname =
        "อำเภอ/เขต " + JSON.stringify(feature.get(namearea[0]), null, 2);
    } else if (namearea[0] === "sdt_n_t") {
      showname =
        "ตำบล/แขวง " + JSON.stringify(feature.get(namearea[0]), null, 2);
    } else if (namearea[0] === "sdt_t") {
      showname =
        "ตำบล/แขวง " + JSON.stringify(feature.get(namearea[0]), null, 2);
    } else if (namearea[0] === "tam_name") {
      showname =
        "ตำบล/แขวง " + JSON.stringify(feature.get(namearea[0]), null, 2);
    } else if (namearea[0] === "abt_name_t") {
      showname = "อบต." + JSON.stringify(feature.get(namearea[0]), null, 2);
    } else if (namearea[0] === "mun_n_t") {
      showname = "" + JSON.stringify(feature.get(namearea[0]), null, 2);
    } else if (namearea[0] === "schoolname") {
      showname = "โรงเรียน" + JSON.stringify(feature.get(namearea[0]), null, 2);
    }
    console.log(showname);
    /*----------------------------------------------------------ข้อมูลแดชบอร์ดระดับจังหวัด---------------*/
    try {
      let datasent = new FormData();
      if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
        datasent.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_Q" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else if (this.props.selectedStat.time.periodType.label === "รายเดือน") {
        datasent.append(
          "year",
          this.props.selectedStat.time.year.id +
          "_M" +
          this.props.selectedStat.time.periodSelect.id
        );
      } else {
        datasent.append("year", this.props.selectedStat.time.year.id);
      }
      datasent.append("area", this.props.selectedStat.area.code);
      datasent.append("cat_sl_id", this.props.selectedStat.statId);
      datasent.append("sub_area", feature.get("id"));
      axios({
        method: "post",
        url: config.API_URL + "/api/gis/dashboard",
        headers: {
          NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
        },
        data: datasent,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          if (res.data.message === "no data") {
            this.setState({
              dashboard: [],
            });
          } else {
            this.setState({
              dashboard: res.data,
            });
          }
        })
        .catch((error) => {
          console.error("error >>>", error);
        });
    } catch (error) {
      console.error(error);
    }
    /*----------------------------------------------------------ข้อมูลที่น่าสนใจ---------------*/
    /*----------------------------------หากมีข้อมูลในแผนที่ให้ทำตรงนี้-------------------------------*/
    let datasent = new FormData();
    if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
      datasent.append(
        "TM_YEAR",
        this.props.selectedStat.time.year.id +
        "_Q" +
        this.props.selectedStat.time.periodSelect.id
      );
    } else if (this.props.selectedStat.time.periodType.label === "รายเดือน") {
      datasent.append(
        "TM_YEAR",
        this.props.selectedStat.time.year.id +
        "_M" +
        this.props.selectedStat.time.periodSelect.id
      );
    } else {
      datasent.append("TM_YEAR", this.props.selectedStat.time.year.id);
    }
    datasent.append("AGENCY_ID", this.props.selectedStat.agency.id);
    datasent.append("AREA", this.props.selectedStat.area.code);
    datasent.append("cat_sl_id", this.props.selectedStat.statId);
    datasent.append("AREA_CODE", feature.get("pro_id"));
    axios({
      method: "post",
      url: config.API_URL + "/api/geo/interest",
      headers: {
        NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
      },
      data: datasent,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then((res) => {
        let tableBody = $("#popup-table");
        tableBody.find("tbody tr").remove();
        res.data.forEach((row, index) => {
          //console.log(res.data)
          tableBody.append(
            `<tr><td>${res.data[index].STAT_LIST_NAME}</td><td>${res.data[index].CODELIST_NAME
            }+${res.data[index].CODELIST_SON_NAME
            }</td><td>${this.numberWithCommas(
              res.data[index].STAT_LIST_COUNT
            )}</td><td>${res.data[index].UNIT}</td><td>${this.props.selectedStat.time.year.label
            }</td></tr>`
          );
        });
      })
      .catch((error) => {
        console.error("errorinterest >>>", error);
        document.getElementById("interest").innerHTML = " (ยังไม่มีการตั่งค่า)";
      });
    namechangwat.innerText = showname;
    namechangwat.style.opacity = 1;
    statlist.innerText = this.props.selectedStat.statName
      ? this.props.selectedStat.statName
      : "ยังไม่ได้เลือรายการข้อมูล";
    statlist.style.opacity = 1;
    datastatlist.innerText = feature.get("TOTAL")
      ? this.numberWithCommas(parseFloat(feature.get("TOTAL"))) +
      " " +
      this.props.selectedStat.description.statUnit
      : "";
    datastatlist.style.opacity = 1;
    document.getElementById("interest").innerHTML = "";
    /*-------------------------------------------------------------style feature select ที่เราเลือก----------------*/
    let style = new Style({
      fill: new Fill({
        color: "#ffe252",
      }),
      stroke: new Stroke({
        color: "#558ED5",
        width: 3,
      }),
      text: new Text({
        overflow: false,
        font: "20px Calibri,sans-serif",
        fill: new Fill({
          color: "#FFF",
        }),
        stroke: new Stroke({
          color: "#f45",
          width: 3,
        }),
      }),
    });
    style.getText().setText(this.numberWithCommas(feature.get("TOTAL")));
    return style;
  };
  Swipemapfunction = () => {
    let inputchangstyle = Array.from(document.querySelectorAll(".mapallstyle"));
    this.props.setChangSwipeMap({
      swipeMapOpen: !this.props.swipeMap.swipeMapOpen,
    });
    if (checkswipmap === false) {
      inputchangstyle.forEach((input) => {
        input.disabled = true;
      });
      console.log(inputchangstyle);
      map.addControl(ctrlswipe);
      ctrlswipe.addLayer(MAPAPI, false);
      ctrlswipe.addLayer(MAPAPIPOINT, false);
      ctrlswipe.addLayer(MAPAPISWIPE, true);
      ctrlswipe.addLayer(MAPAPIPOINTSWIPE, true);

      checkswipmap = true;
      MAPAPISWIPE.setVisible(true);
      document.getElementById("Barchart").checked = false; //ไม่ให้ใช้pinebar
      document.getElementById("checkpie").checked = false; //ไม่ให้ใช้pinebar
    } else {
      inputchangstyle.forEach((input) => {
        input.disabled = false;
      });
      map.removeControl(ctrlswipe);
      MAPAPI.setVisible(true);
      MAPAPISWIPE.setVisible(false);
      checkswipmap = false;
      map.renderSync();

    }
  };
  Playmapdowload = async () => {
    try {
      this.props.setModal({
        modalAction: "open",
        text: "กำลังสร้างแผนที่กรุณารอสักครู่...",
        icon: "loading",
      });
      let isyear = this.props.selectedStat.time.year.id;
      let array = this.props.selectedStatFilter.timeYears.map((x) => x.id);
      array = array.indexOf(isyear);
      let getyear = this.props.selectedStatFilter.timeYears.slice(
        array,
        array + 5
      );
      //console.log(getyear)
      await getyear.forEach(async (load) => {
        let dataToCreateMap = new FormData();
        dataToCreateMap.append(
          "nametable",
          this.props.selectedStat.statNameTable
        );
        dataToCreateMap.append(
          "agency_id",
          this.props.selectedStat.agency.allRawData.REF_ID
        );
        dataToCreateMap.append("type_map", await this.checktypemap());
        if (this.props.selectedStat.time.periodType.label === "รายไตรมาส") {
          dataToCreateMap.append(
            "year",
            load.id + "_Q" + this.props.selectedStat.time.periodSelect.id
          );
        } else if (
          this.props.selectedStat.time.periodType.label === "รายเดือน"
        ) {
          dataToCreateMap.append(
            "year",
            load.id + "_M" + this.props.selectedStat.time.periodSelect.id
          );
        } else {
          dataToCreateMap.append("year", load.id);
        }
        dataToCreateMap.append("area", this.props.selectedStat.area.code);
        dataToCreateMap.append(
          "statlist_code_id",
          this.props.selectedStat.statCodeId
        );
        /*  if (this.props.selectedStat.classify.status) {
           this.props.selectedStat.classify.classifyData.forEach((itemClassify, index) => {
             dataToCreateMap.append(`codelist_[${index}]`, itemClassify.codelist.id);
             dataToCreateMap.append(`codelist_son[${index}]`, 't');
           })
         } */
        if (this.props.selectedStat.statType.CLASSIFY !== 0) {
          if (this.props.selectedStat.classify.status) {
            this.props.selectedStat.classify.classifyData.forEach(
              (itemClassify, index) => {
                if (itemClassify.codelistChild.id != "-") {
                  dataToCreateMap.append(
                    `codelist_[${index}]`,
                    itemClassify.codelist.id
                  );
                  dataToCreateMap.append(
                    `codelist_son[${index}]`,
                    itemClassify.codelistChild.id
                  );
                }
              }
            );
          }
        }

        await axios({
          method: "post",
          url: config.API_URL + "/api/geo/exportgojson",
          headers: {
            NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
          },
          data: dataToCreateMap,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        })
          .then(async (res) => {
            this.props.setModal({
              modalAction: "open",
              text: "กำลังสร้างแผนที่กรุณารอสักครู่...",
              icon: "loading",
            });
            let selectstyle = null;
            let checkstylecolor = document.querySelectorAll(".colorstyle");
            checkstylecolor.forEach((radio) => {
              if (radio.checked === true) {
                switch (radio.value) {
                  case "NaturalBreaks":
                    selectstyle = this.Stylenaturalbreak;
                    break;
                  case "EqualInterval":
                    selectstyle = this.Styleequalinterval;
                    break;
                  case "Quantile":
                    selectstyle = this.StyleQuantile;
                    break;
                  case "StandardDeviation":
                    selectstyle = this.Stylestandrad;
                    break;
                  case "Manaul":
                    selectstyle = this.Stylemanualcolopleth;
                    break;

                  default:
                    selectstyle = this.Stylenaturalbreak;
                    break;
                }
              }
            });
            let layer = new VectorLayer({
              name: load.id,
              visible: true,
              style: selectstyle,
            });
            layer.setSource(
              new VectorSource({
                url: res.data,
                format: new GeoJSON(),
                serverType: "geoserver",
                crossOrigin: "anonymous",
              })
            );

            /*          PLAYMAPPLAYER.getLayers().getArray().push(layer)
             */
            await map.addLayer(layer);
            await layer.on("change", () => {
              this.handleModal();
            });
            /*   PLAYMAPPLAYER.getLayers().forEach((layer)=>{
             console.log(layer)
            })  */
          })
          .catch((error) => {
            console.error("error Show map >>>", error);
            this.props.setModal({
              modalAction: "open",
              title: "เกิดข้อผิดพลาด",
              text: "ไม่สามารถทำการสร้างแผนที่ได้ในขณะนี้...",
              icon: "fail",
              button: [
                {
                  text: "ตกลง",
                  width: "80px",
                  bgcolor: "#f545",
                  functionIn: (e) =>
                    this.handleModal(e, "this is a text from callback ok !"),
                },
              ],
            });
          });
      });
    } catch (error) {
      console.error("error Show try >>>", error);
      this.handleModal();
    }
    this.handleModal();
  };
  Playmapfunction = async () => {
    let checklayeronmap = false;
    map.getLayers().forEach((layer, element) => {
      //เช็คว่ามีแผนที่นี้แสดงผลอยู่หรือป่าววว
      if (
        layer.get("name") === "MAPAPI" ||
        layer.get("name") === "MAPAPISWIPE"
      ) {
        checklayeronmap = true;
      }
    });
    if (checklayeronmap) {
      let isyear = this.props.selectedStat.time.year.id;
      let array = this.props.selectedStatFilter.timeYears.map((x) => x.id);
      array = array.indexOf(isyear);
      let x = this.props.selectedStatFilter.timeYears.slice(array, array + 5);
      let getyear = x.map((x) => +x.id);
      this.setState({
        playerGetyear: getyear,
      });

      // Create Timeline control
      var tline = new ol_control_Timeline({
        className: "ol-pointer",
        features: [
          {
            text: 2015,
            date: new Date(Math.min(...getyear).toString()),
            endDate: new Date(Math.max(...getyear).toString()),
          },
        ],
        graduation: "years", // 'month'
        minDate: new Date(Math.min(...getyear).toString()),
        maxDate: new Date(Math.max(...getyear).toString()),
        getHTML: (f) => {
          return `${this.props.selectedStat.statName !== ""
            ? this.props.selectedStat.statName
            : "กรุณาเลือกรายการสถิติ"
            }-ข้อมูลณปัจจุบัน`;
        },
        getFeatureDate: function (f) {
          return f.date;
        },
        endFeatureDate: function (f) {
          return f.endDate;
        },
      });
      //------------add ปุ่มคอนโทรขึ้นมา------------//
      if (checkplayertime === false) {
        //เปิดการทำงาน playmap
        map.addControl(tline);
        checkplayertime = true;
        await this.Playmapdowload();
      } else {
        //ปิดการทำงาน playmap
        map.getLayers().forEach((layer) => {
          if (layer === basemap.LayerGroups || layer === Changwat) {
          } else if (layer === MAPAPI) {
            layer.setVisible(true);
          } else {
            layer.setVisible(false);
          }
        });

        map.getControls().forEach(function (control) {
          if (control instanceof ol_control_Timeline) {
            map.removeControl(control);
          }
        }, this);
        checkplayertime = false;
      }
      // Set the date when ready
      setTimeout(function () {
        tline.setDate("2015");
      });
      tline.addButton({
        className: "go",
        title: "GO!",
        handleClick: function () {
          go();
        },
      });
      // Show features on scroll
      tline.on("scroll", (e) => {
        var d = tline.roundDate(e.date, "day");
        $(".dateStart").text(
          d.toLocaleDateString(undefined, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        );
        var year = d.getFullYear();
        // Filter features visibility
        /*  Changwat.getFeatures().forEach(function(f) {
          var dt = f.get('time') - e.date;
          if (Math.abs(dt) > 1000*3600*12) {
            f.setStyle([]);
          } else {
            f.setStyle();
          }
        }); */
        playgeojson(year);
      });
      function playgeojson(year) {
        //--------ทำการแสดงผลสไลด์แมพ
        map.getLayers().forEach(async (layer) => {
          console.log(layer);
          if (layer === basemap.LayerGroups) {
          } else if (layer.get("name") === year.toString()) {
            layer.setVisible(true);
          } else {
            layer.setVisible(false);
          }
        });
      }
      // Run on the timeline
      var running = false;
      var start = new Date(Math.min(...getyear).toString());
      var end = new Date(Math.max(...getyear).toString());
      function go(next) {
        var date = tline.getDate();
        if (running) clearTimeout(running);
        if (!next) {
          // stop
          if (date > start && date < end && running) {
            running = false;
            tline.element.classList.remove("running");
            return;
          }
          if (date > end) {
            date = start;
          }
        }
        if (date > end) {
          tline.element.classList.remove("running");
          return;
        }
        if (date < start) {
          date = start;
        }
        // 1 day
        date = new Date(date.getTime() + 24 * 60 * 60 * 50000);
        tline.setDate(date, { anim: false });
        // next
        tline.element.classList.add("running");
        running = setTimeout(function () {
          go(true);
        }, 100);
      }
    } else {
      this.props.setModal({
        modalAction: "open",
        title: "คำอธิบาย?",
        text: "กรุณาเลือกรายการสถิติเพื่อแสดงผลบนแผนที่ก่อน!",
        icon: "warning",
        button: [
          {
            text: "ตกลง",
            width: "80px",
            bgcolor: "#3085d6",
            functionIn: (e) =>
              this.handleModal(e, "this is a text from callback ok !"),
          },
        ],
      });
    }
  };
  handdleClickTools = (toolClicked = false) => {
    if (toolClicked === "swipe") {
      this.Swipemapfunction();
      this.handlebtcontrollOpen(""); //handleของเมนูอื่นๆที่เป็นdropdraw
      if (checkdatainfo === true) {
        this.Popupmapfunction();
      }
      if (checkplayertime === true) {
        this.Playmapfunction();
      }
    }
    if (toolClicked === "popup") {
      this.handlebtcontrollOpen(""); //handleของเมนูอื่นๆที่เป็นdropdraw
      if (checkswipmap === true) {
        this.Swipemapfunction();
      }
      this.Popupmapfunction();
      if (checkplayertime === true) {
        this.Playmapfunction();
      }
    }
    if (toolClicked === "play") {
      this.handlebtcontrollOpen(""); //handleของเมนูอื่นๆที่เป็นdropdraw
      if (checkswipmap === true) {
        this.Swipemapfunction();
      }
      if (checkdatainfo === true) {
        this.Popupmapfunction();
      }
      this.Playmapfunction();
    }
    if (toolClicked === false) {
      if (checkswipmap === true) {
        this.Swipemapfunction();
      }
      if (checkdatainfo === true) {
        this.Popupmapfunction();
      }
      if (checkplayertime === true) {
        this.Playmapfunction();
      }
    }
  };
  checktypemap = async (x = "mapleft") => {
    var typemap = null;
    // debugger
    if (x === "mapleft") {

      let checkmenu = PNSO.find(
        (item) => item.id === this.props.selectedCategory.mainCategory.id
      );
      console.log("checkmanu กลุ่มจังหวัดหรือเปล่า", checkmenu)
      if (this.props.selectedMenu.namemenu == "ข้อมูลภาพรวม") {
        checkmenu = undefined;
      }
      console.log("กลุ่มจังหวัดหรือเปล่า", checkmenu);
      if (this.props.selectedStat.area.code === "AR_CL_REG") {
        await axios({
          method: "post",
          url: config.API_URL + "/api/gis/typemap",
          headers: {
            NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
          },
          data: { cat_sl_id: this.props.selectedStat.statId },
          config: { headers: { "Content-Type": "multipart/form-data" } },
        }).then(async (res) => {
          let data = await res.data;
          if (data.length > 0) {
            typemap = data[0].TABLE_NAME;
          } else {
            typemap = this.props.selectedStat.areaTypeMap.code;
          }
          console.log("then", typemap)
        })
          .catch((error) => {
            typemap = this.props.selectedStat.areaTypeMap.code;
          });

      } else if (this.props.selectedStat.area.code === "AR_CL_CWT") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemap;
        } else {
          if (parseInt(this.props.selectedStat.time.year.id) <= 2553) {
            typemap = "province 2543-2553";
          } else {
            typemap = "province 2554-now";
          }
        }
      } else if (this.props.selectedStat.area.code === "AR_CL_AMP") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemapdistrict;
        } else {
          if (parseInt(this.props.selectedStat.time.year.id) <= 2549) {
            typemap = "district 2543-2549";
          } else if (
            parseInt(this.props.selectedStat.time.year.id) >= 2550 &&
            parseInt(this.props.selectedStat.time.year.id) < 2551
          ) {
            typemap = "district 2550-2551";
          } else if (
            parseInt(this.props.selectedStat.time.year.id) >= 2552 &&
            parseInt(this.props.selectedStat.time.year.id) < 2553
          ) {
            typemap = "district 2552-2553";
          } else if (parseInt(this.props.selectedStat.time.year.id) > 2554) {
            typemap = "district 2554-now";
          } else {
            typemap = "district 2543-2549";
          }
        }
      } else if (this.props.selectedStat.area.code === "AR_CL_TAM") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemapsupdistrict;
        } else {
          if (parseInt(this.props.selectedStat.time.year.id) <= 2553) {
            typemap = "sub-district 2553-2553";
          } else if (
            parseInt(this.props.selectedStat.time.year.id) >= 2554 &&
            parseInt(this.props.selectedStat.time.year.id) < 2559
          ) {
            typemap = "sub-district 2554-2559";
          } else {
            typemap = "sub-district 2560-now";
          }
        }
      } else if (this.props.selectedStat.area.code === "AR_CL_MUNI") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemap;
        } else {
          if (parseInt(this.props.selectedStat.time.year.id) <= 2543) {
            typemap = "MUNI_Thai_2561";
          } else {
            typemap = "MUNI_Thai_2561";
          }
        }
      } else if (this.props.selectedStat.area.code === "AR_CL_CLU") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemap;
        } else {
          if (parseInt(this.props.selectedStat.time.year.id) <= 2543) {
            typemap = this.props.selectedStat.areaTypeMap.code;
          } else {
            typemap = this.props.selectedStat.areaTypeMap.code;
          }
        }
      } else if (this.props.selectedStat.area.code === "AR_CL_SAO") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemap;
        } else {
          if (parseInt(this.props.selectedStat.time.year.id) <= 2543) {
            typemap = this.props.selectedStat.areaTypeMap.code;
          } else {
            typemap = this.props.selectedStat.areaTypeMap.code;
          }
        }
      }
    } else if (x === "mapright") {
      //-----------------------------------swipmapright-----//

      let checkmenu = PNSO.find(
        (item) => item.id === this.props.swipeMap.swipeMapCategory.id
      );
      if (this.props.selectedMenu.namemenu !== "ข้อมูลภาพรวม") {
        checkmenu = undefined;
      }
      if (this.props.swipeMap.swipeMapStat.area.code === "AR_CL_REG") {
        // typemap = this.props.swipeMap.swipeMapStat.areaTypeMap.code;
        await axios({
          method: "post",
          url: config.API_URL + "/api/gis/typemap",
          headers: {
            NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
          },
          data: { cat_sl_id: this.props.swipeMap.swipeMapStat.statId },
          config: { headers: { "Content-Type": "multipart/form-data" } },
        }).then(async (res) => {
          let data = await res.data;
          if (data.length > 0) {
            typemap = data[0].TABLE_NAME;
          } else {
            typemap = this.props.swipeMap.swipeMapStat.areaTypeMap.code;
          }
          console.log("then", typemap)
        })
          .catch((error) => {
            typemap = this.props.swipeMap.swipeMapStat.areaTypeMap.code;
          });
      } else if (this.props.swipeMap.swipeMapStat.area.code === "AR_CL_CWT") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemap;
        } else {
          if (parseInt(this.props.swipeMap.swipeMapStat.time.year.id) <= 2553) {
            typemap = "province 2543-2553";
          } else {
            typemap = "province 2554-now";
          }
        }
      } else if (this.props.swipeMap.swipeMapStat.area.code === "AR_CL_AMP") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemapdistrict;
        } else {
          if (parseInt(this.props.swipeMap.swipeMapStat.time.year.id) <= 2549) {
            typemap = "district 2543-2549";
          } else if (
            parseInt(this.props.swipeMap.swipeMapStat.time.year.id) >= 2550 &&
            parseInt(this.props.swipeMap.swipeMapStat.time.year.id) < 2551
          ) {
            typemap = "district 2550-2551";
          } else if (
            parseInt(this.props.swipeMap.swipeMapStat.time.year.id) >= 2552 &&
            parseInt(this.props.swipeMap.swipeMapStat.time.year.id) < 2553
          ) {
            typemap = "district 2552-2553";
          } else if (
            parseInt(this.props.swipeMap.swipeMapStat.time.year.id) > 2554
          ) {
            typemap = "district 2554-now";
          } else {
            typemap = "district 2543-2549";
          }
        }
      } else if (this.props.swipeMap.swipeMapStat.area.code === "AR_CL_TAM") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemapsupdistrict;
        } else {
          if (parseInt(this.props.swipeMap.swipeMapStat.time.year.id) <= 2553) {
            typemap = "sub-district 2553-2553";
          } else if (
            parseInt(this.props.swipeMap.swipeMapStat.time.year.id) >= 2554 &&
            parseInt(this.props.swipeMap.swipeMapStat.time.year.id) < 2559
          ) {
            typemap = "sub-district 2554-2559";
          } else {
            typemap = "sub-district 2560-now";
          }
        }
      } else if (this.props.swipeMap.swipeMapStat.area.code === "AR_CL_MUNI") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemap;
        } else {
          if (parseInt(this.props.swipeMap.swipeMapStat.time.year.id) <= 2543) {
            typemap = "MUNI_Thai_2561";
          } else {
            typemap = "MUNI_Thai_2561";
          }
        }
      } else if (this.props.swipeMap.swipeMapStat.area.code === "AR_CL_CLU") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemap;
        } else {
          if (parseInt(this.props.swipeMap.swipeMapStat.time.year.id) <= 2543) {
            typemap = this.props.swipeMap.swipeMapStat.areaTypeMap.code;
          } else {
            typemap = this.props.swipeMap.swipeMapStat.areaTypeMap.code;
          }
        }
      } else if (this.props.swipeMap.swipeMapStat.area.code === "AR_CL_SAO") {
        if (checkmenu !== undefined) {
          typemap = checkmenu.Typemap;
        } else {
          if (parseInt(this.props.swipeMap.swipeMapStat.time.year.id) <= 2543) {
            typemap = this.props.swipeMap.swipeMapStat.areaTypeMap.code;
          } else {
            typemap = this.props.swipeMap.swipeMapStat.areaTypeMap.code;
          }
        }
      }
    }

    console.log("return", typemap)
    return typemap;
  };
  numberWithCommas = (x) => {
    //----------------------------------ฟังชั่นใส่คอมม่าให้ค่าของข้อมูล
    if (x != undefined) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };
  stringwraper = (str, width, spaceReplacer) => {
    if (str.length > width) {
      var p = width;
      while (p > 0 && str[p] !== " " && str[p] !== "-") {
        p--;
      }
      if (p > 0) {
        var left;
        if (str.substring(p, p + 1) === "-") {
          left = str.substring(0, p + 1);
        } else {
          left = str.substring(0, p);
        }
        var right = str.substring(p + 1);

        return (
          left + spaceReplacer + this.stringwraper(right, width, spaceReplacer)
        );
      }
    }
    return str;
  };
  handleModal = (e, param = "nothing from callback function !") => {
    // console.log("test handleModal event from modal >>>", e);
    // console.log("test handleModal param from function >>>", param);

    this.props.setModal({ modalAction: "close" });
  };
  DeleateLayer = () => {
    console.log("deleate");
    // console.log('map.getLayers() >>>', map.getLayers())
    map.getLayers().forEach((layer, index) => {
      console.log("layer >>> ", index, " >>> ", layer);

      // -------------- old
      // if (layer === basemap.LayerGroups) {
      // } else if (layer === MAPAPI) {
      //   console.log("1")
      //   map.removeLayer(layer);
      //   map.removeLayer(MAPAPIPINEBAR);
      // } else if (layer === MAPAPIPOINT) {
      //   map.removeLayer(layer);
      // }

      // -------------- test 1
      // if (layer === MAPAPI || layer === MAPAPIPOINT) {
      //   console.log("เข้าเคส MAPAPI หรือ MAPAPIPOINT >>>")
      //   map.removeLayer(layer);
      // }

      // -------------- test 2
      // if (layer !== basemap.LayerGroups && layer !== Changwat) {
      //   console.log("เข้าเคสไม่ใช่ basemap และ Changwat >>>")
      //   map.removeLayer(layer);
      // }

      // -------------- test 3
      if (layer !== basemap.LayerGroups) {
        console.log("เข้าเคสไม่ใช่ basemap >>>");
        map.removeLayer(layer);
      }
    });
    map.addLayer(Changwat);
    // map.renderSync();
  };
  Getwmsbackend = () => {
    let datasent = {
      cat_sl_id: this.props.selectedStat.statId,
    };
    axios({
      method: "post",
      url: config.API_URL + "/api/geo/wms_stat",
      headers: {
        NAME_SCHEMA: this.props.provinceMode.NAME_SCHEMA_MODE,
      },
      data: datasent,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then((res) => {
        this.setState({
          wmsbackend: res.data,
        });
      })
      .catch((error) => {
        this.setState({
          wmsbackend: [],
        });
      });
  };

  render() {
    const style = {
      position: "absolute",
      width: "100%",
      height: "80vh",
      display: "block",
      backgroundColor: "#cccccc",
    };

    return (
      <div id="all">
        <div id="map" style={style}>
          <Tabbar
            showmap={this.Showapimap}
            showswichmap={this.Showapimapswipe}
          />
          <Modalbug
            title={this.props.modal.title}
            message={this.props.modal.text}
            status={this.props.modal.status}
          />
          <div id="popup" className="ol-popup" style={{ display: "none" }}>
            <a href="#" id="popup-closer" className="ol-popup-closer"></a>
            <span style={{ color: "blue" }}>ข้อมูลเขตการปกครอง </span>

            {/* <span>จังหวัด</span> */}
            <span id="popup-namechangwat"></span>
            <br />
            <span id="popup-statlist" style={{ color: "blue" }}>
              ยังไม่ได้เลืกรายการ
            </span>
            <br />
            <span
              id="popup-data"
              style={{ fontWeight: "bold", fontSize: "18px" }}
            ></span>
            <hr></hr>
            <span style={{ color: "blue" }}>ข้อมูลสถิติที่น่าสนใจ</span>
            <span style={{ color: "red" }} id="interest"></span>
            <table style={{ width: "100%" }} id="popup-table">
              <thead>
                <tr>
                  <th>รายการสถิติที่น่าสนใจ</th>
                  <th>การจัดจำแนก</th>
                  <th>ค่าข้อมูลสถิติ</th>
                  <th>หน่วยนับ</th>
                  <th>ปี</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                <td id="popup-id"></td>
                <td id="popup-pro_id"></td>
                <td id="popup-type"></td>sdsad
              </tr> */}
              </tbody>
            </table>
          </div>
          <div id="message" style={{ position: "absolute", zIndex: 99 }}></div>
          <Slidebar
            pnso={this.props.menu}
            showmap={this.Showapimap}
            showswichmap={this.Showapimapswipe}
            fullscreenSlidebar={this.state.fullscreenSlidebar}
            pathSchema={this.props.pathSchema}
            maplayerdeleate={this.DeleateLayer}
          />
          <Chart
            show={this.props.menutab.showchart}
            data={this.state.Datachart}
            channgpage={this.GetapiChart}
            datatreemap={this.state.DatachartTreemap}
          />
          <Table
            show={this.props.menutab.showtable}
            datatable={this.state.datatable}
          />
          <Dashboard
            show={this.props.menutab.showdashboard}
            datadashboard={this.state.dashboard}
          />
          {/*  <SwipeMap>
        <input type="range" id="swipe" className={"hide"} />
        </SwipeMap> */}
          {/*--------------------------------controll map---------------------------------------------------------------*/}
          <div className="controlapi" >

          </div>
          <Controllmap>
            <div className="control">
              <div className="menuoption">
                {/* ไปยังจุดกลาง */}
                <a id="centermap">
                  <img
                    src={centermap}
                    draggable="true"
                    data-bukket-ext-bukket-draggable="true"
                    title="กลับไปจุดศูนย์กลาง"
                  />
                </a>
                {/* ข้อมูลที่น่าสนใจ */}
                <a data-toggle="tooltip" title="ข้อมูลที่น่าสนใจ" id="datainfo">
                  <img
                    src={checkdatainfo ? imginfo : imginfo}
                    alt=""
                    draggable="true"
                    data-bukket-ext-bukket-draggable="true"
                    onClick={() => {
                      this.handdleClickTools("popup");
                    }}
                  />
                </a>
                {/* แผนที่เปรียบเทียบ SWIPE */}
                {/*  <div className="dropdown"> */}
                <a
                  data-toggle="tooltip"
                  id="SwipeMap"
                  title="Swipe Map"
                  onClick={() => {
                    this.handdleClickTools("swipe");
                  }}
                >
                  <img
                    src={checkswipmap ? imgswipe : imgswipe}
                    draggable="true"
                    data-bukket-ext-bukket-draggable="true"
                  />
                </a>
                {/*  </div> */}
                {/* เล่นแผนที่ตามเวลา PLAY */}
                {/*  <div className="dropdown"> */}
                <a data-toggle="tooltip" id="slidemap" title="Slide Map">
                  <img
                    src={checkplayertime ? slide : slide}
                    onClick={() => {
                      this.handdleClickTools("play");
                    }}
                    draggable="true"
                    data-bukket-ext-bukket-draggable="true"
                  />
                </a>
                {/*  <div
                    className="dropdown-content"
                    style={{ width: "150px" }}
                  ></div> */}
                {/*  </div> */}
                {/* วาดภาพบนแผนที่ */}
                <div className="dropdown">
                  <a
                    data-toggle="tooltip"
                    className="bt-open"
                    title="วาดภาพบนแผนที่"
                    onClick={() => {
                      this.handlebtcontrollOpen("draw");
                    }}
                  >
                    <img
                      src={this.state.btcontrolldraw ? imgdraw : imgdraw}
                      draggable="true"
                      data-bukket-ext-bukket-draggable="true"
                    />
                  </a>
                  <div
                    className={`dropdown-content ${this.state.btcontrolldraw ? "active" : ""
                      }`}
                    style={{ width: "150px" }}
                  >
                    <p
                      className="font-weight-bold "
                      style={{ textAlign: "center" }}
                    >
                      วาดภาพบนแผนที่
                    </p>
                    <form className="form-inline">
                      <select id="type" className="selectfilter">
                        <option value="None">เลือกรูปแบบการวาด</option>
                        {/* <option value="clear">clear</option> */}
                        <option value="LineString">รูปเส้น</option>
                        <option value="Polygon">รูปหลายเหลี่ยม</option>
                        <option value="Circle">รูปวงกลม</option>
                        <option value="Square">รูปสีเหลี่ยมด้านเท่า</option>
                        <option value="Box">รูปสีเหลี่ยม</option>
                        <option value="Star">รูปดาว</option>
                      </select>
                    </form>
                    <button
                      className="btn-sm btn-block btn-danger"
                      id="btndrawdel"
                    >
                      ล้างทั้งหมด
                    </button>
                  </div>
                </div>
                {/* ค้นหาพื้นที่ */}
                <div className="dropdown">
                  <a
                    data-toggle="tooltip"
                    className="bt-open"
                    title="ค้นหาพื้นที่"
                    onClick={() => {
                      this.handlebtcontrollOpen("serch");
                    }}
                  >
                    <img
                      src={this.state.btcontrollserch ? imgfine : imgfine}
                      draggable="true"
                      data-bukket-ext-bukket-draggable="true"
                    />
                  </a>
                  <div
                    className={`dropdown-content ${this.state.btcontrollserch ? "active" : ""
                      }`}
                    style={{ textAlign: "center" }}
                  >
                    <span>
                      <strong>ค้นหาแบบเงื่อนไข</strong>
                    </span>
                    <select
                      id="serchif"
                      className="selectfilter"
                      onChange={(e) => {
                        console.log(e.target.value);
                        e.target.value == "eqar"
                          ? (document.querySelector(
                            "#serchvalueopen"
                          ).style.display = "block")
                          : (document.querySelector(
                            "#serchvalueopen"
                          ).style.display = "none");
                        e.target.value == ""
                          ? (document.querySelector(
                            "#serchvalue"
                          ).disabled = true)
                          : (document.querySelector(
                            "#serchvalue"
                          ).disabled = false);
                      }}
                    >
                      <option value="">เลือกเงื่อนไขการค้นหา</option>
                      <option value="over">มากกว่า</option>
                      <option value="overpush">มากกว่าเท่ากับ</option>
                      <option value="little">น้อยกว่า</option>
                      <option value="littlepush">น้อยกว่าเท่ากับ</option>
                      <option value="as">เท่ากับ</option>
                      <option value="notas">ไม่เท่ากับ</option>
                      <option value="eqar">ระหว่าง</option>
                    </select>
                    <div className="input-group input-group-sm mb-1">
                      <Input
                        size="small"
                        type={"number"}
                        id="serchvalue"
                        placeholder="ใส่จำนวน"
                      />
                      <div id="serchvalueopen" style={{ display: "none" }}>
                        <span className="mx-auto">ถึง</span>
                        <Input
                          size="small"
                          type={"number"}
                          id="serchvalue2"
                          placeholder="ใส่จำนวน"
                        />
                      </div>
                    </div>

                    <p style={{ paddingLeft: "5px" }}>
                      <strong>รูปแบบการแสดงผล</strong>
                    </p>
                    <select id="serchstyle" className="selectfilter">
                      <option value="higtligt">ไฮไลท์พื้นที่</option>
                      <option value="point">จุดสี</option>
                      <option value="star">รูปดาว</option>
                      <option value="flag">ปักธง</option>
                    </select>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ width: "50%" }}>
                        <button
                          className="btn btn-block btn-primary "
                          onClick={() => {
                            this.Serchif();
                          }}
                        >
                          ตกลง
                        </button>
                      </div>
                      <div style={{ width: "50%" }}>
                        <button
                          className="btn btn-block btn-danger "
                          onClick={() => {
                            this.Serchif("clear");
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ชั้นข้อมูลแผนที่ */}
                <div className="dropdown">
                  <a
                    data-toggle="tooltip"
                    className="bt-open"
                    title="ชั้นข้อมูลแผนที่"
                    onClick={() => {
                      this.handlebtcontrollOpen("scope");
                    }}
                  >
                    <img
                      src={image053}
                      draggable="true"
                      data-bukket-ext-bukket-draggable="true"
                    />
                  </a>
                  <div
                    className={`dropdown-content ${this.state.btcontrollscope ? "active" : ""
                      }`}
                    style={{
                      width: "280px",
                      height: "300px",
                      overflowY: "auto",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      className="font-weight-bold "
                      style={{ textAlign: "center" }}
                    >
                      ชั้นข้อมูลแผนที่อื่นๆ
                    </p>
                    <span className=" font-weight-bold">
                      เขตการปกครอง(แสดงขอบเขตซ้อนเท่านั้น)
                    </span>
                    <br />
                    <div className="btn-group w-100">
                      <button
                        type="button"
                        className="dropdown w-100 "
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ textAlign: "left !important" }}
                      >
                        <div style={{ float: "left" }}>ภาค</div>
                        <div style={{ float: "right" }}>▼</div>
                      </button>

                      <div className="dropdown-menu t1">
                        <p>เลือกเขตข้อมูล</p>
                        {this.state.DigitalMapReg.map((element, index) => {
                          return (
                            <div className="dropdown-menushow" key={index}>
                              <input
                                type="radio"
                                id={element.ID}
                                className="scope"
                                name="scope"
                                defaultValue={element.TABLE_NAME}
                                onChange={() => {
                                  this.AddScope(element.TABLE_NAME);
                                }}
                              />
                              <label htmlFor={element.ID}>{element.NAME}</label>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <div className="btn-group w-100">
                      <button
                        type="button"
                        className="dropdown w-100"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div style={{ float: "left" }}>จังหวัด</div>
                        <div style={{ float: "right" }}>▼</div>
                      </button>
                      <div className="dropdown-menu t1">
                        <p>เลือกเขตข้อมูล</p>
                        {this.state.DigitalMapProv.map((element, index) => {
                          return (
                            <div className="dropdown-menushow" key={index}>
                              <input
                                type="radio"
                                id={element.ID}
                                className="scope"
                                name="scope"
                                defaultValue={element.TABLE_NAME}
                                onChange={() => {
                                  this.AddScope(element.TABLE_NAME);
                                }}
                              ></input>
                              <label htmlFor={element.ID}>{element.NAME}</label>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <div className="btn-group w-100">
                      <button
                        type="button"
                        className="dropdown w-100"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div style={{ float: "left" }}>อำเภอ</div>
                        <div style={{ float: "right" }}>▼</div>
                      </button>
                      <div className="dropdown-menu t1">
                        <p>เลือกเขตข้อมูล</p>
                        {this.state.DigitalMapDist.map((element, index) => {
                          return (
                            <div className="dropdown-menushow" key={index}>
                              <input
                                type="radio"
                                id={element.ID}
                                className="scope"
                                name="scope"
                                defaultValue={element.TABLE_NAME}
                                onChange={() => {
                                  this.AddScope(element.TABLE_NAME);
                                }}
                              ></input>
                              <label htmlFor={element.ID}>{element.NAME}</label>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <div className="btn-group w-100">
                      <button
                        type="button"
                        className="dropdown w-100"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div style={{ float: "left" }}>ตำบล</div>
                        <div style={{ float: "right" }}>▼</div>
                      </button>
                      <div className="dropdown-menu t1">
                        <p>เลือกเขตข้อมูล</p>
                        {this.state.DigitalMapSubdist.map((element, index) => {
                          return (
                            <div className="dropdown-menushow" key={index}>
                              <input
                                type="radio"
                                id={element.ID}
                                className="scope"
                                name="scope"
                                defaultValue={element.TABLE_NAME}
                                onChange={() => {
                                  this.AddScope(element.TABLE_NAME);
                                }}
                              ></input>
                              <label htmlFor={element.ID}>{element.NAME}</label>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <div className="btn-group w-100">
                      <button
                        type="button"
                        className="dropdown w-100"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div style={{ float: "left" }}>
                          องค์การบริหารส่วนตำบล.
                        </div>
                        <div style={{ float: "right" }}>▼</div>
                      </button>
                      <div className="dropdown-menu t1">
                        <p>เลือกเขตข้อมูล</p>
                        {this.state.DigitalMapAbt.map((element, index) => {
                          return (
                            <div className="dropdown-menushow" key={index}>
                              <input
                                type="radio"
                                id={element.ID}
                                className="scope"
                                name="scope"
                                defaultValue={element.TABLE_NAME}
                                onChange={() => {
                                  this.AddScope(element.TABLE_NAME);
                                }}
                              ></input>
                              <label htmlFor={element.ID}>{element.NAME}</label>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <div className="btn-group w-100">
                      <button
                        type="button"
                        className="dropdown w-100"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div style={{ float: "left" }}>เทศบาล.</div>
                        <div style={{ float: "right" }}>▼</div>
                      </button>
                      <div className="dropdown-menu t1">
                        <p>เลือกเขตข้อมูล</p>
                        {this.state.DigitalMapMuni.map((element, index) => {
                          return (
                            <div className="dropdown-menushow" key={index}>
                              <input
                                type="radio"
                                id={element.ID}
                                className="scope"
                                name="scope"
                                defaultValue={element.TABLE_NAME}
                                onChange={() => {
                                  this.AddScope(element.TABLE_NAME);
                                }}
                              ></input>
                              <label htmlFor={element.ID}>{element.NAME}</label>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <hr />
                    <span className="font-weight-bold">ด้านสังคม</span>
                    <br />
                    <div className="btn-group w-100">
                      <button
                        type="button"
                        className="dropdown w-100"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div style={{ float: "left" }}>
                          สถานศึกษาทั่วประเทศ.
                        </div>
                        <div style={{ float: "right" }}>▼</div>
                      </button>
                      <div className="dropdown-menu t1">
                        <p>เลือกเขตข้อมูล</p>
                        {this.state.DigitalMapSchool.map((element, index) => {
                          return (
                            <div className="dropdown-menushow" key={index}>
                              <input
                                type="radio"
                                id={element.ID}
                                className="scope"
                                name="scope"
                                defaultValue={element.TABLE_NAME}
                                onChange={() => {
                                  this.AddScope(element.TABLE_NAME);
                                }}
                              ></input>
                              <label htmlFor={element.ID}>{element.NAME}</label>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <hr />
                    <span className="font-weight-bold">ชั้นข้อมูลแผนที่ที่เกี่ยวข้อง</span>
                    {this.state.wmsbackend.length > 0 ? (
                      <div className="btn-group w-100">
                        <button
                          type="button"
                          className="dropdown w-100"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div style={{ float: "left" }}>เลือกชั้นข้อมูลแผนที่</div>
                          <div style={{ float: "right" }}>▼</div>
                        </button>
                        <div className="dropdown-menu t1">
                          <p>เลือกชั้นข้อมูลแผนที่</p>
                          {this.state.wmsbackend.map((element, index) => {
                            return (
                              <div className="dropdown-menushow" key={index}>
                                <input
                                  type="checkbox"
                                  id={element.ID}
                                  className="wmsbackend"
                                  name="wmsbackend"
                                  defaultValue={element.ID}
                                  onChange={(e) => {
                                    const addwmslist = () => {
                                      var url = element.URL;
                                      const values = queryString.parse(url);
                                      var paramsLayer = values.layers;
                                      var paramsSLD = values.SLD;
                                      var paramsSTYLES = values.styles;
                                      var cutlegend_options =
                                        values.legend_options;
                                      function cutUrl(str) {
                                        var matched = str.match(/([^/]*\/){6}/);
                                        return matched
                                          ? matched[0]
                                          : str /* or null if you wish */;
                                      }

                                      let layerwmsbackend = new TileLayer({
                                        serverType: "geoserver",
                                        attributions: "NSO@COPPYRIGHT",
                                        name: element.NAME,
                                        zIndex: 4,
                                      });
                                      let wmsbackend = new TileWMSSource({
                                        url: cutlegend_options
                                          ? cutUrl(url)
                                          : url,
                                        params: {
                                          LAYERS: paramsLayer,
                                          TILED: true,
                                          SLD: paramsSLD,

                                        },
                                        serverType: "geoserver",
                                        attributions: "NSO@COPPYRIGHT",
                                      });
                                      layerwmsbackend.setSource(wmsbackend);
                                      map.addLayer(layerwmsbackend);
                                    }
                                    if (e.target.checked == true) {
                                      this.setState({ layerlistselect: [element, ...this.state.layerlistselect] });
                                      addwmslist();
                                    } else {
                                      const checklist1 = this.state.layerlistselect.find((list) => list.NAME === element.NAME);
                                      if (checklist1 !== undefined) { /*ถ้ามันติ้กอยู่แล้ว*/
                                        const checklist2 = this.state.layerlistselect.filter((list) =>
                                          list.NAME !== element.NAME
                                        );
                                        this.setState({ layerlistselect: checklist2 });
                                        this.state.map.getLayers().forEach((layer) => {
                                          const ly = layer && layer.get('name');
                                          if (ly !== undefined && ly === element.NAME) {
                                            map.removeLayer(layer);
                                          }

                                        })
                                      } else {/*ถ้ามันยังไม่ได้ติ้ก*/
                                        this.setState({ layerlistselect: [element, ...this.state.layerlistselect] });
                                        addwmslist();
                                      }
                                    }

                                  }}
                                ></input>
                                <label htmlFor={element.ID}>
                                  {element.NAME}
                                </label>
                                <br />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <p className="pl-3">ไม่มีรายการ WMS ในระบบ</p>
                    )}
                    <hr />
                    <span className="font-weight-bold"> เพิ่มเข้า WMS </span>
                    <br />

                    <div id="addwms"></div>

                    <div className="input-group input-group-sm mb-1">
                      <Input
                        placeholder="UrlWms"
                        icon="map"
                        id="urlwms"
                        style={{ width: "100%" }}
                      />
                    </div>

                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => {
                        this.AddWMS();
                      }}
                    >
                      เพิ่ม WMS
                    </button>
                    <button
                      className="btn btn-danger btn-block"
                      onClick={() => {
                        this.DeleteScope();
                      }}
                    >
                      ล้างท้ั้งหมด
                    </button>
                  </div>
                </div>
                {/* ช่วงชั้นข้อมูล */}
                <div className="dropdown">
                  <a
                    data-toggle="tooltip"
                    className="bt-open"
                    title="ช่วงชั้นข้อมูล"
                    onClick={() => {
                      this.handlebtcontrollOpen("stylecolor");
                    }}
                  >
                    <img
                      src={image056}
                      width={35}
                      draggable="true"
                      data-bukket-ext-bukket-draggable="true"
                    />
                  </a>
                  <div
                    className={`dropdown-content ${this.state.btcontrollstylecolor ? "active" : ""
                      }`}
                    style={{ width: "200px" }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <strong>
                        ช่วงชั้นข้อมูล
                        {this.props.swipeMap.swipeMapOpen === true
                          ? "(ซ้าย)"
                          : ""}
                      </strong>
                    </p>
                    <input
                      type="radio"
                      name="colorstyle"
                      className="colorstyle"
                      id="q2"
                      defaultValue="NaturalBreaks"
                      defaultChecked
                      onClick={() => {
                        this.ChangStyleColor("NaturalBreaks");
                      }}
                    // disabled={
                    //   this.props.selectedStat.statType !== ""
                    //     ? this.props.selectedStat.statType.TYPE !== null
                    //       ? this.props.selectedStat.statType.TYPE.NAME ===
                    //         "ร้อยละ" &&
                    //         this.props.selectedStat.classify.status &&
                    //         this.props.selectedStat.classify.classifyData[0]
                    //           .isMain === false
                    //         ? true
                    //         : false
                    //       : false
                    //     : false
                    // }
                    />
                    <label htmlFor="q2">แบบ Natural Breaks</label>
                    <br />
                    <input
                      type="radio"
                      name="colorstyle"
                      className="colorstyle"
                      id="q3"
                      defaultValue="EqualInterval"
                      onClick={() => {
                        this.ChangStyleColor("EqualInterval");
                      }}
                    // disabled={
                    //   this.props.selectedStat.statType !== ""
                    //     ? this.props.selectedStat.statType.TYPE !== null
                    //       ? this.props.selectedStat.statType.TYPE.NAME ===
                    //         "ร้อยละ" &&
                    //         this.props.selectedStat.classify.status &&
                    //         this.props.selectedStat.classify.classifyData[0]
                    //           .isMain === false
                    //         ? true
                    //         : false
                    //       : false
                    //     : false
                    // }
                    />
                    <label htmlFor="q3">แบบ Equal Interval</label>
                    <br />
                    <input
                      type="radio"
                      name="colorstyle"
                      className="colorstyle"
                      id="q4"
                      defaultValue="Quantile"
                      onClick={() => {
                        this.ChangStyleColor("Quantile");
                      }}
                    // disabled={
                    //   this.props.selectedStat.statType !== ""
                    //     ? this.props.selectedStat.statType.TYPE !== null
                    //       ? this.props.selectedStat.statType.TYPE.NAME ===
                    //         "ร้อยละ" &&
                    //         this.props.selectedStat.classify.status &&
                    //         this.props.selectedStat.classify.classifyData[0]
                    //           .isMain === false
                    //         ? true
                    //         : false
                    //       : false
                    //     : false
                    // }
                    />
                    <label htmlFor="q4">แบบ Quantile</label>
                    <br />
                    <input
                      type="radio"
                      name="colorstyle"
                      className="colorstyle"
                      id="q5"
                      defaultValue="StandardDeviation"
                      onClick={() => {
                        this.ChangStyleColor("StandardDeviation");
                      }}
                    // disabled={
                    //   this.props.selectedStat.statType !== ""
                    //     ? this.props.selectedStat.statType.TYPE !== null
                    //       ? this.props.selectedStat.statType.TYPE.NAME ===
                    //         "ร้อยละ" &&
                    //         this.props.selectedStat.classify.status &&
                    //         this.props.selectedStat.classify.classifyData[0]
                    //           .isMain === false
                    //         ? true
                    //         : false
                    //       : false
                    //     : false
                    // }
                    />
                    <label htmlFor="q5">แบบ Standard Deviation</label>
                    <br />
                    <div>
                      <input
                        disabled={this.state.Stylemanual !== "" ? false : true}
                        type="radio"
                        name="colorstyle"
                        className="colorstyle"
                        id="q1"
                        defaultValue="Manaul"
                        onClick={() => {
                          this.ChangStyleColor("Manaul");
                        }}
                      />
                      <label htmlFor="q1">แบบ Manual</label>
                    </div>

                    {/*----------------------------------------- swipstyle selected --------------------------------*/}
                    <div
                      className="styleswipemapselect"
                      style={{
                        display:
                          this.props.swipeMap.swipeMapOpen === true
                            ? "block"
                            : "none",
                      }}
                    >
                      <hr></hr>
                      <p style={{ textAlign: "center" }}>
                        <strong>
                          ช่วงชั้นข้อมูล
                          {this.props.swipeMap.swipeMapOpen === true
                            ? "(ขวา)"
                            : ""}
                        </strong>
                      </p>
                      <input
                        type="radio"
                        name="colorstyleswipe"
                        className="colorstyleswipe"
                        id="q2swipe"
                        defaultValue="NaturalBreaks"
                        defaultChecked
                        onClick={() => {
                          this.ChangStyleColorswipe("NaturalBreaks");
                        }}
                      // disabled={
                      //   this.props.selectedStat.statType !== ""
                      //     ? this.props.selectedStat.statType.TYPE !== null
                      //       ? this.props.selectedStat.statType.TYPE.NAME ===
                      //         "ร้อยละ" &&
                      //         this.props.selectedStat.classify.status &&
                      //         this.props.selectedStat.classify.classifyData[0]
                      //           .isMain === false
                      //         ? true
                      //         : false
                      //       : false
                      //     : false
                      // }
                      />
                      <label htmlFor="q2swipe">แบบ Natural Breaks</label>
                      <br />
                      <input
                        type="radio"
                        name="colorstyleswipe"
                        className="colorstyleswipe"
                        id="q3swipe"
                        defaultValue="EqualInterval"
                        onClick={() => {
                          this.ChangStyleColorswipe("EqualInterval");
                        }}
                      // disabled={
                      //   this.props.selectedStat.statType !== ""
                      //     ? this.props.selectedStat.statType.TYPE !== null
                      //       ? this.props.selectedStat.statType.TYPE.NAME ===
                      //         "ร้อยละ" &&
                      //         this.props.selectedStat.classify.status &&
                      //         this.props.selectedStat.classify.classifyData[0]
                      //           .isMain === false
                      //         ? true
                      //         : false
                      //       : false
                      //     : false
                      // }
                      />
                      <label htmlFor="q3swipe">แบบ Equal Interval</label>
                      <br />
                      <input
                        type="radio"
                        name="colorstyleswipe"
                        className="colorstyleswipe"
                        id="q4swipe"
                        defaultValue="Quantile"
                        onClick={() => {
                          this.ChangStyleColorswipe("Quantile");
                        }}
                      // disabled={
                      //   this.props.selectedStat.statType !== ""
                      //     ? this.props.selectedStat.statType.TYPE !== null
                      //       ? this.props.selectedStat.statType.TYPE.NAME ===
                      //         "ร้อยละ" &&
                      //         this.props.selectedStat.classify.status &&
                      //         this.props.selectedStat.classify.classifyData[0]
                      //           .isMain === false
                      //         ? true
                      //         : false
                      //       : false
                      //     : false
                      // }
                      />
                      <label htmlFor="q4swipe">แบบ Quantile</label>
                      <br />
                      <input
                        type="radio"
                        name="colorstyleswipe"
                        className="colorstyleswipe"
                        id="q5swipe"
                        defaultValue="StandardDeviation"
                        onClick={() => {
                          this.ChangStyleColorswipe("StandardDeviation");
                        }}
                      // disabled={
                      //   this.props.selectedStat.statType !== ""
                      //     ? this.props.selectedStat.statType.TYPE !== null
                      //       ? this.props.selectedStat.statType.TYPE.NAME ===
                      //         "ร้อยละ" &&
                      //         this.props.selectedStat.classify.status &&
                      //         this.props.selectedStat.classify.classifyData[0]
                      //           .isMain === false
                      //         ? true
                      //         : false
                      //       : false
                      //     : false
                      // }
                      />
                      <label htmlFor="q5swipe">แบบ Standard Deviation</label>
                      <br />
                      <div
                        style={{
                          display:
                            this.props.swipeMap.swipeMapOpen === false
                              ? "none"
                              : "block",
                        }}
                      >
                        <input
                          disabled={
                            this.state.Stylemanualswipe ? this.state.Stylemanualswipe.COLOR_CHOROPLETH !== "" ? false : false : true
                          }
                          type="radio"
                          name="colorstyleswipe"
                          className="colorstyleswipe"
                          id="q1swipe"
                          defaultValue="Manaul"
                          onClick={() => {
                            this.ChangStyleColorswipe("Manaul");
                          }}
                        />
                        <label htmlFor="q1swipe">แบบ Manual</label>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                {/* รูปแบบแผนที่สถิติ */}
                <div className="dropdown">
                  <a
                    data-toggle="tooltip"
                    className="bt-open"
                    title="รูปแบบแผนที่สถิติ"
                    onClick={() => {
                      this.handlebtcontrollOpen("stylelayer");
                    }}
                  >
                    <img
                      src={image055}
                      draggable="true"
                      data-bukket-ext-bukket-draggable="true"
                    />
                  </a>
                  <div
                    className={`dropdown-content ${this.state.btcontrollstylelayer ? "active" : ""
                      }`}
                    style={{
                      width: "200px",
                      /*  top: "-5px", */
                      height: "auto",
                      overflowY: "auto",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <strong>แผนที่สถิติ</strong>
                    </p>
                    <input
                      className="mapallstyle"
                      type="radio"
                      name="mapallstyle"
                      id="Choropleth"
                      defaultValue="Choropleth map"
                      defaultChecked={true}
                      // checked={true}
                      onClick={() => {
                        this.ChangStyleLayer("Choropleth map");
                      }}
                    // onChange={() => { this.ChangStyleLayer('Choropleth map') }}
                    />
                    <label htmlFor="Choropleth">แผนที่แถบสี</label>
                    <br />
                    <input
                      className="mapallstyle"
                      type="radio"
                      name="mapallstyle"
                      id="Barchart"
                      defaultValue="Barchart map"
                      onClick={() => {
                        this.ChangStyleLayer("Barchart map");
                      }}
                    // onChange={() => { this.ChangStyleLayer('Barchart map') }}
                    />
                    <label htmlFor="Barchart">แผนที่กราฟแท่ง</label>
                    <br />
                    <input
                      className="mapallstyle"
                      type="radio"
                      name="mapallstyle"
                      id="checkpie"
                      disabled={
                        this.props.selectedStat.statType !== ""
                          ? this.props.selectedStat.statType.TYPE !== null
                            ? this.props.selectedStat.statType.TYPE.NAME ===
                              "ร้อยละ"
                              ? false
                              : /*  checkstylemanual ? false : */ true
                            : true
                          : true
                      }
                      defaultValue="Pie Chart"
                      onClick={() => {
                        this.ChangStyleLayer("Pie Chart");
                      }}
                    // onChange={() => { this.ChangStyleLayer('Pie Chart') }}
                    />
                    <label htmlFor="checkpie">แผนที่กราฟวงกลม</label>
                    <br />
                    <input
                      className="mapallstyle"
                      type="radio"
                      name="mapallstyle"
                      id="Proportion"
                      defaultValue="Proportion Circle"
                      // checked={true}
                      onClick={() => {
                        this.ChangStyleLayer("Proportion Circle");
                      }}
                    // onChange={() => { this.ChangStyleLayer('Proportion Circle') }}
                    />
                    <label htmlFor="Proportion">แผนที่สัดส่วนวงกลม</label>
                    <br />
                  </div>
                </div>
                {/* แผนที่พื้นหลัง */}
                <div className="dropdown">
                  <a
                    data-toggle="tooltip"
                    className="bt-open"
                    title="แผนที่พื้นหลัง"
                    onClick={() => {
                      this.handlebtcontrollOpen("basemap");
                    }}
                  >
                    <img
                      src={image054}
                      draggable="true"
                      data-bukket-ext-bukket-draggable="true"
                    />
                  </a>
                  <div
                    className={`dropdown-content ${this.state.btcontrollbasemap ? "active" : ""
                      }`}
                    style={{ width: "200px", bottom: "-100px" }}
                  >
                    <p className="font-weight-bold">แผนที่แบบเปิด</p>
                    <input
                      type="radio"
                      name="changbasemap"
                      id={0}
                      defaultValue="mapstandard"
                      defaultChecked
                      onChange={() => {
                        this.props.setChangMapView("mapstandard");
                      }}
                    />
                    <label htmlFor={0}>Open Street Map</label>
                    <p className="font-weight-bold">แผนที่แบบปิด</p>
                    <p className="font-weight-bold">Bing Map</p>
                    <input
                      type="radio"
                      name="changbasemap"
                      id={1}
                      defaultValue="bing3"
                      onChange={() => {
                        this.props.setChangMapView("bing3");
                      }}
                    />
                    <label htmlFor={1}>เส้นถนน</label>
                    <br />
                    <input
                      type="radio"
                      name="changbasemap"
                      id={2}
                      defaultValue="bing0"
                      onChange={() => {
                        this.props.setChangMapView("bing0");
                      }}
                    />
                    <label htmlFor={2}>แผนที่ภูมิประเทศ</label>
                    <br />
                    <input
                      type="radio"
                      name="changbasemap"
                      id={3}
                      defaultValue="bing1"
                      onChange={() => {
                        this.props.setChangMapView("bing1");
                      }}
                    />
                    <label htmlFor={3}>แผนที่ดาวเทียม</label>
                    <br />
                    <input
                      type="radio"
                      name="changbasemap"
                      id={4}
                      defaultValue="bing2"
                      onChange={() => {
                        this.props.setChangMapView("bing2");
                      }}
                    />
                    <label htmlFor={4}>แผนที่ผสม</label>
                    <br />
                    <p className="font-weight-bold">Google Map</p>
                    <input
                      type="radio"
                      name="changbasemap"
                      id={5}
                      defaultValue="Google Road Names"
                      onChange={() => {
                        this.props.setChangMapView("Google Road} Names");
                      }}
                    />
                    <label htmlFor={5}>เส้นถนน</label>
                    <br />
                    <input
                      type="radio"
                      name="changbasemap"
                      id={6}
                      defaultValue="Google Road Map"
                      onChange={() => {
                        this.props.setChangMapView("Google Road} Map");
                      }}
                    />
                    <label htmlFor={6}>แผนที่ภูมิประเทศ</label>
                    <br />
                    <input
                      type="radio"
                      name="changbasemap"
                      id={7}
                      defaultValue="Google Satellite"
                      onChange={() => {
                        this.props.setChangMapView("Google Satellite}");
                      }}
                    />
                    <label htmlFor={7}>แผนที่ดาวเทียม</label>
                    <br />
                    <input
                      type="radio"
                      name="changbasemap"
                      id={8}
                      defaultValue="Google Satellite & Roads"
                      onChange={() => {
                        this.props.setChangMapView("Google Satellite} & Roads");
                      }}
                    />
                    <label htmlFor={8}>แผนที่ผสม</label>
                    <br />
                    <p className="font-weight-bold">แผนที่อื่นๆ</p>
                    <input
                      type="radio"
                      name="changbasemap"
                      id={9}
                      defaultValue="OpenstreetMaptoner"
                      onChange={() => {
                        this.props.setChangMapView("OpenstreetMaptoner");
                      }}
                    />
                    <label htmlFor={9}>พื้นหลังสีเทา(ไม่มีพื้นหลัง)</label>
                    <br />
                    <input
                      type="radio"
                      name="changbasemap"
                      id={10}
                      defaultValue="OpenMapdark"
                    />
                    <label htmlFor={10}>พื้นหลังสีดำ</label>
                    <br />
                    <input
                      type="radio"
                      name="changbasemap"
                      id={11}
                      defaultValue="OpenstreetMapwatercolor"
                      onChange={() => {
                        this.props.setChangMapView("OpenstreetMapwatercolor");
                      }}
                    />
                    <label htmlFor={11}>พื้นน้ำ</label>
                    <br />
                    <input
                      type="radio"
                      name="changbasemap"
                      id={12}
                      defaultValue="Thaichote"
                      onChange={() => {
                        this.props.setChangMapView("Thaichote");
                      }}
                    />
                    <label htmlFor={12}>ดาวเทียมไทยโชติ</label>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Controllmap>

          {/* แสดงป้ายกำกับ */}
          <div className="divinfo">
            <div className="info">
              <a
                onClick={async () => {
                  await this.setState({
                    info: !this.state.info,
                  });
                }}
              >
                <SICON
                  name="question circle"
                  size="large"
                  color="blue"
                  title="คำอธิบายข้อมูล"
                />
              </a>

            </div>
          </div>
          {this.state.info ? "" : <Info check={this.state.showtext} oncheck={() => {
            this.setState({ showtext: !this.state.showtext });
            this.state.map.setView(
          new View({
            projection: "EPSG:3857",
            center:this.state.map.getView().getCenter(),
            zoom: this.state.map.getView().getZoom()+0.01,
          })
        );
          }} />}

          {/*--------------------end controllmap------------------------------------------------------------------------*/}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  menutab: state.menuTab,
  mapView: state.mapView,
  drawType: state.drawType,
  selectedCategory: state.selectedCategory,
  selectedStat: state.selectedStat,
  selectedStatFilter: state.selectedStatFilter,
  swipeMap: state.swipeMap,
  provinceMode: state.provinceMode,
  changeStyle: state.changeStyle,
  layersuccess: state.layersuccess,
  nameparam: state.paramurl,
  modal: state.modal,
  selectedMenu: state.selectedMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setChangMapView: (data) => dispatch(changMapView(data)),
  setChangDrawType: (data) => dispatch(changDrawType(data)),
  setSelectCategoryData: (data) => dispatch(selectCategoryData(data)),
  setSelectStatData: (data) => dispatch(selectStatData(data)),
  setSelectFilterStatData: (data) => dispatch(selectFilterStatData(data)),
  setChangSwipeMap: (data) => dispatch(changSwipeMap(data)),
  setChangeStyle: (data) => dispatch(changeStyle(data)),
  setLayersuccess: (data) => dispatch(layersuccess(data)),
  setModal: (data) => dispatch(showModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Maps);
