const filterState = {
  classifyCodelists: [],
  timeYears: [],
  timePeriodTypes: [],
  areas: [],
  agencys: [],
  typeMap: [],
}

// form of payload possible
//  { updateFilter: 'code_lists', dataArrey: [], }
//  { updateFilter: 'years', dataArrey: [], }
//  { updateFilter: 'period_types', dataArrey: [], }
//  { updateFilter: 'areas', dataArrey: [], }
//  { updateFilter: 'agencys', dataArrey: [], }
//  { updateFilter: 'type_map', dataArrey: [], }

const selectedStatFilterReducer = (state = filterState, action) => {
  switch (action.type) {
    case "FILTER_STAT":
      switch (action.payload.updateFilter) {
        case 'code_lists':
          state = {
            classifyCodelists: action.payload.dataArrey,
            timeYears: state.timeYears,
            timePeriodTypes: state.timePeriodTypes,
            areas: state.areas,
            agencys: state.agencys,
            typeMap: state.typeMap,
          }
          return state;
        case 'years':
          state = {
            classifyCodelists: state.classifyCodelists,
            timeYears: action.payload.dataArrey,
            timePeriodTypes: state.timePeriodTypes,
            areas: state.areas,
            agencys: state.agencys,
            typeMap: state.typeMap,
          }
          return state;
        case 'period_types':
          state = {
            classifyCodelists: state.classifyCodelists,
            timeYears: state.timeYears,
            timePeriodTypes: action.payload.dataArrey,
            areas: state.areas,
            agencys: state.agencys,
            typeMap: state.typeMap,
          }
          return state;
        case 'areas':
          state = {
            classifyCodelists: state.classifyCodelists,
            timeYears: state.timeYears,
            timePeriodTypes: state.timePeriodTypes,
            areas: action.payload.dataArrey,
            agencys: state.agencys,
            typeMap: state.typeMap,
          }
          return state;
        case 'agencys':
          state = {
            classifyCodelists: state.classifyCodelists,
            timeYears: state.timeYears,
            timePeriodTypes: state.timePeriodTypes,
            areas: state.areas,
            agencys: action.payload.dataArrey,
            typeMap: state.typeMap,
          }
          return state;
        case 'type_map':
          state = {
            classifyCodelists: state.classifyCodelists,
            timeYears: state.timeYears,
            timePeriodTypes: state.timePeriodTypes,
            areas: state.areas,
            agencys: state.agencys,
            typeMap: action.payload.dataArrey,
          }
          return state;
        default:
          return state;
      }
    default:
      return state;
  }
}
export default selectedStatFilterReducer; 