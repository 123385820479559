import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import allReducer from './redux/reducers'
import Routes from './Routes';

//------------------- create STORE -------------------
const store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

//------------------- CONSOLE LOG SEE VALUE CHANGE IN REDUX -------------------
// store.subscribe(() => { console.log("state in redux-store has change :", store.getState()); });

//------------------- TEST DISPATCH SET DATA TO REDUX -------------------
// store.dispatch(selectCategoryData({ id: 'main_cate_id' })

ReactDOM.render(
    <Provider store={store}>
        {/* <React.StrictMode> */}
        <Router>
            <Routes />
        </Router>
        {/* </React.StrictMode> */}
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
