import React, { Component } from 'react';
import '../css/modal.css';
import { showModal } from '../redux/actions';
import { connect, } from 'react-redux';
import { Loader, Icon } from 'semantic-ui-react'

class Modalbug extends Component {

    componentDidUpdate = () => {
        if (this.props.status) {
            let modal = document.querySelector('#modal-container');
            modal.classList.remove('one', 'out');
            modal.classList.add('one');
        } else {

            let modal = document.querySelector('#modal-container');
            modal.classList.remove('one', 'out');
            modal.classList.add('one', 'out');

        }

    }
    render() {

        return (
            <div>
                <div id="modal-container" className={``}>
                    <div className="modal-background">
                        <div className="modaltest">
                            {this.props.modal.icon === 'loading'
                                ? <Loader active inline='centered' size='big' />
                                : this.props.modal.icon === 'success'
                                    ? <Icon name='check circle' size='huge' color='green' />
                                    : this.props.modal.icon === 'fail'
                                        ? <Icon name='times circle' size='huge' color='red' />
                                        : this.props.modal.icon === 'warning'
                                            ? <Icon name='warning circle' size='huge' color='orange' />
                                            : this.props.modal.icon === 'question'
                                                ? <Icon name='question circle' size='huge' color='blue' />
                                                : this.props.modal.icon === 'info'
                                                    ? <Icon name='question circle' size='huge' color='blue' />
                                                    : ''}
                            <h2>{this.props.title}</h2>
                            <p>{this.props.message}</p>
                            {this.props.modal.button ? this.props.modal.button.map((item, index) => {
                                return (
                                    <button
                                        key={index}
                                        className='modal-button'
                                        style={{ width: item.width, backgroundColor: item.bgcolor }}
                                        onClick={() => item.functionIn('this is a text from modal !')}>
                                        {item.text}
                                    </button>
                                )
                            }) : ''}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    modal: state.modal,

})

const mapDispatchToProps = (dispatch) => ({
    setModal: (data) => dispatch(showModal(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Modalbug);
