
const drawTypeState = {
  namedraw: 'LineString'
}
const drawTypeReducer = (state = drawTypeState, action) => {
  switch (action.type) {
      case "CHANGE_DRAW_TYPE":
          state = {
              namedraw: action.payload,
          }
          return state;
      default:
          return state;
  }
}
export default drawTypeReducer; 