import React, { Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
/* pages */
import { NotFoundPage } from './pages';
import Nso from './Action/Nso';
import test from './componentnso/TabbarNew.js';

const PathName77Provinces = [
  // ---------- ภาคเหนือ (Northern)
  { nameTH: 'จังหวัดเชียงใหม่', nameEN: 'chiangmai', nameENfull: 'chiang-mai', },
  { nameTH: 'จังหวัดเชียงราย', nameEN: 'chiangrai', nameENfull: 'chiang-rai', },
  { nameTH: 'จังหวัดลำปาง', nameEN: 'lampang', },
  { nameTH: 'จังหวัดลำพูน', nameEN: 'lamphun', },
  { nameTH: 'จังหวัดแม่ฮ่องสอน', nameEN: 'maehson', nameENfull: 'mae-hong-son', },
  { nameTH: 'จังหวัดน่าน', nameEN: 'nan', },
  { nameTH: 'จังหวัดพะเยา', nameEN: 'phayao', },
  { nameTH: 'จังหวัดแพร่', nameEN: 'phrae', },
  { nameTH: 'จังหวัดอุตรดิตถ์', nameEN: 'uttaradit', },
  // ---------- ภาคตะวันออกเฉียงเหนือ (Northeastern)
  { nameTH: 'จังหวัดกาฬสินธุ์', nameEN: 'kalasin', },
  { nameTH: 'จังหวัดขอนแก่น', nameEN: 'khonkaen', nameENfull: 'khon-kaen', },
  { nameTH: 'จังหวัดชัยภูมิ', nameEN: 'chaiyaphum', },
  { nameTH: 'จังหวัดนครพนม', nameEN: 'nkphanom', nameENfull: 'nakhon-phanom', },
  { nameTH: 'จังหวัดนครราชสีมา', nameEN: 'nkrat', nameENfull: 'nakhon-ratchasima', },
  { nameTH: 'จังหวัดบึงกาฬ', nameEN: 'bungkan', nameENfull: 'bueng-kan', },
  { nameTH: 'จังหวัดบุรีรัมย์', nameEN: 'buriram', },
  { nameTH: 'จังหวัดมหาสารคาม', nameEN: 'mahasarakham', nameENfull: 'maha-sarakham', },
  { nameTH: 'จังหวัดมุกดาหาร', nameEN: 'mukdahan', },
  { nameTH: 'จังหวัดยโสธร', nameEN: 'yasothon', },
  { nameTH: 'จังหวัดร้อยเอ็ด', nameEN: 'roiet', nameENfull: 'roi-et', },
  { nameTH: 'จังหวัดเลย', nameEN: 'loei', },
  { nameTH: 'จังหวัดสกลนคร', nameEN: 'sakonnk', nameENfull: 'sakon-nakhon', },
  { nameTH: 'จังหวัดสุรินทร์', nameEN: 'surin', },
  { nameTH: 'จังหวัดศรีสะเกษ', nameEN: 'sisaket', },
  { nameTH: 'จังหวัดหนองคาย', nameEN: 'nongkhai', nameENfull: 'nong-khai', },
  { nameTH: 'จังหวัดหนองบัวลำภู', nameEN: 'nongbualamphu', nameENfull: 'nong-bua-lamphu', },
  { nameTH: 'จังหวัดอุดรธานี', nameEN: 'udon', nameENfull: 'udon-thani', },
  { nameTH: 'จังหวัดอุบลราชธานี', nameEN: 'ubon', nameENfull: 'ubon-ratchathani', },
  { nameTH: 'จังหวัดอำนาจเจริญ', nameEN: 'amnatchr', nameENfull: 'amnat-charoen', },
  // ---------- ภาคกลาง (Central)
  { nameTH: 'กรุงเทพมหานคร', nameEN: 'bangkok', },
  { nameTH: 'จังหวัดกำแพงเพชร', nameEN: 'kpphet', nameENfull: 'kamphaeng-phet', },
  { nameTH: 'จังหวัดชัยนาท', nameEN: 'chainat', nameENfull: 'chai-nat', },
  { nameTH: 'จังหวัดนครนายก', nameEN: 'nknayok', nameENfull: 'nakhon-nayok', },
  { nameTH: 'จังหวัดนครปฐม', nameEN: 'nkpathom', nameENfull: 'nakhon-pathom', },
  { nameTH: 'จังหวัดนครสวรรค์', nameEN: 'nksawan', nameENfull: 'nakhon-sawan', },
  { nameTH: 'จังหวัดนนทบุรี', nameEN: 'nontburi', nameENfull: 'nonthaburi', },
  { nameTH: 'จังหวัดปทุมธานี', nameEN: 'pathumthani', nameENfull: 'pathum-thani', },
  { nameTH: 'จังหวัดพระนครศรีอยุธยา', nameEN: 'ayuttaya', nameENfull: 'phra-nakhon-si-ayutthaya', },
  { nameTH: 'จังหวัดพิจิตร', nameEN: 'phichit', },
  { nameTH: 'จังหวัดพิษณุโลก', nameEN: 'phitsanulok', },
  { nameTH: 'จังหวัดเพชรบูรณ์', nameEN: 'phchabun', nameENfull: 'phetchabun', },
  { nameTH: 'จังหวัดลพบุรี', nameEN: 'lopburi', },
  { nameTH: 'จังหวัดสมุทรปราการ', nameEN: 'smprakan', nameENfull: 'samut-prakan', },
  { nameTH: 'จังหวัดสมุทรสงคราม', nameEN: 'samutsongkhram', nameENfull: 'samut-songkhram', },
  { nameTH: 'จังหวัดสมุทรสาคร', nameEN: 'smsakhon', nameENfull: 'samut-sakhon', },
  { nameTH: 'จังหวัดสิงห์บุรี', nameEN: 'singburi', nameENfull: 'sing-buri', },
  { nameTH: 'จังหวัดสุโขทัย', nameEN: 'sukhothai', },
  { nameTH: 'จังหวัดสุพรรณบุรี', nameEN: 'suphan', nameENfull: 'suphan-buri', },
  { nameTH: 'จังหวัดสระบุรี', nameEN: 'saraburi', },
  { nameTH: 'จังหวัดอ่างทอง', nameEN: 'angthong', nameENfull: 'ang-thong', },
  { nameTH: 'จังหวัดอุทัยธานี', nameEN: 'uthai', nameENfull: 'uthai-thani', },
  // ---------- ภาคตะวันออก (Eastern)
  { nameTH: 'จังหวัดจันทบุรี', nameEN: 'chanthaburi', },
  { nameTH: 'จังหวัดฉะเชิงเทรา', nameEN: 'chchsao', nameENfull: 'chachoengsao', },
  { nameTH: 'จังหวัดชลบุรี', nameEN: 'chonburi', },
  { nameTH: 'จังหวัดตราด', nameEN: 'trat', },
  { nameTH: 'จังหวัดปราจีนบุรี', nameEN: 'pchburi', nameENfull: 'prachinburi', },
  { nameTH: 'จังหวัดระยอง', nameEN: 'rayong', },
  { nameTH: 'จังหวัดสระแก้ว', nameEN: 'sakaeo', nameENfull: 'sa-kaeo', },
  // ---------- ภาคตะวันตก (Western)
  { nameTH: 'จังหวัดกาญจนบุรี', nameEN: 'kanchanaburi', },
  { nameTH: 'จังหวัดตาก', nameEN: 'tak', },
  { nameTH: 'จังหวัดประจวบคีรีขันธ์', nameEN: 'pchkkhan', nameENfull: 'prachuap-khiri-khan', },
  { nameTH: 'จังหวัดเพชรบุรี', nameEN: 'phetburi', nameENfull: 'phetchaburi', },
  { nameTH: 'จังหวัดราชบุรี', nameEN: 'ratburi', nameENfull: 'ratchaburi', },
  // ---------- ภาคใต้ (Southern)
  { nameTH: 'จังหวัดกระบี่', nameEN: 'krabi', },
  { nameTH: 'จังหวัดชุมพร', nameEN: 'chumphon', },
  { nameTH: 'จังหวัดตรัง', nameEN: 'trang', },
  { nameTH: 'จังหวัดนครศรีธรรมราช', nameEN: 'nksitham', nameENfull: 'nakhon-si-thammarat', },
  { nameTH: 'จังหวัดนราธิวาส', nameEN: 'narathiwat', },
  { nameTH: 'จังหวัดปัตตานี', nameEN: 'pattani', },
  { nameTH: 'จังหวัดพังงา', nameEN: 'phangnga', nameENfull: 'phang-nga', },
  { nameTH: 'จังหวัดพัทลุง', nameEN: 'phatlung', nameENfull: 'phatthalung', },
  { nameTH: 'จังหวัดภูเก็ต', nameEN: 'phuket', },
  { nameTH: 'จังหวัดระนอง', nameEN: 'ranong', },
  { nameTH: 'จังหวัดสตูล', nameEN: 'satun', },
  { nameTH: 'จังหวัดสงขลา', nameEN: 'songkhla', },
  { nameTH: 'จังหวัดสุราษฎร์ธานี', nameEN: 'surat', nameENfull: 'surat-thani', },
  { nameTH: 'จังหวัดยะลา', nameEN: 'yala', },
]

class Routes extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Nso} />
        <Route exact path="/test" component={test} />
        <Route exact path="/not-found" component={NotFoundPage} />
        {
          PathName77Provinces.map((item, index) => (
            <Route exact key={index} path={`/${item.nameEN}`} component={Nso} />
          ))
        }
        <Redirect to="/not-found" />
      </Switch>
    );
  }
}

export default Routes;
