
const menuTabState = {
  showchart: false,
  showtable: false,
  showdashboard: false,
}

const menuTabReducer = (state = menuTabState, action) => {
  switch (action.type) {
      case "close":
          state = {
              showchart: false,
              showtable: false,
              showdashboard: false
          }
          return state;
      case "showchart":
          state = {
              showchart: true,
              showtable: false,
              showdashboard: false
          }
          return state;
      case "showtable":
          state = {
              showchart: false,
              showtable: true,
              showdashboard: false
          }
          return state;
      case "showdashboard":
          state = {
              showchart: false,
              showtable: false,
              showdashboard: true
          }
          return state;
      default:
          return state;
  }
}
export default menuTabReducer; 