import React, { Component } from "react";
import "../css/slidebar.css";
import styled from "styled-components";
import { Menu1, Menu2, Menu3, Menu4, Menu5 } from '../componentslidebar'
import { MenuSwipe1, MenuSwipe2, MenuSwipe3, MenuSwipe4, MenuSwipe5 } from '../componentslidebarSwipe'
import { connect, } from 'react-redux';
import { changMapView, changDrawType, selectStatData, selectCategoryData, selectFilterStatData, changSwipeMap, changeProvinceMode, selectedMenu, changparamurl } from '../redux/actions'
import $ from 'jquery';

const Button = styled.button`
  cursor: pointer;
  background: #63c4e1;
  width: 20%;
  height: 65px;
  display: inline-block;
  color: #fff;
  padding-bottom: 40px;
  border: none;
  overflow: hidden;
  &:hover {
    background-color: whitesmoke;
    color: black;
  }
  &.active {
    color: black;
    background-color: white;
  }
`;
const MenuButton = styled.button`
  cursor: pointer;
  height: 45px;
  width: 50%;
  display: inline-block;
  border: none;
  color: #fff;
  background: #63c4e1;
  padding-bottom: 40px;
  &:hover {
    background-color: whitesmoke;
    color: black;
    border-bottom: 1px solid white;
  }
  &.active {
    color: black;
    background-color: white;
    border-bottom: 5px solid white;
  }
`;
const Btopen = styled.button`
  position: absolute;
  z-index: 9;
  /*background:url(https://image.flaticon.com/icons/svg/617/617819.svg) no-repeat;*/
  background-color: #67a6fc;
  cursor: pointer;
  width: 35px;
  height: 70px;
  border: none;
  top: 50%;
  right:-35px;
  transition: 1s;
  i {
    transition: 1s;
  }
  &:hover {
    i {
      margin-right: 10px;
      transform: rotate(180deg);
    }
  }
`;

class Slidebarnso extends Component {

  componentDidMount() {
    /*--------------------------ฟังชั่นย่อขยายเมนูสไลดซ้ายมือ--------------------------*/
    var dragging = false;
    var dragbar = document.getElementById('dragbar');
    var idsidenav = $('#sidenav');
    function resizewidth(ex) {
      var size = ex.pageX;
      var screen = window.innerWidth * 20 / 100;
      if (size < 350) {
        idsidenav.css("width", screen);
        idsidenav.css("left", -screen);
      } else {
        idsidenav.css("width", ex.pageX + 2);
        idsidenav.css("left", -ex.pageX);
      }
      /*console.log("move");*/
    }
    dragbar.addEventListener('mousedown', function (e) {
      /*console.log("mousedown")*/
      e.preventDefault();
      dragging = true;
      document.addEventListener('mousemove', resizewidth);
      idsidenav.css('transition', '0s');
    })
    document.addEventListener('mouseup', function (e) {
      if (dragging) {
        dragging = false;
        /*console.log(dragging)*/
        document.removeEventListener("mousemove", resizewidth);
        /*console.log("mouseup")*/
        idsidenav.css('transition', '0.5s all linear');

      }
    })
    /*------------------------ฟังชันเปิดปิด เมนู--------------------------------------*/
    var opencheck = false;
    const btslide = document.getElementById('open');
    btslide.addEventListener('click', function () {
      var slide = document.getElementById('sidenav');
      if (opencheck === false) {
        slide.classList.add("active");
        opencheck = true;
      } else {
        slide.classList.remove("active");
        opencheck = false;
      }

    })

  }
  closemenu = () => {
    this.setState({
      menu1ProvinceMode: false,
      menu2MainMode: false,

      btmenu1: false,
      btmenu2: false,
      btmenu3: false,
      btmenu4: false,
      btmenu5: false,
    });
  };
  closemenuSwipe = () => {
    this.setState({
      menu1ProvinceMode: false,
      menu2MainMode: false,

      btmenuSwipe1: false,
      btmenuSwipe2: false,
      btmenuSwipe3: false,
      btmenuSwipe4: false,
      btmenuSwipe5: false,
    });
  };
  closebutton = () => {
    this.setState({
      btmenu1: false,
      btmenu2: false,
      btmenu3: false,
      btmenu4: false,
      btmenu5: false,
    });
    if (this.props.pnso && this.state.menu1ProvinceMode) {
      this.props.setChangeProvinceMode({ mode: 'change', provinceModeOpen: true, })
    }
  };
  closebuttonSwipe = () => {
    this.setState({
      btmenuSwipe1: false,
      btmenuSwipe2: false,
      btmenuSwipe3: false,
      btmenuSwipe4: false,
      btmenuSwipe5: false,
    });
    if (this.props.pnso && this.state.menu1ProvinceMode) {
      this.props.setChangeProvinceMode({ mode: 'change', provinceModeOpen: true, })
    }
  };

  state = {
    menu1ProvinceMode: true,
    menu2MainMode: false,

    btmenu1: true,
    btmenu2: false,
    btmenu3: false,
    btmenu4: false,
    btmenu5: false,
    btmenuSwipe1: true,
    btmenuSwipe2: false,
    btmenuSwipe3: false,
    btmenuSwipe4: false,
    btmenuSwipe5: false,
    contentmenu: "รายการข้อมูล",


  }

  render() {

    const { fullscreenSlidebar, swipeMap } = this.props

    return (
      <div>
        <div className={`sidenav ${fullscreenSlidebar ? 'sidenavfullscreen' : ''}`} id="sidenav">
          <div id="dragbar"></div>
          <Btopen className="" id="open">
            <i className="fa fa-chevron-right fa-2x text-light"></i>
          </Btopen>
          <div style={{ height: `${swipeMap.swipeMapOpen ? '50%' : '100%'}` }}>
            {this.props.pnso && (
              <div
                className="tab d-flex justify-content-around "
                style={{ border: "0px solid #155356" }}
              >
                <MenuButton data-toggle="tooltip" data-placement="bottom" title="ข้อมูลของจังหวัด"
                  className={`menubutton ${this.state.menu1ProvinceMode ? "active" : ""}`}
                  onClick={async () => {
                    this.props.setSelectedMenu({ namemenu: 'ข้อมูลเฉพาะจังหวัด', typemap: this.props.paramurl.changwat })
                    this.props.setChangeProvinceMode({ mode: 'change', provinceModeOpen: true, })
                    await this.closemenu();
                    await this.closemenuSwipe()
                    this.setState({ menu1ProvinceMode: true, btmenu1: true, btmenuSwipe1: true });
                    var path = window.location.pathname.split('/');
                    this.props.setChangparamurl(path[1],"","")
                  }}
                >
                  ข้อมูลของจังหวัด
              </MenuButton>
                <MenuButton data-toggle="tooltip" data-placement="bottom" title="ข้อมูลภาพรวม"
                  className={`menubutton ${this.state.menu2MainMode ? "active" : ""}`}
                  onClick={async () => {
                    this.props.setSelectedMenu({ namemenu: 'ข้อมูลภาพรวม', typemap: 'thailand' })
                    this.props.setChangeProvinceMode({ mode: 'change', provinceModeOpen: false, })
                    await this.closemenu();
                    await this.closemenuSwipe()
                    this.setState({ menu2MainMode: true, btmenu1: true, btmenuSwipe1: true });
                    this.props.setChangparamurl("","","")

                  }}
                >
                  ข้อมูลภาพรวม
              </MenuButton>

              </div>
            )}
            <div className="tab d-flex justify-content-around">
              <Button data-toggle="tooltip" data-placement="bottom" title="รายการสถิติ"
                className={`${this.state.btmenu1 ? "active" : ""}`}
                onClick={() => {
                  this.closebutton();
                  this.setState({ btmenu1: true, contentmenu: "รายการข้อมูล" });
                }}
              >
                รายการสถิติ
            </Button>
              <Button data-toggle="tooltip" data-placement="bottom" title="รายการสถิติยอดนิยม"
                className={`${this.state.btmenu2 ? "active" : ""}`}
                onClick={() => {
                  this.closebutton();
                  this.setState({ btmenu2: true, contentmenu: "รายการยอดนิยม" });
                }}
              >
                รายการสถิติยอดนิยม
            </Button>
              <Button data-toggle="tooltip" data-placement="bottom" title="รายการสถิติตามหน่วยงาน"
                className={`${this.state.btmenu3 ? "active" : ""}`}
                onClick={() => {
                  this.closebutton();
                  this.setState({ btmenu3: true, contentmenu: "รายการตามหน่วยงาน" });
                }}
              >
                รายการสถิติตามหน่วยงาน
            </Button>
              <Button data-toggle="tooltip" data-placement="bottom" title="รายการสถิติตามประเภทค่าสถิติ"
                className={`${this.state.btmenu4 ? "active" : ""}`}
                onClick={() => {
                  this.closebutton();
                  this.setState({ btmenu4: true, contentmenu: "รายการตามประเภทค่าสถิติ" });
                }}
              >
                รายการสถิติตามคำนำหน้าชื่อ
            </Button>
              <Button data-toggle="tooltip" data-placement="bottom" title="ค้นหารายการสถิติ"
                className={`${this.state.btmenu5 ? "active" : ""}`}
                onClick={() => {
                  this.closebutton();
                  this.setState({ btmenu5: true, contentmenu: "ค้นหารายการ" });
                }}
              >
                ค้นหารายการสถิติ
            </Button>
            </div>
            <div className="sidecontent">
              {/* <Contentslidebar content={this.state.contentmenu} /> */}
              {this.state.btmenu1 ? <Menu1 showmap={this.props.showmap} pathSchema={this.props.pathSchema} /> : ''}
              {this.state.btmenu2 ? <Menu2 showmap={this.props.showmap} /> : ''}
              {this.state.btmenu3 ? <Menu3 showmap={this.props.showmap} /> : ''}
              {this.state.btmenu4 ? <Menu4 showmap={this.props.showmap} /> : ''}
              {this.state.btmenu5 ? <Menu5 showmap={this.props.showmap} maplayerdeleate={this.props.maplayerdeleate} /> : ''}

            </div>
          </div>
          {swipeMap.swipeMapOpen &&
            <div style={{ height: '50%', paddingTop: '30px', paddingBottom: '40px' }}>
              <div className="tab d-flex justify-content-around">
                <Button data-toggle="tooltip" data-placement="bottom" title="รายการสถิติ"
                  className={`${this.state.btmenuSwipe1 ? "active" : ""}`}
                  onClick={() => {
                    this.closebuttonSwipe();
                    this.setState({ btmenuSwipe1: true, contentmenu: "รายการข้อมูล" });
                  }}
                > รายการสถิติ </Button>
                <Button data-toggle="tooltip" data-placement="bottom" title="รายการสถิติยอดนิยม"
                  className={`${this.state.btmenuSwipe2 ? "active" : ""}`}
                  onClick={() => {
                    this.closebuttonSwipe();
                    this.setState({ btmenuSwipe2: true, contentmenu: "รายการยอดนิยม" });
                  }}
                > รายการสถิติยอดนิยม </Button>
                <Button data-toggle="tooltip" data-placement="bottom" title="รายการสถิติตามหน่วยงาน"
                  className={`${this.state.btmenuSwipe3 ? "active" : ""}`}
                  onClick={() => {
                    this.closebuttonSwipe();
                    this.setState({ btmenuSwipe3: true, contentmenu: "รายการตามหน่วยงาน" });
                  }}
                > รายการสถิติตามหน่วยงาน </Button>
                <Button data-toggle="tooltip" data-placement="bottom" title="รายการสถิติตามประเภทค่าสถิติ"
                  className={`${this.state.btmenuSwipe4 ? "active" : ""}`}
                  onClick={() => {
                    this.closebuttonSwipe();
                    this.setState({ btmenuSwipe4: true, contentmenu: "รายการตามประเภทค่าสถิติ" });
                  }}
                > รายการสถิติตามคำนำหน้าชื่อ </Button>
                <Button data-toggle="tooltip" data-placement="bottom" title="ค้นหารายการสถิติ"
                  className={`${this.state.btmenuSwipe5 ? "active" : ""}`}
                  onClick={() => {
                    this.closebuttonSwipe();
                    this.setState({ btmenuSwipe5: true, contentmenu: "ค้นหารายการ" });
                  }}
                > ค้นหารายการสถิติ </Button>
              </div>
              <div className="sidecontent">
                {/* <Contentslidebar content={this.state.contentmenu} /> */}
                {this.state.btmenuSwipe1 ? <MenuSwipe1 showswichmap={this.props.showswichmap} pathSchema={this.props.pathSchema} /> : ''}
                {this.state.btmenuSwipe2 ? <MenuSwipe2 showswichmap={this.props.showswichmap} /> : ''}
                {this.state.btmenuSwipe3 ? <MenuSwipe3 showswichmap={this.props.showswichmap} /> : ''}
                {this.state.btmenuSwipe4 ? <MenuSwipe4 showswichmap={this.props.showswichmap} /> : ''}
                {this.state.btmenuSwipe5 ? <MenuSwipe5 showswichmap={this.props.showswichmap} /> : ''}
              </div>
            </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paramurl: state.paramurl,
  menutab: state.menuTab,
  mapView: state.mapView,
  drawType: state.drawType,
  selectedCategory: state.selectedCategory,
  selectedStat: state.selectedStat,
  selectedStatFilter: state.selectedStatFilter,
  swipeMap: state.swipeMap,
  provinceMode: state.provinceMode,
  selectedMenu: state.selectedMenu,
})

const mapDispatchToProps = (dispatch) => ({
  setChangMapView: (data) => dispatch(changMapView(data)),
  setChangDrawType: (data) => dispatch(changDrawType(data)),
  setSelectCategoryData: (data) => dispatch(selectCategoryData(data)),
  setSelectStatData: (data) => dispatch(selectStatData(data)),
  setSelectFilterStatData: (data) => dispatch(selectFilterStatData(data)),
  setChangSwipeMap: (data) => dispatch(changSwipeMap(data)),
  setChangeProvinceMode: (data) => dispatch(changeProvinceMode(data)),
  setSelectedMenu: (data) => dispatch(selectedMenu(data)),
  setChangparamurl: (url,center) => dispatch(changparamurl(url,center)),

})

export default connect(mapStateToProps, mapDispatchToProps)(Slidebarnso);