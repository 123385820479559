import React, { Component } from 'react';
import axios from "axios";
import { Radio } from 'antd';
import { changMapView, changDrawType, selectStatData, selectCategoryData, selectFilterStatData, showModal, layersuccess } from '../redux/actions';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import config from '../config'

class DataListByType extends Component {
  state = {
    listData: [],
    tokenWithBearer: '',
    seatchValue: '',
    listResult: [],
    Id_stat: '',
    Stat_list_id: '',
    Stat_list_name: '',

    areaArrayState: [],
  }
  componentDidMount = () => {
    this.handleListType()
    this.handleNoSelect()
  }
  handleListType = () => {
    let list = [];
    axios({
      method: 'post',
      url: config.API_URL + '/api/gis/gisnsounit',
      headers: {
        //'Authorization': tokenWithBearer,
        'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
      }
    }).then((res) => {
      console.log('res after get stat type >>>', res)
      res.data.map((data, inx) => {
        list.push({ key: data.ID, value: data.ID, text: data.NAME })
      });
      this.setState({
        listData: list
      });
    }).catch((error) => {
      console.error("error get cate >>>", error)
    })
  }
  handleSearchValue = (event) => {
    this.setState({ selectedType: event })
    if (event === null || event === '') {
      this.handleNoSelect()
      return
    }
    let search = new FormData();
    search.append('Search', event);
    axios({
      method: 'POST',
      url: config.API_URL + '/api/gis/gisnsocatestatunit',
      headers: {
        //'Authorization': this.state.tokenWithBearer,
        'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
      },
      data: search,
      config: { headers: { 'Content-Type': 'multipart/form-data', } }
    }).then((res) => {
      if (res.data !== 0 && event !== null) {
        this.setState({
          listResult: res.data
        });
      } else {
        this.setState({
          listResult: []
        });
      }
    }).catch((err) => {
      console.error(err);
    });
  }
  handleNoSelect = () => {
    axios({
      method: 'post',
      url: config.API_URL + '/api/gis/gisnsocatestatsearch',
      headers: {
        //'Authorization': tokenWithBearer,
        'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
      }
    }).then((res) => {
      this.setState({
        listResult: res.data
      });
    }).catch((error) => {
      console.error("error get cate >>>", error)
    })
  }

  // ---------- เลือกและ getdata stat ----------
  handleSelectStat = (selectedStatAllData) => {
    console.log('selectedStatAllData >>>', selectedStatAllData)
    try {
      this.props.setModal({
        modalAction: 'open',
        title: 'กำลังโหลดข้อมูล... กรุณารอสักครู่...',
        icon: 'loading',

      })
      let selectedStatID = selectedStatAllData.ID
      this.setState({ selectedStatID: selectedStatAllData.ID })
      this.props.setSelectStatData({
        statName: selectedStatAllData.STAT_LIST_NAME,
        statId: selectedStatAllData.ID,
        statCodeId: selectedStatAllData.STAT_LIST_ID,
      })

      // -------------------- get years
      var promiseYear = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisfrequencycontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat year filter >>>", res)
          let responseData = res.data
          let yearArray = []
          let periodTypesArray = []
          let periodTypesQArray = []
          let periodTypesMArray = []
          let responseDataQ = responseData.freq_q
          let responseDataM = responseData.freq_m
          if (responseDataQ) { responseDataQ.forEach(item => { periodTypesQArray.push({ id: item, label: item, allRawData: item, }) })}
          if (responseDataM) { responseDataM.forEach(item => { periodTypesMArray.push({ id: item, label: item, allRawData: item, }) }) }
          responseData.year.forEach((item) => { yearArray.push({ id: item, label: item, allRawData: item, }) })
          responseData.freq.forEach((item) => {
            if (item.FREQ_NAME === 'รายไตรมาส' && responseDataQ) {
              periodTypesArray.push({ id: item.FREQ_ID, label: item.FREQ_NAME, child: periodTypesQArray, allRawData: item, })
            } else if (item.FREQ_NAME === 'รายเดือน' && responseDataM) {
              periodTypesArray.push({ id: item.FREQ_ID, label: item.FREQ_NAME, child: periodTypesMArray, allRawData: item, })
            } else {
              periodTypesArray.push({ id: item.FREQ_ID, label: item.FREQ_NAME, child: [], allRawData: item, })
            }
          })
          this.props.setSelectFilterStatData({ updateFilter: 'years', dataArrey: yearArray, })
          this.props.setSelectFilterStatData({ updateFilter: 'period_types', dataArrey: periodTypesArray, })

          // ทำการ default ค่าเริ่มต้นลง redux
          if (periodTypesArray.length === 0) {
            this.props.setSelectStatData({ time: { year: yearArray[0], periodType: periodTypesArray[0], periodSelect: '' } })
          } else {
            this.props.setSelectStatData({ time: { year: yearArray[0], periodType: periodTypesArray[0], periodSelect: periodTypesArray[0].child[0] } })
          }
          resolve('promise Year ready')
        }).catch((error) => {
          console.error("error get stat year filter >>>", error)
          reject(Error('promise Year fail'))
        })
      });

      // -------------------- get area
      var promiseArea = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisareacontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat area filter >>>", res)
          let responseData = res.data
          let areaArray = []
          this.setState({ areaArrayState: responseData, })
          responseData.map((item) => {
            areaArray.push({ id: item.ID, label: item.NAME, code: item.CODE_NAME, allRawData: item, })
          })
          this.props.setSelectFilterStatData({ updateFilter: 'areas', dataArrey: areaArray, })

          // ทำการ default ค่าเริ่มต้นลง redux
          this.props.setSelectStatData({ area: areaArray[0] })
          resolve('promise Area ready')
        }).catch((error) => {
          console.error("error get stat area filter >>>", error)
          reject(Error('promise Area fail'))
        })
      });

      // -------------------- get typemap
      var promiseTypeMap = new Promise((resolve, reject) => {
        promiseArea.then((value) => {
          console.log('value after promiseArea >>>', value)
          if (value === 'promise Area ready') {
            // จัดรูปแบบของ ข้อมูลระดับพื้นที่ให้เป็น array ก่อนทำการ foreeach ไปยิง api เอาข้อมูล
            let areaArray = this.state.areaArrayState
            let areaNewArray = []
            let typeMapPromiseArray = []
            let typeMapArray = []
            areaArray.forEach((itemArea) => {
              let typeMapName =
                itemArea.CODE_NAME === 'AR_CL_REG'
                  ? 'reg'
                  : itemArea.CODE_NAME === 'AR_CL_CWT'
                    ? 'prov'
                    : itemArea.CODE_NAME === 'AR_CL_AMP'
                      ? 'dist'
                      : itemArea.CODE_NAME === 'AR_CL_TAM'
                        ? 'subdist'
                        : itemArea.CODE_NAME === 'AR_CL_SAO'
                          ? 'abt'
                          : itemArea.CODE_NAME === 'AR_CL_MUNI'
                            ? 'muni'
                            : ''
              areaNewArray.push({ typeMapName: typeMapName, id: itemArea.ID, label: itemArea.NAME, code: itemArea.CODE_NAME, allRawData: itemArea, })
            })
            areaNewArray.forEach((itemArea) => {
              let promiseEachTypeMap = new Promise((subresolve, subreject) => {
                axios({
                  method: 'post',
                  url: config.API_URL + '/api/geo/getmapdominance',
                  headers: {
                    'Authorization': 'Bearer ' + this.props.loginData.token,
                    'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
                  },
                  data: { name_check: itemArea.typeMapName },
                  config: { headers: { 'Content-Type': 'multipart/form-data', } }
                }).then((res) => {
                  console.log("response get stat typemap filter >>>", itemArea.typeMapName, ' response >>>', res)
                  let responseData = res.data
                  let typeMapEachArray = []
                  responseData.map((item) => {
                    typeMapEachArray.push({ id: item.ID, label: item.NAME, code: item.TABLE_NAME, allRawData: item, })
                  })
                  typeMapArray.push({ id: itemArea.id, label: itemArea.label, code: itemArea.code, child: typeMapEachArray, allRawData: itemArea.allRawData, })

                  // ทำการ default ค่าเริ่มต้นลง redux
                  if (this.props.selectedStat.area.id === itemArea.id) {
                    this.props.setSelectStatData({ areaTypeMap: typeMapEachArray[0] })
                  }
                  subresolve('promise typemap', itemArea.typeMapName, 'ready')
                }).catch((error) => {
                  console.error("error get stat typemap filter >>>", error)
                  subreject(Error('promise typemap fail'))
                })
              })
              typeMapPromiseArray.push(promiseEachTypeMap)
            })

            Promise.all(typeMapPromiseArray)
              .then((values) => {
                console.log('values in promise typemap >>>', values)
                this.props.setSelectFilterStatData({ updateFilter: 'type_map', dataArrey: typeMapArray, })
                resolve('promise typemap ready')
              })
              .catch((errors) => {
                console.error('error in promise typemap >>>', errors)
                reject(Error('promise typemap fail'))
              })

          } else {
            reject(Error('promise typemap fail'))
          }
        });
      })

      // -------------------- get StatType
      var promiseStatType = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/stattype',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: { 'cat_sl_id': selectedStatID, },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat promiseStatType filter >>>", res)
          let responseData = res.data
          if (responseData) {
            this.props.setSelectStatData({
              statType: res.data,
            })
          }
          resolve('promise promiseStatType ready')
        }).catch((error) => {
          console.error("error get stat promiseStatType filter >>>", error)
          reject(Error('promise promiseStatType fail'))
        })
      });

      // -------------------- get codelist
      var promiseCodelist = new Promise((resolve, reject) => {
        promiseStatType.then((value) => {
          console.log('value after promiseStatType >>>', value)
          if (value === 'promise promiseStatType ready') {
            axios({
              method: 'post',
              url: config.API_URL + '/api/gis/giscodelistcontroller',
              headers: {
                'Authorization': 'Bearer ' + this.props.loginData.token,
                'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
              },
              data: {
                'cat_sl_id': selectedStatID,
              },
              config: { headers: { 'Content-Type': 'multipart/form-data', } }
            }).then((res) => {
              console.log("response get stat codelist filter >>>", res)
              let responseData = res.data
              let codelistArray = []
              let codelistArrayForClassify = []
              let checkStatusCodelistHasNoT = false
              responseData.map((item, indexMain) => {
                let codelistChildArray = []
                let codelistChildSelect = {}
                let checkStatusChildlistHasT = false
                item.child.map((item1) => {
                  codelistChildArray.push({ id: item1.CODE, label: item1.CODE_NAME, allRawData: item1, })
                  if (item1.CODE === 't') {
                    codelistChildSelect = { id: item1.CODE, label: item1.CODE_NAME, allRawData: item1, }
                    checkStatusChildlistHasT = true
                  }
                })
                codelistArray.push({ id: item.name_id, label: item.name, childHasT: checkStatusChildlistHasT, child: codelistChildArray, allRawData: item, })
                codelistArrayForClassify.push({
                  check : true,
                  isMain: checkStatusCodelistHasNoT === false && checkStatusChildlistHasT === false ? true : false,
                  codelist: { id: item.name_id, label: item.name, childHasT: checkStatusChildlistHasT, child: codelistChildArray, allRawData: item, },
                  codelistChild: Object.keys(codelistChildSelect).length === 0 && codelistChildSelect.constructor === Object
                    ? codelistChildArray[0]
                    : codelistChildSelect
                })
                if (Object.keys(codelistChildSelect).length === 0 && codelistChildSelect.constructor === Object) {
                  checkStatusCodelistHasNoT = true
                }
              })

              // set ค่าลง redux filter codelist
              this.props.setSelectFilterStatData({ updateFilter: 'code_lists', dataArrey: codelistArray, })

              // set ค่าลง redux select stat ส่วน classify codelist
              if (this.props.selectedStat.statType.CLASSIFY === 0) {
                console.log('รายการข้อมูลนี้เป็นรายการที่ไม่มีการจัดจำแนก >>>')
                this.props.setSelectStatData({ classify: { status: false, statusUseClassify: false, statusCodelistHasNoT: false, classifyData: [] } })
              } else if (this.props.selectedStat.statType.CLASSIFY === 1 && codelistArray.length === 0) {
                console.log('รายการข้อมูลนี้เป็นรายการที่มีการจัดจำแนก แต่!!! codelist การจัดจำแนกไม่สามารถดึงค่ามาได้ บางอย่างผิดพลาด >>>')
                this.props.setSelectStatData({ classify: { status: false, statusUseClassify: false, statusCodelistHasNoT: false, classifyData: [] } })
              } else if (this.props.selectedStat.statType.CLASSIFY === 1 && codelistArray.length !== 0) {
                if (checkStatusCodelistHasNoT === true) {
                  console.log('รายการข้อมูลนี้เป็นรายการที่มีการจัดจำแนก และ codelist การจัดจำแนกบางตัวไม่มี t(รวม) >>>')
                  this.props.setSelectStatData({ classify: { status: true, statusUseClassify: true, statusCodelistHasNoT: true, classifyData: codelistArrayForClassify } })
                } else {
                  console.log('รายการข้อมูลนี้เป็นรายการที่มีการจัดจำแนก และ codelist การจัดจำแนกทุกตัวมี t(รวม) >>>')
                  this.props.setSelectStatData({ classify: { status: true, statusUseClassify: true, statusCodelistHasNoT: false, classifyData: codelistArrayForClassify } })
                }
              } else {
                console.log('รายการข้อมูลนี้บางอย่างผิดพลาดในการดึงข้อมูล codelist >>>')
                this.props.setSelectStatData({ classify: { status: false, statusUseClassify: false, statusCodelistHasNoT: false, classifyData: [] } })
              }
              resolve('promise Codelist ready')
            }).catch((error) => {
              console.error("error get stat codelist filter >>>", error)
              reject(Error('promise Codelist fail'))
            })

          } else {
            reject(Error('promise Codelist fail'))
          }
        })
      });

      // -------------------- get agencys
      var promiseAgency = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisagencycontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat agencys filter >>>", res)
          let responseData = res.data
          let agencyArray = []
          responseData.map((item) => {
            agencyArray.push({ id: item.ID, label: item.AG_NAME, allRawData: item, })
          })
          this.props.setSelectFilterStatData({ updateFilter: 'agencys', dataArrey: agencyArray, })

          // ทำการ default ค่าเริ่มต้นลง redux
          this.props.setSelectStatData({ agency: agencyArray[0] })
          resolve('promise Agency ready')
        }).catch((error) => {
          console.error("error get stat agencys filter >>>", error)
          reject(Error('promise Agency fail'))
        })
      });

      // -------------------- get description
       var promiseDescription = new Promise((resolve, reject) => {
        promiseAgency.then((data) => {
          axios({
            method: 'post',
            url: config.API_URL + '/api/gis/gisreferancecontroller',
            headers: {
              'Authorization': 'Bearer ' + this.props.loginData.token,
              'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
            },
            data: { 'cat_sl_id': selectedStatID,'agency_id':this.props.selectedStat.agency.allRawData.AGAENCY_ID },
            config: { headers: { 'Content-Type': 'multipart/form-data', } }
          }).then((res) => {
            console.log("response get stat description filter >>>", res)
            let responseData = res.data[0]
            if (responseData) {
              this.props.setSelectStatData({
                description: {
                  agency: responseData.AG_NAME ? responseData.AG_NAME : '-',                                        // หน่วยงานเจ้าของข้อมูล: (สำนักงาน... มหาลัย... กรม...)
                  frequency: responseData.NAME ? responseData.NAME : '-',                                           // ความถี่ในการจัดเก็บ: (รายปี รายไตรมาส รายเดือน)
                  timeValidStart: responseData.VALID_FROM ? responseData.VALID_FROM : '-',                          // ช่วงเวลาข้อมูล: 2555 - 2559
                  timeValidEnd: responseData.VALID_TO ? responseData.VALID_TO : '-',                                // ช่วงเวลาข้อมูล: 2555 - 2559
                  resourceType: responseData.METTHOD_NAME ? responseData.METTHOD_NAME : '-',                        // วิธีการได้มา: (สำรวจ สำมะโน ทะเบียน)
                  resourceFrom: responseData.META_SOURCE ? responseData.META_SOURCE : '-',                          // แหล่งที่มา: (...)
                  resourceLink: responseData.URL ? responseData.URL : '-',                                          // แหล่งข้อมูล: (http:...)
                  usage: responseData.IMPLEMENT ? responseData.IMPLEMENT : '-',                                     // การนำไปใช้ประโยชน์: (...)
                  quality: responseData.QUALITY ? responseData.QUALITY : '-',                                       // คุณภาพข้อมูล: (...)
                  officialStatus: responseData.META_IS_OS === 1 ? true : false,                                           // สถิติทางการ: (ใช่ ไม่ใช่)
                  note: responseData.NOTE ? responseData.NOTE : '-',                                                // หมายเหตุ: (...)
                  statType: responseData.ATTRIBUTE.STATISTIC_ID[0] !== '-' ? responseData.ATTRIBUTE.STATISTIC_ID[0] : '-',        // ประเภทสถิติ: (จำนวน ร้อยละ อัตรา)
                  statUnit: responseData.ATTRIBUTE.UNIT_ID[0] !== '-' ? responseData.ATTRIBUTE.UNIT_ID[0] : '-',                  // หน่วยนับ: (บาท คน ตัว)
                  statUnitMultiply: responseData.ATTRIBUTE.UNITMULT_ID[0] !== '-' ? responseData.ATTRIBUTE.UNITMULT_ID[0] : '-',  // ตัวคูณ: (สิบ ร้อย พัน หมื่น)
                  allRawData: responseData,
                },
              })
            }
            resolve('promise Description ready')
          }).catch((error) => {
            console.error("error get stat description filter >>>", error)
            reject(Error('promise Description fail'))
          })
        }).catch((eror) => {
          console.log('eror :>> ', eror);
        })

      });

      // -------------------- get external-knowledge
      var promiseExKnow = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisexknowcontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat external-knowledge filter >>>", res)
          let responseData = res.data
          let listTemp = []
          if (responseData.length !== 0) {
            responseData.map((item) => {
              listTemp.push({ id: item.ID, nameOfKnowledge: item.INFO_NAME, link: item.URL, allRawData: item })
            })
          }
          // ยังไม่ได้จัดข้อมูลก่อนเก็บเข้า redux (ควรจัดให้อยู่ในรูปแบบ [{id: '', nameOfKnowledge: '', link: '' },{id: '', nameOfKnowledge: '', link: '' },...])
          this.props.setSelectStatData({ externalKnowledge: listTemp })
          resolve('promise external-knowledge ready')
        }).catch((error) => {
          console.error("error get stat external-knowledge filter >>>", error)
          reject(Error('promise external-knowledge fail'))
        })
      });

      // -------------------- get external-infomation
      var promiseExInfo = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisinfocontroller',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat external-infomation filter >>>", res)
          let responseData = res.data
          let listTemp = []
          if (responseData.length !== 0) {
            responseData.map((item) => {
              listTemp.push({ id: item.ID, nameOfKnowledge: item.INFO_NAME, link: item.URL, allRawData: item })
            })
          }
          // ยังไม่ได้จัดข้อมูลก่อนเก็บเข้า redux (ควรจัดให้อยู่ในรูปแบบ [{id: '', nameOfInfomation: '', link: '' },{id: '', nameOfInfomation: '', link: '' },...])
          this.props.setSelectStatData({ externalInformation: listTemp })
          resolve('promise external-infomation ready')
        }).catch((error) => {
          console.error("error get stat external-infomation filter >>>", error)
          reject(Error('promise external-infomation fail'))
        })
      });

      // -------------------- get nametable
      var promiseNameTable = new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: config.API_URL + '/api/gis/gisnametableController',
          headers: {
            'Authorization': 'Bearer ' + this.props.loginData.token,
            'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
          },
          data: {
            'cat_sl_id': selectedStatID,
          },
          config: { headers: { 'Content-Type': 'multipart/form-data', } }
        }).then((res) => {
          console.log("response get stat nametable filter >>>", res)
          let responseData = res.data

          // ทำการ set nametable ค่าเริ่มต้นลง redux
          this.props.setSelectStatData({ statNameTable: responseData })
          resolve('promise nametable ready')
        }).catch((error) => {
          console.error("error get stat nametable filter >>>", error)
          reject(Error('promise nametable fail'))
        })
      });

      Promise.all([promiseDescription, promiseYear, promiseArea, promiseTypeMap, promiseCodelist, promiseAgency, promiseExKnow, promiseExInfo, promiseNameTable, promiseStatType])
        .then((values) => {
          console.log('values in promise all >>>', values)
          this.handleModal();
          // ใส่ function ที่ต้องการทำต่อหลังจากได้ข้อมูล รายการที่เลือก และเก็บลง redux แล้วลงในนี้
          // การวาดแผนที่
          this.props.showmap()
          this.pumplike()
        })
        .catch((errors) => {
          console.error('error in promise all >>>', errors)
          this.handleModal();
          this.props.setModal({
            modalAction: 'open',
            title: 'เกิดข้อผิดพลาด!',
            text: 'ข้อมูลในฐานข้อมูลไม่ครบถ้วน ไม่สามารถแสดงผลได้ กรุณาเลือกรายการข้อมูลใหม่อีกครั้ง',
            icon: 'fail',
            button: [
              {
                text: 'ตกลง',
                width: '100px',
                bgcolor: '#d62e45',
                functionIn: (e) => this.handleModal(e, 'this is a text from callback cancle !')
              }
            ]
          })
          this.props.setLayersuccess({ status: this.props.layersuccess.status = false, statusCrassify: 'ไม่สามารถสร้างแผนที่ได้กรุณาลองใหม่อีกครั้ง' });
        })

    } catch (error) {
      console.error(error);
    }
  }
  pumplike = () => {
    axios({
      method: 'post',
      url: config.API_URL + '/api/gis/countstat',
      headers: {
        'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
      },
      data: {
        'cat_sl_id': this.props.selectedStat.statId,
      },
      config: { headers: { 'Content-Type': 'multipart/form-data', } }
    }).then((res) => {
      console.log("pumplike success");
    }).catch((error) => {
      console.error(error + "error pumplike");
    })


  }
  handleModal = (e, param = 'nothing from callback function !') => {
    console.log('test handleModal event from modal >>>', e)
    console.log('test handleModal param from function >>>', param)

    this.props.setModal({ modalAction: 'close', })
  }
  render() {
    return (
      <div style={{ margin: '10px' }}>

        {/* ค้นหา */}
        <div style={{ margin: '10px' }}>
          <label><span><strong> ค้นหาจากประเภทค่าข้อมูลสถิติ : </strong></span></label>
          <Dropdown
            clearable
            selection
            fluid
            search
            value={this.state.selectedType}
            options={this.state.listData}
            placeholder='เลือกคำนำหน้าชื่อ...'
            onChange={(event, data) => this.handleSearchValue(data.value)}
          />
        </div>

        {this.state.listResult.map((itemStat, index) => {
          return (
            <div key={index}>
              <Radio.Group onChange={(event) => { this.handleSelectStat(itemStat) }} value={this.props.selectedStat.statId}>
                <Radio value={itemStat.ID}> {itemStat.STAT_LIST_NAME}</Radio>
                <span className='ml-2 text-primary' >
                {"(หมวด"+itemStat.CATE_NAME+")"}
                  </span>
              </Radio.Group>
            </div>
          )
        })}
      </div>
    )
  }
}

// DataListByType.propTypes = {
//   stringProp: PropTypes.string,
//   numberProp: PropTypes.number,
//   functionProp: PropTypes.func,
//   arrayProp: PropTypes.arrayOf(PropTypes.string),
// };
const mapStateToProps = (state) => ({
  loginData: state.loginData,
  menutab: state.menuTab,
  mapView: state.mapView,
  drawType: state.drawType,
  selectedCategory: state.selectedCategory,
  selectedStat: state.selectedStat,
  selectedStatFilter: state.selectedStatFilter,
  provinceMode: state.provinceMode,
  modal: state.modal,
  layersuccess: state.layersuccess,

});
const mapDispatchToProps = (dispatch) => ({
  setChangMapView: (data) => dispatch(changMapView(data)),
  setChangDrawType: (data) => dispatch(changDrawType(data)),
  setSelectCategoryData: (data) => dispatch(selectCategoryData(data)),
  setSelectStatData: (data) => dispatch(selectStatData(data)),
  setSelectFilterStatData: (data) => dispatch(selectFilterStatData(data)),
  setModal: (data) => dispatch(showModal(data)),
  setLayersuccess: (data) => dispatch(layersuccess(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(DataListByType);