import {
  LOGIN,
  LOGOUT,
  SELECT_STAT,
  SELECT_CATE,
  FILTER_STAT,
  CHANGE_MAP_VIEW,
  CHANGE_DRAW_TYPE,
  CHANGE_SWIPE_MAP,
  CHANGE_PROVINCE_MODE,
  CHANGE_paramurl,
  CHANG_STYLE,
  CHANG_STATUS,
  CHANG_MENU,
  MODAL,
} from './actionTypes'

//------------------- ACTION {changMapView, changDrawType, selectStatData, selectCategoryData, selectFilterStatData, }
export const login = (loginData) => ({
  type: LOGIN,
  payload: loginData
})
export const logout = () => ({
  type: LOGOUT,
})
export const changMenuTab = (menuTab) => ({
  type: menuTab,
  payload: menuTab
})
export const changMapView = (mapView) => ({
  type: CHANGE_MAP_VIEW,
  payload: mapView
})
export const changDrawType = (drawType) => ({
  type: CHANGE_DRAW_TYPE,
  payload: drawType
})
export const selectStatData = (statData) => ({
  type: SELECT_STAT,
  payload: statData
})
export const selectCategoryData = (categoryData) => ({
  type: SELECT_CATE,
  payload: categoryData
})
export const selectFilterStatData = (filterStatData) => ({
  type: FILTER_STAT,
  payload: filterStatData
})
export const changparamurl = (paramurl,center) => ({
  type: CHANGE_paramurl,
  payloadurl: paramurl,
  payloadcenter:center
})
// ... other actions
export const changSwipeMap = (swipeMap) => ({
  type: CHANGE_SWIPE_MAP,
  payload: swipeMap
})
export const changeProvinceMode = (provinceMode) => ({
  type: CHANGE_PROVINCE_MODE,
  payload: provinceMode
})
export const changeStyle = (Style) => ({
  type: CHANG_STYLE,
  payload: Style
})
export const layersuccess = (status) => ({
  type: CHANG_STATUS,
  payload: status
})
export const selectedMenu = (type) => ({
  type: CHANG_MENU,
  payload: type
})
export const showModal = (payload) => ({
  type: MODAL,
  payload: payload
})

// ... other actions
