export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const SELECT_STAT = "SELECT_STAT"
export const SELECT_CATE = "SELECT_CATE"
export const FILTER_STAT = "FILTER_STAT"
export const CHANGE_MAP_VIEW = "CHANGE_MAP_VIEW"
export const CHANGE_DRAW_TYPE = "CHANGE_DRAW_TYPE"
export const CHANGE_paramurl = "CHANGE_paramurl"
export const CHANGE_SWIPE_MAP = "CHANGE_SWIPE_MAP"
export const CHANGE_PROVINCE_MODE = "CHANGE_PROVINCE_MODE"
export const CHANG_STYLE = "CHANG_STYLE"
export const CHANG_STATUS = "CHANG_STATUS"
export const CHANG_MENU = "CHANG_MENU"
export const MODAL = "MODAL"
