const layer = {
    status: false,
    statusCrassify:'',
    classify:'',
}
const layersuccess = (state = layer, action) => {
    switch (action.type) {
        case "CHANG_STATUS":
            state = {
                status: action.payload.status ? action.payload.status : state.status,
                statusCrassify: action.payload.statusCrassify ? action.payload.statusCrassify : state.statusCrassify,
                classify: action.payload.classify ? action.payload.classify : state.classify,
            }
            return state;
        default:
            return state;
    }
  }
  export default layersuccess;