import React, { Component } from "react";
/* ----- layout ----- */
// import Navbar from "../../componentnso/Navbar";
// import Map from "../../Action/Maps";
// import Tabbar from "../../componentnso/Tabbar";
// import Footer from "../../componentnso/Footer";
// import Slidebar from "../../componentnso/Slidebarnso";

class TestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSlidebar: false,
    };
  }
  render() {
    return (
      <div style={{ height: '100% !important' }}>
        <p>this is Test page</p>

        {/* <Navbar />

        <div name='contentWithSidebar'>
          <Slidebar />
          <div name='content'>
            <Tabbar />
            <Map />
          </div>
        </div>

        <Footer /> */}

      </div>
    );
  }
}

export default TestPage;