import React, { Component } from 'react'
import styled from "styled-components";
// import ReactDOM from "react-dom";
// import $ from 'jquery';
import "../css/tabbar.css";
import { Button, Radio, Checkbox } from 'semantic-ui-react'
import { connect, } from 'react-redux';
import { changMapView, changDrawType, selectStatData, selectCategoryData, selectFilterStatData, changSwipeMap, changeProvinceMode, } from '../redux/actions';
import { FieldTimeOutlined, ApartmentOutlined, GatewayOutlined, BankOutlined, SolutionOutlined, ProfileOutlined, FundViewOutlined, } from '@ant-design/icons';
import axios from "axios";
import config from '../config'


const DivTabbar = styled.div`
display: flex;
justify-content: space-around;
flex-wrap: wrap;
background-color: #FFF;
width: ${props => props.swipeMode ? '50%' : '100%'};
align-items: baseline;
border-bottom: 4px solid #23898e;

`
const DivTabbarMenu = styled.div`
background-color: #FFF;
padding: 10px;
height: 40px;
overflow: visible;

`
const DivTabbarMenuName = styled.div`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
font-weight:  ${props => props.activeMenu ? 'bold' : ''};
color: ${props => props.activeMenu ? '#25b1b8' : 'black'};
&:hover {
  /*color: white;*/
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}
`
const DivTabbarMenuExpand = styled.div`
z-index: 300;
position: relative;
margin-top: 10px;
padding-top: 10px;
padding-bottom: 10px;
min-width: 300px;
height: auto;
border: 1px solid gray;
border-radius: 5px;
background-color: #f1f1f1;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
float: ${props => props.float ? props.float : 'none'};
`
const DivTabbarMenuExpandGroup = styled.div`
position: relative;
padding-right: 15px;
padding-left: 15px;
width: auto;
`
const DivTabbarMenuExpandInline = styled.div`
display: flex;
flex-wrap: wrap;
padding-bottom: 10px;
width: auto;
`
const DivTabbarMenuExpandInlineSub = styled.div`
text-align: ${props => props.textAlign ? props.textAlign : 'center'};
width: ${props => props.width ? props.width + '%' : '100%'};
padding-left: ${props => props.notmain ? '15px' : ''};
word-break: break-word;
`
// const SelectTabbarMenuExpand = styled.select`
// border: 1px solid gray;
// border-radius: 5px;
// cursor: pointer;
// height: 30px;
// width: 100%;
// background: white;
// color: gray;
// border: none;
// `

class TabbarNew extends Component {

  state = {
    showTabbarExpandClassify: false,
    showTabbarExpandYear: false,
    showTabbarExpandArea: false,
    showTabbarExpandAgency: false,
    showTabbarExpandDescription: false,
    showTabbarExpandKnowledge: false,
    showTabbarExpandMedia: false,

    showSwipeTabbarExpandClassify: false,
    showSwipeTabbarExpandYear: false,
    showSwipeTabbarExpandArea: false,
    showSwipeTabbarExpandAgency: false,
    showSwipeTabbarExpandDescription: false,
    showSwipeTabbarExpandKnowledge: false,
    showSwipeTabbarExpandMedia: false,

  }

  handleFilterOpen = async(selectFilterOpen) => {
    this.setState({
      showTabbarExpandClassify: selectFilterOpen === 'classify' ? !this.state.showTabbarExpandClassify : false,
      showTabbarExpandYear: selectFilterOpen === 'year' ? !this.state.showTabbarExpandYear : false,
      showTabbarExpandArea: selectFilterOpen === 'area' ? !this.state.showTabbarExpandArea : false,
      showTabbarExpandAgency: selectFilterOpen === 'agency' ? !this.state.showTabbarExpandAgency : false,
      showTabbarExpandDescription: selectFilterOpen === 'description' ? !this.state.showTabbarExpandDescription : false,
      showTabbarExpandKnowledge: selectFilterOpen === 'exknow' ? !this.state.showTabbarExpandKnowledge : false,
      showTabbarExpandMedia: selectFilterOpen === 'exinfo' ? !this.state.showTabbarExpandMedia : false,
      // swipe map
      showSwipeTabbarExpandClassify: selectFilterOpen === 'classifySwipe' ? !this.state.showSwipeTabbarExpandClassify : false,
      showSwipeTabbarExpandYear: selectFilterOpen === 'yearSwipe' ? !this.state.showSwipeTabbarExpandYear : false,
      showSwipeTabbarExpandArea: selectFilterOpen === 'areaSwipe' ? !this.state.showSwipeTabbarExpandArea : false,
      showSwipeTabbarExpandAgency: selectFilterOpen === 'agencySwipe' ? !this.state.showSwipeTabbarExpandAgency : false,
      showSwipeTabbarExpandDescription: selectFilterOpen === 'descriptionSwipe' ? !this.state.showSwipeTabbarExpandDescription : false,
      showSwipeTabbarExpandKnowledge: selectFilterOpen === 'exknowSwipe' ? !this.state.showSwipeTabbarExpandKnowledge : false,
      showSwipeTabbarExpandMedia: selectFilterOpen === 'exinfoSwipe' ? !this.state.showSwipeTabbarExpandMedia : false,
    });
    if(this.props.selectedStat.statName !== "" ) await axios({
      method: 'post',
      url: config.API_URL + '/api/gis/gisreferancecontroller',
      headers: {
        'Authorization': 'Bearer ' + this.props.loginData.token,
        'NAME_SCHEMA': this.props.provinceMode.NAME_SCHEMA_MODE
      },
      data: { 'cat_sl_id': this.props.selectedStat.statId,'agency_id':this.props.selectedStat.agency.allRawData.AGAENCY_ID },
      config: { headers: { 'Content-Type': 'multipart/form-data', } }
    }).then((res) => {
      console.log("response get stat description filter >>>", res)
      let responseData = res.data[0]
      if (responseData) {
        this.props.setSelectStatData({
          description: {
            agency: responseData.AG_NAME ? responseData.AG_NAME : '-',                                        // หน่วยงานเจ้าของข้อมูล: (สำนักงาน... มหาลัย... กรม...)
            frequency: responseData.NAME ? responseData.NAME : '-',                                           // ความถี่ในการจัดเก็บ: (รายปี รายไตรมาส รายเดือน)
            timeValidStart: responseData.VALID_FROM ? responseData.VALID_FROM : '-',                          // ช่วงเวลาข้อมูล: 2555 - 2559
            timeValidEnd: responseData.VALID_TO ? responseData.VALID_TO : '-',                                // ช่วงเวลาข้อมูล: 2555 - 2559
            resourceType: responseData.METTHOD_NAME ? responseData.METTHOD_NAME : '-',                        // วิธีการได้มา: (สำรวจ สำมะโน ทะเบียน)
            resourceFrom: responseData.META_SOURCE ? responseData.META_SOURCE : '-',                          // แหล่งที่มา: (...)
            resourceLink: responseData.URL ? responseData.URL : '-',                                          // แหล่งข้อมูล: (http:...)
            usage: responseData.IMPLEMENT ? responseData.IMPLEMENT : '-',                                     // การนำไปใช้ประโยชน์: (...)
            quality: responseData.QUALITY ? responseData.QUALITY : '-',                                       // คุณภาพข้อมูล: (...)
            officialStatus: responseData.META_IS_OS === 1 ? true : false,                                           // สถิติทางการ: (ใช่ ไม่ใช่)
            note: responseData.NOTE ? responseData.NOTE : '-',                                                // หมายเหตุ: (...)
            statType: responseData.ATTRIBUTE.STATISTIC_ID[0] !== '-' ? responseData.ATTRIBUTE.STATISTIC_ID[0] : '-',        // ประเภทสถิติ: (จำนวน ร้อยละ อัตรา)
            statUnit: responseData.ATTRIBUTE.UNIT_ID[0] !== '-' ? responseData.ATTRIBUTE.UNIT_ID[0] : '-',                  // หน่วยนับ: (บาท คน ตัว)
            statUnitMultiply: responseData.ATTRIBUTE.UNITMULT_ID[0] !== '-' ? responseData.ATTRIBUTE.UNITMULT_ID[0] : '-',  // ตัวคูณ: (สิบ ร้อย พัน หมื่น)
            allRawData: responseData,
          },
        })
      }
    }).catch((error) => {
      console.error("error get stat description filter >>>", error)
    })
  }
  handleDrawNewMap = () => {
    console.log('ข้อมูลรายการ stat และ filter ที่เลือกก่อนส่งไปสร้างแผนที่ใหม่ >>>', this.props.selectedStat)
    this.props.showmap(false)
  }
  handleDrawNewMapSwipe = () => {
    console.log('ข้อมูลรายการ stat และ filter ที่เลือกก่อนส่งไปสร้างแผนที่เปรียบเทียบใหม่ >>>', this.props.swipeMap)
    this.props.showswichmap()
  }

  render() {
    const {
      showTabbarExpandClassify,
      showTabbarExpandYear,
      showTabbarExpandArea,
      showTabbarExpandAgency,
      showTabbarExpandDescription,
      showTabbarExpandKnowledge,
      showTabbarExpandMedia,

      showSwipeTabbarExpandClassify,
      showSwipeTabbarExpandYear,
      showSwipeTabbarExpandArea,
      showSwipeTabbarExpandAgency,
      showSwipeTabbarExpandDescription,
      showSwipeTabbarExpandKnowledge,
      showSwipeTabbarExpandMedia,
    } = this.state
    const {
      selectedStat, setSelectStatData,
      selectedStatFilter,
      swipeMap, setSwipeMapData,
    } = this.props

    // option สำหรับ select ต่างๆจาก redux
    const timeYearsFilter = selectedStatFilter.timeYears
    const timePeriodTypesFilter = selectedStatFilter.timePeriodTypes
    const areasFilter = selectedStatFilter.areas
    const agencysFilter = selectedStatFilter.agencys
    const selectClassifyCode = selectedStat.classify.classifyData
    const classifyStatusNotTArray = selectClassifyCode.length !== 0 && selectClassifyCode.find(item => item.codelistChild.id !== 't'&&item.codelistChild.id !== '-') ? selectClassifyCode.filter(item => item.codelistChild.id !== 't'&&item.codelistChild.id !== '-') : []
    const selectClassifyCodeMain = selectClassifyCode.length !== 0 && selectClassifyCode.find(item => item.isMain === true) ? selectClassifyCode.find(item => item.isMain === true) : ''
    const selectTimeYear = selectedStat.time.year
    const selectTimePeriodType = selectedStat.time.periodType
    const selectTimePeriodSelect = selectedStat.time.periodSelect
    const selectArea = selectedStat.area
    const selectAgency = selectedStat.agency

    // swipe map option สำหรับ select ต่างๆจาก redux
    const swipeTimeYearsFilter = swipeMap.swipeMapStatFilter.timeYears
    const swipeTimePeriodTypesFilter = swipeMap.swipeMapStatFilter.timePeriodTypes
    const swipeAreasFilter = swipeMap.swipeMapStatFilter.areas
    const swipeAgencysFilter = swipeMap.swipeMapStatFilter.agencys
    const swipeSelectClassifyCode = swipeMap.swipeMapStat.classify.classifyData
    const swipeClassifyStatusNotTArray = swipeSelectClassifyCode.length !== 0 && swipeSelectClassifyCode.find(item => item.codelistChild.id !== 't'&&item.codelistChild.id !== '-') ? swipeSelectClassifyCode.filter(item => item.codelistChild.id !== 't'&&item.codelistChild.id !== '-') : []
    const swipeSelectClassifyCodeMain = swipeSelectClassifyCode.length !== 0 && swipeSelectClassifyCode.find(item => item.isMain === true) ? swipeSelectClassifyCode.find(item => item.isMain === true) : ''
    const swipeSelectTimeYear = swipeMap.swipeMapStat.time.year
    const swipeSelectTimePeriodType = swipeMap.swipeMapStat.time.periodType
    const swipeSelectTimePeriodSelect = swipeMap.swipeMapStat.time.periodSelect
    const swipeSelectArea = swipeMap.swipeMapStat.area
    const swipeSelectAgency = swipeMap.swipeMapStat.agency

    let styleSelect = {
      border: '1px solid gray',
      borderRadius: '5px',
      cursor: 'pointer',
      height: '30px',
      width: '100%',
      background: 'white',
      color: 'gray',
      // border: 'none',
    }
    let styleStatName = {
      overflow: 'hidden',
      maxWidth: '250px',
      whiteSpace: 'nowrap',
    }
    return (
      <div style={{ display: 'flex' }}>
        {/* ตัวกรองรายการสถิติหลัก */}
        <DivTabbar swipeMode={this.props.swipeMap.swipeMapOpen}>
          {/* แสดงชื่อรายการสถิติที่เลือก */}
          {this.props.swipeMap.swipeMapOpen
            ? <div style={styleStatName} data-toggle="tooltip" data-placement="bottom" title={this.props.selectedStat.statName}>
              {this.props.selectedStat.statName ? '| ' + this.props.selectedStat.statName : '| กรุณาเลือกรายการสถิติ'}
            </div>
            : ''}
          {/* การจัดจำแนกใหม่ */}
          <DivTabbarMenu style={{ width: `${this.props.swipeMap.swipeMapOpen ? '50px' : '130px'}` }}>
            <DivTabbarMenuName activeMenu={showTabbarExpandClassify} onClick={() => { this.handleFilterOpen('classify') }} >
              {this.props.swipeMap.swipeMapOpen
                ? <ApartmentOutlined
                  style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.selectedStat.statName + " : การจัดจำแนก"} />
                : <><ApartmentOutlined style={{ fontSize: '18px', paddingRight: '5px' }} /><span className="text">การจัดจำแนก</span></>
              }
            </DivTabbarMenuName>
            {showTabbarExpandClassify
              ?
              <DivTabbarMenuExpand >
                {(selectedStat.statName === '')
                  ? <>
                    <DivTabbarMenuExpandGroup>
                      <Button fluid onClick={() => { this.handleFilterOpen('classify') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                    </DivTabbarMenuExpandGroup>
                  </>
                  : <>
                    <DivTabbarMenuExpandGroup>
                      {/* บ่งบอกมีการจัดจำแนก */}
                      <DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInlineSub textAlign="left" width='70'>
                          {this.props.selectedStat.classify.status === false
                            ? <div>รายการข้อมูลนี้ไม่มีการจัดจำแนก</div>
                            : <Radio toggle
                              // disable ถ้าเป็นร้อยละ หรือมี codelist ตัวใดตัวหนึ่งที่ไม่มี t
                              disabled={this.props.selectedStat.classify.statusCodelistHasNoT === true ? true : false}
                              // disabled={true}
                              label={this.props.selectedStat.classify.statusUseClassify ? 'จัดจำแนก' : 'ไม่จัดจำแนก'}
                              checked={classifyStatusNotTArray.length !== 0 ? true : this.props.selectedStat.classify.statusUseClassify}
                              // checked={classifyStatusNotTArray.length !== 0 ? true : false}
                              onClick={async (event, { value }) => {
                                if (this.props.selectedStat.classify.statusUseClassify) {
                                  // ถ้าเลื่อนเปิดเป็นปิด จะทำการเอา isMain ออก และทำการเอา codelist ให้ลูกเป็น t(รวม) ยกเว้นตัวที่ไม่มี
                                  // (ถ้ามีตัวที่ยกเว้นเพราะไม่มี t(รวม) ให้ทำการแจ้งเตือนข้อความบอกด้วยว่าตัวนั้นไม่มี tรวม ต้องมีการจัดจำแนกเท่านั้น)
                                  let newClassifyData = []
                                  let statusBlockNoClassify = false
                                  selectClassifyCode.map((itemClassify) => {
                                    let childIsT = itemClassify.codelist.child.find(itemChild => itemChild.id === 't')
                                    if (!childIsT) {
                                      alert('has codelist that no child t >>> must have classify only')
                                      statusBlockNoClassify = true
                                    }
                                    newClassifyData.push({
                                      check: true,
                                      isMain: false,
                                      codelist: itemClassify.codelist,
                                      codelistChild: childIsT ? childIsT : itemClassify.codelist.child[0]
                                    })
                                    return ''
                                  })
                                  await setSelectStatData({
                                    classify: {
                                      statusUseClassify: statusBlockNoClassify ? true : false,
                                      classifyData: newClassifyData,
                                    }
                                  })
                                  await this.handleDrawNewMap()
                                } else {
                                  // ถ้าเลื่อนปิดเป็นเปิด จะทำให้สามารถเลือกการจัดจำแนกได้ และจะมี default เลือก isMain  ให้ 1 ตัว
                                  let tempClassifyData = selectClassifyCode
                                  tempClassifyData[0].isMain = true
                                  tempClassifyData[0].codelistChild = tempClassifyData[0].codelist.child.find(itemChild => itemChild.id !== 't')
                                  await setSelectStatData({
                                    classify: {
                                      statusUseClassify: true,
                                      classifyData: tempClassifyData
                                    }
                                  })
                                  await this.handleDrawNewMap()
                                }
                              }} />}
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      {/* ลิสของตัวเลือกการจัดจำแนก */}
                      {this.props.selectedStat.statType.CLASSIFY !== 0
                        ? <>
                          {
                            this.props.selectedStat.classify.classifyData.map((itemCode, index) => {

                              return (
                                <DivTabbarMenuExpandInline key={index}>
                                  <DivTabbarMenuExpandInlineSub
                                    notmain={selectClassifyCodeMain && selectClassifyCodeMain.codelist.id !== itemCode.codelist.id}
                                    width='40' textAlign='left'>
                                    <Checkbox
                                      disabled={!this.props.selectedStat.classify.statusUseClassify || itemCode.codelist.childHasT === false}
                                      // checked={classifyStatusNotTArray.find(item => item.codelist.id === itemCode.codelist.id) ? true : false}//ของเก่า
                                      checked={itemCode.check}//false
                                      onClick={(event, { value }) => {

                                        console.log('click on checkbox Codelist id >>> ', itemCode.codelist.id)
                                        let oldClassifyData = this.props.selectedStat.classify.classifyData
                                        let thisCheckboxCodelist = this.props.selectedStat.classify.classifyData.find(item => item.codelist.id === itemCode.codelist.id)
                                        let thisCheckboxCodelistIndex = this.props.selectedStat.classify.classifyData.indexOf(thisCheckboxCodelist)
                                        console.log('click on checkbox Codelist >>> index ', thisCheckboxCodelistIndex, ' >>> ', index, ' >>> ', thisCheckboxCodelist)
                                        if (this.props.selectedStat.classify.statusUseClassify) {

                                          if (thisCheckboxCodelist.check) {
                                            console.log('เชคอยู่ >>> ต้องเปลี่ยนไปไม่เชค')
                                            thisCheckboxCodelist.check = false;
                                              thisCheckboxCodelist.isMain = false
                                            // ถ้าเชค > ไม่เชค
                                            // // เชคถ้าตัวนั้นมี t ไหม
                                            // // // ถ้าไม่มี t ให้บล็อค ไม่สามารถเอา ติดถูกออกได้ isMain เอา isMain ตรงข้าม
                                            // // // ถ้ามี t ให้เปลียนกลับเป็น t เอา isMain ออก
                                            // if (thisCheckboxCodelist.codelist.childHasT) {

                                            //   thisCheckboxCodelist.codelistChild = thisCheckboxCodelist.codelist.child.find(item => item.id === 't')
                                            // } else {
                                            //   alert('ไม่สามารถ uncheck ได้ เพราะว่า codelist นี้ไม่มี t(รวม) >>>')
                                            //   thisCheckboxCodelist.isMain = !thisCheckboxCodelist.isMain
                                            //   thisCheckboxCodelist.codelistChild = thisCheckboxCodelist.codelist.child[0]
                                            // }
                                          } else {
                                            console.log('ไม่เชค >>> ต้องเปลี่ยนไปเชค')
                                            // ถ้าไม่เชค > เชค
                                            // ถ้าไม่มี isMain ด้วย ก็จะกลายเป็น isMain
                                            // ตัวนี้จาก t จะกลายเป็น ไม่ t
                                            thisCheckboxCodelist.check = true;
                                            if (!selectClassifyCodeMain) { thisCheckboxCodelist.isMain = true }
                                            // thisCheckboxCodelist.codelistChild = thisCheckboxCodelist.codelist.child.find(item => item.id !== 't')
                                          }
                                          oldClassifyData[thisCheckboxCodelistIndex] = thisCheckboxCodelist
                                          setSelectStatData({ classify: { classifyData: oldClassifyData, } })
                                        } else {
                                          console.log('ไม่สามารถเลือกได้หาก ยังไม่เลือกใช้ โหมด จัดจำแนก >>>')
                                        }
                                      }} />
                                    <span style={{ paddingLeft: '3px', cursor: 'pointer', fontWeight: `${itemCode.isMain ? 'bold' : ''}` }}
                                      onClick={() => {
                                        let oldClassifyData = this.props.selectedStat.classify.classifyData
                                        if (!itemCode.isMain) {
                                          // ถ้าตัวนี้ไม่เป็น ismain จะทำให้ตัวนี้เป็น ismain และทำให้ ตัวอื่นๆไม่เป็น ismain
                                          oldClassifyData.map((oldItem, indexoldItem) => {
                                            oldClassifyData[indexoldItem].isMain = false
                                            return ''
                                          })
                                        }
                                        oldClassifyData[index].isMain = !oldClassifyData[index].isMain
                                        if (oldClassifyData[index].codelistChild.id ==="-"){
                                          oldClassifyData[index].codelistChild = oldClassifyData[index].codelist.child[0]
                                        }
                                        setSelectStatData({ classify: { classifyData: oldClassifyData, } })
                                      }}
                                    >{itemCode.codelist.label}</span>
                                  </DivTabbarMenuExpandInlineSub>
                                  <DivTabbarMenuExpandInlineSub width='60'>
                                    <select style={styleSelect} id={itemCode.codelist.id}
                                      disabled={!this.props.selectedStat.classify.statusUseClassify ? true : false || itemCode.check ? false : true}
                                      value={(itemCode.codelist.id && selectClassifyCode.find(item => item.codelist.id === itemCode.codelist.id))
                                        ? selectClassifyCode.find(item => item.codelist.id === itemCode.codelist.id).codelistChild.id
                                        : ''}
                                      onChange={(event) => {
                                        let thisSelectChildValue = event.target.value
                                        console.log('click on select Codelist child value >>> ', thisSelectChildValue)
                                        let oldClassifyData = this.props.selectedStat.classify.classifyData
                                        let thisSelectCodelist = this.props.selectedStat.classify.classifyData.find(item => item.codelist.id === itemCode.codelist.id)
                                        let thisSelectCodelistIndex = this.props.selectedStat.classify.classifyData.indexOf(thisSelectCodelist)
                                        console.log('click on select Codelist child >>> index ', thisSelectCodelistIndex, ' >>> ', index, ' >>> ', thisSelectCodelist)
                                        thisSelectCodelist.isMain = thisSelectChildValue==="-"?false:thisSelectCodelist.isMain ? true : selectClassifyCodeMain ? false : true
                                        thisSelectCodelist.codelistChild = itemCode.codelist.child.find((item) => item.id === thisSelectChildValue)
                                        oldClassifyData[thisSelectCodelistIndex] = thisSelectCodelist
                                        setSelectStatData({ classify: { classifyData: oldClassifyData, } })
                                      }} >
                                      {itemCode.codelist.child && itemCode.codelist.child.map((itemSon, i) => {
                                        // return (<option key={i} value={itemSon.id} label={itemSon.label} hidden={itemSon.id === 't' ? true : false}></option>)
                                        return (<option key={i} value={itemSon.id} label={itemSon.label} ></option>)
                                      })}
                                    </select>
                                  </DivTabbarMenuExpandInlineSub>
                                </DivTabbarMenuExpandInline>
                              )
                            })
                          } </>
                        : ''}
                    </DivTabbarMenuExpandGroup>
                    {selectClassifyCode.length !== 0 && selectClassifyCode.find(item => item.codelistChild.id === '-')?
                    <DivTabbarMenuExpandGroup>
                      <p className="text-danger">หมายเหตุ : รายการข้อมูลนี้ไม่สามารถติ๊กเลือกการจัดจำแนกทั้งหมดพร้อมกันได้</p>

                    </DivTabbarMenuExpandGroup>:""}
                    <DivTabbarMenuExpandGroup>
                      <Button fluid onClick={() => { this.handleFilterOpen('classify'); this.handleDrawNewMap(); }}>สร้างแผนที่ใหม่ตามตัวกรองที่เลือก</Button>
                    </DivTabbarMenuExpandGroup>
                  </>
                }
              </DivTabbarMenuExpand>
              : ''}
          </DivTabbarMenu>
          {/* เวลา */}
          <DivTabbarMenu style={{ width: `${this.props.swipeMap.swipeMapOpen ? '50px' : '90px'}` }}>
            <DivTabbarMenuName activeMenu={showTabbarExpandYear} onClick={() => { this.handleFilterOpen('year') }} >
              {this.props.swipeMap.swipeMapOpen
                ? <FieldTimeOutlined
                  style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.selectedStat.statName + " : เวลา"} />
                : <><FieldTimeOutlined style={{ fontSize: '18px', paddingRight: '5px' }} /><span className="text">เวลา</span></>
              }
            </DivTabbarMenuName>
            {showTabbarExpandYear
              ? <DivTabbarMenuExpand>
                {(selectedStat.statName === '')
                  ? <div className='col'>
                    <Button fluid onClick={() => { this.handleFilterOpen('year') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                  </div>
                  : <>
                    <DivTabbarMenuExpandGroup>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='30'>
                          <span>ปี:</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='70'>
                          <select style={styleSelect} value={selectTimeYear.id}
                            onChange={(event) => {
                              let thisSelectYearValue = event.target.value
                              let yearSelectData = timeYearsFilter.find((item) => item.id === thisSelectYearValue)
                              setSelectStatData({ time: { year: yearSelectData } })
                            }}>
                            <option value="" hidden> {timeYearsFilter && timeYearsFilter.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกปี...'} </option>
                            {timeYearsFilter.map((itemYear, i) => {
                              return (<option key={itemYear.id} value={itemYear.id}>{itemYear.label}</option>)
                            })}
                          </select>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='30'>
                          <span>ความถี่ :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='70'>
                          <select style={styleSelect} value={selectTimePeriodType.id}
                            onChange={(event) => {
                              let thisSelectPeriodTypeValue = event.target.value
                              let periodTypeSelectData = timePeriodTypesFilter.find((item) => item.id === thisSelectPeriodTypeValue)
                              setSelectStatData({ time: { periodType: periodTypeSelectData, periodSelect: periodTypeSelectData.child[0] } })
                            }}>
                            <option value="" hidden> {timePeriodTypesFilter && timePeriodTypesFilter.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกระดับความถี่...'} </option>
                            {timePeriodTypesFilter.map((item, i) => {
                              return (<option key={item.id} value={item.id}>{item.label}</option>)
                            })}
                          </select>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='30'>
                          <span>ช่วงที่ :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='70'>
                          <select style={styleSelect} value={selectTimePeriodSelect.id}
                            onChange={(event) => {
                              let thisSelectPeriodSelectValue = event.target.value
                              let periodSelectSelectData = selectTimePeriodType.child.find((item) => item.id === thisSelectPeriodSelectValue)
                              setSelectStatData({ time: { periodSelect: periodSelectSelectData } })
                            }}>
                            <option value="" hidden>  {selectTimePeriodType.child && selectTimePeriodType.child.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกช่วงของความถี่...'} </option>
                            {selectTimePeriodType.child.map((item, i) => {
                              return (<option key={item.id} value={item.id}>{item.label}</option>)
                            })}
                          </select>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                    </DivTabbarMenuExpandGroup>
                    <DivTabbarMenuExpandGroup>
                      <Button fluid onClick={() => { this.handleFilterOpen('year'); this.handleDrawNewMap(); }}>สร้างแผนที่ใหม่ตามตัวกรองที่เลือก</Button>
                    </DivTabbarMenuExpandGroup>
                  </>}
              </DivTabbarMenuExpand>
              : ''}
          </DivTabbarMenu>
          {/* พื้นที่ */}
          <DivTabbarMenu style={{ width: `${this.props.swipeMap.swipeMapOpen ? '50px' : '90px'}` }}>
            <DivTabbarMenuName activeMenu={showTabbarExpandArea} onClick={() => { this.handleFilterOpen('area') }} >
              {this.props.swipeMap.swipeMapOpen
                ? <GatewayOutlined
                  style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.selectedStat.statName + " : พื้นที่"} />
                : <><GatewayOutlined style={{ fontSize: '18px', paddingRight: '5px' }} /><span className="text">พื้นที่</span></>
              }
            </DivTabbarMenuName>
            {showTabbarExpandArea
              ? <DivTabbarMenuExpand>
                {(selectedStat.statName === '')
                  ? <div className='col'>
                    <Button fluid onClick={() => { this.handleFilterOpen('area') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                  </div>
                  : <>
                    <DivTabbarMenuExpandGroup>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='30'>
                          <span>ระดับ :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='70'>
                          <select style={styleSelect} value={selectArea.id}
                            onChange={(event) => {
                              let thisSelectAreaValue = event.target.value
                              let areaSelectData = areasFilter.find((item) => item.id === thisSelectAreaValue)
                              setSelectStatData({ area: areaSelectData })
                            }}>
                            <option value="" hidden>  {areasFilter && areasFilter.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกระดับพื้นที่...'} </option>
                            {areasFilter.map((itemArea, i) => {
                              return (<option key={itemArea.id} value={itemArea.id}>{itemArea.label}</option>)
                            })}
                          </select>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                    </DivTabbarMenuExpandGroup>
                    <DivTabbarMenuExpandGroup>
                      <Button fluid onClick={() => { this.handleFilterOpen('area'); this.handleDrawNewMap(); }}>สร้างแผนที่ใหม่ตามตัวกรองที่เลือก</Button>
                    </DivTabbarMenuExpandGroup>
                  </>}
              </DivTabbarMenuExpand>
              : ''}
          </DivTabbarMenu>
          {/* หน่วยงานเจ้าของข้อมูล */}
          <DivTabbarMenu style={{ width: `${this.props.swipeMap.swipeMapOpen ? '50px' : '190px'}` }}>
            <DivTabbarMenuName activeMenu={showTabbarExpandAgency} onClick={() => { this.handleFilterOpen('agency') }} >
              {this.props.swipeMap.swipeMapOpen
                ? <BankOutlined
                  style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.selectedStat.statName + " : หน่วยงานเจ้าของข้อมูล"} />
                : <><BankOutlined style={{ fontSize: '18px', paddingRight: '5px' }} /><span className="text">หน่วยงานเจ้าของข้อมูล</span></>
              }
            </DivTabbarMenuName>
            {showTabbarExpandAgency
              ? <DivTabbarMenuExpand>
                {(selectedStat.statName === '')
                  ? <div className='col'>
                    <Button fluid onClick={() => { this.handleFilterOpen('agency') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                  </div>
                  : <>
                    <DivTabbarMenuExpandGroup>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='30'>
                          <span>หน่วยงาน:</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='70'>
                          <select style={styleSelect} value={selectAgency.id}
                            onChange={(event) => {
                              let thisSelectAgencyValue = event.target.value
                              let agencySelectData = agencysFilter.find((item) => item.id === thisSelectAgencyValue)
                              console.log("agencySelectData",agencySelectData)
                              setSelectStatData({ agency: agencySelectData })
                            }}>
                            <option value="" hidden>  {agencysFilter && agencysFilter.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกหน่วยงาน...'} </option>
                            {agencysFilter.map((itemAgency, i) => {
                              return (<option key={i+itemAgency.id} value={itemAgency.id}>{itemAgency.label}</option>)
                            })}
                          </select>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                    </DivTabbarMenuExpandGroup>
                    <DivTabbarMenuExpandGroup>
                      <Button fluid onClick={() => { this.handleFilterOpen('agency'); this.handleDrawNewMap(); }}>สร้างแผนที่ใหม่ตามตัวกรองที่เลือก</Button>
                    </DivTabbarMenuExpandGroup>
                  </>}
              </DivTabbarMenuExpand>
              : ''}
          </DivTabbarMenu>
          {/* คำอธิบาย */}
          <DivTabbarMenu style={{ width: `${this.props.swipeMap.swipeMapOpen ? '50px' : '110px'}` }}>
            <DivTabbarMenuName activeMenu={showTabbarExpandDescription} onClick={() => { this.handleFilterOpen('description') }} >
              {this.props.swipeMap.swipeMapOpen
                ? <SolutionOutlined
                  style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.selectedStat.statName + " : คำอธิบาย"} />
                : <><SolutionOutlined style={{ fontSize: '18px', paddingRight: '5px' }} /><span className="text">คำอธิบาย</span></>
              }
            </DivTabbarMenuName>
            {showTabbarExpandDescription
              ? <DivTabbarMenuExpand>
                {(selectedStat.statName === '')
                  ? <div className='col'>
                    <Button fluid onClick={() => { this.handleFilterOpen('description') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                  </div>
                  : <>
                    <DivTabbarMenuExpandGroup>
                    <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>ประเภทสถิติ :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.statType ? selectedStat.description.statType : '-'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>หน่วยนับ :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.statUnitMultiply !== 'ไม่มีข้อมูล' && selectedStat.description.statUnitMultiply !== "-" ? selectedStat.description.statUnitMultiply: ''}{selectedStat.description.statUnit ? selectedStat.description.statUnit : ''}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>หน่วยงานเจ้าของข้อมูล :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.agency ? selectedStat.description.agency : '-'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>ความถี่ในการจัดเก็บ :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.frequency ? selectedStat.description.frequency : '-'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>ช่วงเวลาข้อมูล :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>ปี {selectedStat.description.timeValidStart ? selectedStat.description.timeValidStart : '-'} ถึง {selectedStat.description.timeValidEnd ? selectedStat.description.timeValidEnd : '-'} </span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>วิธีการได้มา :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.resourceType ? selectedStat.description.resourceType : '-'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>แหล่งที่มา :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.resourceFrom ? selectedStat.description.resourceFrom : '-'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>URLแหล่งข้อมูล :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.resourceLink ? selectedStat.description.resourceLink : '-'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>การนำไปใช้ประโยชน์ :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.usage ? selectedStat.description.usage : '-'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>คุณภาพข้อมูล :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.quality ? selectedStat.description.quality : '-'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>สถิติทางการ :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.officialStatus ? 'เป็น' : 'ไม่เป็น'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>
                      <DivTabbarMenuExpandInline >
                        <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                          <span>หมายเหตุ :</span>
                        </DivTabbarMenuExpandInlineSub>
                        <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                          <span>{selectedStat.description.note ? selectedStat.description.note : '-'}</span>
                        </DivTabbarMenuExpandInlineSub>
                      </DivTabbarMenuExpandInline>

                    </DivTabbarMenuExpandGroup>
                    <DivTabbarMenuExpandGroup>
                      <Button fluid onClick={() => { this.handleFilterOpen('description'); }}>ปิด</Button>
                    </DivTabbarMenuExpandGroup>
                  </>}
              </DivTabbarMenuExpand>
              : ''}
          </DivTabbarMenu>
          {/* องค์ความรู้ */}
          <DivTabbarMenu style={{ width: `${this.props.swipeMap.swipeMapOpen ? '50px' : '120px'}` }}>
            <DivTabbarMenuName activeMenu={showTabbarExpandKnowledge} onClick={() => { this.handleFilterOpen('exknow') }} >
              {this.props.swipeMap.swipeMapOpen
                ? <ProfileOutlined
                  style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.selectedStat.statName + " : องค์ความรู้"} />
                : <><ProfileOutlined style={{ fontSize: '18px', paddingRight: '5px' }} /><span className="text">องค์ความรู้</span></>
              }
            </DivTabbarMenuName>
            {showTabbarExpandKnowledge
              ? <DivTabbarMenuExpand float='right'>
                {(selectedStat.statName === '')
                  ? <div className='col'>
                    <Button fluid onClick={() => { this.handleFilterOpen('exknow') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                  </div>
                  : <>
                    <DivTabbarMenuExpandGroup>
                      <DivTabbarMenuExpandInline>
                        <span>ลิงค์เว็บไซต์ที่เข้าถึงบทความหรือสิ่งพิมพ์ที่{/* ใช้ข้อมูลสถิติตามรายการที่เลือกในการนำเสนอข้อมูลเพื่อ */}เป็นองค์ความรู้ในการใช้ประโยชน์จากข้อมูลสถิติ</span>
                      </DivTabbarMenuExpandInline>
                    </DivTabbarMenuExpandGroup>
                    {selectedStat.externalKnowledge.length !== 0
                      ?
                      <DivTabbarMenuExpandGroup>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='15' textAlign='center'>
                            <span><strong>ลำดับ</strong></span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                            <span><strong>หัวข้อ</strong></span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='40' textAlign='center' notmain>
                            <span><strong>เว็บลิงค์</strong></span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        {selectedStat.externalKnowledge.map((item, index) => (
                          < DivTabbarMenuExpandInline key={index} >
                            <DivTabbarMenuExpandInlineSub width='15' textAlign='center'>
                              <span>{index + 1}</span>
                            </DivTabbarMenuExpandInlineSub>
                            <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                              <span>{item.nameOfKnowledge}</span>
                            </DivTabbarMenuExpandInlineSub>
                            <DivTabbarMenuExpandInlineSub width='40' textAlign='center' notmain>
                              <a href={item.link} target="_blank" rel="noopener noreferrer">คลิก{/* item.link */}</a>
                            </DivTabbarMenuExpandInlineSub>
                          </DivTabbarMenuExpandInline>
                        ))}
                      </DivTabbarMenuExpandGroup>
                      :
                      <DivTabbarMenuExpandGroup>
                        <DivTabbarMenuExpandInline>
                          <span><strong>ไม่มีรายการลิงค์เว็บไซต์องค์ความรู้</strong></span>
                        </DivTabbarMenuExpandInline>
                      </DivTabbarMenuExpandGroup>}
                    <DivTabbarMenuExpandGroup>
                      <Button fluid onClick={() => { this.handleFilterOpen('exknow'); }}>ปิด</Button>
                    </DivTabbarMenuExpandGroup>
                  </>}
              </DivTabbarMenuExpand>
              : ''}
          </DivTabbarMenu>
          {/* สารสนเทศ */}
          <DivTabbarMenu style={{ width: `${this.props.swipeMap.swipeMapOpen ? '50px' : '210px'}` }}>
            <DivTabbarMenuName activeMenu={showTabbarExpandMedia} onClick={() => { this.handleFilterOpen('exinfo') }} >
              {this.props.swipeMap.swipeMapOpen
                ? <FundViewOutlined
                  style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.selectedStat.statName + " : ระบบสารสนเทศที่เกี่ยวข้อง"} />
                : <><FundViewOutlined style={{ fontSize: '18px', paddingRight: '5px' }} /><span className="text">ระบบสารสนเทศที่เกี่ยวข้อง</span></>
              }
            </DivTabbarMenuName>
            {showTabbarExpandMedia
              ? <DivTabbarMenuExpand float='right'>
                {(selectedStat.statName === '')
                  ? <div className='col'>
                    <Button fluid onClick={() => { this.handleFilterOpen('exinfo') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                  </div>
                  : <>
                    <DivTabbarMenuExpandGroup name='discription'>
                      <DivTabbarMenuExpandInline>
                        <span>ลิงค์เว็บไซต์ที่เข้าถึงระบบสารสนเทศที่เกี่ยวข้องกับรายการข้อมูลสถิติที่เลือก</span>
                      </DivTabbarMenuExpandInline>
                    </DivTabbarMenuExpandGroup>
                    {selectedStat.externalInformation.length !== 0
                      ?
                      <DivTabbarMenuExpandGroup name='listOfMedia'>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='15' textAlign='center'>
                            <span><strong>ลำดับ</strong></span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                            <span><strong>ชื่อระบบ</strong></span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='40' textAlign='center' notmain>
                            <span><strong>เว็บลิงค์</strong></span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        {selectedStat.externalInformation.map((item, index) => (
                          <DivTabbarMenuExpandInline key={index}>
                            <DivTabbarMenuExpandInlineSub width='15' textAlign='center'>
                              <span>{index + 1}</span>
                            </DivTabbarMenuExpandInlineSub>
                            <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                              <span>{item.nameOfKnowledge}</span>
                            </DivTabbarMenuExpandInlineSub>
                            <DivTabbarMenuExpandInlineSub width='40' textAlign='center' notmain>
                              <a href={item.link} target="_blank" rel="noopener noreferrer">คลิก{/* item.link */}</a>
                            </DivTabbarMenuExpandInlineSub>
                          </DivTabbarMenuExpandInline>
                        ))}
                      </DivTabbarMenuExpandGroup>
                      :
                      <DivTabbarMenuExpandGroup>
                        <DivTabbarMenuExpandInline>
                          <span><strong>ไม่มีรายการลิงค์เว็บไซต์ระบบสารสนเทศ</strong></span>
                        </DivTabbarMenuExpandInline>
                      </DivTabbarMenuExpandGroup>}
                    <DivTabbarMenuExpandGroup>
                      <Button fluid onClick={() => { this.handleFilterOpen('exinfo'); }}>ปิด</Button>
                    </DivTabbarMenuExpandGroup>
                  </>}
              </DivTabbarMenuExpand>
              : ''}
          </DivTabbarMenu>
        </DivTabbar>

        {/* ตัวกรองรายการสถิติเปรียบเทียบ */}
        {this.props.swipeMap.swipeMapOpen
          ?
          <DivTabbar swipeMode={this.props.swipeMap.swipeMapOpen}>
            {/* แสดงชื่อรายการสถิติเปรียบเทียบที่เลือก */}
            <div style={styleStatName} data-toggle="tooltip" data-placement="bottom" title={this.props.swipeMap.swipeMapStat.statName}>
              {this.props.swipeMap.swipeMapStat.statName ? '| ' + this.props.swipeMap.swipeMapStat.statName : '| กรุณาเลือกรายการสถิติเปรียบเทียบ'}
            </div>
            {/* การจัดจำแนกใหม่ */}
            <DivTabbarMenu style={{ width: '50px' }}>
              <DivTabbarMenuName activeMenu={showSwipeTabbarExpandClassify} onClick={() => { this.handleFilterOpen('classifySwipe') }} >
                <ApartmentOutlined
                  style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.swipeMap.swipeMapStat.statName + " : การจัดจำแนก"} />
              </DivTabbarMenuName>
              {showSwipeTabbarExpandClassify
                ?
                <DivTabbarMenuExpand float='right'>
                  {(this.props.swipeMap.swipeMapStat.statName === '')
                    ? <>
                      <DivTabbarMenuExpandGroup>
                        <Button fluid onClick={() => { this.handleFilterOpen('classifySwipe') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                      </DivTabbarMenuExpandGroup>
                    </>
                    : <>
                      <DivTabbarMenuExpandGroup>
                        {/* บ่งบอกมีการจัดจำแนก */}
                        <DivTabbarMenuExpandInline>
                          <DivTabbarMenuExpandInlineSub textAlign="left" width='70'>
                            {this.props.swipeMap.swipeMapStat.classify.status === false
                              ? <div>รายการข้อมูลนี้ไม่มีการจัดจำแนก</div>
                              : <Radio toggle
                                disabled={this.props.swipeMap.swipeMapStat.classify.statusCodelistHasNoT === true ? true : false}
                                label={swipeClassifyStatusNotTArray.length !== 0 ? 'จัดจำแนก' : 'ไม่จัดจำแนก'}
                                checked={swipeClassifyStatusNotTArray.length !== 0 ? true : this.props.swipeMap.swipeMapStat.classify.statusUseClassify}
                                onClick={async (event, { value }) => {
                                  if (this.props.swipeMap.swipeMapStat.classify.statusUseClassify) {
                                    let newClassifyData = []
                                    let statusBlockNoClassify = false
                                    swipeSelectClassifyCode.map((itemClassify) => {
                                      let childIsT = itemClassify.codelist.child.find(itemChild => itemChild.id === 't')
                                      if (!childIsT) {
                                        alert('has codelist that no child t >>> must have classify only')
                                        statusBlockNoClassify = true
                                      }
                                      newClassifyData.push({
                                        check: true,
                                        isMain: false,
                                        codelist: itemClassify.codelist,
                                        codelistChild: childIsT ? childIsT : itemClassify.codelist.child[0]
                                      })
                                      return ''
                                    })
                                    await setSwipeMapData({
                                      swipeMapStat: {
                                        classify: {
                                          statusUseClassify: statusBlockNoClassify ? true : false,
                                          classifyData: newClassifyData,
                                        }
                                      }
                                    })
                                    await this.handleDrawNewMapSwipe()
                                  } else {
                                    let tempClassifyData = swipeSelectClassifyCode
                                    tempClassifyData[0].isMain = true
                                    tempClassifyData[0].codelistChild = tempClassifyData[0].codelist.child.find(itemChild => itemChild.id !== 't')
                                    await setSwipeMapData({
                                      swipeMapStat: {
                                        classify: {
                                          statusUseClassify: true,
                                          classifyData: tempClassifyData
                                        }
                                      }
                                    })
                                    await this.handleDrawNewMapSwipe()
                                  }
                                }} />}
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        {/* ลิสของตัวเลือกการจัดจำแนก */}
                        {this.props.swipeMap.swipeMapStat.statType.CLASSIFY !== 0
                          ? <>
                            {this.props.swipeMap.swipeMapStat.classify.classifyData.map((itemCode, index) => {
                              return (
                                <DivTabbarMenuExpandInline key={index}>
                                  <DivTabbarMenuExpandInlineSub
                                    notmain={swipeSelectClassifyCodeMain && swipeSelectClassifyCodeMain.codelist.id !== itemCode.codelist.id}
                                    width='40' textAlign='left'>
                                    <Checkbox
                                      disabled={!this.props.swipeMap.swipeMapStat.classify.statusUseClassify || itemCode.codelist.childHasT === false}
                                      // checked={swipeClassifyStatusNotTArray.find(item => item.codelist.id === itemCode.codelist.id) ? true : false}
                                      checked={itemCode.check}//false
                                      onClick={(event, { value }) => {
                                        console.log('click on checkbox Codelist id swipe >>> ', itemCode.codelist.id)
                                        let oldClassifyData = this.props.swipeMap.swipeMapStat.classify.classifyData
                                        let thisCheckboxCodelist = this.props.swipeMap.swipeMapStat.classify.classifyData.find(item => item.codelist.id === itemCode.codelist.id)
                                        let thisCheckboxCodelistIndex = this.props.swipeMap.swipeMapStat.classify.classifyData.indexOf(thisCheckboxCodelist)
                                        console.log('click on checkbox Codelist swipe >>> index ', thisCheckboxCodelistIndex, ' >>> ', index, ' >>> ', thisCheckboxCodelist)
                                        if (this.props.swipeMap.swipeMapStat.classify.statusUseClassify) {

                                          if (thisCheckboxCodelist.check) {
                                            console.log('เชคอยู่ >>> ต้องเปลี่ยนไปไม่เชค swipe')
                                            thisCheckboxCodelist.check = false;
                                              thisCheckboxCodelist.isMain = false
                                            // ถ้าเชค > ไม่เชค
                                            // // เชคถ้าตัวนั้นมี t ไหม
                                            // // // ถ้าไม่มี t ให้บล็อค ไม่สามารถเอา ติดถูกออกได้ isMain เอา isMain ตรงข้าม
                                            // // // ถ้ามี t ให้เปลียนกลับเป็น t เอา isMain ออก
                                            // if (thisCheckboxCodelist.codelist.childHasT) {
                                            //   thisCheckboxCodelist.isMain = false
                                            //   thisCheckboxCodelist.codelistChild = thisCheckboxCodelist.codelist.child.find(item => item.id === 't')
                                            // } else {
                                            //   alert('ไม่สามารถ uncheck ได้ เพราะว่า codelist นี้ไม่มี t(รวม) swipe >>>')
                                            //   thisCheckboxCodelist.isMain = !thisCheckboxCodelist.isMain
                                            //   thisCheckboxCodelist.codelistChild = thisCheckboxCodelist.codelist.child[0]
                                            // }
                                          } else {
                                            console.log('ไม่เชค >>> ต้องเปลี่ยนไปเชค swipe')
                                            // ถ้าไม่เชค > เชค
                                            // // ถ้าไม่มี isMain ด้วย ก็จะกลายเป็น isMain
                                            // // ตัวนี้จาก t จะกลายเป็น ไม่ t
                                            thisCheckboxCodelist.check = true;
                                            if (!swipeSelectClassifyCodeMain) { thisCheckboxCodelist.isMain = true }
                                            // thisCheckboxCodelist.codelistChild = thisCheckboxCodelist.codelist.child.find(item => item.id !== 't')
                                          }
                                          oldClassifyData[thisCheckboxCodelistIndex] = thisCheckboxCodelist
                                          setSwipeMapData({ swipeMapStat: { classify: { classifyData: oldClassifyData, } } })
                                        } else {
                                          console.log('ไม่สามารถเลือกได้หาก ยังไม่เลือกใช้ โหมด จัดจำแนก swipe >>>')
                                        }
                                      }} />
                                    <span style={{ paddingLeft: '3px', cursor: 'pointer', fontWeight: `${itemCode.isMain ? 'bold' : ''}` }}
                                      onClick={() => {
                                        let oldClassifyData = this.props.swipeMap.swipeMapStat.classify.classifyData
                                        if (!itemCode.isMain) {
                                          oldClassifyData.map((oldItem, indexoldItem) => { oldClassifyData[indexoldItem].isMain = false; return '' })
                                        }
                                        oldClassifyData[index].isMain = !oldClassifyData[index].isMain
                                        if (oldClassifyData[index].codelistChild.id ==="-"){
                                          oldClassifyData[index].codelistChild = oldClassifyData[index].codelist.child[0]
                                        }
                                        setSwipeMapData({ swipeMapStat: { classify: { classifyData: oldClassifyData, } } })
                                      }}
                                    >{itemCode.codelist.label}</span>
                                  </DivTabbarMenuExpandInlineSub>
                                  <DivTabbarMenuExpandInlineSub width='60'>
                                    <select style={styleSelect} id={itemCode.codelist.id}
                                      disabled={!this.props.swipeMap.swipeMapStat.classify.statusUseClassify}
                                      value={(itemCode.codelist.id && swipeSelectClassifyCode.find(item => item.codelist.id === itemCode.codelist.id))
                                        ? swipeSelectClassifyCode.find(item => item.codelist.id === itemCode.codelist.id).codelistChild.id
                                        : ''}
                                      onChange={(event) => {
                                        let thisSelectChildValue = event.target.value
                                        console.log('click on select Codelist child value swipe >>> ', thisSelectChildValue)
                                        let oldClassifyData = this.props.swipeMap.swipeMapStat.classify.classifyData
                                        let thisSelectCodelist = this.props.swipeMap.swipeMapStat.classify.classifyData.find(item => item.codelist.id === itemCode.codelist.id)
                                        let thisSelectCodelistIndex = this.props.swipeMap.swipeMapStat.classify.classifyData.indexOf(thisSelectCodelist)
                                        console.log('click on select Codelist child swipe >>> index ', thisSelectCodelistIndex, ' >>> ', index, ' >>> ', thisSelectCodelist)
                                        thisSelectCodelist.isMain = thisSelectChildValue==="-"?false:thisSelectCodelist.isMain ? true : swipeSelectClassifyCodeMain ? false : true
                                        thisSelectCodelist.codelistChild = itemCode.codelist.child.find((item) => item.id === thisSelectChildValue)
                                        oldClassifyData[thisSelectCodelistIndex] = thisSelectCodelist
                                        setSwipeMapData({ swipeMapStat: { classify: { classifyData: oldClassifyData, } } })
                                      }} >
                                      {itemCode.codelist.child && itemCode.codelist.child.map((itemSon, i) => {
                                        // return (<option key={i} value={itemSon.id} label={itemSon.label} hidden={itemSon.id === 't' ? true : false}></option>)
                                        return (<option key={i} value={itemSon.id} label={itemSon.label} ></option>)
                                      })}
                                    </select>
                                  </DivTabbarMenuExpandInlineSub>
                                </DivTabbarMenuExpandInline>
                              )
                            })
                            } </>
                          : ''}
                      </DivTabbarMenuExpandGroup>
                    {swipeSelectClassifyCode.length !== 0 && swipeSelectClassifyCode.find(item => item.codelistChild.id === '-')?
                    <DivTabbarMenuExpandGroup>
                      <p>หมายเหตุ : รายการข้อมูลนี้ไม่สามารถติ๊กเลือกการจัดจำแนกทั้งหมดพร้อมกันได้</p>
                    </DivTabbarMenuExpandGroup>:""}
                      <DivTabbarMenuExpandGroup>
                        <Button fluid onClick={() => { this.handleFilterOpen('classifySwipe'); this.handleDrawNewMapSwipe(); }}>สร้างแผนที่ใหม่ตามตัวกรองที่เลือก</Button>
                      </DivTabbarMenuExpandGroup>
                    </>
                  }
                </DivTabbarMenuExpand>
                : ''}
            </DivTabbarMenu>
            {/* เวลา */}
            <DivTabbarMenu style={{ width: '50px' }}>
              <DivTabbarMenuName activeMenu={showSwipeTabbarExpandYear} onClick={() => { this.handleFilterOpen('yearSwipe') }} >
                <FieldTimeOutlined style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.swipeMap.swipeMapStat.statName + " : เวลา"} />
              </DivTabbarMenuName>
              {showSwipeTabbarExpandYear
                ? <DivTabbarMenuExpand float='right'>
                  {(swipeMap.swipeMapStat.statName === '')
                    ? <div className='col'>
                      <Button fluid onClick={() => { this.handleFilterOpen('yearSwipe') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                    </div>
                    : <>
                      <DivTabbarMenuExpandGroup>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='30'>
                            <span>ปี :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='70'>
                            <select style={styleSelect} value={swipeSelectTimeYear.id}
                              onChange={(event) => {
                                let thisSelectYearValue = event.target.value
                                let yearSelectData = swipeTimeYearsFilter.find((item) => item.id === thisSelectYearValue)
                                setSwipeMapData({ swipeMapStat: { time: { year: yearSelectData } } })
                              }}>
                              <option value="" hidden> {swipeTimeYearsFilter && swipeTimeYearsFilter.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกปี...'} </option>
                              {swipeTimeYearsFilter.map((itemYear, i) => {
                                return (<option key={itemYear.id} value={itemYear.id}>{itemYear.label}</option>)
                              })}
                            </select>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='30'>
                            <span>ความถี่ :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='70'>
                            <select style={styleSelect} value={swipeSelectTimePeriodType.id}
                              onChange={(event) => {
                                let thisSelectPeriodTypeValue = event.target.value
                                let periodTypeSelectData = swipeTimePeriodTypesFilter.find((item) => item.id === thisSelectPeriodTypeValue)
                                setSwipeMapData({ swipeMapStat: { time: { periodType: periodTypeSelectData, periodSelect: periodTypeSelectData.child[0] } } })
                              }}>
                              <option value="" hidden> {swipeTimePeriodTypesFilter && swipeTimePeriodTypesFilter.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกระดับความถี่...'} </option>
                              {swipeTimePeriodTypesFilter.map((item, i) => {
                                return (<option key={item.id} value={item.id}>{item.label}</option>)
                              })}
                            </select>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='30'>
                            <span>ช่วงที่ :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='70'>
                            <select style={styleSelect} value={swipeSelectTimePeriodSelect.id}
                              onChange={(event) => {
                                let thisSelectPeriodSelectValue = event.target.value
                                let periodSelectSelectData = swipeSelectTimePeriodType.child.find((item) => item.id === thisSelectPeriodSelectValue)
                                setSwipeMapData({ swipeMapStat: { time: { periodSelect: periodSelectSelectData } } })
                              }}>
                              <option value="" hidden>  {swipeSelectTimePeriodType.child && swipeSelectTimePeriodType.child.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกช่วงของความถี่...'} </option>
                              {swipeSelectTimePeriodType.child.map((item, i) => {
                                return (<option key={item.id} value={item.id}>{item.label}</option>)
                              })}
                            </select>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                      </DivTabbarMenuExpandGroup>
                      <DivTabbarMenuExpandGroup>
                        <Button fluid onClick={() => { this.handleFilterOpen('yearSwipe'); this.handleDrawNewMapSwipe(); }}>สร้างแผนที่ใหม่ตามตัวกรองที่เลือก</Button>
                      </DivTabbarMenuExpandGroup>
                    </>}
                </DivTabbarMenuExpand>
                : ''}
            </DivTabbarMenu>
            {/* พื้นที่ */}
            <DivTabbarMenu style={{ width: '50px' }}>
              <DivTabbarMenuName activeMenu={showSwipeTabbarExpandArea} onClick={() => { this.handleFilterOpen('areaSwipe') }} >
                <GatewayOutlined style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.swipeMap.swipeMapStat.statName + " : พื้นที่"} />
              </DivTabbarMenuName>
              {showSwipeTabbarExpandArea
                ? <DivTabbarMenuExpand float='right'>
                  {(swipeMap.swipeMapStat.statName === '')
                    ? <div className='col'>
                      <Button fluid onClick={() => { this.handleFilterOpen('areaSwipe') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                    </div>
                    : <>
                      <DivTabbarMenuExpandGroup>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='30'>
                            <span>ระดับ :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='70'>
                            <select style={styleSelect} value={swipeSelectArea.id}
                              onChange={(event) => {
                                let thisSelectAreaValue = event.target.value
                                let areaSelectData = swipeAreasFilter.find((item) => item.id === thisSelectAreaValue)
                                setSwipeMapData({ swipeMapStat: { area: areaSelectData } })
                              }}>
                              <option value="" hidden>  {swipeAreasFilter && swipeAreasFilter.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกระดับพื้นที่...'} </option>
                              {swipeAreasFilter.map((itemArea, i) => {
                                return (<option key={itemArea.id} value={itemArea.id}>{itemArea.label}</option>)
                              })}
                            </select>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                      </DivTabbarMenuExpandGroup>
                      <DivTabbarMenuExpandGroup>
                        <Button fluid onClick={() => { this.handleFilterOpen('areaSwipe'); this.handleDrawNewMapSwipe(); }}>สร้างแผนที่ใหม่ตามตัวกรองที่เลือก</Button>
                      </DivTabbarMenuExpandGroup>
                    </>}
                </DivTabbarMenuExpand>
                : ''}
            </DivTabbarMenu>
            {/* หน่วยงานเจ้าของข้อมูล */}
            <DivTabbarMenu style={{ width: '50px' }}>
              <DivTabbarMenuName activeMenu={showSwipeTabbarExpandAgency} onClick={() => { this.handleFilterOpen('agencySwipe') }} >
                <BankOutlined style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.swipeMap.swipeMapStat.statName + " : หน่วยงานเจ้าของข้อมูล"} />
              </DivTabbarMenuName>
              {showSwipeTabbarExpandAgency
                ? <DivTabbarMenuExpand float='right'>
                  {(swipeMap.swipeMapStat.statName === '')
                    ? <div className='col'>
                      <Button fluid onClick={() => { this.handleFilterOpen('agencySwipe') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                    </div>
                    : <>
                      <DivTabbarMenuExpandGroup>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='30'>
                            <span>หน่วยงาน:</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='70'>
                            <select style={styleSelect} value={swipeSelectAgency.id}
                              onChange={(event) => {
                                let thisSelectAgencyValue = event.target.value
                                let agencySelectData = swipeAgencysFilter.find((item) => item.id === thisSelectAgencyValue)
                                setSwipeMapData({ swipeMapStat: { agency: agencySelectData } })
                              }}>
                              <option value="" hidden>  {swipeAgencysFilter && swipeAgencysFilter.length === 0 ? 'ไม่มีรายการให้เลือก' : 'เลือกหน่วยงาน...'} </option>
                              {swipeAgencysFilter.map((itemAgency, i) => {
                                return (<option key={i+itemAgency} value={itemAgency.id}>{itemAgency.label}</option>)
                              })}
                            </select>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                      </DivTabbarMenuExpandGroup>
                      <DivTabbarMenuExpandGroup>
                        <Button fluid onClick={() => { this.handleFilterOpen('agencySwipe'); this.handleDrawNewMapSwipe(); }}>สร้างแผนที่ใหม่ตามตัวกรองที่เลือก</Button>
                      </DivTabbarMenuExpandGroup>
                    </>}
                </DivTabbarMenuExpand>
                : ''}
            </DivTabbarMenu>
            {/* คำอธิบาย */}
            <DivTabbarMenu style={{ width: '50px' }}>
              <DivTabbarMenuName activeMenu={showSwipeTabbarExpandDescription} onClick={() => { this.handleFilterOpen('descriptionSwipe') }} >
                <SolutionOutlined style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.swipeMap.swipeMapStat.statName + " : คำอธิบาย"} />
              </DivTabbarMenuName>
              {showSwipeTabbarExpandDescription
                ? <DivTabbarMenuExpand float='right'>
                  {(swipeMap.swipeMapStat.statName === '')
                    ? <div className='col'>
                      <Button fluid onClick={() => { this.handleFilterOpen('descriptionSwipe') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                    </div>
                    : <>
                      <DivTabbarMenuExpandGroup>
                      <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>ประเภทสถิติ :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.statType ? swipeMap.swipeMapStat.description.statType : '-'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>หน่วยนับ :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.statUnitMultiply !== 'ไม่มีข้อมูล' &&  swipeMap.swipeMapStat.description.statUnitMultiply !== "-" ? swipeMap.swipeMapStat.description.statUnitMultiply: ''}{swipeMap.swipeMapStat.description.statUnit ? swipeMap.swipeMapStat.description.statUnit : ''}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>หน่วยงานเจ้าของข้อมูล :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.agency ? swipeMap.swipeMapStat.description.agency : '-'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>ความถี่ในการจัดเก็บ :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.frequency ? swipeMap.swipeMapStat.description.frequency : '-'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>ช่วงเวลาข้อมูล :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>ปี {swipeMap.swipeMapStat.description.timeValidStart ? swipeMap.swipeMapStat.description.timeValidStart : '-'} ถึง {swipeMap.swipeMapStat.description.timeValidEnd ? swipeMap.swipeMapStat.description.timeValidEnd : '-'} </span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>วิธีการได้มา :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.resourceType ? swipeMap.swipeMapStat.description.resourceType : '-'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>แหล่งที่มา :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.resourceFrom ? swipeMap.swipeMapStat.description.resourceFrom : '-'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>แหล่งข้อมูล :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.resourceLink ? swipeMap.swipeMapStat.description.resourceLink : '-'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>การนำไปใช้ประโยชน์ :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.usage ? swipeMap.swipeMapStat.description.usage : '-'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>คุณภาพข้อมูล :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.quality ? swipeMap.swipeMapStat.description.quality : '-'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>สถิติทางการ :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.officialStatus ? 'เป็น' : 'ไม่เป็น'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>
                        <DivTabbarMenuExpandInline >
                          <DivTabbarMenuExpandInlineSub width='55' textAlign='right'>
                            <span>หมายเหตุ :</span>
                          </DivTabbarMenuExpandInlineSub>
                          <DivTabbarMenuExpandInlineSub width='45' textAlign='left' notmain>
                            <span>{swipeMap.swipeMapStat.description.note ? swipeMap.swipeMapStat.description.note : '-'}</span>
                          </DivTabbarMenuExpandInlineSub>
                        </DivTabbarMenuExpandInline>

                      </DivTabbarMenuExpandGroup>
                      <DivTabbarMenuExpandGroup>
                        <Button fluid onClick={() => { this.handleFilterOpen('descriptionSwipe'); }}>ปิด</Button>
                      </DivTabbarMenuExpandGroup>
                    </>}
                </DivTabbarMenuExpand>
                : ''}
            </DivTabbarMenu>
            {/* องค์ความรู้ */}
            <DivTabbarMenu style={{ width: '50px' }}>
              <DivTabbarMenuName activeMenu={showSwipeTabbarExpandKnowledge} onClick={() => { this.handleFilterOpen('exknowSwipe') }} >
                <ProfileOutlined style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.swipeMap.swipeMapStat.statName + " : องค์ความรู้"} />
              </DivTabbarMenuName>
              {showSwipeTabbarExpandKnowledge
                ? <DivTabbarMenuExpand float='right'>
                  {(swipeMap.swipeMapStat.statName === '')
                    ? <div className='col'>
                      <Button fluid onClick={() => { this.handleFilterOpen('exknowSwipe') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                    </div>
                    : <>
                      <DivTabbarMenuExpandGroup>
                        <DivTabbarMenuExpandInline>
                          <span>ลิงค์เว็บไซต์ที่เข้าถึงบทความหรือสิ่งพิมพ์ที่{/* ใช้ข้อมูลสถิติตามรายการที่เลือกในการนำเสนอข้อมูลเพื่อ */}เป็นองค์ความรู้ในการใช้ประโยชน์จากข้อมูลสถิติ</span>
                        </DivTabbarMenuExpandInline>
                      </DivTabbarMenuExpandGroup>
                      {swipeMap.swipeMapStat.externalKnowledge.length !== 0
                        ?
                        <DivTabbarMenuExpandGroup>
                          <DivTabbarMenuExpandInline >
                            <DivTabbarMenuExpandInlineSub width='10' textAlign='center'>
                              <span><strong>ลำดับ</strong></span>
                            </DivTabbarMenuExpandInlineSub>
                            <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                              <span><strong>หัวข้อ</strong></span>
                            </DivTabbarMenuExpandInlineSub>
                            <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                              <span><strong>เว็บลิงค์</strong></span>
                            </DivTabbarMenuExpandInlineSub>
                          </DivTabbarMenuExpandInline>
                          {/* {testexternalKnowledge.map((item, index) => ( */}
                          {swipeMap.swipeMapStat.externalKnowledge.map((item, index) => (
                            < DivTabbarMenuExpandInline key={index} >
                              <DivTabbarMenuExpandInlineSub width='10' textAlign='center'>
                                <span>{index + 1}</span>
                              </DivTabbarMenuExpandInlineSub>
                              <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                                <span>{item.nameOfKnowledge}</span>
                              </DivTabbarMenuExpandInlineSub>
                              <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                                <a href={item.link} target="_blank" rel="noopener noreferrer">คลิ้ก{/* item.link */}</a>
                              </DivTabbarMenuExpandInlineSub>
                            </DivTabbarMenuExpandInline>
                          ))}
                        </DivTabbarMenuExpandGroup>
                        :
                        <DivTabbarMenuExpandGroup>
                          <DivTabbarMenuExpandInline>
                            <span><strong>ไม่มีรายการลิงค์เว็บไซต์องค์ความรู้</strong></span>
                          </DivTabbarMenuExpandInline>
                        </DivTabbarMenuExpandGroup>}
                      <DivTabbarMenuExpandGroup>
                        <Button fluid onClick={() => { this.handleFilterOpen('exknowSwipe'); }}>ปิด</Button>
                      </DivTabbarMenuExpandGroup>
                    </>}
                </DivTabbarMenuExpand>
                : ''}
            </DivTabbarMenu>
            {/* สารสนเทศ */}
            <DivTabbarMenu style={{ width: '50px' }}>
              <DivTabbarMenuName activeMenu={showSwipeTabbarExpandMedia} onClick={() => { this.handleFilterOpen('exinfoSwipe') }} >
                <FundViewOutlined style={{ fontSize: '18px', paddingTop: '5px', paddingBottom: '5px' }}
                  data-toggle="tooltip" data-placement="bottom" title={this.props.swipeMap.swipeMapStat.statName + " : ระบบสารสนเทศที่เกี่ยวข้อง"} />
              </DivTabbarMenuName>
              {showSwipeTabbarExpandMedia
                ? <DivTabbarMenuExpand float='right'>
                  {(swipeMap.swipeMapStat.statName === '')
                    ? <div className='col'>
                      <Button fluid onClick={() => { this.handleFilterOpen('exinfoSwipe') }}>กรุณาเลือกรายการข้อมูลก่อน</Button>
                    </div>
                    : <>
                      <DivTabbarMenuExpandGroup name='discription'>
                        <DivTabbarMenuExpandInline>
                          <span>ลิงค์เว็บไซต์ที่เข้าถึงระบบสารสนเทศที่เกี่ยวข้องกับรายการข้อมูลสถิติที่เลือก</span>
                        </DivTabbarMenuExpandInline>
                      </DivTabbarMenuExpandGroup>
                      {swipeMap.swipeMapStat.externalInformation.length !== 0
                        ?
                        <DivTabbarMenuExpandGroup name='listOfMedia'>
                          <DivTabbarMenuExpandInline >
                            <DivTabbarMenuExpandInlineSub width='10' textAlign='center'>
                              <span><strong>ลำดับ</strong></span>
                            </DivTabbarMenuExpandInlineSub>
                            <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                              <span><strong>ชื่อระบบ</strong></span>
                            </DivTabbarMenuExpandInlineSub>
                            <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                              <span><strong>เว็บลิงค์</strong></span>
                            </DivTabbarMenuExpandInlineSub>
                          </DivTabbarMenuExpandInline>
                          {/* {testexternalMedia.map((item, index) => ( */}
                          {swipeMap.swipeMapStat.externalInformation.map((item, index) => (
                            <DivTabbarMenuExpandInline key={index}>
                              <DivTabbarMenuExpandInlineSub width='10' textAlign='center'>
                                <span>{index + 1}</span>
                              </DivTabbarMenuExpandInlineSub>
                              <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                                <span>{item.nameOfKnowledge}</span>
                              </DivTabbarMenuExpandInlineSub>
                              <DivTabbarMenuExpandInlineSub width='45' textAlign='center' notmain>
                                <a href={item.link} target="_blank" rel="noopener noreferrer">คลิก{/* item.link */}</a>
                              </DivTabbarMenuExpandInlineSub>
                            </DivTabbarMenuExpandInline>
                          ))}
                        </DivTabbarMenuExpandGroup>
                        :
                        <DivTabbarMenuExpandGroup>
                          <DivTabbarMenuExpandInline>
                            <span><strong>ไม่มีรายการลิงค์เว็บไซต์ระบบสารสนเทศ</strong></span>
                          </DivTabbarMenuExpandInline>
                        </DivTabbarMenuExpandGroup>}
                      <DivTabbarMenuExpandGroup>
                        <Button fluid onClick={() => { this.handleFilterOpen('exinfoSwipe'); }}>ปิด</Button>
                      </DivTabbarMenuExpandGroup>
                    </>}
                </DivTabbarMenuExpand>
                : ''}
            </DivTabbarMenu>

          </DivTabbar>
          : ''
        }
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  loginData: state.loginData,
  menutab: state.menuTab,
  mapView: state.mapView,
  drawType: state.drawType,
  selectedCategory: state.selectedCategory,
  selectedStat: state.selectedStat,
  selectedStatFilter: state.selectedStatFilter,
  swipeMap: state.swipeMap,
  provinceMode: state.provinceMode,
})

const mapDispatchToProps = (dispatch) => ({
  setChangMapView: (data) => dispatch(changMapView(data)),
  setChangDrawType: (data) => dispatch(changDrawType(data)),
  setSelectCategoryData: (data) => dispatch(selectCategoryData(data)),
  setSelectStatData: (data) => dispatch(selectStatData(data)),
  setSelectFilterStatData: (data) => dispatch(selectFilterStatData(data)),
  setSwipeMapData: (data) => dispatch(changSwipeMap(data)),
  setChangeProvinceMode: (data) => dispatch(changeProvinceMode(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TabbarNew);
// export default componentName