const mapViewState = {
  namemap: 'openstreetmap'
}

const mapViewReducer = (state = mapViewState, action) => {
  switch (action.type) {
      case "CHANGE_MAP_VIEW":
          state = {
              namemap: state.namemap = action.payload,
          }
          return state;
      default:
          return state;
  }
}
export default mapViewReducer; 