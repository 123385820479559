import React, { Component } from "react";
// import imgfooter from '../icon/image148.png';

class Footer extends Component {
  render() {
    const style = {
      /* textAlign: 'center',
      position: 'relative',
      left: 0,
      width: '100%',
      color: 'white',
      backgroundColor: '#558ED5',
      wordWrap: 'break-word',
      zIndex: 97,
      margin: '0% 0% -15px',
      display: 'block',
      padding: '5px' */
      "position": "fixed",
      "left": "0",
      "bottom": "0",
      "width": "100%",
      backgroundColor: '#67a6fc',
      "color": "white",
      "textAlign": "center"
    }
    return (
      <div className="footer" style={style}>
        <strong>สงวนลิขสิทธิ์ โดยกองนโยบายและวิชาการสถิติ สำนักงานสถิติแห่งชาติ พ.ศ. 2563</strong>
        <br />
        <a style={{ color: 'white' }} href="https://gis.nso.go.th/portal/?page_id=693">{'นโยบายการรักษาความมั่นคงปลอดภัย '}</a>
        |<a style={{ color: 'white' }} href="https://gis.nso.go.th/portal/?page_id=685">{' นโยบายการคุ้มครองข้อมูลส่วนบุคคล'}</a>

      </div>
    );
  }
}

export default Footer;